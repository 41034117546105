import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusquedaDTO } from '@entities/dtos/busqueda.dto';
import { ReporteContableVentasJurisdiccionView } from '@entities/views/reportes/reporte-contable-ventas-jurisdiccion.view';
import { BaseService } from '@shared/services/base.service';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReporteContableVentasJurisdiccionService extends BaseService<ReporteContableVentasJurisdiccionView> {
    public constructor(http: HttpClient) {
        super('ventas/reporte/contable-ventas-jurisdiccion', http);
    }

    public imprimir(parametros: any, detallado = false): Observable<HttpResponse<Blob>> {
        const busqueda = new BusquedaDTO(parametros);
        return this.http
            .post(`${this.API_URL}/${this.endpointUrl}/imprimir${detallado ? '?detallado=true' : ''}`, busqueda, {
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(map((response) => response));
    }
}
