import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GlobalConfigService {
    public static DEBOUNCE_TIME = 300;
    public static DEBOUNCE_TIME_NONE = 0;

    public static POPOVER_DELAY = 500;

    public static TOOLTIP_DELAY = 250;

    public static TOAST_DELAY = 5000;
    public static TOAST_DELAY_ERROR = 10000;
    public static TOAST_DELAY_SUCCESS = 5000;
    public static TOAST_DELAY_INFO = 10000;
    public static TOAST_DELAY_WARNING = 10000;

    public static MESA_AYUDA_EMAIL = 'kratos@folderit.net';
    public static MESA_AYUDA_ASUNTO = 'Solicitud de Soporte';
}
