import { BaseEntity } from '@entities/base.entity';
import { ComprobanteCompra } from '@entities/comprobante-compra.entity';
import { MotivoCompra } from '@entities/motivo-compra.entity';
import { TipoAlicuotaIVA } from '@entities/tipo-alicuota-iva.entity';
import { UnidadMedida } from '@entities/unidad-medida.entity';
import Big from 'big.js';

export class ComprobanteCompraItem extends BaseEntity {
    private _importeSubtotal: number;
    private _importeNeto: number;
    private _importeIVA: number;
    private _importeTotal: number;

    public descripcion?: string;
    public cantidad?: number;
    public precioUnitario?: number;
    public comprobanteCompra?: ComprobanteCompra;
    public motivoCompra?: MotivoCompra;
    public tipoAlicuotaIVA?: TipoAlicuotaIVA;
    public unidadMedida?: UnidadMedida;
    public comprobanteCompraAsociadoId?: number;

    public constructor(partial: Partial<ComprobanteCompraItem> = {}) {
        super();
        Object.assign(this, partial);
        // Actualizar los importes
        this._importeSubtotal = this.importeSubtotal;
        this._importeNeto = this.importeNeto;
        this._importeIVA = this.importeIVA;
        this._importeTotal = this.importeTotal;
    }

    public get importeSubtotal(): number {
        return Big(this.cantidad ?? 0)
            .times(Big(this.precioUnitario ?? 0))
            .round(2)
            .toNumber();
    }

    public set importeSubtotal(value: number) {
        this._importeSubtotal = value;
    }

    public get importeNeto(): number {
        return Big(this._importeSubtotal).round(2).toNumber();
    }

    public set importeNeto(value: number) {
        this._importeNeto = value;
    }

    public get importeIVA(): number {
        return Big(this.importeNeto)
            .times(Big(this.tipoAlicuotaIVA?.porcentaje ?? 0))
            .div(100)
            .round(2)
            .toNumber();
    }

    public set importeIVA(value: number) {
        this._importeIVA = value;
    }

    public get importeTotal(): number {
        return Big(this._importeNeto).add(Big(this._importeIVA)).round(2).toNumber();
    }

    public set importeTotal(value: number) {
        this._importeTotal = value;
    }

    public recalcularImportes(): void {
        this._importeSubtotal = this.importeSubtotal;
        this._importeNeto = this.importeNeto;
        this._importeIVA = this.importeIVA;
        this._importeTotal = this.importeTotal;
    }
}
