import { BaseEntity } from '@entities/base.entity';
import { CentroCosto } from '@entities/centro-costo.entity';
import { ComprobanteCompraItem } from '@entities/comprobante-compra-item.entity';
import { ComprobanteCompraMedioPago } from '@entities/comprobante-compra-medio-pago.entity';
import { ComprobanteCompraTributo } from '@entities/comprobante-compra-tributo.entity';
import { Comprobante } from '@entities/comprobante.entity';
import { Empresa } from '@entities/empresa.entity';
import { Moneda } from '@entities/moneda.entity';
import { Proveedor } from '@entities/proveedor.entity';
import { TipoActividad } from '@entities/tipo-actividad.entity';

export class ComprobanteCompra extends BaseEntity {
    public comprobante?: Comprobante;
    public empresa?: Empresa;
    public proveedor?: Proveedor;
    public puntoVenta?: number;
    public centroCosto?: CentroCosto;
    public tipoActividad?: TipoActividad;
    public moneda?: Moneda;
    public items?: ComprobanteCompraItem[];
    public mediosPago?: ComprobanteCompraMedioPago[];
    public tributos?: ComprobanteCompraTributo[];
    public comprobantesAsociados?: ComprobanteCompra[];
    public cotizacion?: number;
    public importeTotal?: number;
}
