import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreComponent } from '@core/components/core/core.component';

const routes: Routes = [
    {
        path: '',
        title: 'Kratos',
        component: CoreComponent,
        children: [
            {
                path: 'administracion',
                loadChildren: () =>
                    import('../administracion/administracion.module').then((m) => m.AdministracionModule),
            },
            {
                path: 'configuracion',
                loadChildren: () => import('../configuracion/configuracion.module').then((m) => m.ConfiguracionModule),
            },
            {
                path: 'ventas',
                loadChildren: () => import('../ventas/ventas.module').then((m) => m.VentasModule),
            },
            {
                path: 'compras',
                loadChildren: () => import('../compras/compras.module').then((m) => m.ComprasModule),
            },
            {
                path: 'migracion',
                loadChildren: () => import('../migracion/migracion.module').then((m) => m.MigracionModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CoreRoutingModule {}
