import { NgModule } from '@angular/core';
import { CentroCostoComponent } from '@compras/components/centro-costo.component';
import { ComprobanteCompraComprobanteAsociadoComponent } from '@compras/components/comprobante-compra-comprobante-asociado.component';
import { ComprobanteCompraItemComponent } from '@compras/components/comprobante-compra-item.component';
import { ComprobanteCompraMedioPagoComponent } from '@compras/components/comprobante-compra-medio-pago.component';
import { ComprobanteCompraTributoComponent } from '@compras/components/comprobante-compra-tributo.component';
import { ComprobanteCompraComponent } from '@compras/components/comprobante-compra.component';
import { MotivoCompraComponent } from '@compras/components/motivo-compra.component';
import { ProveedorComponent } from '@compras/components/proveedor.component';
import { ComprasRoutingModule } from '@compras/compras.routing.module';
import { ReporteContableComprasMotivosComponent } from '@compras/reportes/components/reporte-contable-compras-motivos.component';
import { ReporteContableComprasComponent } from '@compras/reportes/components/reporte-contable-compras.component';
import { ReporteLibroIVAComprasComponent } from '@compras/reportes/components/reporte-libro-iva-compras.component';
import { ReportePagosPendientesComponent } from '@compras/reportes/components/reporte-pagos-pendientes.component';
import { SharedModule } from '@shared/shared.module';
import { VentasModule } from '@ventas/ventas.module';

@NgModule({
    declarations: [
        ComprobanteCompraComponent,
        ComprobanteCompraItemComponent,
        ComprobanteCompraMedioPagoComponent,
        ComprobanteCompraTributoComponent,
        ComprobanteCompraComprobanteAsociadoComponent,
        ProveedorComponent,
        CentroCostoComponent,
        MotivoCompraComponent,
        ReportePagosPendientesComponent,
        ReporteLibroIVAComprasComponent,
        ReporteContableComprasComponent,
        ReporteContableComprasMotivosComponent,
    ],
    imports: [SharedModule, ComprasRoutingModule, VentasModule],
})
export class ComprasModule {}
