import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ReporteContableComprasMotivosService } from '@compras/reportes/services/reporte-contable-compras-motivos.service';
import { ReporteContableComprasMotivosView } from '@entities/views/reportes/reporte-contable-compras-motivos.view';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ReporteComponent } from '@shared/directives/abmc/reporte.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { Observable, finalize, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-reporte-contable-compras-motivos',
    templateUrl: './reporte-contable-compras-motivos.component.html',
    styleUrls: ['./reporte-contable-compras-motivos.component.scss'],
})
export class ReporteContableComprasMotivosComponent
    extends ReporteComponent<ReporteContableComprasMotivosView>
    implements OnInit
{
    @ViewChild('tabla') public tabla!: TablaComponent;

    public titulo = 'Reporte Contable: Motivos de Compras';

    public columnas: ColumnaTabla[] = [
        {
            title: 'Empresa',
            data: 'empresaNombre',
            searchable: true,
        },
        {
            title: 'Proveedor',
            data: 'proveedorRazonSocial',
            searchable: true,
        },
        {
            title: 'Tipo',
            data: 'tipoComprobanteNombre',
            searchable: true,
        },
        {
            title: 'Fecha',
            data: 'fecha',
            tipo: 'date',
            searchable: true,
        },
        {
            title: 'Pto. Vta.',
            data: 'puntoVentaNumero',
            searchable: true,
        },
        {
            title: 'Número',
            data: 'numero',
            searchable: true,
        },
        {
            title: 'Motivo',
            data: 'motivoCompraNombre',
            searchable: true,
        },
        {
            title: 'Importe Motivo',
            data: 'importeTotalMotivo',
            tipo: 'moneda',
            searchable: true,
        },
    ];

    public constructor(
        protected reporteContableComprasMotivosService: ReporteContableComprasMotivosService,
        protected empresaService: EmpresaService,
    ) {
        super(reporteContableComprasMotivosService, {
            inicializaciones: {
                botones: [
                    {
                        name: 'imprimirReporteContable',
                        text: 'Imprimir Reporte Contable',
                    },
                ],
            },
        });
    }

    public override ajaxHandler(event: any) {
        super.ajaxHandler(event, this.reporteContableComprasMotivosService);
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
        });
    }

    protected override cargarBusquedaAvanzada(): void {
        this.tablaOpciones.order = [[3, 'asc']];
        this.tablaOpciones.searchBuilder = {
            columns: ['Empresa', 'Fecha'],
            preDefined: {
                criteria: [
                    {
                        data: 'Empresa',
                        origData: 'empresaNombre',
                        condition: '=',
                        value: [] as any[],
                    },
                    {
                        data: 'Fecha',
                        origData: 'fecha',
                        condition: 'between',
                        value: [] as any[],
                    },
                ],
            },
        };
        this.tablaOpciones.searchBuilder.preDefined.criteria.forEach((criteria: any) => {
            if (criteria.data === 'Empresa') {
                // Si existe, tomar el valor de la empresa del perfil del usuario
                try {
                    criteria.value = [
                        JSON.parse(localStorage.getItem('empresa') ?? '{}')?.nombre ??
                            this.relaciones.empresas[0]?.nombre ??
                            '',
                    ];
                } catch (error) {
                    criteria.value = [this.relaciones.empresas[0]?.nombre ?? ''];
                }
            }
            if (criteria.data === 'Fecha') {
                // Establecer el rango de fechas entre el primer y último día del mes anterior
                // Utilizar formato de fecha 'yyyy-mm-dd'
                const fecha = new Date();
                const primerDia = new Date(fecha.getFullYear(), fecha.getMonth() - 1, 1);
                const ultimoDia = new Date(fecha.getFullYear(), fecha.getMonth(), 0);
                criteria.value = [primerDia.toISOString().split('T')[0], ultimoDia.toISOString().split('T')[0]];
            }
        });
    }

    private async imprimirReporteContable() {
        this.tabla.recargar();
        // Deshabilitar botones para evitar múltiples clicks
        await this.tabla.deshabilitarBotones();
        const busquedaAvanzada = await this.tabla.obtenerBusquedaAvanzada();
        this.subscription.add(
            this.reporteContableComprasMotivosService
                .imprimir({
                    searchBuilder: busquedaAvanzada,
                })
                .pipe(finalize(() => this.tabla.habilitarBotones()))
                .subscribe({
                    next: (respuesta) => {
                        const contentDisposition = respuesta.headers.get('Content-Disposition');
                        const nombreArchivo =
                            contentDisposition?.split('filename=')[1].replace(/"/g, '') ?? 'reporte.pdf';
                        const blob = new File([respuesta.body ?? ''], nombreArchivo, { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const pdfWindow = window.open(url, '_blank');
                        if (pdfWindow) {
                            pdfWindow.addEventListener('load', () => {
                                setTimeout(() => {
                                    pdfWindow.document.title = nombreArchivo;
                                    pdfWindow.name = nombreArchivo;
                                }, 1);
                            });
                        }
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }
}
