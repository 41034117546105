import { ParametroService } from '@administracion/services/parametro.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ComprobanteVentaItem } from '@entities/comprobante-venta-item.entity';
import { ComprobanteVenta } from '@entities/comprobante-venta.entity';
import { Moneda } from '@entities/moneda.entity';
import { Parametro } from '@entities/parametro.entity';
import { TipoAlicuotaIVA } from '@entities/tipo-alicuota-iva.entity';
import { UnidadMedida } from '@entities/unidad-medida.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCVolatilComponent } from '@shared/directives/abmc/abmc-volatil.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { ProductoService } from '@ventas/services/producto.service';
import { TipoAlicuotaIVAService } from '@ventas/services/tipo-alicuota-iva.service';
import { UnidadMedidaService } from '@ventas/services/unidad-medida.service';
import Big from 'big.js';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-comprobante-venta-item',
    templateUrl: './comprobante-venta-item.component.html',
    styleUrls: ['./comprobante-venta-item.component.scss'],
})
export class ComprobanteVentaItemComponent extends ABMCVolatilComponent<ComprobanteVentaItem> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;
    @ViewChild('formCargaRapidaElement') public formCargaRapidaElement!: ElementRef<any>;

    public facturacionSimple = false;
    public facturacionInvoice = false;
    public mascaraImporte = 'separator.2';
    public unidadMedidaAbreviatura = '';
    public nombre = 'Item';
    public titulo = 'Items';
    public tablaOpciones = {
        botones: {
            size: 'sm',
        },
    };
    public moneda: Moneda = { simbolo: '$', cotizacion: +1.0 };
    public parametroUnidadMedidaDefecto!: Parametro;
    public cargando = {
        tipoAlicuotaIVA: false,
    };

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        descripcion: ['', Validators.required],
        cantidad: ['', Validators.required],
        precioUnitario: ['', Validators.required],
        importeBonificado: ['', Validators.required],
        codigoNCM: [''],
        producto: [''],
        tipoAlicuotaIVA: ['', Validators.required],
        unidadMedida: ['', Validators.required],
        periodo: [''],
    });

    public formCargaRapida = this.formBuilder.group({
        descripcion: ['', Validators.required],
        tipoAlicuotaIVA: [null, Validators.required],
        periodo: [null],
        cantidad: ['', Validators.required],
        precioUnitario: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'Producto',
            data: 'producto.nombre',
            searchable: true,
            defaultContent: '',
        },
        {
            title: 'Descripción',
            data: 'descripcion',
            searchable: true,
        },
        {
            title: 'Cantidad',
            data: 'cantidad',
            tipo: 'number',
            searchable: true,
            render: (data: any, type: any, row: any, meta: any) => {
                return `${data.toLocaleString('es-ES', { minimumFractionDigits: 2 })}${
                    row.unidadMedida?.abreviatura ? ` ${row.unidadMedida.abreviatura}` : ''
                }`;
            },
            width: '5rem',
        },
        {
            title: 'Precio Unitario',
            data: 'precioUnitario',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Alícuota IVA',
            data: 'tipoAlicuotaIVA.nombre',
            searchable: true,
            width: '7rem',
        },
        {
            title: 'Importe IVA',
            data: 'importeIVA',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Importe Bonificado',
            data: 'importeBonificado',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Importe Total',
            data: 'importeTotal',
            tipo: 'moneda',
            searchable: true,
        },
    ];

    public constructor(
        protected productoService: ProductoService,
        protected tipoAlicuotaIVAService: TipoAlicuotaIVAService,
        protected unidadMedidaService: UnidadMedidaService,
        protected parametroService: ParametroService,
        private formBuilder: FormBuilder,
    ) {
        super({
            inicializaciones: {
                nombre: 'Item',
                anchoModal: 'lg',
                acciones: [
                    {
                        nombre: 'modificacion',
                        icono: 'pencil-fill',
                        tooltip: `Modificar`,
                        deshabilitado: (elemento: ComprobanteVentaItem) =>
                            !elemento || (elemento && elemento.baja != null) || elemento.comprobanteVentaAsociadoId,
                    },
                    {
                        nombre: 'baja',
                        icono: 'x-lg',
                        color: 'warning',
                        tooltip: `Eliminar`,
                        deshabilitado: (elemento: ComprobanteVentaItem) =>
                            !elemento || (elemento && elemento.baja != null) || elemento.comprobanteVentaAsociadoId,
                    },
                ],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    consulta: true,
                },
            },
        });
        this.facturacionSimple = localStorage.getItem('facturacionSimple') === 'true';
        this.facturacionInvoice = localStorage.getItem('facturacionInvoice') === 'true';
        this.form.get('unidadMedida')?.valueChanges.subscribe(this.cambiarUnidadMedida.bind(this));

        // Establecer columnas para facturación simple
        if (this.facturacionSimple) {
            this.columnas = this.columnas.filter((columna) => {
                return !['producto.nombre', 'tipoAlicuotaIVA.nombre', 'importeBonificado'].includes(columna.data);
            });
        }
        // Establecer columnas para facturación de invoices
        if (this.facturacionInvoice) {
            this.columnas = this.columnas.filter((columna) => {
                return !['producto.nombre', 'tipoAlicuotaIVA.nombre', 'importeBonificado', 'importeIVA'].includes(
                    columna.data,
                );
            });
        }

        // Precargar relaciones
        this.subscription.add(
            forkJoin({
                relaciones: this.obtenerRelaciones(),
            }).subscribe({
                next: ({ relaciones }) => {
                    this.cargarRelaciones(relaciones);
                },
                error: this.toastService.errorHandler.bind(this.toastService),
            }),
        );
    }

    public cambiarProducto(producto?: any): void {
        this.form.get('producto')?.setValue(producto ?? null);
        if (producto && producto.unidadMedida) {
            this.form.get('unidadMedida')?.setValue(producto.unidadMedida ?? null);
        }
        if (producto?.descripcion && !this.form.get('descripcion')?.value) {
            this.form.get('descripcion')?.setValue(producto.descripcion);
        }
        if (producto?.tipoAlicuotaIVA && !this.form.get('tipoAlicuotaIVA')?.value) {
            this.form.get('tipoAlicuotaIVA')?.setValue(producto.tipoAlicuotaIVA ?? null);
        }
        if (producto?.codigoNCM) {
            this.form.get('codigoNCM')?.setValue(producto.codigoNCM);
        }
    }

    public cambiarUnidadMedida(unidadMedida: any): void {
        this.unidadMedidaAbreviatura = unidadMedida?.abreviatura ?? '';
    }

    public buscarUnidadMedida = (term: string, item: UnidadMedida) => {
        term = term.toLocaleLowerCase();
        return (
            (item?.nombre?.toLocaleLowerCase().indexOf(term) ?? -1) > -1 ||
            (item?.abreviatura?.toLocaleLowerCase().indexOf(term) ?? -1) > -1
        );
    };

    public toFixedImporte = (value: string | number | undefined | null): number => {
        return this.toFixed(value, 2);
    };

    public toFixed(value: string | number | undefined | null, decimales = 2): number {
        const formattedValue = String(value).split(' ').join('');
        if (String(value).includes('.') && String(value).split('.').length === 2) {
            const decimal = String(value).split('.')[1]?.length;
            if (decimal && decimal > decimales) {
                return Number(parseFloat(formattedValue).toFixed(decimales));
            }
        }
        return Number(formattedValue);
    }

    public saldarComprobante(comprobanteVenta: ComprobanteVenta): void {
        // Si ya se han cargado las relaciones, solo agregar el item de saldo
        if (this.relaciones && Object.keys(this.relaciones).length) {
            this.agregarItemSaldo(comprobanteVenta);
            return;
        }
        this.subscription.add(
            forkJoin({
                relaciones: this.obtenerRelaciones(),
            }).subscribe({
                next: ({ relaciones }) => {
                    this.cargarRelaciones(relaciones);
                    this.agregarItemSaldo(comprobanteVenta);
                },
                error: this.toastService.errorHandler.bind(this.toastService),
            }),
        );
    }

    public agregarItemCargaRapida(): void {
        this.formValidar(this.formCargaRapida, this.formCargaRapidaElement);
        if (!this.formCargaRapida.valid) {
            return;
        }
        const item = new ComprobanteVentaItem({ ...this.formCargaRapida.value } as ComprobanteVentaItem);
        item.unidadMedida = this.relaciones.unidadesMedida.find(
            (unidadMedida: UnidadMedida) => unidadMedida.codigoAFIP === this.parametroUnidadMedidaDefecto?.valor,
        );
        item.importeBonificado = 0;
        item.producto = { nombre: '' };
        item.precioUnitario = this.convertirImporte(item.precioUnitario ?? 0, this.moneda.cotizacion);
        item.recalcularImportes();
        this.subscription.add(
            this.baseService.crear(item).subscribe({
                next: (resp: any) => {
                    setTimeout(() => {
                        this.postAlta(resp);
                    });
                },
                error: this.toastService.errorHandler.bind(this.toastService),
            }),
        );
    }

    public obtenerImporteTotal(): number {
        return this.elementos.reduce((total: number, item: ComprobanteVentaItem) => total + item.importeTotal, 0) ?? 0;
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            productos: this.productoService.obtenerTodos(),
            tiposAlicuotaIVA: this.tipoAlicuotaIVAService.obtenerTodos(),
            unidadesMedida: this.unidadMedidaService.obtenerTodos(),
        });
    }

    protected override crearElemento(): ComprobanteVentaItem {
        const elemento = new ComprobanteVentaItem(super.crearElemento());
        // Convertir importes del elemento a la moneda oficial, según la cotización del comprobante
        elemento.precioUnitario = this.convertirImporte(elemento.precioUnitario ?? 0, this.moneda.cotizacion);
        elemento.importeBonificado = this.convertirImporte(elemento.importeBonificado ?? 0, this.moneda.cotizacion);
        elemento.recalcularImportes();
        return elemento;
    }

    protected override inicializarElemento(): void {
        super.inicializarElemento();
        // Si está habilitada la facturación simple, precargar valores por defecto
        if (this.facturacionSimple) {
            this.form
                .get('unidadMedida')
                ?.setValue(
                    this.relaciones.unidadesMedida.find(
                        (unidadMedida: UnidadMedida) =>
                            unidadMedida.codigoAFIP === this.parametroUnidadMedidaDefecto?.valor,
                    ) ??
                        this.relaciones.unidadesMedida[0] ??
                        null,
                );
        }
        // Si está habilitada la facturación de invoices, precargar valores por defecto
        if (this.facturacionInvoice) {
            this.form
                .get('unidadMedida')
                ?.setValue(
                    this.relaciones.unidadesMedida.find(
                        (unidadMedida: UnidadMedida) =>
                            unidadMedida.codigoAFIP === this.parametroUnidadMedidaDefecto?.valor,
                    ) ??
                        this.relaciones.unidadesMedida[0] ??
                        null,
                );
            this.form
                .get('tipoAlicuotaIVA')
                ?.setValue(
                    this.relaciones.tiposAlicuotaIVA.find(
                        (tipoAlicuotaIVA: TipoAlicuotaIVA) => tipoAlicuotaIVA.porcentaje === 0,
                    ) ?? this.relaciones.tiposAlicuotaIVA[0],
                );
        }
        this.form.get('importeBonificado')?.setValue('0');
    }

    protected override cargarElemento(elemento: ComprobanteVentaItem): void {
        // Convertir importes de moneda oficial a moneda seleccionada por el usuario, según la cotización del comprobante
        const item = new ComprobanteVentaItem(elemento);
        const cotizacion = 1 / (this.moneda.cotizacion ?? 1);
        item.precioUnitario = this.convertirImporte(elemento.precioUnitario ?? 0, cotizacion);
        item.importeBonificado = this.convertirImporte(elemento.importeBonificado ?? 0, cotizacion);
        super.cargarElemento(item);
    }

    private convertirImporte(importe: number, cotizacion = +1.0): number {
        return Big(importe).times(Big(cotizacion)).round(2).toNumber();
    }

    private agregarItemSaldo(comprobanteVenta: ComprobanteVenta): void {
        // Agrupar items del comprobante por alícuota de IVA, sumar importes, y crear un item de saldo por cada grupo
        const items: ComprobanteVentaItem[] = [];
        (comprobanteVenta.items ?? []).forEach((comprobanteVentaItem) => {
            if (!comprobanteVentaItem.tipoAlicuotaIVA) {
                return;
            }
            const item = items.find((item) => item.tipoAlicuotaIVA?.id === comprobanteVentaItem.tipoAlicuotaIVA?.id);
            if (item) {
                item.precioUnitario = Big(item.precioUnitario ?? 0)
                    .plus(comprobanteVentaItem.importeNeto)
                    .toNumber();
                item.recalcularImportes();
            } else {
                items.push(this.crearElementoSaldo(comprobanteVenta, comprobanteVentaItem));
            }
        });
        // Agregar items de saldo al comprobante
        items.forEach((item) => {
            this.subscription.add(
                this.baseService.crear(item).subscribe({
                    next: (resp: any) => {
                        setTimeout(() => {
                            this.postAlta(resp);
                        });
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
            );
        });
    }

    private crearElementoSaldo(
        comprobanteVenta: ComprobanteVenta,
        comprobanteVentaItem: ComprobanteVentaItem,
    ): ComprobanteVentaItem {
        return new ComprobanteVentaItem({
            producto: { nombre: '' },
            cantidad: 1,
            precioUnitario: comprobanteVentaItem.importeNeto,
            importeBonificado: 0,
            descripcion: `Saldo del comprobante ${comprobanteVenta?.comprobante?.tipoComprobante?.nombre} ${
                String(comprobanteVenta?.puntoVenta?.numero).padStart(4, '0') ?? '0000'
            }-${String(comprobanteVenta?.comprobante?.numero).padStart(8, '0') ?? '00000000'} IVA ${comprobanteVentaItem
                .tipoAlicuotaIVA?.nombre}`,
            tipoAlicuotaIVA: comprobanteVentaItem.tipoAlicuotaIVA,
            unidadMedida:
                this.relaciones.unidadesMedida.find(
                    (unidadMedida: UnidadMedida) =>
                        unidadMedida.codigoAFIP === this.parametroUnidadMedidaDefecto?.valor,
                ) ||
                this.relaciones.unidadesMedida[0] ||
                undefined,
            comprobanteVentaAsociadoId: comprobanteVenta?.id,
        });
    }
}
