import { BaseEntity } from '@entities/base.entity';
import { ComprobanteTransaccion } from '@entities/comprobante-transaccion.entity';
import { TipoComprobante } from '@entities/tipo-comprobante.entity';
import { TipoDocumento } from '@entities/tipo-documento.entity';

export class Comprobante extends BaseEntity {
    public numero?: number;
    public fecha?: DateTimeString;
    public numeroDocumento?: string;
    public tipoComprobante?: TipoComprobante;
    public tipoDocumento?: TipoDocumento;
    public transacciones?: ComprobanteTransaccion[];
}
