import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ComprobanteVentaDatoOpcional } from '@entities/comprobante-venta-dato-opcional.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCVolatilComponent } from '@shared/directives/abmc/abmc-volatil.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { TipoDatoOpcionalService } from '@ventas/services/tipo-dato-opcional.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-comprobante-venta-dato-opcional',
    templateUrl: './comprobante-venta-dato-opcional.component.html',
    styleUrls: ['./comprobante-venta-dato-opcional.component.scss'],
})
export class ComprobanteVentaDatoOpcionalComponent extends ABMCVolatilComponent<ComprobanteVentaDatoOpcional> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Dato Opcional';
    public titulo = 'Datos Opcionales';
    public tablaOpciones = {
        botones: {
            size: 'sm',
        },
    };

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        valor: ['', Validators.required],
        tipoDatoOpcional: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'Tipo',
            data: 'tipoDatoOpcional.nombre',
            searchable: true,
        },
        {
            title: 'Valor',
            data: 'valor',
            searchable: true,
        },
    ];

    public constructor(
        protected tipoDatoOpcionalService: TipoDatoOpcionalService,
        private formBuilder: FormBuilder,
    ) {
        super({
            inicializaciones: {
                nombre: 'Dato Opcional',
                anchoModal: 'lg',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            tiposDatoOpcional: this.tipoDatoOpcionalService.obtenerTodos(),
        });
    }
}
