<div class="modal-header bg-secondary-subtle" [ngClass]="{ 'mt-3': advertencia === true }">
    <div
        *ngIf="advertencia === true"
        style="height: 1rem; width: 100%"
        class="position-absolute top-0 start-0 seccion-advertencia"
    ></div>
    <h4 class="modal-title">{{ titulo }}</h4>
    <button type="button" class="btn-close" (click)="activeModal.dismiss('cross')"></button>
</div>
<div class="modal-body">
    <p [innerHTML]="mensaje"></p>
</div>
<div class="modal-footer bg-secondary-subtle" [ngClass]="{ 'mb-3': advertencia === true }">
    <button type="button" class="btn btn-primary" (click)="aceptar()" [disabled]="cargando">
        <span class="spinner-border spinner-border-sm mx-3" *ngIf="cargando"></span>
        <span *ngIf="!cargando">Aceptar</span>
    </button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('cerrar')">Cerrar</button>
    <div
        *ngIf="advertencia === true"
        style="height: 1rem; width: 100%; margin: 0"
        class="position-absolute bottom-0 start-0 seccion-advertencia"
    ></div>
</div>
