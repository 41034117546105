import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';

@Component({
    selector: 'kratos-theme-switcher',
    templateUrl: './theme-switcher.component.html',
    styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent implements OnInit {
    dropdownToggleModeIcon: string;

    constructor(private themeService: ThemeService) {
        this.dropdownToggleModeIcon = this.getThemeModeIcon();
    }

    ngOnInit(): void {}

    public switchThemeMode(theme: string = 'auto'): void {
        this.themeService.currentMode = theme;
        this.dropdownToggleModeIcon = this.getThemeModeIcon();
    }

    public switchThemeSize(size: string = 'false'): void {
        this.themeService.currentSize = size;
    }

    public switchThemeDebug(): void {
        if (this.themeService.currentDebug === 'false') {
            this.themeService.currentDebug = 'true';
        } else if (this.themeService.currentDebug === 'true') {
            this.themeService.currentDebug = 'false';
        }
        // Recargar sitio
        window.location.reload();
    }

    public getThemeMode(): string {
        return this.themeService.currentMode;
    }

    public getThemeSize(): string {
        return this.themeService.currentSize;
    }

    public getThemeDebug(): string {
        return this.themeService.currentDebug;
    }

    private getThemeModeIcon(): string {
        switch (this.themeService.currentMode) {
            case 'light':
                return 'bi-sun-fill';
            case 'dark':
                return 'bi-moon-stars-fill';
            case 'auto':
                return 'bi-circle-half';
            default:
                return 'bi-circle-half';
        }
    }
}
