import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipoTributo } from '@entities/tipo-tributo.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class TipoTributoService extends BaseService<TipoTributo> {
    public constructor(http: HttpClient) {
        super('ventas/tipo-tributo', http);
    }
}
