export enum TipoComprobanteLetraEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    E = 'E',
    M = 'M',
    R = 'R',
    X = 'X',
}

export const tipoComprobanteLetraIdPorKeyValue = (tipoComprobanteLetra: any) => {
    let id = null;
    switch (tipoComprobanteLetra) {
        case 0:
        case 'A':
            id = 0;
            break;
        case 1:
        case 'B':
            id = 1;
            break;
        case 2:
        case 'C':
            id = 2;
            break;
        case 3:
        case 'E':
            id = 3;
            break;
        case 4:
        case 'M':
            id = 4;
            break;
        case 5:
        case 'R':
            id = 5;
            break;
        case 6:
        case 'X':
            id = 6;
            break;
    }
    return id;
};

export const obtenerTipoComprobanteLetras = () => [
    { id: 0, clave: 'A', nombre: 'A' },
    { id: 1, clave: 'B', nombre: 'B' },
    { id: 2, clave: 'C', nombre: 'C' },
    { id: 3, clave: 'E', nombre: 'E' },
    { id: 4, clave: 'M', nombre: 'M' },
    { id: 5, clave: 'R', nombre: 'R' },
    { id: 6, clave: 'X', nombre: 'X' },
];
