<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="fecha" class="form-label">Fecha</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Fecha"
                        ngbPopover="Fecha de la transacción."
                    ></i>
                    <div class="input-group">
                        <input
                            placeholder="dd/mm/aaaa"
                            formControlName="fecha"
                            class="form-control"
                            id="fecha"
                            ngbDatepicker
                            #fecha="ngbDatepicker"
                        />
                        <button
                            *ngIf="form.get('fecha')?.value && !form?.get('fecha')?.disabled"
                            class="btn btn-sm btn-outline-secondary bi bi-x-lg"
                            (click)="form.get('fecha')?.setValue(null)"
                            type="button"
                        ></button>
                        <button
                            *ngIf="!form.get('fecha')?.disabled"
                            class="btn btn-outline-secondary bi bi-calendar3"
                            (click)="fecha.toggle()"
                            type="button"
                        ></button>
                        <ng-container *ngFor="let error of formErrores('fecha', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="col">
                    <label for="fechaVencimiento" class="form-label">Fecha Vencimiento</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Fecha Vencimiento"
                        ngbPopover="Fecha de vencimiento de la transacción."
                    ></i>
                    <div class="input-group">
                        <input
                            placeholder="dd/mm/aaaa"
                            formControlName="fechaVencimiento"
                            class="form-control"
                            id="fechaVencimiento"
                            ngbDatepicker
                            #fechaVencimiento="ngbDatepicker"
                        />
                        <button
                            *ngIf="form.get('fechaVencimiento')?.value && !form?.get('fechaVencimiento')?.disabled"
                            class="btn btn-sm btn-outline-secondary bi bi-x-lg"
                            (click)="form.get('fechaVencimiento')?.setValue(null)"
                            type="button"
                        ></button>
                        <button
                            *ngIf="!form.get('fechaVencimiento')?.disabled"
                            class="btn btn-outline-secondary bi bi-calendar3"
                            (click)="fechaVencimiento.toggle()"
                            type="button"
                        ></button>
                        <ng-container *ngFor="let error of formErrores('fechaVencimiento', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="resultado" class="form-label">Resultado</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Resultado"
                        ngbPopover="Resultado de la transacción."
                    ></i>
                    <input
                        placeholder="Resultado"
                        formControlName="resultado"
                        type="text"
                        class="form-control"
                        id="resultado"
                    />
                    <ng-container *ngFor="let error of formErrores('resultado', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="codigoAutorizacion" class="form-label">Código Autorización</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Código Autorización"
                        ngbPopover="Código de autorización de la transacción."
                    ></i>
                    <input
                        placeholder="Código Autorización"
                        formControlName="codigoAutorizacion"
                        type="text"
                        class="form-control"
                        id="codigoAutorizacion"
                    />
                    <ng-container *ngFor="let error of formErrores('codigoAutorizacion', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="observaciones" class="form-label">Observaciones</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Observaciones"
                        ngbPopover="Observaciones de la transacción."
                    ></i>
                    <textarea
                        placeholder="Observaciones"
                        formControlName="observaciones"
                        rows="3"
                        type="text"
                        class="form-control"
                        id="valor"
                    ></textarea>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="codigoError" class="form-label">Código de Error</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Código de Error"
                        ngbPopover="Código de error de la transacción."
                    ></i>
                    <input
                        placeholder="Código de Error"
                        formControlName="codigoError"
                        type="text"
                        class="form-control"
                        id="codigoError"
                    />
                    <ng-container *ngFor="let error of formErrores('codigoError', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="mensajeError" class="form-label">Mensaje de Error</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Mensaje de Error"
                        ngbPopover="Mensaje de error de la transacción."
                    ></i>
                    <textarea
                        placeholder="Mensaje de Error"
                        formControlName="mensajeError"
                        rows="3"
                        type="text"
                        class="form-control"
                        id="valor"
                    ></textarea>
                </div>
            </div>
        </div>
    </form>
</ng-template>
