import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth/auth.guard';
import { CentroCostoComponent } from '@compras/components/centro-costo.component';
import { ComprobanteCompraComponent } from '@compras/components/comprobante-compra.component';
import { MotivoCompraComponent } from '@compras/components/motivo-compra.component';
import { ProveedorComponent } from '@compras/components/proveedor.component';
import { ReporteContableComprasMotivosComponent } from '@compras/reportes/components/reporte-contable-compras-motivos.component';
import { ReporteContableComprasComponent } from '@compras/reportes/components/reporte-contable-compras.component';
import { ReporteLibroIVAComprasComponent } from '@compras/reportes/components/reporte-libro-iva-compras.component';
import { ReportePagosPendientesComponent } from '@compras/reportes/components/reporte-pagos-pendientes.component';

const routes: Routes = [
    {
        path: '',
        title: 'Compras',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'comprobante-compra',
                title: 'Comprobantes de Compra',
                component: ComprobanteCompraComponent,
            },
            {
                path: 'proveedor',
                title: 'Proveedores',
                component: ProveedorComponent,
            },
            {
                path: 'centro-costo',
                title: 'Centros de Costo',
                component: CentroCostoComponent,
            },
            {
                path: 'motivo-compra',
                title: 'Motivos de Compra',
                component: MotivoCompraComponent,
            },
            {
                path: 'reporte/pagos-pendientes',
                title: 'Reporte: Pagos Pendientes',
                component: ReportePagosPendientesComponent,
            },
            {
                path: 'reporte/libro-iva-compras',
                title: 'Reporte: Libro IVA Compras',
                component: ReporteLibroIVAComprasComponent,
            },
            {
                path: 'reporte/contable-compras',
                title: 'Reporte Contable: Compras',
                component: ReporteContableComprasComponent,
            },
            {
                path: 'reporte/contable-compras-motivos',
                title: 'Reporte Contable: Motivos de Compras',
                component: ReporteContableComprasMotivosComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ComprasRoutingModule {}
