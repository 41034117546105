import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReporteSaldosClientesDTO } from '@entities/dtos/reportes/reporte-saldos-clientes.dto';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class ReporteSaldosClientesService extends BaseService<ReporteSaldosClientesDTO> {
    public constructor(http: HttpClient) {
        super('ventas/reporte/saldos-clientes', http);
    }
}
