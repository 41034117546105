import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginDTO } from '@entities/dtos/login.dto';
import { Usuario } from '@entities/usuario.entity';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface ApplicationUser {
    accessToken: string;
    expiresIn: Date;
    username: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    private API_URL = environment.API_URL;
    private headers = new HttpHeaders().set('Content-Type', 'application/json');

    public get isLoggedIn(): boolean {
        const authToken = localStorage.getItem('access_token');
        return authToken !== null ? true : false;
    }

    public constructor(
        private http: HttpClient,
        private router: Router,
    ) {}

    public login(usuario: Usuario): Observable<any> {
        return this.http.post<ApplicationUser>(`${this.API_URL}/auth/login`, usuario);
    }

    public cambiarContrasena(login: LoginDTO): Observable<any> {
        return this.http.post(`${this.API_URL}/auth/cambiar-contrasena`, login);
    }

    public logout() {
        const removeToken = localStorage.removeItem('access_token');
        if (removeToken == null) {
            localStorage.removeItem('usuario');
            this.router.navigate(['login']);
        }
    }

    public getToken() {
        return localStorage.getItem('access_token');
    }

    public getUserProfile(id: any): Observable<any> {
        const api = `${this.API_URL}/administracion/usuario/${id}`;
        return this.http.get<Usuario>(api);
    }
}
