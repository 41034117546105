import { Directive } from '@angular/core';
import { BaseEntity } from '@entities/base.entity';
import { BusquedaDTO } from '@entities/dtos/busqueda.dto';
import { RespuestaDTO } from '@entities/dtos/respuesta.dto';
import { AutoUnsubscribe } from '@helpers/auto-unsubscribe.decorator';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { of } from 'rxjs';

@Directive()
@AutoUnsubscribe
export abstract class ABMCVolatilComponent<T extends BaseEntity> extends ABMCComponent<T> {
    public constructor(configuracion: any) {
        const volatilService = {
            obtenerTodos: (parametros?: any) => {
                if (parametros == null) {
                    return of(this.elementos);
                }
                const busqueda = new BusquedaDTO(parametros);
                // Buscar de manera volátil en base a los parámetros de búsqueda
                const registrosBuscados = this.elementos.filter((elemento) => {
                    let resultado = true;
                    if (busqueda.busqueda) {
                        resultado = false;
                        this.columnas.forEach((columna) => {
                            if (columna.searchable === true) {
                                const valor = (elemento as any)[columna.data] ?? '';
                                if (
                                    valor
                                        .toString()
                                        .toLowerCase()
                                        .includes(busqueda.busqueda?.toLowerCase())
                                ) {
                                    resultado = true;
                                }
                            }
                        });
                    }
                    return resultado;
                });
                // Filtrar por columnas en base a los parámetros
                const registrosFiltrados = registrosBuscados.filter((elemento) => {
                    let resultado = true;
                    if (busqueda.columnas) {
                        busqueda.columnas
                            .filter((c) => c.filtro != null)
                            .forEach((columna: any) => {
                                const valor = (elemento as any)[columna.data] ?? '';
                                if (!valor.toString().toLowerCase().includes(columna.filtro.toLowerCase())) {
                                    resultado = false;
                                }
                            });
                    }
                    return resultado;
                });
                // Ordenar en base a los parámetros
                const registrosOrdenados = registrosFiltrados.sort((a, b) => {
                    let resultado = 0;
                    if (busqueda.columnas) {
                        busqueda.columnas
                            .filter((c) => c.orden != null)
                            .forEach((columna: any) => {
                                const valorA = (a as any)[columna.data] ?? '';
                                const valorB = (b as any)[columna.data] ?? '';
                                if (valorA > valorB) {
                                    resultado = columna.orden === 'ASC' ? 1 : -1;
                                } else if (valorA < valorB) {
                                    resultado = columna.orden === 'ASC' ? -1 : 1;
                                }
                            });
                    }
                    return resultado;
                });
                // Paginar en base a los parámetros
                const registrosPaginados = registrosOrdenados.slice(
                    busqueda.desde ?? 0,
                    busqueda.cantidad ? (busqueda.desde ?? 0) + busqueda.cantidad : registrosOrdenados.length,
                );
                // Devolver los registros paginados
                const respuesta = new RespuestaDTO<T>();
                respuesta.cantidadFiltrada = registrosFiltrados.length;
                respuesta.cantidadTotal = this.elementos.length;
                respuesta.elementos = registrosPaginados;
                return of(respuesta);
            },
            crear: (elemento: T) => {
                // Generar un ID único negativo aleatorio
                let idVolatil = Math.floor(Math.random() * -1000000);
                while (this.elementos.find((e) => e.id === idVolatil)) {
                    idVolatil = Math.floor(Math.random() * -1000000);
                }
                elemento.id = idVolatil;
                this.elementos.push(elemento);
                return of(elemento);
            },
            eliminar: (id: number) => {
                this.elementos = this.elementos.filter((e) => e.id !== id);
                return of(null);
            },
            modificar: (elemento: T) => {
                this.elementos = this.elementos.map((e) => {
                    if (e.id === elemento.id) {
                        return elemento;
                    }
                    return e;
                });
                return of(elemento);
            },
            obtener: (id: number) => {
                return of(this.elementos.find((e) => e.id === id));
            },
        } as any;
        super(volatilService, configuracion);
    }
}
