import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MonedaCotizacion } from '@entities/moneda-cotizacion.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCVolatilComponent } from '@shared/directives/abmc/abmc-volatil.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'kratos-moneda-cotizacion',
    templateUrl: './moneda-cotizacion.component.html',
    styleUrls: ['./moneda-cotizacion.component.scss'],
})
export class MonedaCotizacionComponent extends ABMCVolatilComponent<MonedaCotizacion> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public mascaraCotizacion = 'separator.6';
    public nombre = 'Cotización';
    public titulo = 'Cotizaciones';
    public tablaOpciones = {
        botones: {
            size: 'sm',
        },
    };

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        fecha: ['', Validators.required],
        cotizacion: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'Fecha',
            data: 'fecha',
            tipo: 'date',
            searchable: true,
        },
        {
            title: 'Cotización',
            data: 'cotizacion',
            tipo: 'cotizacion',
            searchable: true,
        },
    ];

    public constructor(private formBuilder: FormBuilder) {
        super({
            inicializaciones: {
                nombre: 'Cotización',
                genero: 'a',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    public toFixedCotizacion = (value: string | number | undefined | null): number => {
        return this.toFixed(value, 6);
    };

    public toFixed(value: string | number | undefined | null, decimales = 2): number {
        const formattedValue = String(value).split(' ').join('');
        if (String(value).includes('.') && String(value).split('.').length === 2) {
            const decimal = String(value).split('.')[1]?.length;
            if (decimal && decimal > decimales) {
                return Number(parseFloat(formattedValue).toFixed(decimales));
            }
        }
        return Number(formattedValue);
    }

    protected obtenerRelaciones(): Observable<any> {
        return of({});
    }
}
