import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Producto } from '@entities/producto.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class ProductoService extends BaseService<Producto> {
    public constructor(http: HttpClient) {
        super('ventas/producto', http);
    }
}
