<h2 class="mb-3">{{ titulo }}</h2>

<kratos-tabla
    #tabla
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="nombre" class="form-label">Nombre</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Nombre"
                        ngbPopover="Nombre del tipo de concepto."
                    ></i>
                    <input placeholder="Nombre" formControlName="nombre" type="text" class="form-control" id="nombre" />
                    <ng-container *ngFor="let error of formErrores('nombre', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="desde" class="form-label">Desde</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Desde"
                        ngbPopover="Fecha desde la cual el tipo de concepto es válido."
                    ></i>
                    <div class="input-group">
                        <input
                            placeholder="dd/mm/aaaa"
                            formControlName="desde"
                            class="form-control"
                            id="desde"
                            ngbDatepicker
                            #desde="ngbDatepicker"
                        />
                        <button
                            *ngIf="form.get('desde')?.value && !form?.get('desde')?.disabled"
                            class="btn btn-sm btn-outline-secondary bi bi-x-lg"
                            (click)="form.get('desde')?.setValue(null)"
                            type="button"
                        ></button>
                        <button
                            *ngIf="!form.get('desde')?.disabled"
                            class="btn btn-outline-secondary bi bi-calendar3"
                            (click)="desde.toggle()"
                            type="button"
                        ></button>
                        <ng-container *ngFor="let error of formErrores('desde', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="col">
                    <label for="hasta" class="form-label">Hasta</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Hasta"
                        ngbPopover="Fecha hasta la cual el tipo de concepto es válido."
                    ></i>
                    <div class="input-group">
                        <input
                            placeholder="dd/mm/aaaa"
                            formControlName="hasta"
                            class="form-control"
                            id="hasta"
                            ngbDatepicker
                            #hasta="ngbDatepicker"
                        />
                        <button
                            *ngIf="form.get('hasta')?.value && !form.get('hasta')?.disabled"
                            class="btn btn-sm btn-outline-secondary bi bi-x-lg"
                            (click)="form.get('hasta')?.setValue(null)"
                            type="button"
                        ></button>
                        <button
                            *ngIf="!form.get('hasta')?.disabled"
                            class="btn btn-outline-secondary bi bi-calendar3"
                            (click)="hasta.toggle()"
                            type="button"
                        ></button>
                        <ng-container *ngFor="let error of formErrores('hasta', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="codigoAFIP" class="form-label">Código AFIP</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Código AFIP"
                        ngbPopover="Código AFIP del tipo de concepto. Este código es utilizado para la autorización de comprobantes. Se puede obtener en los listados de la AFIP."
                    ></i>
                    <input
                        placeholder="Código AFIP"
                        formControlName="codigoAFIP"
                        type="text"
                        class="form-control"
                        id="codigoAFIP"
                    />
                    <ng-container *ngFor="let error of formErrores('codigoAFIP', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-template>
