import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipoDocumento } from '@entities/tipo-documento.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class TipoDocumentoService extends BaseService<TipoDocumento> {
    public constructor(http: HttpClient) {
        super('configuracion/tipo-documento', http);
    }
}
