import { EmpresaComponent } from '@administracion/components/empresa.component';
import { MigracionHermesComponent } from 'src/app/modules/migracion/components/migracion-hermes.component';
import { ParametroComponent } from '@administracion/components/parametro.component';
import { PerfilComponent } from '@administracion/components/perfil.component';
import { UsuarioComponent } from '@administracion/components/usuario.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth/auth.guard';

const routes: Routes = [
    {
        path: '',
        title: 'Administración',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'empresa',
                title: 'Empresas',
                component: EmpresaComponent,
            },
            {
                path: 'usuario',
                title: 'Usuarios',
                component: UsuarioComponent,
            },
            {
                path: 'parametro',
                title: 'Parámetros',
                component: ParametroComponent,
            },
            {
                path: 'perfil',
                title: 'Perfil',
                component: PerfilComponent,
            },
            {
                path: 'migracion-hermes',
                title: 'Migración Hermes',
                component: MigracionHermesComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdministracionRoutingModule {}
