import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'kratos-modal-informacion',
    templateUrl: './modal-informacion.component.html',
    styleUrls: ['./modal-informacion.component.scss'],
})
export class ModalInformacionComponent {
    @Input() public titulo = 'Kratos Modal Información';
    public mensaje = 'Mensaje';

    public constructor(public activeModal: NgbActiveModal) {}
}
