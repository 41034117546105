import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { LoginComponent } from './modules/auth/components/login.component';
import { CoreComponent } from './modules/core/components/core/core.component';
import { ConcatTitleStrategy } from './modules/core/concat-title.strategy';

const routes: Routes = [
    {
        path: '',
        title: 'Kratos',
        component: CoreComponent,
    },
    {
        path: 'login',
        title: 'Login',
        component: LoginComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
    providers: [
        {
            provide: TitleStrategy,
            useClass: ConcatTitleStrategy,
        },
    ],
})
export class AppRoutingModule {}
