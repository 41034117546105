import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Moneda } from '@entities/moneda.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { MonedaCotizacionComponent } from '@ventas/components/moneda-cotizacion.component';
import { MonedaService } from '@ventas/services/moneda.service';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'kratos-moneda',
    templateUrl: './moneda.component.html',
    styleUrls: ['./moneda.component.scss'],
})
export class MonedaComponent extends ABMCComponent<Moneda> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    @ViewChild('monedaCotizacion') private monedaCotizacion!: MonedaCotizacionComponent;

    public mascaraCotizacion = 'separator.6';
    public nombre = 'Moneda';
    public titulo = 'Monedas';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        abreviatura: ['', Validators.required],
        simbolo: ['', Validators.required],
        cotizacion: [''],
        codigoAFIP: [''],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Abreviatura',
            data: 'abreviatura',
            searchable: true,
        },
        {
            title: 'Símbolo',
            data: 'simbolo',
            searchable: true,
        },
        {
            title: 'Cotización',
            data: 'cotizacion',
            tipo: 'cotizacion',
            searchable: true,
        },
        {
            title: 'Código AFIP',
            data: 'codigoAFIP',
            searchable: true,
        },
    ];

    public constructor(
        protected monedaService: MonedaService,
        private formBuilder: FormBuilder,
    ) {
        super(monedaService, {
            inicializaciones: {
                nombre: 'Moneda',
                anchoModal: 'lg',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    public toFixed = (value: string | number | undefined | null): number => {
        const formattedValue = String(value).split(' ').join('');
        if (String(value).includes('.') && String(value).split('.').length === 2) {
            const decimal = String(value).split('.')[1]?.length;
            if (decimal && decimal > 6) {
                return Number(parseFloat(formattedValue).toFixed(6));
            }
        }
        return Number(formattedValue);
    };

    protected obtenerRelaciones(): Observable<any> {
        return of({});
    }

    protected override cargarElemento(elemento: Moneda) {
        super.cargarElemento(elemento);
        this.monedaCotizacion.elementos = elemento?.cotizaciones ?? [];
        this.monedaCotizacion.tabla.recargar();
    }

    protected override crearElemento(): Moneda {
        this.elementoActual = { ...new Moneda(), ...this.elementoActual };
        this.elementoActual.cotizaciones = this.monedaCotizacion.elementos ?? [];
        // Nulificar los IDs de los elementos que tengan ID negativo, ya que son elementos volátiles
        this.elementoActual.cotizaciones.forEach((cotizacion) => {
            if (cotizacion.id && cotizacion.id < 0) {
                cotizacion.id = undefined;
            }
        });
        return super.crearElemento();
    }
}
