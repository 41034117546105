import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonedaCotizacion } from '@entities/moneda-cotizacion.entity';
import { Moneda } from '@entities/moneda.entity';
import { BaseService } from '@shared/services/base.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MonedaService extends BaseService<Moneda> {
    public constructor(http: HttpClient) {
        super('ventas/moneda', http);
    }

    public obtenerCotizacionPorFecha(id: number, fecha: DateString): Observable<MonedaCotizacion> {
        return this.http.get<MonedaCotizacion>(
            `${this.API_URL}/${this.endpointUrl}/cotizacion?id=${id}&fecha=${fecha}`,
        );
    }

    public obtenerCotizacionAFIP(empresaId: number, monedaId: number): Observable<MonedaCotizacion> {
        return this.http.get<MonedaCotizacion>(
            `${this.API_URL}/${this.endpointUrl}/cotizacion-afip?empresaId=${empresaId}&monedaId=${monedaId}`,
        );
    }
}
