import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportePagosPendientesView } from '@entities/views/reportes/reporte-pagos-pendientes.view';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class ReportePagosPendientesService extends BaseService<ReportePagosPendientesView> {
    public constructor(http: HttpClient) {
        super('compras/reporte/pagos-pendientes', http);
    }
}
