import { EmpresaService } from '@administracion/services/empresa.service';
import { ParametroService } from '@administracion/services/parametro.service';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComprobanteCompraComprobanteAsociadoComponent } from '@compras/components/comprobante-compra-comprobante-asociado.component';
import { ComprobanteCompraItemComponent } from '@compras/components/comprobante-compra-item.component';
import { ComprobanteCompraMedioPagoComponent } from '@compras/components/comprobante-compra-medio-pago.component';
import { ComprobanteCompraTributoComponent } from '@compras/components/comprobante-compra-tributo.component';
import { ProveedorComponent } from '@compras/components/proveedor.component';
import { CentroCostoService } from '@compras/services/centro-costo.service';
import { ComprobanteCompraService, ComprobanteCompraViewService } from '@compras/services/comprobante-compra.service';
import { ProveedorService } from '@compras/services/proveedor.service';
import { TipoComprobanteService } from '@configuracion/services/tipo-comprobante.service';
import { TipoDocumentoService } from '@configuracion/services/tipo-documento.service';
import { CentroCosto } from '@entities/centro-costo.entity';
import { ComprobanteCompraItem } from '@entities/comprobante-compra-item.entity';
import { ComprobanteCompra } from '@entities/comprobante-compra.entity';
import { Comprobante } from '@entities/comprobante.entity';
import { RespuestaDTO } from '@entities/dtos/respuesta.dto';
import { Empresa } from '@entities/empresa.entity';
import { TipoComprobanteDenominacionEnum } from '@entities/enums/tipo-comprobante-denominacion.enum';
import { TipoComprobanteLetraEnum } from '@entities/enums/tipo-comprobante-letra.enum';
import { obtenerTipoDocumentoDenominaciones } from '@entities/enums/tipo-documento-denominacion.enum';
import { MonedaCotizacion } from '@entities/moneda-cotizacion.entity';
import { Moneda } from '@entities/moneda.entity';
import { Proveedor } from '@entities/proveedor.entity';
import { TipoActividad } from '@entities/tipo-actividad.entity';
import { TipoAlicuotaIVA } from '@entities/tipo-alicuota-iva.entity';
import { TipoComprobante } from '@entities/tipo-comprobante.entity';
import { TipoDocumento } from '@entities/tipo-documento.entity';
import { ComprobanteCompraView } from '@entities/views/comprobante-compra.view';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { ComprobanteTransaccionComponent } from '@ventas/components/comprobante-transaccion.component';
import { MonedaService } from '@ventas/services/moneda.service';
import { TipoActividadService } from '@ventas/services/tipo-actividad.service';
import { TipoAlicuotaIVAService } from '@ventas/services/tipo-alicuota-iva.service';
import Big from 'big.js';
import { format, parse, parseISO } from 'date-fns';
import { Observable, finalize, forkJoin } from 'rxjs';

const MONEDA_OFICIAL_CODIGO_AFIP = 'PES';

@Component({
    selector: 'kratos-comprobante-compra',
    templateUrl: './comprobante-compra.component.html',
    styleUrls: ['./comprobante-compra.component.scss'],
})
export class ComprobanteCompraComponent extends ABMCComponent<ComprobanteCompra> implements OnInit {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;
    @ViewChild('footerTemplate') public footerModalTemplate!: TemplateRef<any>;

    @ViewChild('comprobanteCompraItem') private comprobanteCompraItem!: ComprobanteCompraItemComponent;
    @ViewChild('comprobanteCompraMedioPago') private comprobanteCompraMedioPago!: ComprobanteCompraMedioPagoComponent;
    @ViewChild('comprobanteCompraTributo') private comprobanteCompraTributo!: ComprobanteCompraTributoComponent;
    @ViewChild('comprobanteCompraComprobanteAsociado')
    private comprobanteCompraComprobanteAsociado!: ComprobanteCompraComprobanteAsociadoComponent;
    @ViewChild('comprobanteTransaccion') private comprobanteTransaccion!: ComprobanteTransaccionComponent;

    @ViewChild('nav') private nav!: NgbNav;

    public monedaOficial: Moneda | null = null;
    public monedaOficialEmpresa: Moneda | null = null;
    private cotizacionAnterior: number | null = null;
    public agregandoProveedor = false;

    @ViewChild('proveedorComponent') private proveedorComponent!: ProveedorComponent;

    public facturacionSimple = false;
    public tipoDocumentoDenominaciones = obtenerTipoDocumentoDenominaciones();
    public mascaraPuntoVenta = '9990';
    public mascaraNumero = '99999990';
    public mascaraNumeroDocumento = '';
    public mascaraCotizacion = 'separator.6';
    public nombreMascaraNumeroDocumento = '';
    public nombre = 'Comprobante de Compra';
    public titulo = 'Comprobantes de Compra';
    public tablaOpciones = {
        order: [[3, 'desc']],
    };
    public cargando = {
        centroCosto: false,
        cotizacion: false,
    };

    protected formErroresControles = {
        items: {
            required: 'Debe agregar al menos un item',
        },
    };

    public form = this.formBuilder.group({
        fecha: ['', Validators.required],
        numeroDocumento: ['', Validators.required],
        tipoDocumento: ['', Validators.required],
        tipoComprobante: ['', Validators.required],
        centroCosto: ['', Validators.required],
        puntoVenta: ['', Validators.required],
        numero: ['', Validators.required],
        empresa: ['', Validators.required],
        proveedor: ['', Validators.required],
        tipoActividad: ['', Validators.required],
        moneda: ['', Validators.required],
        cotizacion: ['', Validators.required],
        items: [[]],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Proveedor',
            data: 'proveedorRazonSocial',
            searchable: true,
        },
        {
            title: 'Tipo',
            data: 'tipoComprobanteNombre',
            searchable: true,
        },
        {
            title: 'Fecha',
            data: 'fecha',
            tipo: 'date',
            searchable: true,
        },
        {
            title: 'Centro Costo',
            data: 'centroCostoNombre',
            searchable: true,
        },
        {
            title: 'Pto. Vta.',
            data: 'puntoVentaNumero',
            searchable: true,
        },
        {
            title: 'Número',
            data: 'numero',
            searchable: true,
        },
        {
            title: 'Importe Neto',
            data: 'importeNeto',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Importe IVA',
            data: 'importeIVA',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Importe Total',
            data: 'importeTotal',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Saldo',
            data: 'saldo',
            tipo: 'moneda',
            searchable: true,
        },
    ];

    public constructor(
        protected comprobanteCompraService: ComprobanteCompraService,
        protected comprobanteCompraViewService: ComprobanteCompraViewService,
        protected empresaService: EmpresaService,
        protected proveedorService: ProveedorService,
        protected centroCostoService: CentroCostoService,
        protected tipoComprobanteService: TipoComprobanteService,
        protected tipoDocumentoService: TipoDocumentoService,
        protected tipoActividadService: TipoActividadService,
        protected monedaService: MonedaService,
        protected tipoAlicuotaIVAService: TipoAlicuotaIVAService,
        protected parametroService: ParametroService,
        private formBuilder: FormBuilder,
    ) {
        super(comprobanteCompraService, {
            inicializaciones: {
                nombre: 'Comprobante de Compra',
                genero: 'o',
                anchoModal: 'xl',
                acciones: [
                    {
                        nombre: 'pagarComprobante',
                        icono: 'credit-card',
                        tooltip: 'Pagar Comprobante',
                        deshabilitado: (elemento: ComprobanteCompraView) => !elemento?.saldo,
                    },
                ],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
        this.subscription.add(
            this.afterViewInit.subscribe(() => {
                this.footerTemplate = this.footerModalTemplate;
            }),
        );
        this.facturacionSimple = localStorage.getItem('facturacionSimple') === 'true';
        this.subscription.add(this.elementoInicializado.subscribe(this.vincularItemsComprobantesAsociados.bind(this)));
        this.subscription.add(this.elementoCargado.subscribe(this.vincularItemsComprobantesAsociados.bind(this)));
    }

    public cambiarFecha(fecha?: any): void {
        this.establecerCotizacion();
    }

    public cambiarMoneda(moneda?: Moneda, opciones?: { sobreescribir?: boolean }): void {
        if (!this.form.get('moneda')?.value || opciones?.sobreescribir === true) {
            this.form.get('moneda')?.setValue((moneda ?? this.monedaOficialEmpresa) as any);
        }
        this.establecerCotizacion();
    }

    public cambiarEmpresa(empresa?: Empresa, opciones?: { saldar?: boolean; inicializar?: boolean }): void {
        if (!this.form.get('empresa')?.value) {
            this.form.get('empresa')?.setValue(empresa as any);
        }
        if (!empresa || opciones?.saldar === true) {
            return;
        }
        this.cargarParametrosPorDefecto(empresa, opciones);
    }

    public cambiarProveedor(proveedor?: Proveedor): void {
        this.form.get('proveedor')?.setValue((proveedor as any) ?? null);
        this.cambiarTipoDocumento(proveedor?.tipoDocumento, { sobreescribir: true });
        this.form.get('numeroDocumento')?.setValue(proveedor?.numeroDocumento as any);
        this.form
            .get('tipoActividad')
            ?.setValue((proveedor?.tipoActividad as any) ?? this.form.get('tipoActividad')?.value ?? null);
        this.cambiarMoneda(proveedor?.moneda, { sobreescribir: true });
        this.comprobanteCompraComprobanteAsociado.proveedor = proveedor;
    }

    public cambiarTipoDocumento(tipoDocumento?: TipoDocumento, opciones?: { sobreescribir?: boolean }): void {
        if (!this.form.get('tipoDocumento')?.value || opciones?.sobreescribir === true) {
            this.form.get('tipoDocumento')?.setValue((tipoDocumento as any) ?? null);
        }
        const { mascaraNumeroDocumento, nombreMascaraNumeroDocumento } =
            this.obtenerMascaraTipoDocumento(tipoDocumento);
        this.mascaraNumeroDocumento = mascaraNumeroDocumento;
        this.nombreMascaraNumeroDocumento = nombreMascaraNumeroDocumento;
    }

    public buscarCentroCosto = (term: string, item: CentroCosto) => {
        term = term.toLocaleLowerCase();
        return (item?.nombre?.toLocaleLowerCase().indexOf(term) ?? -1) > -1;
    };

    public buscarProveedor = (term: string, item: Proveedor) => {
        term = term.toLocaleLowerCase();
        return (
            (item?.razonSocial?.toLocaleLowerCase().indexOf(term) ?? -1) > -1 ||
            (item?.tipoDocumento?.nombre?.toLocaleLowerCase().indexOf(term) ?? -1) > -1 ||
            (item?.numeroDocumento?.toLocaleLowerCase().indexOf(term) ?? -1) > -1
        );
    };

    public actualizarCotizacion(): void {
        const cotizacionAnterior =
            this.cotizacionAnterior != null
                ? this.cotizacionAnterior
                : Big(this.elementoActual?.cotizacion ?? 1).toNumber();
        const cotizacion = Big(this.form.get('cotizacion')?.value ?? 1).toNumber();
        this.cotizacionAnterior = cotizacion;
        const moneda = {
            ...(this.form.get('moneda')?.value as Moneda),
            cotizacion,
        };
        // Actualizar los importes en base a la nueva cotización
        this.comprobanteCompraItem.elementos.forEach((item) => {
            item.precioUnitario = Big(item.precioUnitario ?? 0)
                .div(Big(cotizacionAnterior))
                .times(Big(cotizacion))
                .toNumber();
            item.recalcularImportes();
        });
        this.comprobanteCompraMedioPago.elementos.forEach((medioPago) => {
            medioPago.importe = Big(medioPago.importe ?? 0)
                .div(Big(cotizacionAnterior))
                .times(Big(cotizacion))
                .toNumber();
        });
        this.comprobanteCompraTributo.elementos.forEach((tributo) => {
            tributo.importe = Big(tributo.importe ?? 0)
                .div(Big(cotizacionAnterior))
                .times(Big(cotizacion))
                .toNumber();
        });
        this.comprobanteCompraComprobanteAsociado.elementos.forEach((comprobanteAsociado) => {
            comprobanteAsociado.importe = Big(comprobanteAsociado.importe ?? 0)
                .div(Big(cotizacionAnterior))
                .times(Big(cotizacion))
                .toNumber();
        });
        this.comprobanteCompraItem.moneda = moneda;
        this.comprobanteCompraItem.tabla.opciones.moneda = moneda;
        this.comprobanteCompraItem.tabla.recargar();
        this.comprobanteCompraMedioPago.moneda = moneda;
        this.comprobanteCompraMedioPago.tabla.opciones.moneda = moneda;
        this.comprobanteCompraMedioPago.tabla.recargar();
        this.comprobanteCompraTributo.moneda = moneda;
        this.comprobanteCompraTributo.tabla.opciones.moneda = moneda;
        this.comprobanteCompraTributo.tabla.recargar();
        this.comprobanteCompraComprobanteAsociado.moneda = moneda;
        this.comprobanteCompraComprobanteAsociado.recargar();
    }

    public toFixedCotizacion = (value: string | number | undefined | null): number => {
        return this.toFixed(value, 6);
    };

    public toFixed(value: string | number | undefined | null, decimales = 2): number {
        const formattedValue = String(value).split(' ').join('');
        if (String(value).includes('.') && String(value).split('.').length === 2) {
            const decimal = String(value).split('.')[1]?.length;
            if (decimal && decimal > decimales) {
                return Number(parseFloat(formattedValue).toFixed(decimales));
            }
        }
        return Number(formattedValue);
    }

    public cotizacionOficial(): boolean {
        return this.monedaOficial?.id === (this.form.get('moneda')?.value as Moneda)?.id;
    }

    public override ajaxHandler(event: any) {
        super.ajaxHandler(event, this.comprobanteCompraViewService);
    }

    public agregarProveedor(): void {
        this.agregandoProveedor = true;
        setTimeout(() => {
            // Invocar ProveedorComponent en un modal para agregar un nuevo proveedor
            this.proveedorComponent.alta();
            this.subscription.add(
                this.proveedorComponent.modalRef.hidden.subscribe(() => {
                    this.agregandoProveedor = false;
                }),
            );
            this.subscription.add(
                this.proveedorComponent.postAltaEvent.subscribe((proveedorNuevo: Proveedor) => {
                    this.subscription.add(
                        this.proveedorService.obtenerTodos().subscribe({
                            next: (respuesta: RespuestaDTO<Proveedor>) => {
                                this.relaciones.proveedores = respuesta?.elementos ?? [];
                                this.cambiarProveedor(proveedorNuevo);
                            },
                            error: this.toastService.errorHandler.bind(this.toastService),
                        }),
                    );
                }),
            );
        });
    }

    public cambiarFacturacionSimple(): void {
        localStorage.setItem('facturacionSimple', `${this.facturacionSimple}`);
        this.comprobanteCompraItem.facturacionSimple = this.facturacionSimple;
    }

    public obtenerCotizacionAFIP(): void {
        const empresa = this.form.get('empresa')?.value as Empresa;
        if (!empresa || !empresa.id) {
            this.toastService.warningHandler('Debe seleccionar una empresa para obtener la cotización');
            return;
        }
        const moneda = this.form.get('moneda')?.value as Moneda;
        if (!moneda || !moneda.id) {
            this.toastService.warningHandler('Debe seleccionar una moneda para obtener la cotización');
            return;
        }
        if (moneda.id === this.monedaOficial?.id) {
            this.toastService.infoHandler('No es necesario obtener la cotización para la moneda oficial');
            return;
        }
        this.form.get('cotizacion')?.disable();
        this.cargando.cotizacion = true;
        this.modalRef.componentInstance.cargando = true;
        this.subscription.add(
            this.monedaService
                .obtenerCotizacionAFIP(empresa.id, moneda.id)
                .pipe(
                    finalize(() => {
                        this.form.get('cotizacion')?.enable();
                        this.cargando.cotizacion = false;
                        this.modalRef.componentInstance.cargando = false;
                    }),
                )
                .subscribe({
                    next: (monedaCotizacion: MonedaCotizacion) => {
                        if (!monedaCotizacion) {
                            this.toastService.errorHandler(
                                `No se pudo obtener la cotización de la moneda ${moneda.nombre}`,
                            );
                        }
                        const cotizacion = Big(monedaCotizacion.cotizacion ?? +1.0).toFixed(6);
                        this.form.get('cotizacion')?.setValue(cotizacion);
                        this.actualizarCotizacion();
                        this.toastService.successHandler(
                            `Cotización actualizada a ${this.monedaOficial?.simbolo ?? '$'} ${this.toFixedCotizacion(
                                cotizacion,
                            ).toLocaleString('es-ES', {
                                minimumFractionDigits: 2,
                            })} según AFIP para ${moneda.nombre} (${moneda.codigoAFIP}) en la fecha ${format(
                                monedaCotizacion.fecha
                                    ? parse(monedaCotizacion.fecha, 'yyyy-MM-dd', new Date())
                                    : new Date(),
                                'dd/MM/yyyy',
                            )}`,
                        );
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
            proveedores: this.proveedorService.obtenerTodos(),
            centrosCosto: this.centroCostoService.obtenerTodos(),
            tiposComprobante: this.tipoComprobanteService.obtenerTodos(),
            tiposDocumento: this.tipoDocumentoService.obtenerTodos(),
            tiposActividad: this.tipoActividadService.obtenerTodos(),
            monedas: this.monedaService.obtenerTodos(),
            tiposAlicuotaIVA: this.tipoAlicuotaIVAService.obtenerTodos(),
        });
    }

    protected override cargarRelaciones(relaciones: any): void {
        super.cargarRelaciones(relaciones);
        this.monedaOficial = this.relaciones.monedas.find((m: Moneda) => m.codigoAFIP === MONEDA_OFICIAL_CODIGO_AFIP);
    }

    protected override cargarElemento(elemento: ComprobanteCompra) {
        this.cotizacionAnterior = elemento?.cotizacion ?? null;
        elemento = { ...elemento, ...elemento?.comprobante, id: elemento?.id };
        super.cargarElemento(elemento);

        this.cargarParametrosPorDefecto(elemento?.empresa);
        this.cambiarTipoDocumento(elemento?.comprobante?.tipoDocumento);
        this.actualizarCotizacion();

        // Cargar e instanciar items
        const items = (elemento?.items ?? []).map((item) => new ComprobanteCompraItem(item));
        this.comprobanteCompraItem.elementos = items;
        this.comprobanteCompraItem.tabla.recargar();

        // Cargar medioPago
        const mediosPago = elemento?.mediosPago ?? [];
        this.comprobanteCompraMedioPago.elementos = mediosPago;
        this.comprobanteCompraMedioPago.tabla.recargar();

        // Cargar tributos
        const tributos = elemento?.tributos ?? [];
        this.comprobanteCompraTributo.elementos = tributos;
        this.comprobanteCompraTributo.tabla.recargar();

        // Cargar comprobantes asociados
        const comprobantesAsociados = elemento?.comprobantesAsociados ?? [];
        this.comprobanteCompraComprobanteAsociado.elementos = comprobantesAsociados;
        this.comprobanteCompraComprobanteAsociado.recargar();

        // Cargar transacciones
        const transacciones = elemento?.comprobante?.transacciones ?? [];
        this.comprobanteTransaccion.elementos = transacciones;
        this.comprobanteTransaccion.tabla.recargar();
    }

    protected override crearElemento(): ComprobanteCompra {
        this.elementoActual = { ...new ComprobanteCompra(), ...this.elementoActual };

        // Crear comprobante
        const comprobante = { ...new Comprobante(), ...this.elementoActual.comprobante };
        comprobante.numero = Number(this.form.get('numero')?.value);
        const fecha = parseISO(this.form.get('fecha')?.value ?? '');
        // Agregar hora actual a la fecha
        comprobante.fecha = format(fecha, 'yyyy-MM-dd HH:mm:ss') as DateTimeString;
        if (fecha.getHours() === 0 && fecha.getMinutes() === 0 && fecha.getSeconds() === 0) {
            comprobante.fecha = `${format(fecha, 'yyyy-MM-dd')} ${format(new Date(), 'HH:mm:ss')}` as DateTimeString;
        }
        comprobante.numeroDocumento = this.form.get('numeroDocumento')?.value ?? '';
        comprobante.tipoComprobante = this.form.get('tipoComprobante')?.value as TipoComprobante;
        comprobante.tipoDocumento = this.form.get('tipoDocumento')?.value as TipoDocumento;
        comprobante.transacciones = this.elementoActual?.comprobante?.transacciones ?? [];

        // Asociar items
        this.elementoActual.items = this.comprobanteCompraItem.elementos ?? [];
        this.elementoActual.items.forEach((item) => {
            if (item.id && item.id < 0) {
                item.id = undefined;
            }
        });

        // Asociar mediosPago
        this.elementoActual.mediosPago = this.comprobanteCompraMedioPago.elementos ?? [];
        this.elementoActual.mediosPago.forEach((medioPago) => {
            if (medioPago.id && medioPago.id < 0) {
                medioPago.id = undefined;
            }
        });

        // Asociar tributos
        this.elementoActual.tributos = this.comprobanteCompraTributo.elementos ?? [];
        this.elementoActual.tributos.forEach((tributo) => {
            if (tributo.id && tributo.id < 0) {
                tributo.id = undefined;
            }
        });

        // Asociar comprobantes asociados
        this.elementoActual.comprobantesAsociados = this.comprobanteCompraComprobanteAsociado.elementos ?? [];

        // Asociar transacciones
        comprobante.transacciones = this.comprobanteTransaccion.elementos ?? [];
        comprobante.transacciones.forEach((transaccion) => {
            if (transaccion.id && transaccion.id < 0) {
                transaccion.id = undefined;
            }
        });

        this.elementoActual.comprobante = comprobante;

        const comprobanteCompra = super.crearElemento() as ComprobanteCompra;
        comprobanteCompra.items = this.elementoActual.items;

        return comprobanteCompra;
    }

    protected override inicializarElemento(): void {
        this.cotizacionAnterior = null;
        super.inicializarElemento();
        setTimeout(() => {
            // Si existe, tomar el valor de la empresa del perfil del usuario
            try {
                let empresa = JSON.parse(localStorage.getItem('empresa') ?? '{}') as Empresa;
                if (this.relaciones?.empresas?.length > 0) {
                    const empresaPerfil = this.relaciones?.empresas?.find((e: Empresa) => e.id === empresa.id);
                    if (empresaPerfil) {
                        empresa = empresaPerfil;
                    }
                    this.cambiarEmpresa(empresa.id ? empresa : this.relaciones?.empresas?.[0], { inicializar: true });
                }
            } catch (error) {
                this.cambiarEmpresa(this.relaciones?.empresas?.[0], { inicializar: true });
            }
            // Establece por defecto la fecha actual
            this.form.get('fecha')?.setValue(format(new Date(), 'yyyy-MM-dd'));
            // Reiniciar tipo de documento
            this.cambiarTipoDocumento();
        });
    }

    protected override formValidar(form: FormGroup, formElement: ElementRef<any>) {
        super.formValidar(form, formElement);
        this.validarItems();
    }

    private obtenerMascaraTipoDocumento(tipoDocumento: any): {
        mascaraNumeroDocumento: string;
        nombreMascaraNumeroDocumento: string;
    } {
        const denominacion = tipoDocumento?.denominacion;
        if (denominacion) {
            const tipoDocumentoDenominacion = this.tipoDocumentoDenominaciones.find(
                (tipoDocumentoDenominacion) => tipoDocumentoDenominacion.nombre === denominacion,
            );
            if (tipoDocumentoDenominacion) {
                return {
                    mascaraNumeroDocumento: tipoDocumentoDenominacion.mascara,
                    nombreMascaraNumeroDocumento: tipoDocumentoDenominacion.nombre,
                };
            }
        }
        return { mascaraNumeroDocumento: '', nombreMascaraNumeroDocumento: '' };
    }

    private establecerCotizacion(): void {
        const moneda = this.form.get('moneda')?.value as Moneda;
        const fecha = this.form.get('fecha')?.value as DateString;
        if (!moneda || !moneda.id) {
            return;
        }
        if (moneda && !fecha) {
            this.form.get('cotizacion')?.setValue(Big(moneda.cotizacion ?? +1.0).toFixed(6));
            this.actualizarCotizacion();
            return;
        }
        // Si es la moneda oficial de la empresa, establecer cotización 1
        if (moneda.id === this.monedaOficialEmpresa?.id) {
            this.form.get('cotizacion')?.setValue(Big(+1.0).toFixed(6));
            this.actualizarCotizacion();
            return;
        }
        this.form.get('cotizacion')?.disable();
        this.cargando.cotizacion = true;
        this.modalRef.componentInstance.cargando = true;
        this.subscription.add(
            this.monedaService
                .obtenerCotizacionPorFecha(moneda.id, fecha)
                .pipe(
                    finalize(() => {
                        this.form.get('cotizacion')?.enable();
                        this.cargando.cotizacion = false;
                        this.modalRef.componentInstance.cargando = false;
                    }),
                )
                .subscribe({
                    next: (monedaCotizacion) => {
                        if (!monedaCotizacion) {
                            this.toastService.errorHandler(
                                `No se pudo obtener la cotización de la moneda ${moneda.nombre}`,
                            );
                        }
                        const cotizacion = Big(monedaCotizacion?.cotizacion ?? moneda.cotizacion ?? +1.0).toFixed(6);
                        this.form.get('cotizacion')?.setValue(cotizacion);
                        this.actualizarCotizacion();
                        this.toastService.successHandler(
                            `Cotización actualizada a ${this.monedaOficial?.simbolo ?? '$'} ${this.toFixedCotizacion(
                                cotizacion,
                            ).toLocaleString('es-ES', {
                                minimumFractionDigits: 2,
                            })} en la fecha ${format(
                                monedaCotizacion.fecha
                                    ? parse(monedaCotizacion.fecha, 'yyyy-MM-dd', new Date())
                                    : new Date(fecha),
                                'dd/MM/yyyy',
                            )}`,
                        );
                    },
                    error: (error) => {
                        error.class = 'warning';
                        this.form.get('cotizacion')?.setValue(Big(moneda.cotizacion ?? 1).toFixed(6));
                        this.actualizarCotizacion();
                        this.toastService.errorHandler(error);
                    },
                }),
        );
    }

    private saldarComprobante(elemento: ComprobanteCompraView, cancelar = false): void {
        if (!elemento || !elemento.id) {
            return;
        }
        // Crear nuevo comprobante de tipo Recibo, con la misma letra que el comporbante a saldar
        // Completar los datos del recibo en base a los datos del comprobante a saldar (proveedor, empresa, etc.)
        // Agregar entre los comprobantes asociados el comprobante a saldar
        // Agregar un item por el saldo del comprobante a saldar
        // Agregar un medio de pago por el saldo del comprobante a saldar
        this.subscription.add(
            this.comprobanteCompraService.obtener(elemento.id).subscribe((comprobante) => {
                if (!comprobante || !comprobante.id) {
                    return;
                }
                const sub = this.elementoInicializado.subscribe(() => {
                    setTimeout(() => {
                        this.nav.select('comprobantesAsociados');
                        // Si se está cancelando el comprobante, y la denominación de su tipo de comprobante es "Factura",
                        // cambiar la denominación para que sea considerado "Nota de Débito"
                        if (
                            cancelar &&
                            comprobante.comprobante?.tipoComprobante?.denominacion ===
                                TipoComprobanteDenominacionEnum.FACTURA
                        ) {
                            comprobante.comprobante.tipoComprobante.denominacion =
                                TipoComprobanteDenominacionEnum.NOTA_DEBITO;
                        }
                        // Si se está cobrando el comprobante, y la denominación de su tipo de comprobante es "Nota de Débito",
                        // cambiar la denominación para que sea considerado "Factura"
                        if (
                            !cancelar &&
                            comprobante.comprobante?.tipoComprobante?.denominacion ===
                                TipoComprobanteDenominacionEnum.NOTA_DEBITO
                        ) {
                            comprobante.comprobante.tipoComprobante.denominacion =
                                TipoComprobanteDenominacionEnum.FACTURA;
                        }
                        const tipoComprobanteOpuesto = this.obtenerTipoComprobanteOpuesto(
                            comprobante.comprobante?.tipoComprobante,
                        );
                        // Si el tipo de comprobante es de denominación "Recibo",
                        // agrupar todos los items en uno solo con alícuota 0,
                        // y eliminar los tributos sumándolos al item agrupado
                        if (tipoComprobanteOpuesto?.denominacion === TipoComprobanteDenominacionEnum.RECIBO) {
                            const item = new ComprobanteCompraItem({
                                cantidad: +1.0,
                                precioUnitario: Math.abs(elemento.saldo ?? +0.0),
                                tipoAlicuotaIVA: this.relaciones.tiposAlicuotaIVA.find(
                                    (tipoAlicuotaIVA: TipoAlicuotaIVA) => tipoAlicuotaIVA.porcentaje === 0,
                                ),
                            });
                            item.recalcularImportes();
                            comprobante.items = [item];
                            comprobante.tributos = [];
                        }
                        this.comprobanteCompraItem.saldarComprobante(comprobante);
                        // Si el tipo de comprobante es Recibo, agregar un medio de pago por el saldo del comprobante a saldar
                        if (tipoComprobanteOpuesto?.denominacion === TipoComprobanteDenominacionEnum.RECIBO) {
                            this.comprobanteCompraMedioPago.saldarComprobante(comprobante);
                        }
                        // Si el tipo de comprobante es Nota de crédito o Nota de débito, saldar los tributos del comprobante compensado
                        if (
                            tipoComprobanteOpuesto?.denominacion === TipoComprobanteDenominacionEnum.NOTA_CREDITO ||
                            tipoComprobanteOpuesto?.denominacion === TipoComprobanteDenominacionEnum.NOTA_DEBITO
                        ) {
                            this.comprobanteCompraTributo.saldarComprobante(comprobante);
                        }
                        this.comprobanteCompraComprobanteAsociado.elementos = [
                            {
                                comprobanteCompraAsociado: elemento,
                                importe: Math.abs(elemento.importeTotal ?? +0.0),
                            } as any,
                        ];
                        this.comprobanteCompraComprobanteAsociado.proveedor = comprobante.proveedor;
                        this.comprobanteCompraComprobanteAsociado.recargar();
                        this.cambiarEmpresa(comprobante.empresa, { saldar: true });
                        this.form.get('centroCosto')?.setValue(comprobante.centroCosto as any);
                        this.form.get('puntoVenta')?.setValue(comprobante.puntoVenta as any);
                        this.form.get('proveedor')?.setValue(comprobante.proveedor as any);
                        this.form.get('numeroDocumento')?.setValue(comprobante.proveedor?.numeroDocumento as any);
                        this.form.get('moneda')?.setValue(comprobante.moneda as any);
                        this.form.get('cotizacion')?.setValue(comprobante.cotizacion as any);
                        this.cambiarTipoDocumento(comprobante.comprobante?.tipoDocumento);
                        this.form.get('tipoComprobante')?.setValue(tipoComprobanteOpuesto as any);
                        this.form.get('tipoActividad')?.setValue(comprobante.tipoActividad as any);
                        this.form.get('cotizacion')?.setValue(comprobante.cotizacion as any);
                        this.cotizacionAnterior = comprobante.cotizacion ?? +1.0;
                        this.actualizarCotizacion();
                        sub.unsubscribe();
                    });
                });
                this.subscription.add(sub);
            }),
        );
        this.alta();
    }

    private pagarComprobante(elemento: ComprobanteCompraView): void {
        if (!elemento || !elemento.id) {
            return;
        }
        this.saldarComprobante(elemento);
    }

    private cargarParametrosPorDefecto(empresa?: Empresa, opciones?: { inicializar?: boolean }) {
        if (!empresa) {
            return;
        }
        // Cargar datos por defecto
        this.form.get('centroCosto')?.disable();
        this.cargando.centroCosto = true;
        this.modalRef.componentInstance.cargando = true;
        this.subscription.add(
            forkJoin({
                parametroCentroCostoDefecto: this.parametroService.obtenerPorNombre(empresa.id, 'CENTRO_COSTO_DEFECTO'),
                parametroTipoActividadDefecto: this.parametroService.obtenerPorNombre(
                    empresa.id,
                    'TIPO_ACTIVIDAD_DEFECTO',
                ),
                parametroMonedaOficial: this.parametroService.obtenerPorNombre(empresa.id, 'MONEDA_OFICIAL'),
            })
                .pipe(
                    finalize(() => {
                        this.form.get('centroCosto')?.enable();
                        this.cargando.centroCosto = false;
                        this.modalRef.componentInstance.cargando = false;
                    }),
                )
                .subscribe({
                    next: (parametrosDefecto) => {
                        const parametroCentroCostoDefecto = parametrosDefecto?.parametroCentroCostoDefecto;
                        if (parametroCentroCostoDefecto?.valor && this.relaciones.centrosCosto?.length) {
                            const centroCosto = this.relaciones.centrosCosto.find(
                                (centroCosto: CentroCosto) => centroCosto.nombre === parametroCentroCostoDefecto.valor,
                            );
                            if (centroCosto && !this.form.get('centroCosto')?.value) {
                                this.form.get('centroCosto')?.setValue(centroCosto);
                            }
                        }
                        const parametroTipoActividadDefecto = parametrosDefecto?.parametroTipoActividadDefecto;
                        if (parametroTipoActividadDefecto?.valor && this.relaciones.tiposActividad?.length) {
                            const tipoActividad = this.relaciones.tiposActividad.find(
                                (tipoActividad: TipoActividad) =>
                                    tipoActividad.codigoAFIP === parametroTipoActividadDefecto.valor,
                            );
                            if (tipoActividad && !this.form.get('tipoActividad')?.value) {
                                this.form.get('tipoActividad')?.setValue(tipoActividad);
                            }
                        }
                        const parametroMonedaOficial = parametrosDefecto?.parametroMonedaOficial;
                        if (parametroMonedaOficial?.valor && this.relaciones.monedas?.length) {
                            const moneda = this.relaciones.monedas.find(
                                (moneda: Moneda) => moneda.codigoAFIP === parametroMonedaOficial.valor,
                            );
                            if (moneda) {
                                this.monedaOficialEmpresa = moneda;
                                if (!this.form.get('cotizacion')?.value) {
                                    this.form.get('cotizacion')?.setValue(moneda?.cotizacion);
                                    this.actualizarCotizacion();
                                }
                                if (!this.form.get('moneda')?.value || opciones?.inicializar === true) {
                                    this.cambiarMoneda(moneda, { sobreescribir: opciones?.inicializar });
                                }
                            }
                        }
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    private obtenerTipoComprobanteOpuesto(tipoComprobante: TipoComprobante | undefined): TipoComprobante | undefined {
        if (!tipoComprobante) {
            return undefined;
        }
        // Obtener el tipo de comprobante con la misma letra pero distinto tipo, utilizando por defecto la letra X
        // Buscar tipo de comprobante:
        // Si se está saldando una Factura, buscar un Recibo X, o en su defecto un Recibo de la misma letra
        // Si se está saldando una Nota de Débito, buscar una Nota de Crédito
        // Si se está saldando un Recibo o Nota de Crédito, buscar una Nota de Débito
        const tiposComprobante = (this.relaciones.tiposComprobante as TipoComprobante[])
            .filter((tc) => tc.letra === tipoComprobante.letra || tc.letra === TipoComprobanteLetraEnum.X)
            .sort((a, b) => (a.letra ?? '').localeCompare(b.letra ?? ''));
        switch (tipoComprobante.denominacion) {
            case TipoComprobanteDenominacionEnum.FACTURA:
                return (
                    tiposComprobante.find(
                        (tc) =>
                            tc.denominacion === TipoComprobanteDenominacionEnum.RECIBO &&
                            tc.signo === -1 &&
                            tc.letra === 'X',
                    ) ??
                    tiposComprobante.find(
                        (tc) => tc.denominacion === TipoComprobanteDenominacionEnum.RECIBO && tc.signo === -1,
                    )
                );
            case TipoComprobanteDenominacionEnum.NOTA_DEBITO:
                return tiposComprobante.find(
                    (tc) => tc.denominacion === TipoComprobanteDenominacionEnum.NOTA_CREDITO && tc.signo === -1,
                );
            case TipoComprobanteDenominacionEnum.RECIBO:
            case TipoComprobanteDenominacionEnum.NOTA_CREDITO:
                return tiposComprobante.find(
                    (tc) => tc.denominacion === TipoComprobanteDenominacionEnum.NOTA_DEBITO && tc.signo === -1,
                );
            default:
                return undefined;
        }
    }

    private validarItems() {
        if (!this.comprobanteCompraItem) {
            return;
        }
        // Validar que exista al menos un item
        if (!this.comprobanteCompraItem.elementos.length) {
            this.form.get('items')?.setErrors({ required: true });
            this.comprobanteCompraItem.hostClasses = 'is-invalid invalid-standalone';
        } else {
            this.comprobanteCompraItem.hostClasses = '';
        }
        this.comprobanteCompraItem.tabla.validar((): boolean => this.comprobanteCompraItem.elementos.length > 0);
    }

    private vincularItemsComprobantesAsociados() {
        // Vincular modificaciones entre los items y los comprobantes asociados
        this.subscription.add(
            this.comprobanteCompraComprobanteAsociado.postModificacionEvent.subscribe((c) => {
                // Buscar los items asociados al saldo del comprobante a saldar
                const items = this.comprobanteCompraItem.elementos.filter(
                    (i) => i.comprobanteCompraAsociadoId === c.comprobanteCompraAsociado.id,
                );
                // Capturar el precio unitario actual de cada item, para que en el caso de que sean varios, se mantenga la proporción
                const importeTotalActual = items.reduce((total, item) => total + (item.importeTotal ?? +0.0), +0.0);
                const proporciones = items.map((item) => {
                    return {
                        id: item.id,
                        proporcion: (item.importeTotal ?? +0.0) / (importeTotalActual > 0 ? importeTotalActual : 1),
                    };
                });
                // Calcular el precio unitario de cada item, según la proporción del importe total (importe)
                items.forEach((item) => {
                    if (item) {
                        const proporcion = proporciones.find((p) => item.id === p.id)?.proporcion ?? 0;
                        const importeTotal = Big(c.importe).times(Big(proporcion)).toNumber();
                        // Calcular el precio unitario del item, considerando que el importe total del item debe quedar como precio unitario + IVA
                        const importeNeto = Big(importeTotal)
                            .div(Big(1).plus(Big(item.tipoAlicuotaIVA?.porcentaje ?? 0).div(100)))
                            .toNumber();
                        item.precioUnitario = importeNeto;
                        item.recalcularImportes();
                    }
                });
                this.comprobanteCompraItem.tabla.recargar();
                this.comprobanteCompraMedioPago.recalcularImportes(this.comprobanteCompraItem.obtenerImporteTotal());
                this.validarItems();
            }),
        );
        this.subscription.add(
            this.comprobanteCompraItem.postModificacionEvent.subscribe((item) => {
                // Buscar el comprobante asociado al item y actualizar el importe
                const comprobanteAsociado = this.comprobanteCompraComprobanteAsociado.elementos.find(
                    (c) => c.comprobanteCompraAsociado?.id === item.comprobanteCompraAsociadoId,
                );
                if (comprobanteAsociado) {
                    comprobanteAsociado.importe = Math.abs(item.precioUnitario ?? +0.0);
                    this.comprobanteCompraComprobanteAsociado.recargar();
                    this.comprobanteCompraMedioPago.recalcularImportes(
                        this.comprobanteCompraItem.obtenerImporteTotal(),
                    );
                }
            }),
        );
        // Vincular eliminaciones entre los items y los comprobantes asociados
        this.subscription.add(
            this.comprobanteCompraComprobanteAsociado.postBajaEvent.subscribe((c) => {
                // Eliminar los items asociados al saldo del comprobante a saldar
                const items = this.comprobanteCompraItem.elementos.filter(
                    (i) => i.comprobanteCompraAsociadoId === c.id,
                );
                items.forEach((item) => {
                    if (item && item.id) {
                        this.comprobanteCompraItem.baseService.eliminar(item.id);
                    }
                });
                this.comprobanteCompraItem.tabla.recargar();
                this.comprobanteCompraMedioPago.recalcularImportes(this.comprobanteCompraItem.obtenerImporteTotal());
                this.validarItems();
            }),
        );
        this.subscription.add(
            this.comprobanteCompraItem.postBajaEvent.subscribe((item) => {
                // Eliminar el comprobante asociado al item
                const comprobanteAsociado = this.comprobanteCompraComprobanteAsociado.elementos.find(
                    (c) => c.comprobanteCompraAsociado?.id === item.comprobanteCompraAsociadoId,
                );
                if (comprobanteAsociado && comprobanteAsociado.comprobanteCompraAsociado?.id) {
                    this.comprobanteCompraComprobanteAsociado.baseService.eliminar(
                        comprobanteAsociado.comprobanteCompraAsociado.id,
                    );
                    this.comprobanteCompraComprobanteAsociado.recargar();
                    this.comprobanteCompraMedioPago.recalcularImportes(
                        this.comprobanteCompraItem.obtenerImporteTotal(),
                    );
                }
                this.validarItems();
            }),
        );
        // Vincular alta de comprobantes asociados con los items
        this.subscription.add(
            this.comprobanteCompraComprobanteAsociado.postAltaEvent.subscribe((c) => {
                // Crear un item asociado al saldo del comprobante a saldar
                this.comprobanteCompraItem.saldarComprobante(c.comprobanteCompraAsociado);
                this.comprobanteCompraMedioPago.recalcularImportes(this.comprobanteCompraItem.obtenerImporteTotal());
                this.validarItems();
            }),
        );
        this.subscription.add(
            this.comprobanteCompraItem.postAltaEvent.subscribe((item) => {
                this.validarItems();
            }),
        );
    }
}
