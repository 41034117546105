import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cliente } from '@entities/cliente';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class ClienteService extends BaseService<Cliente> {
    public constructor(http: HttpClient) {
        super('ventas/cliente', http);
    }
}
