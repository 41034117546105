<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="tipoDatoOpcional" class="form-label">Tipo</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Dato Opcional"
                        ngbPopover="Tipo de dato opcional."
                    ></i>
                    <ng-select
                        [items]="relaciones.tiposDatoOpcional"
                        labelForId="tipoDatoOpcional"
                        formControlName="tipoDatoOpcional"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoDatoOpcional', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="valor" class="form-label">Valor</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Valor"
                        ngbPopover="Valor del dato opcional."
                    ></i>
                    <textarea
                        maxlength="255"
                        placeholder="Valor"
                        formControlName="valor"
                        rows="3"
                        type="text"
                        class="form-control"
                        id="valor"
                    ></textarea>
                </div>
            </div>
        </div>
    </form>
</ng-template>
