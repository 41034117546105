import { BaseEntity } from '@entities/base.entity';
import { PaisNumeroDocumento } from '@entities/pais-numero-documento.entity';
import { Provincia } from '@entities/provincia.entity';

export class Pais extends BaseEntity {
    public nombre?: string;
    public codigoAFIP?: string;
    public numerosDocumento?: PaisNumeroDocumento[];
    public provincias?: Provincia[];
}
