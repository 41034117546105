import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Empresa } from 'src/domain/entities/empresa.entity';
import { BaseService } from '../../shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class EmpresaService extends BaseService<Empresa> {
    public constructor(http: HttpClient) {
        super('administracion/empresa', http);
    }
}
