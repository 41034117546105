<form [formGroup]="formCargaRapida" #formCargaRapidaElement class="mb-3" *ngIf="accion !== 'consulta'">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-lg-9">
                <div class="form-floating">
                    <input
                        placeholder="Descripción"
                        formControlName="descripcion"
                        type="text"
                        class="form-control"
                        id="descripcion"
                    />
                    <label for="descripcion" class="form-label">Descripción</label>
                </div>
            </div>
            <div class="col">
                <div class="form-floating input-group">
                    <input
                        placeholder="dd/mm/aaaa"
                        formControlName="periodo"
                        class="form-control"
                        id="periodoCargaRapida"
                        ngbDatepicker
                        #periodoCargaRapida="ngbDatepicker"
                    />
                    <label for="periodoCargaRapida" class="form-label">Período</label>
                    <button
                        *ngIf="formCargaRapida.get('periodo')?.value && !formCargaRapida?.get('periodo')?.disabled"
                        class="btn btn-sm btn-outline-secondary bi bi-x-lg"
                        (click)="formCargaRapida.get('periodo')?.setValue(null)"
                        type="button"
                    ></button>
                    <button
                        *ngIf="!formCargaRapida.get('periodo')?.disabled"
                        class="btn btn-outline-secondary bi bi-calendar3"
                        (click)="periodoCargaRapida.toggle()"
                        type="button"
                    ></button>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col" [ngClass]="{ 'd-none': facturacionInvoice }">
                <div class="input-group">
                    <span class="input-group-text"
                        >IVA<span *ngIf="cargando.tipoAlicuotaIVA" class="spinner-grow spinner-grow-sm"> </span
                    ></span>
                    <ng-select
                        [items]="relaciones.tiposAlicuotaIVA"
                        labelForId="tipoAlicuotaIVA"
                        formControlName="tipoAlicuotaIVA"
                        class="form-control"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col">
                <div class="input-group">
                    <span class="input-group-text">#</span>
                    <div class="form-floating">
                        <input
                            placeholder="Cantidad"
                            formControlName="cantidad"
                            [mask]="mascaraImporte"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="1000000000000000"
                            [outputTransformFn]="toFixedImporte"
                            class="form-control"
                            id="cantidad"
                        />
                        <label for="cantidad" class="form-label">Cantidad</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="input-group">
                    <span class="input-group-text">{{ moneda.simbolo }}</span>
                    <div class="form-floating">
                        <input
                            placeholder="Precio Unitario"
                            formControlName="precioUnitario"
                            [mask]="mascaraImporte"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="1000000000000000"
                            [outputTransformFn]="toFixedImporte"
                            class="form-control"
                            id="precioUnitario"
                        />
                        <label for="precioUnitario" class="form-label">Precio Unitario</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <button
                    class="btn btn-primary"
                    popoverTitle="Agregar Item"
                    ngbPopover="Agregar item a la tabla de items del comprobante de venta. Se utilizarán los datos ingresados en los campos de carga rápida."
                    (click)="agregarItemCargaRapida()"
                    type="button"
                >
                    Agregar Item
                </button>
            </div>
        </div>
    </div>
</form>

<div class="mx-n3 border-bottom mb-3" *ngIf="accion !== 'consulta'"></div>

<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div [ngClass]="{ col: facturacionSimple, 'col-lg-8': !facturacionSimple && !facturacionInvoice }">
                    <label for="producto" class="form-label">Producto</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Producto"
                        ngbPopover="Producto del item."
                    ></i>
                    <ng-select
                        [items]="relaciones.productos"
                        labelForId="producto"
                        formControlName="producto"
                        class="form-control"
                        (change)="cambiarProducto($event)"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('producto', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col" [ngClass]="{ 'd-none': facturacionSimple || facturacionInvoice }">
                    <label for="codigoNCM" class="form-label">Código NCM</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Código NCM"
                        ngbPopover="Código NCM del producto."
                    ></i>
                    <input
                        placeholder="Código NCM"
                        formControlName="codigoNCM"
                        type="text"
                        class="form-control"
                        id="codigoNCM"
                    />
                    <ng-container *ngFor="let error of formErrores('codigoNCM', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="descripcion" class="form-label">Descripción</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Descripción"
                        ngbPopover="Descripción del item."
                    ></i>
                    <textarea
                        maxlength="255"
                        placeholder="Descripción"
                        formControlName="descripcion"
                        rows="3"
                        type="text"
                        class="form-control"
                        id="descripcion"
                    ></textarea>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col" [ngClass]="{ 'd-none': facturacionInvoice }">
                    <label for="tipoAlicuotaIVA" class="form-label">Alícuota IVA</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Alícuota de IVA"
                        ngbPopover="Alícuota de IVA del item."
                    ></i>
                    <ng-select
                        [items]="relaciones.tiposAlicuotaIVA"
                        labelForId="tipoAlicuotaIVA"
                        formControlName="tipoAlicuotaIVA"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoAlicuotaIVA', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col" [ngClass]="{ 'd-none': facturacionSimple || facturacionInvoice }">
                    <label for="unidadMedida" class="form-label">Unidad de Medida</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Unidad de Medida"
                        ngbPopover="Unidad de medida del item."
                    ></i>
                    <ng-select
                        [items]="relaciones.unidadesMedida"
                        labelForId="unidadMedida"
                        [searchFn]="buscarUnidadMedida"
                        formControlName="unidadMedida"
                        class="form-control"
                    >
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <span [ngOptionHighlight]="search">{{ item.nombre }}</span
                            >&NonBreakingSpace;<small [ngOptionHighlight]="search"
                                >&#40;{{ item.abreviatura }}&#41;</small
                            >
                        </ng-template>
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('unidadMedida', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="periodo" class="form-label">Período</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Período"
                        ngbPopover="Período del item."
                    ></i>
                    <div class="input-group">
                        <input
                            placeholder="dd/mm/aaaa"
                            formControlName="periodo"
                            class="form-control"
                            id="periodo"
                            ngbDatepicker
                            #periodo="ngbDatepicker"
                        />
                        <button
                            *ngIf="form.get('periodo')?.value && !form?.get('periodo')?.disabled"
                            class="btn btn-sm btn-outline-secondary bi bi-x-lg"
                            (click)="form.get('periodo')?.setValue(null)"
                            type="button"
                        ></button>
                        <button
                            *ngIf="!form.get('periodo')?.disabled"
                            class="btn btn-outline-secondary bi bi-calendar3"
                            (click)="periodo.toggle()"
                            type="button"
                        ></button>
                        <ng-container *ngFor="let error of formErrores('periodo', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="cantidad" class="form-label">Cantidad</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Cantidad"
                        ngbPopover="Cantidad del item."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">#</span>
                        <input
                            placeholder="Cantidad"
                            formControlName="cantidad"
                            [mask]="mascaraImporte"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="1000000000000000"
                            [outputTransformFn]="toFixedImporte"
                            class="form-control"
                            id="cantidad"
                        />
                        <span class="input-group-text" *ngIf="unidadMedidaAbreviatura">{{
                            unidadMedidaAbreviatura
                        }}</span>
                        <ng-container *ngFor="let error of formErrores('cantidad', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="col">
                    <label for="precioUnitario" class="form-label">Precio Unitario</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Precio Unitario"
                        ngbPopover="Precio unitario del item."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">{{ moneda.simbolo }}</span>
                        <input
                            placeholder="Precio Unitario"
                            formControlName="precioUnitario"
                            [mask]="mascaraImporte"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="1000000000000000"
                            [outputTransformFn]="toFixedImporte"
                            class="form-control"
                            id="precioUnitario"
                        />
                        <ng-container *ngFor="let error of formErrores('precioUnitario', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="col" [ngClass]="{ 'd-none': facturacionSimple || facturacionInvoice }">
                    <label for="importeBonificado" class="form-label">Importe Bonificado</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Importe Bonificado"
                        ngbPopover="Importe bonificado del item."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">{{ moneda.simbolo }}</span>
                        <input
                            placeholder="Importe Bonificado"
                            formControlName="importeBonificado"
                            [mask]="mascaraImporte"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="1000000000000000"
                            [outputTransformFn]="toFixedImporte"
                            class="form-control"
                            id="importeBonificado"
                        />
                        <ng-container
                            *ngFor="let error of formErrores('importeBonificado', form, formErroresControles)"
                        >
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
