import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnidadMedida } from '@entities/unidad-medida.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class UnidadMedidaService extends BaseService<UnidadMedida> {
    public constructor(http: HttpClient) {
        super('ventas/unidad-medida', http);
    }
}
