import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ThemeService } from '@core/services/theme.service';
import { RespuestaDTO } from '@dtos/respuesta.dto';
import { BaseEntity } from '@entities/base.entity';
import { AutoUnsubscribe } from '@helpers/auto-unsubscribe.decorator';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmacionComponent } from '@shared/components/modal-confirmacion/modal-confirmacion.component';
import { ModalFormularioComponent } from '@shared/components/modal-formulario/modal-formulario.component';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { AccionTabla } from '@shared/interfaces/accion-tabla.interface';
import { BotonTabla } from '@shared/interfaces/boton-tabla.interface';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { BaseService } from '@shared/services/base.service';
import { GlobalConfigService } from '@shared/services/global-config.service';
import { ToastService } from '@shared/services/toast.service';
import { BaseViewEntity } from '@views/base.view';
import { Observable, Subject, Subscription, finalize, forkJoin } from 'rxjs';

@Directive()
@AutoUnsubscribe
export abstract class ABMCComponent<T extends BaseEntity | BaseViewEntity> implements OnInit, AfterViewInit {
    protected subscription = new Subscription();
    protected themeService = inject(ThemeService);
    protected toastService = inject(ToastService);
    protected modalService = inject(NgbModal);
    protected renderer = inject(Renderer2);

    @HostBinding('className') public hostClasses!: string;

    @Input() public inicializarTabla = true;

    @Output() public init: EventEmitter<ABMCComponent<T>> = new EventEmitter();
    @Output() public afterViewInit: EventEmitter<ABMCComponent<T>> = new EventEmitter();
    @Output() public postAltaEvent: EventEmitter<any> = new EventEmitter();
    @Output() public postBajaEvent: EventEmitter<any> = new EventEmitter();
    @Output() public postModificacionEvent: EventEmitter<any> = new EventEmitter();

    protected genero: string | undefined;
    protected anchoModal: string;
    protected modalPantallaCompleta: boolean;
    protected elementoActual: T | undefined;
    protected elementoCargado: Subject<T> = new Subject();
    protected elementoInicializado: Subject<void> = new Subject();

    public modalRef!: NgbModalRef;
    public elementos: T[] = [];
    public acciones: AccionTabla[] = [];
    public botones: BotonTabla[] = [];
    public popoverDelay = GlobalConfigService.POPOVER_DELAY;
    public relaciones: any = {};
    public footerTemplate: TemplateRef<any> | null = null;

    public abstract nombre: string;
    public abstract columnas: ColumnaTabla[];
    public abstract tabla: TablaComponent;
    public abstract form: FormGroup;
    public abstract formTemplate: TemplateRef<any>;
    public abstract formElement: ElementRef<any>;

    protected abstract formErroresControles: { [key: string]: { [error: string]: string } };

    private accionesDefault = {
        baja: {
            nombre: 'baja',
            icono: 'x-lg',
            color: 'warning',
            tooltip: `Eliminar`,
            deshabilitado: (elemento: BaseEntity) => !elemento || (elemento && elemento.baja != null),
        },
        modificacion: {
            nombre: 'modificacion',
            icono: 'pencil-fill',
            tooltip: `Modificar`,
            deshabilitado: (elemento: BaseEntity) => !elemento || (elemento && elemento.baja != null),
        },
        consulta: {
            nombre: 'consulta',
            icono: 'search',
            tooltip: `Consultar`,
        },
        destruccion: {
            nombre: 'destruccion',
            icono: 'trash',
            color: 'danger',
            tooltip: `Destruir`,
        },
        restauracion: {
            nombre: 'restauracion',
            texto: `Restaurar`,
            grupo: 1,
            deshabilitado: (elemento: BaseEntity) => !elemento || (elemento && elemento.baja == null),
        },
    };

    private botonesDefault = {
        alta: {
            text: `Nuevo`,
            action: this.alta.bind(this),
        },
    };

    public constructor(
        public baseService: BaseService<T>,
        configuracion: {
            inicializaciones?: {
                nombre?: string;
                genero?: string;
                anchoModal?: string;
                modalPantallaCompleta?: boolean;
                footerModalTemplate?: TemplateRef<any>;
                acciones?: AccionTabla[];
                botones?: BotonTabla[];
            };
            opciones?: {
                botones?: { alta?: boolean };
                acciones?: {
                    baja?: boolean;
                    modificacion?: boolean;
                    consulta?: boolean;
                    destruccion?: boolean;
                    restauracion?: boolean;
                };
            };
        },
    ) {
        const inicializaciones = configuracion?.inicializaciones;
        const opciones = configuracion?.opciones;
        this.genero = inicializaciones?.genero;
        this.anchoModal = inicializaciones?.anchoModal ?? '';
        this.modalPantallaCompleta = inicializaciones?.modalPantallaCompleta ?? false;

        // Inicialización de Acciones de tabla y Botones
        if (inicializaciones?.acciones) {
            this.acciones = inicializaciones.acciones;
        }
        if (inicializaciones?.botones) {
            this.botones = inicializaciones.botones;
        }

        // Configuración de botones estándar
        if (opciones?.botones?.alta === true) {
            this.botones.unshift({
                ...this.botonesDefault.alta,
                text: `Nuev${this.generoElemento(inicializaciones?.nombre)} ${inicializaciones?.nombre}`,
            });
        }

        // Configuración de acciones estándar
        if (opciones?.acciones?.modificacion === true) {
            this.acciones.unshift({
                ...this.accionesDefault.modificacion,
                tooltip: `Modificar ${inicializaciones?.nombre}`,
            });
        }
        if (opciones?.acciones?.consulta === true) {
            this.acciones.unshift({
                ...this.accionesDefault.consulta,
                tooltip: `Consultar ${inicializaciones?.nombre}`,
            });
        }
        if (opciones?.acciones?.baja === true) {
            this.acciones.push({
                ...this.accionesDefault.baja,
                tooltip: `Eliminar ${inicializaciones?.nombre}`,
            });
        }
        if (opciones?.acciones?.destruccion === true) {
            this.acciones.push({
                ...this.accionesDefault.destruccion,
                tooltip: `Destruir ${inicializaciones?.nombre}`,
            });
        }
        if (opciones?.acciones?.restauracion === true) {
            this.acciones.unshift({
                ...this.accionesDefault.restauracion,
                texto: `Restaurar ${inicializaciones?.nombre}`,
            });
        }
    }

    public ngOnInit(): void {
        this.init.emit(this);
    }

    public ngAfterViewInit(): void {
        this.afterViewInit.emit(this);
    }

    public ajaxHandler(event: any, service = this.baseService) {
        const callback = event.callback;
        const parametros = {
            ...event.dataTablesParameters,
        };
        this.subscription.add(
            service.obtenerTodos(parametros).subscribe({
                next: (resp: RespuestaDTO<T>) => {
                    callback({
                        recordsTotal: resp.cantidadTotal,
                        recordsFiltered: resp.cantidadFiltrada,
                        data: resp.elementos,
                    });
                },
                error: (error: any) => {
                    callback({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: [],
                    });
                    this.toastService.errorHandler(error);
                },
            }),
        );
    }

    public accionHandler(accion: AccionTabla) {
        if (!accion.nombre) {
            return;
        }
        const callback =
            typeof (this as any)[accion.nombre] === 'function'
                ? (this as any)[accion.nombre].bind(this)
                : () => {
                      this.toastService.errorHandler({
                          message: `No se encontró el método '${accion.nombre}' en el componente '${this.constructor.name}'`,
                          class: 'warning',
                      });
                  };
        callback(accion.data);
    }

    public botonHandler(boton: BotonTabla) {
        if (!boton.name) {
            return;
        }
        const callback =
            typeof (this as any)[boton.name] === 'function'
                ? (this as any)[boton.name].bind(this)
                : () => {
                      this.toastService.errorHandler({
                          message: `No se encontró el método '${boton.name}' en el componente '${this.constructor.name}'`,
                          class: 'warning',
                      });
                  };
        callback();
    }

    public alta(): void {
        this.modalRef = this.modalService.open(ModalFormularioComponent, {
            centered: true,
            size: this.anchoModal,
            fullscreen: this.modalPantallaCompleta,
        });
        this.form.reset();
        this.form.disable();
        this.modalRef.componentInstance.formTemplate = this.formTemplate;
        this.modalRef.componentInstance.form = this.form;
        this.modalRef.componentInstance.titulo = `Nuev${this.generoElemento()} ${this.nombre}`;
        this.modalRef.componentInstance.botonAceptar = true;
        this.modalRef.componentInstance.footerTemplate = this.footerTemplate;
        this.modalRef.componentInstance.cargando = true;
        this.subscription.add(
            forkJoin({
                relaciones: this.obtenerRelaciones(),
            })
                .pipe(
                    finalize(() => {
                        this.modalRef.componentInstance.cargando = false;
                        this.form.enable();
                    }),
                )
                .subscribe({
                    next: ({ relaciones }) => {
                        this.cargarRelaciones(relaciones);
                        this.inicializarElemento();
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
        this.subscription.add(
            this.modalRef.componentInstance.aceptarHandler.subscribe(() => {
                this.formValidar(this.form, this.formElement);
                if (!this.form.valid) {
                    return;
                }
                this.modalRef.componentInstance.cargando = true;
                this.subscription.add(
                    this.baseService
                        .crear(this.crearElemento())
                        .pipe(finalize(() => (this.modalRef.componentInstance.cargando = false)))
                        .subscribe({
                            next: (resp: any) => {
                                setTimeout(() => {
                                    this.postAlta(resp);
                                });
                            },
                            error: this.toastService.errorHandler.bind(this.toastService),
                        }),
                );
            }),
        );
    }

    public baja(elemento: T, service = this.baseService) {
        if (!elemento || !elemento.id) {
            return;
        }
        this.modalRef = this.modalService.open(ModalConfirmacionComponent, {
            centered: true,
        });
        this.modalRef.componentInstance.titulo = `Eliminar ${this.nombre}: ID ${elemento.id}`;
        this.modalRef.componentInstance.mensaje = '¿Está seguro que desea eliminar este registro?';
        this.subscription.add(
            this.modalRef.componentInstance.aceptarHandler.subscribe(() => {
                if (!elemento || !elemento.id) {
                    return;
                }
                this.modalRef.componentInstance.cargando = true;
                this.subscription.add(
                    service
                        .eliminar(elemento.id)
                        .pipe(finalize(() => (this.modalRef.componentInstance.cargando = false)))
                        .subscribe({
                            next: () => {
                                setTimeout(() => {
                                    this.postBaja(elemento);
                                });
                            },
                            error: this.toastService.errorHandler.bind(this.toastService),
                        }),
                );
            }),
        );
    }

    public modificacion(elemento: T) {
        if (!elemento || !elemento.id) {
            return;
        }
        this.modalRef = this.modalService.open(ModalFormularioComponent, {
            centered: true,
            size: this.anchoModal,
            fullscreen: this.modalPantallaCompleta,
        });
        this.form.reset();
        this.form.disable();
        this.modalRef.componentInstance.formTemplate = this.formTemplate;
        this.modalRef.componentInstance.form = this.form;
        this.modalRef.componentInstance.titulo = `Modificar ${this.nombre}: ID ${elemento.id}`;
        this.modalRef.componentInstance.botonAceptar = true;
        this.modalRef.componentInstance.footerTemplate = this.footerTemplate;
        this.modalRef.componentInstance.cargando = true;
        this.subscription.add(
            forkJoin({
                relaciones: this.obtenerRelaciones(),
                elemento: this.baseService.obtener(elemento.id),
            })
                .pipe(
                    finalize(() => {
                        this.modalRef.componentInstance.cargando = false;
                        this.form.enable();
                    }),
                )
                .subscribe({
                    next: ({ relaciones, elemento }) => {
                        this.cargarRelaciones(relaciones);
                        this.cargarElemento(elemento);
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
        this.subscription.add(
            this.modalRef.componentInstance.aceptarHandler.subscribe(() => {
                this.formValidar(this.form, this.formElement);
                if (!this.form.valid) {
                    return;
                }
                this.modalRef.componentInstance.cargando = true;
                this.subscription.add(
                    this.baseService
                        .modificar(this.crearElemento())
                        .pipe(finalize(() => (this.modalRef.componentInstance.cargando = false)))
                        .subscribe({
                            next: (resp: any) => {
                                setTimeout(() => {
                                    this.postModificacion(resp);
                                });
                            },
                            error: this.toastService.errorHandler.bind(this.toastService),
                        }),
                );
            }),
        );
    }

    public consulta(elemento: T, service = this.baseService) {
        if (!elemento || !elemento.id) {
            return;
        }
        this.modalRef = this.modalService.open(ModalFormularioComponent, {
            centered: true,
            size: this.anchoModal,
            fullscreen: this.modalPantallaCompleta,
        });
        this.form.reset();
        this.form.disable();
        this.modalRef.componentInstance.formTemplate = this.formTemplate;
        this.modalRef.componentInstance.titulo = `Consultar ${this.nombre}: ID ${elemento.id}`;
        this.modalRef.componentInstance.footerTemplate = this.footerTemplate;
        this.modalRef.componentInstance.cargando = true;
        this.subscription.add(
            forkJoin({
                relaciones: this.obtenerRelaciones(),
                elemento: service.obtener(elemento.id),
            })
                .pipe(finalize(() => (this.modalRef.componentInstance.cargando = false)))
                .subscribe({
                    next: ({ relaciones, elemento }) => {
                        this.cargarRelaciones(relaciones);
                        this.cargarElemento(elemento);
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    public destruccion(elemento: T) {
        if (!elemento || !elemento.id) {
            return;
        }
        const modalRef = this.modalService.open(ModalConfirmacionComponent, {
            centered: true,
        });
        modalRef.componentInstance.advertencia = true;
        modalRef.componentInstance.titulo = `Destruir ${this.nombre}: ID ${elemento.id}`;
        modalRef.componentInstance.mensaje =
            '¿Está seguro que desea destruir este registro?<br/><strong>No podrá ser restaurado.</strong>';
        this.subscription.add(
            modalRef.componentInstance.aceptarHandler.subscribe(() => {
                if (!elemento || !elemento.id) {
                    return;
                }
                modalRef.componentInstance.cargando = true;
                this.subscription.add(
                    this.baseService
                        .destruir(elemento.id)
                        .pipe(finalize(() => (modalRef.componentInstance.cargando = false)))
                        .subscribe({
                            next: (resp: any) => {
                                this.toastService.successHandler(
                                    `${this.nombre}, ID ${elemento.id}, destruid${this.generoElemento()} con éxito`,
                                );
                                modalRef.close('aceptar');
                                this.tabla.recargar();
                            },
                            error: this.toastService.errorHandler.bind(this.toastService),
                        }),
                );
            }),
        );
    }

    public restauracion(elemento: T) {
        if (!elemento || !elemento.id) {
            return;
        }
        const modalRef = this.modalService.open(ModalConfirmacionComponent, {
            centered: true,
        });
        modalRef.componentInstance.titulo = `Restaurar ${this.nombre}: ID ${elemento.id}`;
        modalRef.componentInstance.mensaje = '¿Está seguro que desea restaurar este registro?';
        this.subscription.add(
            modalRef.componentInstance.aceptarHandler.subscribe(() => {
                if (!elemento || !elemento.id) {
                    return;
                }
                modalRef.componentInstance.cargando = true;
                this.subscription.add(
                    this.baseService
                        .restaurar(elemento.id)
                        .pipe(finalize(() => (modalRef.componentInstance.cargando = false)))
                        .subscribe({
                            next: (resp: any) => {
                                this.toastService.successHandler(
                                    `${this.nombre}, ID ${elemento.id}, restaurad${this.generoElemento()} con éxito`,
                                );
                                modalRef.close('aceptar');
                                this.tabla.recargar();
                            },
                            error: this.toastService.errorHandler.bind(this.toastService),
                        }),
                );
            }),
        );
    }

    public formErrores(control: string, form: FormGroup, formErroresControles: any): string[] {
        if (!control || !form || !form.get(control)) {
            return [];
        }
        const erroresControl = form.get(control)?.errors;
        if (!erroresControl) {
            return [];
        }
        const mensajes: { [key: string]: string } = {
            ngbDate: {
                invalid: 'El formato de la fecha es inválido',
            },
            required: 'Este campo es requerido',
            email: 'El formato del email es inválido',
            mask: 'El formato del número es inválido',
            ...formErroresControles[control],
        };
        const errores = this.obtenerErrores(mensajes, erroresControl);
        return errores;
    }

    public getThemeHelpEnabled(): boolean {
        return this.themeService.currentHelp === 'true';
    }

    protected obtenerErrores(mensajes: { [x: string]: any }, erroresControl: { [x: string]: any }) {
        let errores: string[] = [];
        Object.keys(erroresControl).forEach((key) => {
            if (mensajes[key] && typeof mensajes[key] === 'string') {
                errores.push(mensajes[key]);
            }
            if (mensajes[key] && typeof mensajes[key] === 'object') {
                errores = errores.concat(this.obtenerErrores(mensajes[key], erroresControl[key]));
            }
        });
        return errores;
    }

    protected formValidar(form: FormGroup, formElement: ElementRef<any>) {
        this.renderer.removeClass(formElement.nativeElement, 'was-validated');
        Object.keys(form.controls).forEach((key) => {
            form.get(key)?.markAsTouched();
            form.get(key)?.updateValueAndValidity();
        });
        this.renderer.addClass(formElement.nativeElement, 'was-validated');
    }

    protected abstract obtenerRelaciones(): Observable<any>;

    protected cargarRelaciones(relaciones: any) {
        this.relaciones = {};
        Object.keys(relaciones).forEach((key) => {
            this.relaciones[key] = relaciones[key]?.elementos ?? relaciones[key];
        });
    }

    protected cargarElemento(elemento: T) {
        this.elementoActual = elemento;
        this.form.patchValue(elemento);
        this.elementoCargado.next(elemento);
    }

    protected generoElemento(texto?: string): string {
        if (this.genero) {
            return this.genero;
        }

        const nombre = texto ?? this.nombre;
        return nombre?.charAt(nombre?.length - 1) === 'a' ? 'a' : 'o';
    }

    protected crearElemento(): T {
        return { ...this.elementoActual, ...this.form.value };
    }

    protected inicializarElemento() {
        this.elementoActual = {} as T;
        this.elementoInicializado.next();
    }

    protected postAlta(resp: any): void {
        this.toastService.successHandler(`${this.nombre}, ID ${resp?.id}, cread${this.generoElemento()} con éxito`);
        this.modalRef?.close('aceptar');
        this.tabla?.recargar();
        this.postAltaEvent.emit(resp);
    }

    protected postBaja(resp: any): void {
        this.toastService.successHandler(`${this.nombre}, ID ${resp?.id}, eliminad${this.generoElemento()} con éxito`);
        this.modalRef?.close('aceptar');
        this.tabla?.recargar();
        this.postBajaEvent.emit(resp);
    }

    protected postModificacion(resp: any): void {
        this.toastService.successHandler(`${this.nombre}, ID ${resp?.id}, modificad${this.generoElemento()} con éxito`);
        this.modalRef?.close('aceptar');
        this.tabla?.recargar();
        this.postModificacionEvent.emit(resp);
    }
}
