<h2 class="mb-3">{{ titulo }}</h2>

<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
    (botonHandler)="botonHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="nombre" class="form-label">Nombre</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Nombre"
                        ngbPopover="Nombre de la tarea a realizar."
                    ></i>
                    <input placeholder="Nombre" formControlName="nombre" type="text" class="form-control" id="nombre" />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="empresa" class="form-label">Empresa</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Empresa"
                        ngbPopover="Empresa a la que pertenece la tarea a realizar."
                    ></i>
                    <ng-select
                        [items]="relaciones.empresas"
                        labelForId="empresa"
                        formControlName="empresa"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('empresa', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="descripcion" class="form-label">Descripción</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Descripción"
                        ngbPopover="Descripción de la tarea a realizar."
                    ></i>
                    <textarea
                        placeholder="Descripción"
                        formControlName="descripcion"
                        class="form-control"
                        id="descripcion"
                    ></textarea>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="parametros" class="form-label">Parámetros</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Parámetros"
                        ngbPopover="Parámetros de la tarea a realizar. Se deben ingresar en formato JSON."
                    ></i>
                    <textarea
                        placeholder="Parámetros"
                        formControlName="parametros"
                        class="form-control"
                        id="parametros"
                    ></textarea>
                    <ng-container *ngFor="let error of formErrores('parametros', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <div class="form-check form-switch mb-2">
                        <label for="finalizado" class="form-check-label">Finalizado</label
                        ><i
                            *ngIf="getThemeHelpEnabled()"
                            class="instrucciones-pantalla bi bi-question-circle"
                            popoverTitle="Finalizado"
                            ngbPopover="Indica si la tarea ha sido finalizada. Indica el resultado de la tarea en formato JSON."
                        ></i>
                        <input
                            placeholder="Finalizado"
                            formControlName="finalizado"
                            type="checkbox"
                            class="form-check-input me-1"
                            id="finalizado"
                        />
                    </div>
                    <textarea
                        placeholder="Resultado"
                        formControlName="resultado"
                        class="form-control"
                        id="resultado"
                    ></textarea>
                    <ng-container *ngFor="let error of formErrores('resultado', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <div class="form-check form-switch mb-2">
                        <label for="error" class="form-check-label">Error</label
                        ><i
                            *ngIf="getThemeHelpEnabled()"
                            class="instrucciones-pantalla bi bi-question-circle"
                            popoverTitle="Error"
                            ngbPopover="Indica si la tarea ha tenido un error y el detalle del mismo."
                        ></i>
                        <input
                            placeholder="Error"
                            formControlName="error"
                            class="form-check-input me-1"
                            type="checkbox"
                            id="error"
                        />
                    </div>
                    <input
                        placeholder="Mensaje de error"
                        formControlName="mensajeError"
                        type="text"
                        class="form-control"
                        id="mensajeError"
                    />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="fechaInicio" class="form-label">Fecha de Inicio</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Fecha de Inicio"
                        ngbPopover="Fecha de inicio de la tarea."
                    ></i>
                    <div class="input-group">
                        <input
                            placeholder="dd/mm/aaaa"
                            formControlName="fechaInicio"
                            class="form-control"
                            id="fechaInicio"
                            ngbDatepicker
                            #fechaInicio="ngbDatepicker"
                        />
                        <button
                            *ngIf="form.get('fechaInicio')?.value && !form?.get('fechaInicio')?.disabled"
                            class="btn btn-sm btn-outline-secondary bi bi-x-lg"
                            (click)="form.get('fechaInicio')?.setValue(null)"
                            type="button"
                        ></button>
                        <button
                            *ngIf="!form.get('fechaInicio')?.disabled"
                            class="btn btn-outline-secondary bi bi-calendar3"
                            (click)="fechaInicio.toggle()"
                            type="button"
                        ></button>
                        <ng-container *ngFor="let error of formErrores('fechaInicio', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="col">
                    <label for="horaInicio" class="form-label">Hora de Inicio</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Hora de Inicio"
                        ngbPopover="Hora de inicio de la tarea."
                    ></i>
                    <div class="input-group">
                        <ngb-timepicker formControlName="horaInicio"></ngb-timepicker>
                        <ng-container *ngFor="let error of formErrores('horaInicio', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="fechaFin" class="form-label">Fecha de Fin</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Fecha de Fin"
                        ngbPopover="Fecha de fin de la tarea."
                    ></i>
                    <div class="input-group">
                        <input
                            placeholder="dd/mm/aaaa"
                            formControlName="fechaFin"
                            class="form-control"
                            id="fechaFin"
                            ngbDatepicker
                            #fechaFin="ngbDatepicker"
                        />
                        <button
                            *ngIf="form.get('fechaFin')?.value && !form?.get('fechaFin')?.disabled"
                            class="btn btn-sm btn-outline-secondary bi bi-x-lg"
                            (click)="form.get('fechaFin')?.setValue(null)"
                            type="button"
                        ></button>
                        <button
                            *ngIf="!form.get('fechaFin')?.disabled"
                            class="btn btn-outline-secondary bi bi-calendar3"
                            (click)="fechaFin.toggle()"
                            type="button"
                        ></button>
                        <ng-container *ngFor="let error of formErrores('fechaFin', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="col">
                    <label for="horaFin" class="form-label">Hora de Fin</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Hora de Fin"
                        ngbPopover="Hora de fin de la tarea."
                    ></i>
                    <div class="input-group">
                        <ngb-timepicker formControlName="horaFin"></ngb-timepicker>
                        <ng-container *ngFor="let error of formErrores('horaFin', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
