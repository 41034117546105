import { BaseEntity } from '@entities/base.entity';
import { Cliente } from '@entities/cliente';
import { ComprobanteVentaDatoOpcional } from '@entities/comprobante-venta-dato-opcional.entity';
import { ComprobanteVentaItem } from '@entities/comprobante-venta-item.entity';
import { ComprobanteVentaMedioPago } from '@entities/comprobante-venta-medio-pago.entity';
import { ComprobanteVentaSaldo } from '@entities/comprobante-venta-saldo.entity';
import { ComprobanteVentaTributo } from '@entities/comprobante-venta-tributo.entity';
import { Comprobante } from '@entities/comprobante.entity';
import { Empresa } from '@entities/empresa.entity';
import { Idioma } from '@entities/idioma.entity';
import { Moneda } from '@entities/moneda.entity';
import { PuntoVenta } from '@entities/punto-venta.entity';
import { TipoActividad } from '@entities/tipo-actividad.entity';
import { TipoConcepto } from '@entities/tipo-concepto.entity';

export class ComprobanteVenta extends BaseEntity {
    public cotizacion?: number;
    public qr?: Buffer;
    public comprobante?: Comprobante;
    public empresa?: Empresa;
    public cliente?: Cliente;
    public puntoVenta?: PuntoVenta;
    public moneda?: Moneda;
    public tipoConcepto?: TipoConcepto;
    public tipoActividad?: TipoActividad;
    public idioma?: Idioma;
    public items?: ComprobanteVentaItem[];
    public mediosPago?: ComprobanteVentaMedioPago[];
    public tributos?: ComprobanteVentaTributo[];
    public datosOpcionales?: ComprobanteVentaDatoOpcional[];
    public comprobantesAsociados?: ComprobanteVentaSaldo[];
    public importeTotal?: number;
}
