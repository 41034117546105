import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipoPersonaEnum } from '@entities/enums/tipo-persona.enum';
import { PaisNumeroDocumento } from '@entities/pais-numero-documento.entity';
import { BaseService } from '@shared/services/base.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaisNumeroDocumentoService extends BaseService<PaisNumeroDocumento> {
    public constructor(http: HttpClient) {
        super('configuracion/pais-numero-documento', http);
    }

    public obtenerPorTipoPersonaYPais(tipoPersona: TipoPersonaEnum, paisId: number): Observable<PaisNumeroDocumento> {
        const url = `${this.API_URL}/${this.endpointUrl}/tipo-persona-pais?tipoPersona=${tipoPersona}&paisId=${paisId}`;
        return this.http.get<PaisNumeroDocumento>(url);
    }
}
