import { Injectable } from '@angular/core';
import { ToastInterface } from '../interfaces/toast.interface';
import { GlobalConfigService } from './global-config.service';

@Injectable({ providedIn: 'root' })
export class ToastService {
    public toasts: ToastInterface[] = [];

    public show(toast: ToastInterface) {
        this.toasts.push(toast);
    }

    public remove(toast: ToastInterface) {
        this.toasts = this.toasts.filter((t) => t !== toast);
    }

    public clear() {
        this.toasts.splice(0, this.toasts.length);
    }

    public errorHandler(error: any): string {
        console.error(error);
        let mensaje =
            error && error.error && error.error.message
                ? error.error.message
                : error && error.statusText
                ? error.statusText
                : error && error.message
                ? error.message
                : error && (typeof error === 'string' || typeof error === 'number')
                ? `${error}`
                : 'Error desconocido';
        mensaje = this.normalizarMensaje(mensaje);
        this.show({
            header: `${error.header ?? 'Error'} ${error?.status ? `[${error.status}]` : ''}`,
            body: mensaje,
            className: error.class ?? 'danger',
            delay: GlobalConfigService.TOAST_DELAY_ERROR,
            icon: 'exclamation-triangle-fill',
        });
        return mensaje;
    }

    public successHandler(mensaje: string, img?: HTMLImageElement): string {
        mensaje = this.normalizarMensaje(mensaje);
        this.show({
            header: 'Éxito',
            body: mensaje,
            className: 'success',
            delay: GlobalConfigService.TOAST_DELAY_SUCCESS,
            icon: 'check-circle-fill',
            img,
        });
        return mensaje;
    }

    public infoHandler(mensaje: string): string {
        mensaje = this.normalizarMensaje(mensaje);
        this.show({
            header: 'Información',
            body: mensaje,
            className: 'info',
            delay: GlobalConfigService.TOAST_DELAY_INFO,
            icon: 'info-circle-fill',
        });
        return mensaje;
    }

    public warningHandler(mensaje: string): string {
        mensaje = this.normalizarMensaje(mensaje);
        this.show({
            header: 'Advertencia',
            body: mensaje,
            className: 'warning',
            delay: GlobalConfigService.TOAST_DELAY_WARNING,
            icon: 'exclamation-triangle-fill',
        });
        return mensaje;
    }

    private normalizarMensaje(mensaje: string | string[]): string {
        mensaje = Array.isArray(mensaje)
            ? mensaje.map((m) => m.charAt(0).toUpperCase() + m.slice(1)).join('. ')
            : mensaje.charAt(0).toUpperCase() + mensaje.slice(1);
        if (!mensaje.endsWith('.') && !mensaje.endsWith('!') && !mensaje.endsWith('?')) {
            mensaje += '.';
        }
        return mensaje;
    }
}
