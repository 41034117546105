import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TipoConcepto } from '@entities/tipo-concepto.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { TipoConceptoService } from '@ventas/services/tipo-concepto.service';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'kratos-tipo-concepto',
    templateUrl: './tipo-concepto.component.html',
    styleUrls: ['./tipo-concepto.component.scss'],
})
export class TipoConceptoComponent extends ABMCComponent<TipoConcepto> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Tipo de Concepto';
    public titulo = 'Tipos de Concepto';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        desde: [''],
        hasta: [''],
        codigoAFIP: [''],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Desde',
            data: 'desde',
            tipo: 'date',
            searchable: true,
        },
        {
            title: 'Hasta',
            data: 'hasta',
            tipo: 'date',
            searchable: true,
        },
        {
            title: 'Código AFIP',
            data: 'codigoAFIP',
            searchable: true,
        },
    ];

    public constructor(
        protected tipoConceptoService: TipoConceptoService,
        private formBuilder: FormBuilder,
    ) {
        super(tipoConceptoService, {
            inicializaciones: {
                nombre: 'Tipo de Concepto',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return of({});
    }
}
