export class BusquedaDTO {
    public desde?: number;
    public cantidad?: number;
    public busqueda?: string;
    public columnas?: any[];
    public incluirEliminados?: boolean;
    public busquedaAvanzada?: any;

    public constructor(parametros?: any) {
        parametros = { ...parametros };
        this.desde = parametros.start ?? undefined;
        this.cantidad = parametros.length ?? undefined;
        this.incluirEliminados = parametros.incluirEliminados ?? undefined;
        this.busquedaAvanzada = parametros.searchBuilder ?? undefined;
        this.busqueda = parametros.search && parametros.search.value != null ? parametros.search.value : undefined;
        this.columnas = parametros.columns
            ? parametros.columns
                  .filter((columna: any) => columna.name != null && columna.data != null)
                  .filter((columna: any) => columna.searchable || columna.orderable)
                  .map((columna: any, index: number) => {
                      const order =
                          parametros.order && columna.orderable
                              ? parametros.order.find((orden: any) => orden.column === index)
                              : undefined;
                      return {
                          nombre: columna.data,
                          orden: order ? order.dir : undefined,
                          not: columna.searchable && columna.search?.not === true,
                          exact: columna.search?.exact === true,
                          filtro:
                              columna.searchable &&
                              columna.search.value != null &&
                              ((Array.isArray(columna.search.value) && columna.search.value.length > 0) ||
                                  (!Array.isArray(columna.search.value) &&
                                      String(columna.search.value).replace(/^\s+|\s+$/g, '').length > 0))
                                  ? columna.search.value
                                  : undefined,
                      };
                  })
            : undefined;
    }
}
