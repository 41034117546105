import { CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@auth/auth.module';
import { CoreModule } from '@core/core.module';
import { EmpresaHeaderInterceptor } from '@helpers/empresa-header.interceptor';
import { AppComponent } from 'src/app/app.component';
import { AppRoutingModule } from 'src/app/app.routing.module';

@NgModule({
    declarations: [AppComponent],
    imports: [HttpClientModule, BrowserAnimationsModule, AppRoutingModule, AuthModule, CoreModule],
    providers: [
        CurrencyPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EmpresaHeaderInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
