import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, OperatorFunction, Subject, debounceTime, distinctUntilChanged, map, merge, of } from 'rxjs';
import { LoginDTO } from 'src/domain/entities/dtos/login.dto';
import { obtenerUsuarioRoles } from 'src/domain/entities/enums/usuario-rol.enum';
import { Usuario } from 'src/domain/entities/usuario.entity';
import { AuthService } from '../../auth/services/auth.service';
import { ModalFormularioComponent } from '../../shared/components/modal-formulario/modal-formulario.component';
import { TablaComponent } from '../../shared/components/tabla/tabla.component';
import { ABMCComponent } from '../../shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '../../shared/interfaces/columna-tabla.interface';
import { GlobalConfigService } from '../../shared/services/global-config.service';
import { UsuarioService } from '../services/usuario.service';

@Component({
    selector: 'kratos-usuario',
    templateUrl: './usuario.component.html',
    styleUrls: ['./usuario.component.scss'],
})
export class UsuarioComponent extends ABMCComponent<Usuario> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    @ViewChild('cambiarContrasenaFormTemplate') private cambiarContrasenaFormTemplate!: TemplateRef<any>;
    @ViewChild('cambiarContrasenaFormElement') public cambiarContrasenaFormElement!: ElementRef<any>;

    public focusUsuarioRoles$ = new Subject<string>();
    public usuarioRoles = obtenerUsuarioRoles();
    public nombre = 'Usuario';
    public titulo = 'Usuarios';

    public formErroresControles = {
        nombre: {
            required: 'Debe ingresar un nombre',
        },
        email: {
            required: 'Debe ingresar un email',
            email: 'Debe ingresar un email válido',
        },
    };

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        rol: ['', Validators.required],
    });

    public cambiarContrasenaFormErroresControles = {
        contrasena: {
            required: 'Debe ingresar una contraseña',
        },
        nuevaContrasena: {
            required: 'Debe ingresar una contraseña',
        },
        nuevaContrasenaControl: {
            required: 'Debe ingresar una contraseña',
            noCoincide: 'Las contraseñas no coinciden',
        },
    };

    public cambiarContrasenaForm: FormGroup = this.formBuilder.group({
        email: [{ value: '', disabled: true }, Validators.required],
        contrasena: ['', Validators.required],
        nuevaContrasena: ['', Validators.required],
        nuevaContrasenaControl: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Email',
            tipo: 'email',
            data: 'email',
            searchable: true,
        },
        {
            title: 'Rol',
            data: 'rol',
            searchable: true,
        },
    ];

    public constructor(
        protected usuarioService: UsuarioService,
        private formBuilder: FormBuilder,
        private authService: AuthService,
    ) {
        super(usuarioService, {
            inicializaciones: {
                nombre: 'Usuario',
                acciones: [
                    {
                        nombre: 'cambiarContrasena',
                        texto: 'Cambiar Contraseña',
                        grupo: 1,
                    },
                ],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
        this.cambiarContrasenaForm.controls['nuevaContrasenaControl'].setValidators([
            Validators.required,
            this.validarContrasena(),
        ]);
    }

    public cambiarContrasena(elemento: LoginDTO) {
        const modalRef = this.modalService.open(ModalFormularioComponent, {
            centered: true,
        });
        this.cambiarContrasenaForm.reset();
        this.cambiarContrasenaForm.controls['email'].setValue(elemento.email);
        modalRef.componentInstance.formTemplate = this.cambiarContrasenaFormTemplate;
        modalRef.componentInstance.form = this.cambiarContrasenaForm;
        modalRef.componentInstance.titulo = 'Cambiar Contraseña';
        modalRef.componentInstance.botonAceptar = true;
        this.subscription.add(
            modalRef.componentInstance.aceptarHandler.subscribe(() => {
                this.formValidar(this.cambiarContrasenaForm, this.cambiarContrasenaFormElement);
                if (!this.cambiarContrasenaForm.valid) {
                    return;
                }
                this.subscription.add(
                    this.authService
                        .cambiarContrasena({ ...this.cambiarContrasenaForm.value, email: elemento.email })
                        .subscribe({
                            next: (resp: any) => {
                                this.toastService.successHandler('Contraseña cambiada con éxito');
                                modalRef.close('aceptar');
                            },
                            error: this.toastService.errorHandler.bind(this.toastService),
                        }),
                );
            }),
        );
    }

    public typeaheadRolesUsuario: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(GlobalConfigService.DEBOUNCE_TIME_NONE), distinctUntilChanged());
        const inputFocus$ = this.focusUsuarioRoles$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ''
                    ? obtenerUsuarioRoles().map((v) => v.nombre)
                    : obtenerUsuarioRoles()
                          .map((v) => v.nombre)
                          .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
                ).slice(0, 10),
            ),
        );
    };

    protected obtenerRelaciones() {
        return of({});
    }

    private validarContrasena(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const nuevaContrasena = this.cambiarContrasenaForm.controls['nuevaContrasena'];
            return nuevaContrasena?.value !== control?.value ? { noCoincide: true } : null;
        };
    }
}
