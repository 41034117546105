import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipoConcepto } from '@entities/tipo-concepto.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class TipoConceptoService extends BaseService<TipoConcepto> {
    public constructor(http: HttpClient) {
        super('ventas/tipo-concepto', http);
    }
}
