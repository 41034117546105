<h5 class="mb-3">{{ titulo }}</h5>

<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="fecha" class="form-label">Fecha</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Fecha"
                        ngbPopover="Fecha de la cotización."
                    ></i>
                    <div class="input-group">
                        <input
                            placeholder="dd/mm/aaaa"
                            formControlName="fecha"
                            class="form-control"
                            id="fecha"
                            ngbDatepicker
                            #fecha="ngbDatepicker"
                        />
                        <button
                            *ngIf="form.get('fecha')?.value && !form?.get('fecha')?.disabled"
                            class="btn btn-sm btn-outline-secondary bi bi-x-lg"
                            (click)="form.get('fecha')?.setValue(null)"
                            type="button"
                        ></button>
                        <button
                            *ngIf="!form.get('fecha')?.disabled"
                            class="btn btn-outline-secondary bi bi-calendar3"
                            (click)="fecha.toggle()"
                            type="button"
                        ></button>
                        <ng-container *ngFor="let error of formErrores('fecha', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="cotizacion" class="form-label">Cotización</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Cotización"
                        ngbPopover="Cotización de la moneda."
                    ></i>
                    <input
                        placeholder="Cotización"
                        formControlName="cotizacion"
                        [mask]="mascaraCotizacion"
                        [leadZero]="true"
                        thousandSeparator="."
                        decimalMarker=","
                        separatorLimit="100000000000"
                        [outputTransformFn]="toFixedCotizacion"
                        class="form-control"
                        id="cotizacion"
                    />
                    <ng-container *ngFor="let error of formErrores('cotizacion', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-template>
