import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TipoPuntoVenta } from '@entities/tipo-punto-venta.entity';
import { obtenerTipoPuntoVentaDenominaciones } from '@enums/tipo-punto-venta-denominacion.enum';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { TipoPuntoVentaService } from '@ventas/services/tipo-punto-venta.service';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'kratos-tipo-punto-venta',
    templateUrl: './tipo-punto-venta.component.html',
    styleUrls: ['./tipo-punto-venta.component.scss'],
})
export class TipoPuntoVentaComponent extends ABMCComponent<TipoPuntoVenta> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public tipoPuntoVentaDenominaciones = obtenerTipoPuntoVentaDenominaciones();
    public nombre = 'Tipo de Punto de Venta';
    public titulo = 'Tipos de Puntos de Venta';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        denominacion: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Denominación',
            data: 'denominacion',
            searchable: true,
        },
    ];

    public constructor(
        protected tipoPuntoVentaService: TipoPuntoVentaService,
        private formBuilder: FormBuilder,
    ) {
        super(tipoPuntoVentaService, {
            inicializaciones: {
                nombre: 'Tipo de Punto de Venta',
                genero: 'o',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return of({});
    }
}
