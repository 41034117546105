import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MigracionHermesImportarTablaDTO } from '@entities/dtos/migracion/migracion-hermes-importar-tabla.dto';
import { MigracionHermes } from '@entities/migracion-hermes.entity';
import { BaseService } from '@shared/services/base.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MigracionHermesService extends BaseService<MigracionHermes> {
    public constructor(http: HttpClient) {
        super('migracion/migracion-hermes', http);
    }

    public importarTabla(dto: MigracionHermesImportarTablaDTO, importarTodo = false): Observable<string> {
        let accion = 'importar-tabla';
        if (importarTodo) {
            accion = 'importar-todo';
        }
        return this.http.post<string>(`${this.API_URL}/${this.endpointUrl}/${accion}`, dto, {
            responseType: 'text' as 'json',
        });
    }

    public actualizarTabla(dto: MigracionHermesImportarTablaDTO): Observable<string> {
        return this.http.post<string>(`${this.API_URL}/${this.endpointUrl}/actualizar-tabla`, dto, {
            responseType: 'text' as 'json',
        });
    }
}
