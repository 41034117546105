import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MigracionSIImportarTablaDTO } from '@entities/dtos/migracion/migracion-si-importar-tabla.dto';
import { MigracionSI } from '@entities/migracion-si.entity';
import { BaseService } from '@shared/services/base.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MigracionSIService extends BaseService<MigracionSI> {
    public constructor(http: HttpClient) {
        super('migracion/migracion-si', http);
    }

    public importarTabla(dto: MigracionSIImportarTablaDTO, importarTodo = false): Observable<string> {
        let accion = 'importar-tabla';
        if (importarTodo) {
            accion = 'importar-todo';
        }
        return this.http.post<string>(`${this.API_URL}/${this.endpointUrl}/${accion}`, dto, {
            responseType: 'text' as 'json',
        });
    }
}
