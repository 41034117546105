import { Component, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { AccionTabla } from '../../interfaces/accion-tabla.interface';

@Component({
    selector: 'kratos-accion',
    templateUrl: './accion.component.html',
    styleUrls: ['./accion.component.scss'],
})
export class AccionComponent implements OnDestroy {
    @Output() private emitter = new Subject<AccionTabla>();

    @Input() public data = {};
    @Input() public acciones!: AccionTabla[];

    public ngOnDestroy() {
        this.emitter.unsubscribe();
    }

    public onAccion(accion: AccionTabla) {
        this.emitter.next({
            nombre: accion.nombre,
            data: this.data,
            tooltip: accion.tooltip,
        });
    }

    public deshabilitado(accion: AccionTabla) {
        if (!accion || !this.data) {
            return true;
        }
        if (accion && typeof accion?.deshabilitado == 'function') {
            return accion.deshabilitado(this.data);
        }
        return accion.deshabilitado === true;
    }

    public cargando(accion: AccionTabla) {
        if (!accion || !this.data) {
            return false;
        }
        if (accion && typeof accion?.cargando == 'function') {
            return accion.cargando(this.data);
        }
        return accion.cargando === true || (this.data as any).__cargando === true;
    }

    public listaAcciones(grupo?: number) {
        switch (grupo) {
            case -1:
                return this.acciones.filter((accion) => accion.grupo != null && accion.grupo < 0);
            case 0:
                return this.acciones.filter((accion) => accion.grupo == null || accion.grupo === 0);
            default:
                return this.acciones.filter((accion) => accion.grupo != null && accion.grupo > 0);
        }
    }

    public getTexto(accion: AccionTabla) {
        if (typeof accion.texto == 'function') {
            return accion.texto(this.data);
        }
        return accion.texto;
    }
}
