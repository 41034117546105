<div class="row gx-2 d-inline-flex flex-nowrap">
    <div class="col btn-group text-center" *ngIf="listaAcciones(-1).length">
        <div ngbDropdown container="body">
            <button ngbDropdownToggle class="btn btn-secondary btn-toggle-no-arrow">
                <i class="bi bi-list"></i>
            </button>
            <div ngbDropdownMenu>
                <ng-container *ngFor="let accion of listaAcciones(-1)">
                    <button
                        ngbDropdownItem
                        (click)="onAccion(accion)"
                        [ngbTooltip]="accion?.tooltip"
                        [disabled]="deshabilitado(accion) || cargando(accion)"
                    >
                        <ng-template
                            [ngTemplateOutlet]="cargando(accion) ? cargandoBlock : iconColorBlock"
                            [ngTemplateOutletContext]="{ $implicit: accion }"
                        ></ng-template>
                        <ng-template
                            [ngTemplateOutlet]="textoBlock"
                            [ngTemplateOutletContext]="{ $implicit: accion }"
                        ></ng-template>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col btn-group text-center" *ngIf="listaAcciones(0).length">
        <div class="btn-group text-center">
            <ng-container *ngFor="let accion of listaAcciones(0)">
                <button
                    class="btn btn-{{ accion.color ?? 'primary' }}"
                    (click)="onAccion(accion)"
                    [ngbTooltip]="accion?.tooltip"
                    [disabled]="deshabilitado(accion) || cargando(accion)"
                >
                    <ng-template
                        [ngTemplateOutlet]="cargando(accion) ? cargandoBlock : iconBlock"
                        [ngTemplateOutletContext]="{ $implicit: accion }"
                    ></ng-template>
                    <ng-template
                        [ngTemplateOutlet]="textoBlock"
                        [ngTemplateOutletContext]="{ $implicit: accion }"
                    ></ng-template>
                </button>
            </ng-container>
        </div>
    </div>
    <div class="col btn-group text-center" *ngIf="listaAcciones(1).length">
        <div ngbDropdown container="body">
            <button ngbDropdownToggle class="btn btn-secondary btn-toggle-no-arrow">
                <i class="bi bi-list"></i>
            </button>
            <div ngbDropdownMenu>
                <ng-container *ngFor="let accion of listaAcciones(1)">
                    <button
                        ngbDropdownItem
                        (click)="onAccion(accion)"
                        [ngbTooltip]="accion?.tooltip"
                        [disabled]="deshabilitado(accion) || cargando(accion)"
                    >
                        <ng-template
                            [ngTemplateOutlet]="cargando(accion) ? cargandoBlock : iconColorBlock"
                            [ngTemplateOutletContext]="{ $implicit: accion }"
                        ></ng-template>
                        <ng-template
                            [ngTemplateOutlet]="textoBlock"
                            [ngTemplateOutletContext]="{ $implicit: accion }"
                        ></ng-template>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #cargandoBlock>
    <ng-container>
        <span class="spinner-border spinner-border-sm"></span>
    </ng-container>
</ng-template>

<ng-template #iconBlock let-accion>
    <ng-container *ngIf="accion.icono">
        <i class="bi bi-{{ accion.icono }}"></i>
    </ng-container>
</ng-template>

<ng-template #iconColorBlock let-accion>
    <ng-container *ngIf="accion.icono">
        <i
            class="bi bi-{{ accion.icono }}"
            [ngClass]="{
                'text-primary': accion.color === 'primary',
                'text-secondary': accion.color === 'secondary',
                'text-success': accion.color === 'success',
                'text-danger': accion.color === 'danger',
                'text-warning': accion.color === 'warning'
            }"
        ></i>
    </ng-container>
</ng-template>

<ng-template #textoBlock let-accion>
    <ng-container *ngIf="accion.texto">
        <span>{{ getTexto(accion) }}</span>
    </ng-container>
</ng-template>
