import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Proveedor } from '@entities/proveedor.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class ProveedorService extends BaseService<Proveedor> {
    public constructor(http: HttpClient) {
        super('compras/proveedor', http);
    }
}
