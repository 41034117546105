import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PaisNumeroDocumentoService } from '@configuracion/services/pais-numero-documento.service';
import { ProvinciaService } from '@configuracion/services/provincia.service';
import { TipoDocumentoService } from '@configuracion/services/tipo-documento.service';
import { TipoResponsableIVAService } from '@configuracion/services/tipo-responsable-iva.service';
import { Cliente } from '@entities/cliente';
import { TipoPersonaEnum, obtenerTiposPersona } from '@entities/enums/tipo-persona.enum';
import { PaisNumeroDocumento } from '@entities/pais-numero-documento.entity';
import { Provincia } from '@entities/provincia.entity';
import { TipoDocumento } from '@entities/tipo-documento.entity';
import {
    TipoDocumentoDenominacionEnum,
    obtenerTipoDocumentoDenominaciones,
} from '@enums/tipo-documento-denominacion.enum';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { ClienteService } from '@ventas/services/cliente.service';
import { MonedaService } from '@ventas/services/moneda.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-cliente',
    templateUrl: './cliente.component.html',
    styleUrls: ['./cliente.component.scss'],
})
export class ClienteComponent extends ABMCComponent<Cliente> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public tipoDocumentoDenominaciones = obtenerTipoDocumentoDenominaciones();
    public tiposPersona = obtenerTiposPersona();
    public mascaraNumeroDocumento = '';
    public nombreMascaraNumeroDocumento = '';
    public nombre = 'Cliente';
    public titulo = 'Clientes';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        razonSocial: ['', Validators.required],
        numeroDocumento: [''],
        domicilio: [''],
        provincia: [''],
        tipoDocumento: [''],
        tipoResponsableIVA: [''],
        tipoPersona: ['', Validators.required],
        moneda: [''],
        empresas: [[], Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Razón Social',
            data: 'razonSocial',
            searchable: true,
        },
        {
            title: 'Número de Documento',
            data: 'numeroDocumento',
            render: (data: any, type: any, row: any, meta: any) => {
                return data
                    ? row.tipoDocumento && row.tipoDocumento.nombre
                        ? `${row.tipoDocumento.nombre} ${data}`
                        : data
                    : '';
            },
            searchable: true,
        },
        {
            title: 'Provincia',
            data: 'provincia.nombre',
            searchable: true,
        },
        {
            title: 'Domicilio',
            data: 'domicilio',
            searchable: true,
        },
    ];

    public constructor(
        protected clienteService: ClienteService,
        protected provinciaService: ProvinciaService,
        protected tipoDocumentoService: TipoDocumentoService,
        protected tipoResponsableIVAService: TipoResponsableIVAService,
        protected monedaService: MonedaService,
        protected empresaService: EmpresaService,
        protected paisNumeroDocumentoService: PaisNumeroDocumentoService,
        private formBuilder: FormBuilder,
    ) {
        super(clienteService, {
            inicializaciones: {
                nombre: 'Cliente',
                anchoModal: 'lg',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
        this.form.get('tipoDocumento')?.valueChanges.subscribe(this.cambiarTipoDocumento.bind(this));
    }

    // Al cambiar de tipo de documento, se cambia la máscara del número de documento
    public cambiarTipoDocumento(tipoDocumento: any): void {
        const { mascaraNumeroDocumento, nombreMascaraNumeroDocumento } =
            this.obtenerMascaraTipoDocumento(tipoDocumento);
        this.mascaraNumeroDocumento = mascaraNumeroDocumento;
        this.nombreMascaraNumeroDocumento = nombreMascaraNumeroDocumento;
    }

    public autoNumeroDocumento(): void {
        // Buscar el país correspondiente al cliente en base a su provincia
        // Obtener el número de documento del país según el tipo de persona del cliente
        // Completar el número de documento del cliente con el número de documento del país
        const provincia = this.form.get('provincia')?.value as Provincia;
        const tipoPersona = this.form.get('tipoPersona')?.value as TipoPersonaEnum;
        if (!provincia || !provincia.id) {
            this.toastService.warningHandler('Debe seleccionar la provincia para autocompletar el número de documento');
            return;
        }
        if (!provincia.paisId) {
            this.toastService.warningHandler('La provincia seleccionada no tiene un país asociado');
            return;
        }
        if (!tipoPersona) {
            this.toastService.warningHandler(
                'Debe seleccionar el tipo de persona para autocompletar el número de documento',
            );
            return;
        }
        this.subscription.add(
            this.paisNumeroDocumentoService
                .obtenerPorTipoPersonaYPais(tipoPersona, provincia.paisId)
                .subscribe((resp: PaisNumeroDocumento) => {
                    if (resp && resp.numeroDocumento) {
                        // Establecer tipo de documento como CUIT CUIL
                        const tipoDocumentoCUITCUIL = this.relaciones.tiposDocumento.find(
                            (tipoDocumento: TipoDocumento) =>
                                tipoDocumento.denominacion === TipoDocumentoDenominacionEnum.CUIT_CUIL,
                        );
                        if (tipoDocumentoCUITCUIL) {
                            this.cambiarTipoDocumento(tipoDocumentoCUITCUIL);
                            this.form.get('tipoDocumento')?.setValue(tipoDocumentoCUITCUIL);
                        }
                        this.form.get('numeroDocumento')?.setValue(resp.numeroDocumento);
                        this.toastService.successHandler('Número de documento autocompletado');
                    } else {
                        this.toastService.errorHandler(
                            'No se encontró el número de documento correspondiente al país y tipo de persona',
                        );
                    }
                }),
        );
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            provincias: this.provinciaService.obtenerTodos(),
            tiposDocumento: this.tipoDocumentoService.obtenerTodos(),
            tiposResponsableIVA: this.tipoResponsableIVAService.obtenerTodos(),
            monedas: this.monedaService.obtenerTodos(),
            empresas: this.empresaService.obtenerTodos(),
        });
    }

    private obtenerMascaraTipoDocumento(tipoDocumento: any): {
        mascaraNumeroDocumento: string;
        nombreMascaraNumeroDocumento: string;
    } {
        const denominacion = tipoDocumento?.denominacion;
        if (denominacion) {
            const tipoDocumentoDenominacion = this.tipoDocumentoDenominaciones.find(
                (tipoDocumentoDenominacion) => tipoDocumentoDenominacion.nombre === denominacion,
            );
            if (tipoDocumentoDenominacion) {
                return {
                    mascaraNumeroDocumento: tipoDocumentoDenominacion.mascara,
                    nombreMascaraNumeroDocumento: tipoDocumentoDenominacion.nombre,
                };
            }
        }
        return { mascaraNumeroDocumento: '', nombreMascaraNumeroDocumento: '' };
    }
}
