import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RespuestaDTO } from '@entities/dtos/respuesta.dto';
import { PuntoVenta } from '@entities/punto-venta.entity';
import { BaseService } from '@shared/services/base.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PuntoVentaService extends BaseService<PuntoVenta> {
    public constructor(http: HttpClient) {
        super('ventas/punto-venta', http);
    }

    public obtenerActivos(): Observable<RespuestaDTO<PuntoVenta>> {
        return this.http.get<RespuestaDTO<PuntoVenta>>(`${this.API_URL}/${this.endpointUrl}/activos`);
    }
}
