import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Provincia } from '@entities/provincia.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class ProvinciaService extends BaseService<Provincia> {
    public constructor(http: HttpClient) {
        super('configuracion/provincia', http);
    }
}
