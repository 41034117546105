import { CommonModule } from '@angular/common';
import { Injectable, NgModule, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    NgbDateAdapter,
    NgbDateParserFormatter,
    NgbDateStruct,
    NgbDatepickerConfig,
    NgbDatepickerI18n,
    NgbInputDatepickerConfig,
    NgbModalConfig,
    NgbModule,
    NgbOffcanvasConfig,
    NgbPopoverConfig,
    NgbTimepickerConfig,
    NgbTimepickerI18n,
    NgbTooltipConfig,
    NgbTypeaheadConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { ModalInformacionComponent } from '@shared/components/modal-informacion/modal-informacion.component';
import { RequiredInputDirective } from '@shared/directives/required-input.directive';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { DataTablesModule } from 'angular-datatables';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ThemeService } from '../core/services/theme.service';
import { AccionComponent } from './components/accion/accion.component';
import { ModalConfirmacionComponent } from './components/modal-confirmacion/modal-confirmacion.component';
import { ModalFormularioComponent } from './components/modal-formulario/modal-formulario.component';
import { TablaComponent } from './components/tabla/tabla.component';
import { ToastsContainerComponent } from './components/toasts-container/toasts-container.component';
import { GlobalConfigService } from './services/global-config.service';

@Injectable()
export class I18n {
    public language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    private I18N_VALUES = {
        es: {
            weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá', 'Do'],
            months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            weekLabel: 'sem',
        },
    };

    public constructor() {
        super();
    }

    public getWeekdayLabel(weekday: number): string {
        return this.I18N_VALUES['es'].weekdays[weekday - 1];
    }

    public override getWeekLabel(): string {
        return this.I18N_VALUES['es'].weekLabel;
    }

    public getMonthShortName(month: number): string {
        return this.I18N_VALUES['es'].months[month - 1];
    }

    public getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    public getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}

@Injectable()
export class CustomTimepickerI18n extends NgbTimepickerI18n {
    public getHourPlaceholder(): string {
        return 'hh';
    }

    public getMinutePlaceholder(): string {
        return 'mm';
    }

    public getSecondPlaceholder(): string {
        return 'ss';
    }

    public override getMorningPeriod(): string {
        return 'AM';
    }

    public override getAfternoonPeriod(): string {
        return 'PM';
    }
}

@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<string> {
    public readonly DELIMITER = '-';

    public fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                year: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                day: parseInt(date[2], 10),
            };
        }
        return null;
    }

    public toModel(date: NgbDateStruct | null): string | null {
        return date
            ? date.year + this.DELIMITER + ('0' + date.month).slice(-2) + this.DELIMITER + ('0' + date.day).slice(-2)
            : null;
    }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    public readonly DELIMITER = '/';

    public parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10),
            };
        }
        return null;
    }

    public format(date: NgbDateStruct | null): string {
        return date
            ? ('0' + date.day).slice(-2) + this.DELIMITER + ('0' + date.month).slice(-2) + this.DELIMITER + date.year
            : '';
    }
}

@NgModule({
    declarations: [
        TablaComponent,
        AccionComponent,
        ModalFormularioComponent,
        ModalConfirmacionComponent,
        ModalInformacionComponent,
        ToastsContainerComponent,
        RequiredInputDirective,
        SafeHtmlPipe,
    ],
    imports: [CommonModule, NgbModule, DataTablesModule, NgxMaskDirective, NgxMaskPipe],
    providers: [
        { provide: NgbTimepickerI18n, useClass: CustomTimepickerI18n },
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbDateAdapter, useClass: CustomDateAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        provideNgxMask(),
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgbModule,
        TablaComponent,
        ToastsContainerComponent,
        NgSelectModule,
        NgOptionHighlightModule,
        NgxMaskDirective,
        NgxMaskPipe,
        RequiredInputDirective,
        SafeHtmlPipe,
    ],
})
export class SharedModule {
    private themeService = inject(ThemeService);

    public constructor(
        private tooltipConfig: NgbTooltipConfig,
        private popoverConfig: NgbPopoverConfig,
        private offcanvasConfig: NgbOffcanvasConfig,
        private modalConfig: NgbModalConfig,
        private typeaheadConfig: NgbTypeaheadConfig,
        private selectConfig: NgSelectConfig,
        private datepickerConfig: NgbDatepickerConfig,
        private inputDatepickerConfig: NgbInputDatepickerConfig,
        private timepickerConfig: NgbTimepickerConfig,
    ) {
        this.tooltipConfig.animation = true;
        this.tooltipConfig.container = 'body';
        this.tooltipConfig.openDelay = GlobalConfigService.TOOLTIP_DELAY;

        this.popoverConfig.animation = true;
        this.popoverConfig.autoClose = 'outside';
        this.popoverConfig.closeDelay = GlobalConfigService.POPOVER_DELAY;
        this.popoverConfig.container = 'body';
        this.popoverConfig.disablePopover = !this.getThemeHelpEnabled();
        this.popoverConfig.openDelay = GlobalConfigService.POPOVER_DELAY;
        this.popoverConfig.triggers = 'mouseenter:mouseleave';

        this.offcanvasConfig.animation = true;

        this.modalConfig.animation = true;
        this.modalConfig.backdrop = 'static';
        this.modalConfig.centered = true;
        this.modalConfig.keyboard = false;
        this.modalConfig.scrollable = true;

        this.typeaheadConfig.container = 'body';
        this.typeaheadConfig.editable = false;
        this.typeaheadConfig.focusFirst = false;
        this.typeaheadConfig.showHint = true;
        this.typeaheadConfig.selectOnExact = true;

        this.selectConfig.appendTo = 'body';
        this.selectConfig.bindLabel = 'nombre';
        this.selectConfig.clearAllText = 'Limpiar todo';
        this.selectConfig.loadingText = 'Cargando...';
        this.selectConfig.notFoundText = 'No se encontraron resultados';
        this.selectConfig.placeholder = 'Seleccione...';
        this.selectConfig.typeToSearchText = 'Escriba para buscar';

        this.datepickerConfig.minDate = { year: 1900, month: 1, day: 1 };

        this.inputDatepickerConfig.container = 'body';
        this.inputDatepickerConfig.minDate = { year: 1900, month: 1, day: 1 };

        this.timepickerConfig.spinners = false;
        this.timepickerConfig.seconds = true;
    }

    private getThemeHelpEnabled(): boolean {
        return this.themeService.currentHelp === 'true';
    }
}
