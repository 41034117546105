export enum RolUsuarioEnum {
    ADMINISTRADOR = 'Administrador',
    USUARIO = 'Usuario',
}

export const usuarioRolIdPorKeyValue = (rolUsuario: any) => {
    let id = null;
    switch (rolUsuario) {
        case 0:
        case 'ADMINISTRADOR':
            id = 0;
            break;
        case 1:
        case 'USUARIO':
            id = 1;
            break;
    }
    return id;
};

export const obtenerUsuarioRoles = () => [
    { id: 0, clave: 'ADMINISTRADOR', nombre: 'Administrador' },
    { id: 1, clave: 'USUARIO', nombre: 'Usuario' },
];
