import { BaseEntity } from '@entities/base.entity';
import { ComprobanteCompra } from '@entities/comprobante-compra.entity';
import { TipoMedioPago } from '@entities/tipo-medio-pago.entity';

export class ComprobanteCompraMedioPago extends BaseEntity {
    public descripcion?: string;
    public importe?: number;
    public comprobanteCompra?: ComprobanteCompra;
    public tipoMedioPago?: TipoMedioPago;

    public constructor(partial: Partial<ComprobanteCompraMedioPago> = {}) {
        super();
        Object.assign(this, partial);
    }
}
