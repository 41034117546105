import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ThemeService {
    public static modeDefault = 'light';
    public static sizeDefault = 'false';
    public static helpDefault = 'false';
    public static debugDefault = 'false';
    public static pageLengthDefault = 10;
    public static sidebarCollapsedDefault = 'false';

    public get currentMode(): string {
        return localStorage.getItem('theme-mode') ?? ThemeService.modeDefault;
    }

    public set currentMode(value: string) {
        localStorage.setItem('theme-mode', value);
        document.documentElement.setAttribute('data-bs-theme', this.currentMode);
    }

    public get currentSize(): string {
        return localStorage.getItem('theme-size') ?? ThemeService.sizeDefault;
    }

    public set currentSize(value: string) {
        localStorage.setItem('theme-size', value);
    }

    public get currentHelp(): string {
        return localStorage.getItem('theme-help') ?? ThemeService.helpDefault;
    }

    public set currentHelp(value: string) {
        localStorage.setItem('theme-help', value);
    }

    public get currentDebug(): string {
        return localStorage.getItem('theme-debug') ?? ThemeService.debugDefault;
    }

    public set currentDebug(value: string) {
        localStorage.setItem('theme-debug', value);
    }

    public get currentPageLength(): number {
        return localStorage.getItem('theme-page-length')
            ? Number(localStorage.getItem('theme-page-length'))
            : ThemeService.pageLengthDefault;
    }

    public set currentPageLength(value: string) {
        localStorage.setItem('theme-page-length', value);
    }

    public get currentSidebarCollapsed(): string {
        return localStorage.getItem('theme-sidebar-collapsed') ?? ThemeService.sidebarCollapsedDefault;
    }

    public set currentSidebarCollapsed(value: string) {
        localStorage.setItem('theme-sidebar-collapsed', value);
    }

    constructor() {
        document.documentElement.setAttribute('data-bs-theme', this.currentMode);
    }
}
