export enum TipoComprobanteDenominacionEnum {
    FACTURA = 'Factura',
    NOTA_CREDITO = 'Nota de Crédito',
    NOTA_DEBITO = 'Nota de Débito',
    RECIBO = 'Recibo',
    REMITO = 'Remito',
    OTRO = 'Otro',
}

export const tipoComprobanteDenominacionIdPorKeyValue = (tipoComprobanteDenominacion: any) => {
    let id = null;
    switch (tipoComprobanteDenominacion) {
        case 0:
        case 'FACTURA':
            id = 0;
            break;
        case 1:
        case 'NOTA_CREDITO':
            id = 1;
            break;
        case 2:
        case 'NOTA_DEBITO':
            id = 2;
            break;
        case 3:
        case 'RECIBO':
            id = 3;
            break;
        case 4:
        case 'REMITO':
            id = 4;
            break;
        case 5:
        case 'OTRO':
            id = 5;
            break;
    }
    return id;
};

export const obtenerTipoComprobanteDenominaciones = () => [
    { id: 0, clave: 'Factura', nombre: 'Factura' },
    { id: 1, clave: 'Nota de Crédito', nombre: 'Nota de Crédito' },
    { id: 2, clave: 'Nota de Débito', nombre: 'Nota de Débito' },
    { id: 3, clave: 'Recibo', nombre: 'Recibo' },
    { id: 4, clave: 'Remito', nombre: 'Remito' },
    { id: 5, clave: 'Otro', nombre: 'Otro' },
];
