import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MigracionSIImportarTablaDTO } from '@entities/dtos/migracion/migracion-si-importar-tabla.dto';
import { Empresa } from '@entities/empresa.entity';
import { MigracionSITablaEnum, obtenerMigracionSITablas } from '@entities/enums/migracion/migracion-si-tabla.enum';
import { MigracionSI } from '@entities/migracion-si.entity';
import { MigracionSIService } from '@migracion/services/migracion-si.service';
import { ModalFormularioComponent } from '@shared/components/modal-formulario/modal-formulario.component';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { Observable, finalize, forkJoin, of } from 'rxjs';

@Component({
    selector: 'kratos-migracion-si',
    templateUrl: './migracion-si.component.html',
    styleUrls: ['./migracion-si.component.scss'],
})
export class MigracionSIComponent extends ABMCComponent<MigracionSI> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    @ViewChild('formImportarTemplate') public formImportarTemplate!: TemplateRef<any>;
    @ViewChild('formImportarElement') public formImportarElement!: ElementRef<any>;

    public nombre = 'Migración Simple Invoices';
    public titulo = 'Migraciones de Simple Invoices';
    public tablas = obtenerMigracionSITablas();
    public relacionesImportar: any = {};
    public importarResultado = '';
    public importarResultadoEstado = '';

    protected formErroresControles = {};
    protected formImportarErroresControles = {};

    public form = this.formBuilder.group({
        empresa: ['', Validators.required],
        tablaOrigen: ['', Validators.required],
        tablaDestino: ['', Validators.required],
        idOrigen: ['', Validators.required],
        idDestino: ['', Validators.required],
    });

    public formImportar = this.formBuilder.group({
        empresa: ['', Validators.required],
        tabla: ['', Validators.required],
        desde: [0],
        cantidad: [100],
        ordenInverso: [false],
        importarTodo: [false],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Empresa',
            data: 'empresa.nombre',
            searchable: true,
            defaultContent: '',
        },
        {
            title: 'Tabla Origen',
            data: 'tablaOrigen',
            searchable: true,
        },
        {
            title: 'Tabla Destino',
            data: 'tablaDestino',
            searchable: true,
        },
        {
            title: 'ID Origen',
            data: 'idOrigen',
            tipo: 'number',
            searchable: true,
        },
        {
            title: 'ID Destino',
            data: 'idDestino',
            tipo: 'number',
            searchable: true,
        },
    ];

    public constructor(
        protected migracionSIService: MigracionSIService,
        protected empresaService: EmpresaService,
        private formBuilder: FormBuilder,
    ) {
        super(migracionSIService, {
            inicializaciones: {
                nombre: 'Migración Simple Invoices',
                genero: 'a',
                acciones: [],
                botones: [
                    {
                        name: 'importarTabla',
                        text: 'Importar Tabla',
                    },
                ],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    modificacion: true,
                    consulta: true,
                    destruccion: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
        });
    }

    private importarTabla(): void {
        const modalRef = this.modalService.open(ModalFormularioComponent, {
            centered: true,
        });
        this.formImportar.reset();
        this.formImportar.disable();
        modalRef.componentInstance.formTemplate = this.formImportarTemplate;
        modalRef.componentInstance.form = this.formImportar;
        modalRef.componentInstance.titulo = 'Importar Tabla';
        modalRef.componentInstance.botonAceptar = true;
        modalRef.componentInstance.cargando = true;
        this.importarResultado = '';
        this.importarResultadoEstado = '';
        this.subscription.add(
            forkJoin({
                relaciones: forkJoin({
                    empresas: this.empresaService.obtenerTodos(),
                    tablas: of(this.tablas),
                }),
            })
                .pipe(
                    finalize(() => {
                        modalRef.componentInstance.cargando = false;
                        this.formImportar.enable();
                        this.formImportar.get('desde')?.setValue(1);
                        this.formImportar.get('cantidad')?.setValue(100);
                    }),
                )
                .subscribe({
                    next: ({ relaciones }) => {
                        this.relacionesImportar = {};
                        Object.keys(relaciones).forEach((key) => {
                            this.relacionesImportar[key] =
                                (relaciones as any)[key]?.elementos ?? (relaciones as any)[key];
                        });
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
        this.subscription.add(
            modalRef.componentInstance.aceptarHandler.subscribe(() => {
                this.formValidar(this.formImportar, this.formImportarElement);
                if (!this.formImportar.valid) {
                    return;
                }
                const empresa = this.formImportar.get('empresa')?.value as Empresa;
                const tabla = this.formImportar.get('tabla')?.value as MigracionSITablaEnum;
                if (!empresa?.id || !tabla) {
                    return;
                }
                const dto = new MigracionSIImportarTablaDTO({
                    empresaId: empresa.id,
                    tabla,
                    desde: this.formImportar.get('desde')?.value ?? 1,
                    cantidad: this.formImportar.get('cantidad')?.value ?? 100,
                    orden: this.formImportar.get('ordenInverso')?.value ? 'DESC' : 'ASC',
                });
                const importarTodo = this.formImportar.get('importarTodo')?.value ?? false;
                this.importarResultado = `Importando tabla ${tabla} de la empresa ${empresa.nombre}...`;
                this.importarResultadoEstado = '';
                modalRef.componentInstance.cargando = true;
                this.subscription.add(
                    this.migracionSIService
                        .importarTabla(dto, importarTodo)
                        .pipe(finalize(() => (modalRef.componentInstance.cargando = false)))
                        .subscribe({
                            next: (resultado: string) => {
                                this.importarResultado = resultado;
                                this.importarResultadoEstado = 'OK';
                                this.toastService.successHandler(
                                    `Empresa ${empresa.nombre}, tabla ${tabla} importada correctamente. ${resultado}`,
                                );
                                this.tabla.recargar();
                            },
                            error: (err) => {
                                if (
                                    /^[\],:{}\s]*$/.test(
                                        (err.error ?? err)
                                            .replace(/\\["\\/bfnrtu]/g, '@')
                                            .replace(
                                                /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
                                                ']',
                                            )
                                            .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
                                    )
                                ) {
                                    this.importarResultado = this.toastService.errorHandler(
                                        JSON.parse(err.error ?? err),
                                    );
                                } else {
                                    this.importarResultado = this.toastService.errorHandler(err);
                                }
                                this.importarResultadoEstado = 'ERROR';
                            },
                        }),
                );
            }),
        );
    }
}
