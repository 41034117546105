import { Component, TemplateRef } from '@angular/core';
import { ToastInterface } from '@shared/interfaces/toast.interface';
import { GlobalConfigService } from '@shared/services/global-config.service';
import { ToastService } from '@shared/services/toast.service';

@Component({
    selector: 'kratos-toasts-container',
    templateUrl: './toasts-container.component.html',
    styleUrls: ['./toasts-container.component.scss'],
})
export class ToastsContainerComponent {
    public constructor(public toastService: ToastService) {}

    public isHeaderTemplate(toast: ToastInterface) {
        return toast.header instanceof TemplateRef;
    }

    public isBodyTemplate(toast: ToastInterface) {
        return toast.body instanceof TemplateRef;
    }

    public getHeaderTemplate(toast: ToastInterface) {
        return toast.header as TemplateRef<any>;
    }

    public getBodyTemplate(toast: ToastInterface) {
        return toast.body as TemplateRef<any>;
    }

    public getAutohide(toast: ToastInterface) {
        return toast.autohide != null ? toast.autohide : true;
    }

    public toastDelay() {
        return GlobalConfigService.TOAST_DELAY;
    }

    public toggleAutohide(toast: ToastInterface, event: string): void {
        if (event === 'enter' && this.getAutohide(toast)) {
            toast.autohide = false;
        } else if (event === 'leave') {
            toast.autohide = true;
        }
    }
}
