import { MigracionSITablaEnum } from '@entities/enums/migracion/migracion-si-tabla.enum';

export class MigracionSIImportarTablaDTO {
    public empresaId?: number;

    public tabla?: MigracionSITablaEnum;

    public desde: number;

    public cantidad: number;

    public orden: string;

    public constructor(partial: Partial<MigracionSIImportarTablaDTO>) {
        Object.assign(this, partial);
        this.desde = partial.desde ?? 1;
        this.cantidad = partial.cantidad ?? 100;
        this.orden = partial.orden ?? 'ASC';
    }
}
