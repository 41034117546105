import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ComprobanteCompraItem } from '@entities/comprobante-compra-item.entity';
import { ComprobanteCompraMedioPago } from '@entities/comprobante-compra-medio-pago.entity';
import { ComprobanteCompra } from '@entities/comprobante-compra.entity';
import { TipoMedioPagoDenominacionEnum } from '@entities/enums/tipo-medio-pago-denominacion.enum';
import { Moneda } from '@entities/moneda.entity';
import { TipoMedioPago } from '@entities/tipo-medio-pago.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCVolatilComponent } from '@shared/directives/abmc/abmc-volatil.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { TipoMedioPagoService } from '@ventas/services/tipo-medio-pago.service';
import Big from 'big.js';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-comprobante-compra-medio-pago',
    templateUrl: './comprobante-compra-medio-pago.component.html',
    styleUrls: ['./comprobante-compra-medio-pago.component.scss'],
})
export class ComprobanteCompraMedioPagoComponent extends ABMCVolatilComponent<ComprobanteCompraMedioPago> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public mascaraImporte = 'separator.2';
    public nombre = 'Medio de Pago';
    public titulo = 'Medios de Pago';
    public tablaOpciones = {
        botones: {
            size: 'sm',
        },
    };
    public moneda: Moneda = { simbolo: '$', cotizacion: +1.0 };

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        descripcion: [''],
        importe: ['', Validators.required],
        tipoMedioPago: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'Tipo',
            data: 'tipoMedioPago.nombre',
            searchable: true,
        },
        {
            title: 'Descripción',
            data: 'descripcion',
            searchable: true,
        },
        {
            title: 'Importe',
            data: 'importe',
            tipo: 'moneda',
            searchable: true,
        },
    ];

    public constructor(
        protected tipoMedioPagoService: TipoMedioPagoService,
        private formBuilder: FormBuilder,
    ) {
        super({
            inicializaciones: {
                nombre: 'Medio de Pago',
                anchoModal: 'lg',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    public buscarTipoMedioPago = (term: string, item: TipoMedioPago) => {
        term = term.toLocaleLowerCase();
        return (
            (item?.nombre?.toLocaleLowerCase().indexOf(term) ?? -1) > -1 ||
            (item?.denominacion?.toLocaleLowerCase().indexOf(term) ?? -1) > -1
        );
    };

    public toFixedImporte = (value: string | number | undefined | null): number => {
        return this.toFixed(value, 2);
    };

    public toFixed(value: string | number | undefined | null, decimales = 2): number {
        const formattedValue = String(value).split(' ').join('');
        if (String(value).includes('.') && String(value).split('.').length === 2) {
            const decimal = String(value).split('.')[1]?.length;
            if (decimal && decimal > decimales) {
                return Number(parseFloat(formattedValue).toFixed(decimales));
            }
        }
        return Number(formattedValue);
    }

    public saldarComprobante(comprobanteCompra: ComprobanteCompra): void {
        this.subscription.add(
            forkJoin({
                relaciones: this.obtenerRelaciones(),
            }).subscribe({
                next: ({ relaciones }) => {
                    this.cargarRelaciones(relaciones);
                    const item = this.crearElementoSaldo(
                        comprobanteCompra,
                        comprobanteCompra.items?.[0] ?? new ComprobanteCompraItem(),
                    );
                    this.subscription.add(
                        this.baseService.crear(item).subscribe({
                            next: (resp: any) => {
                                this.tabla.recargar();
                            },
                            error: this.toastService.errorHandler.bind(this.toastService),
                        }),
                    );
                },
                error: this.toastService.errorHandler.bind(this.toastService),
            }),
        );
    }

    public recalcularImportes(importeTotalRecalculado: number): void {
        // Actualizar importes de los medios de pago
        // Capturar el importe actual de cada medio de pago, para que en el caso de que sean varios, se mantenga la proporción
        const importeTotalActual = this.elementos.reduce((suma, elemento) => suma + (elemento?.importe ?? 0), 0);
        const proporciones = this.elementos.map((elemento) => {
            return {
                id: elemento?.id,
                proporcion: (elemento.importe ?? 0) / (importeTotalActual > 0 ? importeTotalActual : 1),
            };
        });
        // Calcular el importe de cada medio de pago, según la proporción del importe total (importe)
        this.elementos.forEach((elemento) => {
            const proporcion = proporciones.find((item) => item.id === elemento.id)?.proporcion ?? 0;
            elemento.importe = Big(importeTotalRecalculado).times(Big(proporcion)).round(2).toNumber();
        });
        // Si la suma de los importes de los medios de pago es distinta al importe total, ajustar el importe del primer medio de pago
        const sumaImportes = this.elementos.reduce((suma, elemento) => suma + (elemento?.importe ?? 0), 0);
        if (this.elementos[0] && sumaImportes !== importeTotalRecalculado) {
            this.elementos[0].importe = Big(this.elementos[0].importe ?? 0)
                .plus(Big(importeTotalRecalculado).minus(sumaImportes))
                .round(2)
                .toNumber();
        }
        this.tabla.recargar();
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            tiposMedioPago: this.tipoMedioPagoService.obtenerTodos(),
        });
    }

    protected override crearElemento(): ComprobanteCompraMedioPago {
        const elemento = new ComprobanteCompraMedioPago(super.crearElemento());
        // Convertir importes del elemento a la moneda oficial, según la cotización del comprobante
        elemento.importe = this.convertirImporte(elemento.importe ?? 0, this.moneda.cotizacion);
        return elemento;
    }

    protected override cargarElemento(elemento: ComprobanteCompraMedioPago): void {
        // Convertir importes de moneda oficial a moneda seleccionada por el usuario, según la cotización del comprobante
        const item = new ComprobanteCompraMedioPago(elemento);
        const cotizacion = 1 / (this.moneda.cotizacion ?? 1);
        item.importe = this.convertirImporte(elemento.importe ?? 0, cotizacion);
        super.cargarElemento(item);
    }

    private convertirImporte(importe: number, cotizacion = +1.0): number {
        return Big(importe).times(Big(cotizacion)).round(2).toNumber();
    }

    private crearElementoSaldo(
        comprobanteCompra: ComprobanteCompra,
        comprobanteCompraItem: ComprobanteCompraItem,
    ): ComprobanteCompraMedioPago {
        return new ComprobanteCompraMedioPago({
            importe: comprobanteCompraItem.importeNeto,
            descripcion: `Pago del comprobante ${comprobanteCompra?.comprobante?.tipoComprobante?.nombre} ${
                String(comprobanteCompra?.puntoVenta).padStart(4, '0') ?? '0000'
            }-${String(comprobanteCompra?.comprobante?.numero).padStart(8, '0') ?? '00000000'}`,
            tipoMedioPago:
                this.relaciones.tiposMedioPago.find(
                    (tipoMedioPago: TipoMedioPago) =>
                        tipoMedioPago.denominacion === TipoMedioPagoDenominacionEnum.EFECTIVO,
                ) ||
                this.relaciones.tiposMedioPago[0] ||
                undefined,
        });
    }
}
