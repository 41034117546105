import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IdiomaService } from '@configuracion/services/idioma.service';
import { Idioma } from '@entities/idioma.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'kratos-idioma',
    templateUrl: './idioma.component.html',
    styleUrls: ['./idioma.component.scss'],
})
export class IdiomaComponent extends ABMCComponent<Idioma> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Idioma';
    public titulo = 'Idiomas';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        codigoAFIP: [''],
        codigoISO: [''],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Código AFIP',
            data: 'codigoAFIP',
            searchable: true,
        },
        {
            title: 'Código ISO',
            data: 'codigoISO',
            searchable: true,
        },
    ];

    public constructor(
        protected idiomaService: IdiomaService,
        private formBuilder: FormBuilder,
    ) {
        super(idiomaService, {
            inicializaciones: {
                nombre: 'Idioma',
                genero: 'o',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return of({});
    }
}
