<h2 class="mb-3" *ngIf="titulo">{{ titulo }}</h2>

<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    [autoInit]="false"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
    (botonHandler)="botonHandler($event)"
></kratos-tabla>
