import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Parametro } from '@entities/parametro.entity';
import { BaseService } from '@shared/services/base.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ParametroService extends BaseService<Parametro> {
    public constructor(http: HttpClient) {
        super('administracion/parametro', http);
    }

    public obtenerPorNombre(empresaId: number | undefined, nombre: string): Observable<Parametro> {
        let url = `${this.API_URL}/${this.endpointUrl}/nombre?nombre=${nombre}`;
        if (empresaId) {
            url += `&empresaId=${empresaId}`;
        }
        return this.http.get<Parametro>(url);
    }
}
