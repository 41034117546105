<div ngbDropdown class="col-6 col-lg-auto">
    <button ngbDropdownToggle class="btn btn-link nav-link">
        <i class="bi {{ dropdownToggleModeIcon }}"></i>
        <span class="d-lg-none">Cambiar Tema</span>
    </button>
    <div ngbDropdownMenu class="dropdown-menu-lg-end">
        <button ngbDropdownItem (click)="switchThemeMode('light')" [ngClass]="{ active: getThemeMode() === 'light' }">
            <i class="bi bi-sun-fill"></i><span>Claro</span>
        </button>
        <button ngbDropdownItem (click)="switchThemeMode('dark')" [ngClass]="{ active: getThemeMode() === 'dark' }">
            <i class="bi bi-moon-stars-fill"></i><span>Oscuro</span>
        </button>
        <button ngbDropdownItem (click)="switchThemeMode('auto')" [ngClass]="{ active: getThemeMode() === 'auto' }">
            <i class="bi bi-circle-half"></i><span>Automático</span>
        </button>
        <hr class="dropdown-divider d-none d-lg-block" />
        <button
            ngbDropdownItem
            (click)="switchThemeSize('fluid')"
            class="d-none d-lg-block"
            [ngClass]="{ active: getThemeSize() === 'fluid' }"
        >
            <i class="bi bi-arrows-fullscreen"></i><span>Pantalla Completa</span>
        </button>
        <button
            ngbDropdownItem
            (click)="switchThemeSize('xxl')"
            class="d-none d-lg-block"
            [ngClass]="{ active: getThemeSize() === 'xxl' }"
        >
            <i class="bi bi-arrows-angle-contract"></i><span>Vista Compacta</span>
        </button>
        <hr class="dropdown-divider d-none d-lg-block" />
        <button
            ngbDropdownItem
            (click)="switchThemeDebug()"
            class="d-none d-lg-block"
            [ngClass]="{ active: getThemeDebug() === 'true' }"
        >
            <i class="bi bi-{{ getThemeDebug() === 'true' ? 'check-circle-fill' : 'circle' }}"></i
            ><span>Modo Debug (test)</span>
        </button>
    </div>
</div>
