<h2 class="mb-3">{{ titulo }}</h2>

<kratos-tabla
    #tabla
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="nombre" class="form-label">Nombre</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Nombre del usuario"
                        ngbPopover="Nombre y apellido del usuario."
                    ></i>
                    <input formControlName="nombre" type="text" class="form-control" id="nombre" />
                    <ng-container *ngFor="let error of formErrores('nombre', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="rol" class="form-label">Rol</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Rol del usuario"
                        ngbPopover="Debe ser un rol válido, entre las opciones disponibles."
                    ></i>
                    <ng-select
                        [items]="usuarioRoles"
                        bindValue="nombre"
                        labelForId="rol"
                        formControlName="rol"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('rol', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="email" class="form-label">Email</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Email del usuario"
                        ngbPopover="Debe ser un email válido y único en el sistema."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">@</span>
                        <input formControlName="email" type="email" class="form-control" id="email" />
                        <ng-container *ngFor="let error of formErrores('email', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #cambiarContrasenaFormTemplate>
    <form [formGroup]="cambiarContrasenaForm" #cambiarContrasenaFormElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="email" class="form-label">Email</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Email del usuario"
                        ngbPopover="Debe ser un email válido y único en el sistema."
                    ></i>
                    <input formControlName="email" type="email" class="form-control" id="email" />
                    <ng-container
                        *ngFor="
                            let error of formErrores(
                                'email',
                                cambiarContrasenaForm,
                                cambiarContrasenaFormErroresControles
                            )
                        "
                    >
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="contrasena" class="form-label">Contraseña Actual</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Contraseña actual del usuario"
                        ngbPopover="Debe ser la contraseña actual del usuario."
                    ></i>
                    <input formControlName="contrasena" type="password" class="form-control" id="contrasena" />
                    <ng-container
                        *ngFor="
                            let error of formErrores(
                                'contrasena',
                                cambiarContrasenaForm,
                                cambiarContrasenaFormErroresControles
                            )
                        "
                    >
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="nuevaContrasena" class="form-label">Nueva Contraseña</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Nueva contraseña del usuario"
                        ngbPopover="Debe ser una contraseña válida."
                    ></i>
                    <input
                        formControlName="nuevaContrasena"
                        type="password"
                        class="form-control"
                        id="nuevaContrasena"
                    />
                    <ng-container
                        *ngFor="
                            let error of formErrores(
                                'nuevaContrasena',
                                cambiarContrasenaForm,
                                cambiarContrasenaFormErroresControles
                            )
                        "
                    >
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="nuevaContrasena" class="form-label">Repetir Nueva Contraseña</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Repetir nueva contraseña del usuario"
                        ngbPopover="Debe ser la misma contraseña que la anterior."
                    ></i>
                    <input
                        formControlName="nuevaContrasenaControl"
                        type="password"
                        class="form-control"
                        id="nuevaContrasenaControl"
                    />
                    <ng-container
                        *ngFor="
                            let error of formErrores(
                                'nuevaContrasenaControl',
                                cambiarContrasenaForm,
                                cambiarContrasenaFormErroresControles
                            )
                        "
                    >
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-template>
