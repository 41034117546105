import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MigracionTarea } from '@entities/migracion-tarea.entity';
import { MigracionTareaService } from '@migracion/services/migracion-tarea.service';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-migracion-tarea',
    templateUrl: './migracion-tarea.component.html',
    styleUrls: ['./migracion-tarea.component.scss'],
})
export class MigracionTareaComponent extends ABMCComponent<MigracionTarea> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Tarea de Migración';
    public titulo = 'Tareas de Migración';

    public tablaOpciones = { order: [[3, 'desc']] };

    protected formErroresControles = {
        parametros: {
            jsonInvalido: 'Debe ingresar un JSON válido',
        },
        resultado: {
            jsonInvalido: 'Debe ingresar un JSON válido',
        },
    };
    protected formImportarErroresControles = {};

    public form = this.formBuilder.group({
        empresa: ['', Validators.required],
        nombre: ['', Validators.required],
        descripcion: [''],
        parametros: [''],
        resultado: [''],
        finalizado: [false],
        error: [false],
        mensajeError: [''],
        fechaInicio: [''],
        horaInicio: [''],
        fechaFin: [''],
        horaFin: [''],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
            defaultContent: '',
        },
        {
            title: 'Empresa',
            data: 'empresa.nombre',
            searchable: true,
            defaultContent: '',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Fecha de Inicio',
            data: 'fechaInicio',
            tipo: 'datetime',
            searchable: true,
        },
        {
            title: 'Fecha de Fin',
            data: 'fechaFin',
            tipo: 'datetime',
            searchable: true,
        },
        {
            title: 'Registros',
            data: 'resultado',
            orderable: false,
            render: (data: any, type: any, row: any) => {
                // Mostrar cantidad de registros solicitados (cantidad), procesados, importados, existentes, total
                const resultado = row.resultado;
                if (resultado) {
                    return `Cantidad: ${resultado.cantidad ?? 0}<br />Procesados: ${
                        resultado.procesados ?? 0
                    }<br />Importados: ${resultado.importados ?? 0}<br />Existentes: ${
                        resultado.existentes ?? 0
                    }<br />Total: ${resultado.cantidadTotal ?? 0}`;
                }
                return '';
            },
        },
        {
            title: 'Finalizado',
            data: 'finalizado',
            tipo: 'boolean',
            searchable: true,
        },
        {
            title: 'Error',
            data: 'error',
            tipo: 'boolean',
            searchable: true,
        },
    ];

    public constructor(
        protected migracionTareaService: MigracionTareaService,
        protected empresaService: EmpresaService,
        protected formBuilder: FormBuilder,
    ) {
        super(migracionTareaService, {
            inicializaciones: {
                nombre: 'Tarea de Migración',
                anchoModal: 'lg',
                genero: 'a',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: {
                    alta: true,
                },
                acciones: {
                    modificacion: true,
                    consulta: true,
                    baja: true,
                },
            },
        });
        this.form.controls['parametros'].setValidators([this.validarJson()]);
        this.form.controls['resultado'].setValidators([this.validarJson()]);
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
        });
    }

    protected override inicializarElemento(): void {
        super.inicializarElemento();
        // Establecer valor iniciales por defecto
        this.form.get('finalizado')?.setValue(false);
        this.form.get('error')?.setValue(false);
    }

    protected override cargarElemento(elemento: MigracionTarea): void {
        super.cargarElemento(elemento);
        this.form.get('parametros')?.setValue(JSON.stringify(elemento.parametros, null, 2));
        this.form.get('resultado')?.setValue(JSON.stringify(elemento.resultado, null, 2));
        // Extraer hora de fechaInicio y fechaFin, y asignarlas a los campos horaInicio y horaFin
        if (elemento.fechaInicio) {
            const fechaInicio = elemento.fechaInicio ? new Date(elemento.fechaInicio) : new Date();
            this.form.get('horaInicio')?.setValue({
                hour: fechaInicio.getHours(),
                minute: fechaInicio.getMinutes(),
                second: fechaInicio.getSeconds(),
            } as any);
        }
        if (elemento.fechaFin) {
            const fechaFin = elemento.fechaFin ? new Date(elemento.fechaFin) : new Date();
            this.form.get('horaFin')?.setValue({
                hour: fechaFin.getHours(),
                minute: fechaFin.getMinutes(),
                second: fechaFin.getSeconds(),
            } as any);
        }
    }

    protected override crearElemento(): MigracionTarea {
        const tarea = super.crearElemento();
        // Asignar valores de horaInicio y horaFin a fechaInicio y fechaFin
        let fechaInicio = this.form.get('fechaInicio')?.value as any;
        let fechaFin = this.form.get('fechaFin')?.value as any;
        const horaInicio = this.form.get('horaInicio')?.value as any;
        const horaFin = this.form.get('horaFin')?.value as any;
        if (horaInicio && tarea.fechaInicio) {
            fechaInicio = new Date(fechaInicio);
            tarea.fechaInicio = `${fechaInicio.getUTCFullYear() || '1900'}-${
                fechaInicio.getUTCMonth() + 1 < 10 ? `0${fechaInicio.getUTCMonth() + 1}` : fechaInicio.getUTCMonth() + 1
            }-${fechaInicio.getUTCDate() < 10 ? `0${fechaInicio.getUTCDate()}` : fechaInicio.getUTCDate()}T${
                horaInicio.hour < 10 ? `0${horaInicio.hour}` : horaInicio.hour
            }:${horaInicio.minute < 10 ? `0${horaInicio.minute}` : horaInicio.minute}:${
                horaInicio.second < 10 ? `0${horaInicio.second}` : horaInicio.second
            }`;
            tarea.fechaInicio = new Date(Date.parse(tarea.fechaInicio)).toISOString();
        }
        if (horaFin && tarea.fechaFin) {
            fechaFin = new Date(fechaFin);
            tarea.fechaFin = `${fechaFin.getUTCFullYear() || '1900'}-${
                fechaFin.getUTCMonth() + 1 < 10 ? `0${fechaFin.getUTCMonth() + 1}` : fechaFin.getUTCMonth() + 1
            }-${fechaFin.getUTCDate() < 10 ? `0${fechaFin.getUTCDate()}` : fechaFin.getUTCDate()}T${
                horaFin.hour < 10 ? `0${horaFin.hour}` : horaFin.hour
            }:${horaFin.minute < 10 ? `0${horaFin.minute}` : horaFin.minute}:${
                horaFin.second < 10 ? `0${horaFin.second}` : horaFin.second
            }`;
            tarea.fechaFin = new Date(Date.parse(tarea.fechaFin)).toISOString();
        }
        return { ...(delete (tarea as any).horaInicio && delete (tarea as any).horaFin && tarea) };
    }

    private validarJson(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            try {
                JSON.parse(control.value);
            } catch (error) {
                return { jsonInvalido: true };
            }
            return null;
        };
    }
}
