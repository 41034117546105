export enum TipoTributoDenominacionEnum {
    PERCEPCIONES_NACIONALES = 'Percepciones Nacionales',
    PERCEPCIONES_MUNICIPALES = 'Percepciones Municipales',
    PERCEPCIONES_INGRESOS_BRUTOS = 'Percepciones de Ingresos Brutos',
    PERCEPCIONES_IVA = 'Percepciones de IVA',
    EXENTOS = 'Exentos',
    IMPUESTOS_INTERNOS = 'Impuestos Internos',
    PERCEPCIONES_A_SUJETOS_NO_CATEGORIZADOS = 'Percepciones a Sujetos no Categorizados',
    NO_GRAVADOS = 'No Gravados',
    RETENCIONES = 'Retenciones',
    OTROS = 'Otros',
}

export const tipoTributoDenominacionIdPorKeyValue = (tipoTributoDenominacion: any) => {
    let id = null;
    switch (tipoTributoDenominacion) {
        case 0:
        case 'PERCEPCIONES_NACIONALES':
            id = 0;
            break;
        case 1:
        case 'PERCEPCIONES_MUNICIPALES':
            id = 1;
            break;
        case 2:
        case 'PERCEPCIONES_INGRESOS_BRUTOS':
            id = 2;
            break;
        case 3:
        case 'PERCEPCIONES_IVA':
            id = 3;
            break;
        case 4:
        case 'EXENTOS':
            id = 4;
            break;
        case 5:
        case 'IMPUESTOS_INTERNOS':
            id = 5;
            break;
        case 6:
        case 'PERCEPCIONES_A_SUJETOS_NO_CATEGORIZADOS':
            id = 6;
            break;
        case 7:
        case 'NO_GRAVADOS':
            id = 7;
            break;
        case 8:
        case 'RETENCIONES':
            id = 8;
            break;
        case 9:
        case 'OTROS':
            id = 9;
            break;
    }
    return id;
};

export const obtenerTipoTributoDenominaciones = () => [
    { id: 0, clave: 'PERCEPCIONES_NACIONALES', nombre: 'Percepciones Nacionales' },
    { id: 1, clave: 'PERCEPCIONES_MUNICIPALES', nombre: 'Percepciones Municipales' },
    { id: 2, clave: 'PERCEPCIONES_INGRESOS_BRUTOS', nombre: 'Percepciones de Ingresos Brutos' },
    { id: 3, clave: 'PERCEPCIONES_IVA', nombre: 'Percepciones de IVA' },
    { id: 4, clave: 'EXENTOS', nombre: 'Exentos' },
    { id: 5, clave: 'IMPUESTOS_INTERNOS', nombre: 'Impuestos Internos' },
    { id: 6, clave: 'PERCEPCIONES_A_SUJETOS_NO_CATEGORIZADOS', nombre: 'Percepciones a Sujetos no Categorizados' },
    { id: 7, clave: 'NO_GRAVADOS', nombre: 'No Gravados' },
    { id: 8, clave: 'RETENCIONES', nombre: 'Retenciones' },
    { id: 9, clave: 'OTROS', nombre: 'Otros' },
];
