<div class="toast-container position-fixed bottom-0 end-0 p-3">
    <ngb-toast
        *ngFor="let toast of toastService.toasts"
        [class]="toast.className"
        [class.noheader]="!toast?.header"
        [autohide]="getAutohide(toast)"
        [delay]="toast.delay || toastDelay()"
        (hidden)="toastService.remove(toast)"
        (mouseenter)="toggleAutohide(toast, 'enter')"
        (mouseleave)="toggleAutohide(toast, 'leave')"
    >
        <!-- Header  -->
        <ng-template ngbToastHeader>
            <ng-template [ngIf]="isHeaderTemplate(toast)" [ngIfElse]="headerText">
                <ng-template [ngTemplateOutlet]="getHeaderTemplate(toast)"></ng-template>
            </ng-template>
        </ng-template>
        <ng-template #headerText>
            <i *ngIf="toast.icon" class="bi bi-{{ toast.icon }}"></i>
            <strong class="me-auto">{{ toast.header }}</strong>
        </ng-template>

        <!-- Body  -->
        <ng-template [ngIf]="isBodyTemplate(toast)" [ngIfElse]="bodyText">
            <ng-template
                [ngTemplateOutlet]="getBodyTemplate(toast)"
                [ngTemplateOutletContext]="{ toast: toast }"
            ></ng-template>
        </ng-template>

        <ng-template #bodyText>
            <img
                *ngIf="toast.img"
                [src]="toast.img.src"
                alt="toast.img.alt"
                class="img-fluid me-2"
                [style.width]="toast.img.style.width || '4rem'"
            /><span>{{ toast.body }}</span></ng-template
        >
    </ngb-toast>
</div>
