import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth/auth.guard';
import { IdiomaComponent } from '@configuracion/components/idioma.component';
import { PaisComponent } from '@configuracion/components/pais.component';
import { ProvinciaComponent } from '@configuracion/components/provincia.component';
import { TipoDocumentoComponent } from '@configuracion/components/tipo-documento.component';
import { TipoResponsableIVAComponent } from '@configuracion/components/tipo-responsable-iva.component';
import { TipoComprobanteComponent } from './components/tipo-comprobante.component';

const routes: Routes = [
    {
        path: '',
        title: 'Configuración',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'idioma',
                title: 'Idiomas',
                component: IdiomaComponent,
            },
            {
                path: 'tipo-responsable-iva',
                title: 'Tipos de responsables de IVA',
                component: TipoResponsableIVAComponent,
            },
            {
                path: 'tipo-documento',
                title: 'Tipos de documentos',
                component: TipoDocumentoComponent,
            },
            {
                path: 'pais',
                title: 'Países',
                component: PaisComponent,
            },
            {
                path: 'provincia',
                title: 'Provincias',
                component: ProvinciaComponent,
            },
            {
                path: 'tipo-comprobante',
                title: 'Tipos de Comprobantes',
                component: TipoComprobanteComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ConfiguracionRoutingModule {}
