import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ReporteSaldosClientesDTO } from '@entities/dtos/reportes/reporte-saldos-clientes.dto';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ReporteComponent } from '@shared/directives/abmc/reporte.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { ReporteSaldosClientesService } from '@ventas/reportes/services/reporte-saldos-clientes.service';
import { ClienteService } from '@ventas/services/cliente.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-reporte-saldos-clientes',
    templateUrl: './reporte-saldos-clientes.component.html',
    styleUrls: ['./reporte-saldos-clientes.component.scss'],
})
export class ReporteSaldosClientesComponent extends ReporteComponent<ReporteSaldosClientesDTO> implements OnInit {
    @ViewChild('tabla') public tabla!: TablaComponent;

    public titulo = 'Reporte: Saldos de Clientes';

    public columnas: ColumnaTabla[] = [
        {
            title: 'Empresa',
            data: 'empresaNombre',
            searchable: true,
            name: 'empresas',
            label: 'nombre',
        },
        {
            title: 'Cliente',
            data: 'clienteRazonSocial',
            searchable: true,
            name: 'clientes',
            label: 'razonSocial',
        },
        {
            title: 'Fecha',
            data: 'fecha',
            tipo: 'date',
            searchable: false,
            orderable: false,
        },
        {
            title: 'Debe',
            data: 'debe',
            tipo: 'moneda',
            searchable: false,
            orderable: false,
        },
        {
            title: 'Haber',
            data: 'haber',
            tipo: 'moneda',
            searchable: false,
            orderable: false,
        },
        {
            title: 'Saldo',
            data: 'saldo',
            tipo: 'moneda',
            searchable: false,
            orderable: false,
        },
    ];

    public constructor(
        protected reporteSaldosClientesService: ReporteSaldosClientesService,
        protected empresaService: EmpresaService,
        protected clienteService: ClienteService,
    ) {
        super(reporteSaldosClientesService);
        this.paginacion = false;
    }

    public override ajaxHandler(event: any) {
        super.ajaxHandler(event, this.reporteSaldosClientesService);
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
        });
    }

    protected override cargarBusquedaAvanzada(): void {
        this.tablaOpciones.order = [[1, 'asc']];
        this.tablaOpciones.searchBuilder = {
            columns: ['Empresa', 'Cliente', 'Fecha'],
            preDefined: {
                criteria: [
                    {
                        data: 'Empresa',
                        origData: 'empresaNombre',
                        condition: '=',
                        value: [] as any[],
                    },
                    {
                        data: 'Fecha',
                        origData: 'fecha',
                        condition: '<',
                        value: [] as any[],
                    },
                ],
            },
        };
        this.tablaOpciones.searchBuilder.preDefined.criteria.forEach((criteria: any) => {
            if (criteria.data === 'Empresa') {
                // Si existe, tomar el valor de la empresa del perfil del usuario
                try {
                    criteria.value = [
                        JSON.parse(localStorage.getItem('empresa') ?? '{}')?.nombre ??
                            this.relaciones.empresas[0]?.nombre ??
                            '',
                    ];
                } catch (error) {
                    criteria.value = [this.relaciones.empresas[0]?.nombre ?? ''];
                }
            }
            if (criteria.data === 'Fecha') {
                criteria.value = [new Date().toISOString().split('T')[0]];
            }
        });
    }
}
