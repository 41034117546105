<h2 class="mb-3">{{ titulo }}</h2>

<kratos-tabla
    #tabla
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="nombre" class="form-label">Nombre</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Nombre"
                        ngbPopover="Nombre del tipo de comprobante."
                    ></i>
                    <input placeholder="Nombre" formControlName="nombre" type="text" class="form-control" id="nombre" />
                    <ng-container *ngFor="let error of formErrores('nombre', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="empresa" class="form-label">Empresa</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Empresa"
                        ngbPopover="Empresa a la que pertenece el tipo de comprobante. Se utiliza para filtrar los comprobantes por empresa."
                    ></i>
                    <ng-select
                        [items]="relaciones.empresas"
                        labelForId="empresa"
                        formControlName="empresa"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('empresa', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="codigoAFIP" class="form-label">Código AFIP</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Código AFIP"
                        ngbPopover="Código AFIP del tipo de comprobante. Este código es utilizado para la autorización de comprobantes. Se puede obtener en los listados de la AFIP."
                    ></i>
                    <input
                        placeholder="Código AFIP"
                        formControlName="codigoAFIP"
                        type="text"
                        class="form-control"
                        id="codigoAFIP"
                    />
                    <ng-container *ngFor="let error of formErrores('codigoAFIP', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="denominacion" class="form-label">Denominación</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Denominación"
                        ngbPopover="Denominación del tipo de comprobante. Se utiliza para la lógica de generación y validación del comprobante."
                    ></i>
                    <ng-select
                        [items]="tipoComprobanteDenominaciones"
                        bindValue="nombre"
                        labelForId="denominacion"
                        formControlName="denominacion"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('denominacion', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="letra" class="form-label">Letra</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Letra"
                        ngbPopover="Letra del tipo de comprobante. Se utiliza para determinar el tipo de comprobante a utilizar según el receptor del mismo."
                    ></i>
                    <ng-select
                        [items]="tipoComprobanteLetras"
                        bindValue="nombre"
                        labelForId="letra"
                        formControlName="letra"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('letra', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="signo" class="form-label">Signo</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Signo"
                        ngbPopover="Signo del tipo de comprobante. Se utiliza para determinar la forma en que afecta al saldo del receptor (cliente, proveedor) en su cuenta corriente. Para operaciones de compra, venta, cobro y pago signo 1 indica que el comprobante suma al saldo, signo -1 indica que resta al saldo."
                    ></i>
                    <ng-select
                        [items]="tipoComprobanteSignos"
                        labelForId="signo"
                        formControlName="signo"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('signo', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-template>
