import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComprobanteTransaccion } from '@entities/comprobante-transaccion.entity';
import { ComprobanteVenta } from '@entities/comprobante-venta.entity';
import { AFIPServicioEnum } from '@entities/enums/afip-servicio.enum';
import { BaseService } from '@shared/services/base.service';
import { ComprobanteVentaView } from '@views/comprobante-venta.view';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ComprobanteVentaService extends BaseService<ComprobanteVenta> {
    public constructor(http: HttpClient) {
        super('ventas/comprobante-venta', http);
    }

    public solicitarCAE(comprobanteVentaId: number, generarQR = false): Observable<ComprobanteTransaccion> {
        return this.http.get<ComprobanteTransaccion>(
            `${this.API_URL}/${this.endpointUrl}/solicitar-cae?comprobanteVentaId=${comprobanteVentaId}&generarQR=${generarQR}`,
        );
    }

    public generarQR(comprobanteVentaId: number): Observable<Blob> {
        return this.http.get(
            `${this.API_URL}/${this.endpointUrl}/solicitar-qr?comprobanteVentaId=${comprobanteVentaId}`,
            {
                responseType: 'blob',
            },
        );
    }

    public consultarUltimoComprobante(
        empresaId: number,
        tipoComprobanteId: number,
        puntoVentaId: number,
    ): Observable<ComprobanteVenta> {
        return this.http.get(
            `${this.API_URL}/${this.endpointUrl}/consultar-ultimo-comprobante?empresaId=${empresaId}&tipoComprobanteId=${tipoComprobanteId}&puntoVentaId=${puntoVentaId}`,
        );
    }

    public imprimirComprobante(comprobanteVentaId: number, pdf = false): Observable<HttpResponse<Blob>> {
        return this.http
            .get(
                `${this.API_URL}/${this.endpointUrl}/imprimir/${comprobanteVentaId}${pdf === true ? '?pdf=true' : ''}`,
                {
                    observe: 'response',
                    responseType: 'blob',
                },
            )
            .pipe(map((response) => response));
    }
}

@Injectable({ providedIn: 'root' })
export class ComprobanteVentaViewService extends BaseService<ComprobanteVentaView> {
    public constructor(http: HttpClient) {
        super('ventas/comprobante-venta/view', http);
    }

    public siguienteNumero(
        empresaId: number,
        puntoVentaId: number,
        tipoComprobanteId: number,
        siguienteNumeroAFIP: boolean,
        servicio?: AFIPServicioEnum,
    ): Observable<number> {
        return this.http.get<number>(
            `${this.API_URL}/${
                this.endpointUrl
            }/siguiente-numero?empresaId=${empresaId}&puntoVentaId=${puntoVentaId}&tipoComprobanteId=${tipoComprobanteId}&siguienteNumeroAFIP=${siguienteNumeroAFIP}${
                servicio ? `&servicio=${servicio}` : ''
            }`,
        );
    }
}
