<h2 class="mb-3" *ngIf="inicializarTabla">{{ titulo }}</h2>

<kratos-tabla
    *ngIf="inicializarTabla"
    #tabla
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="razonSocial" class="form-label">Razón Social</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Razón Social"
                        ngbPopover="Razón social del cliente. Debe ser única."
                    ></i>
                    <input
                        placeholder="Razón Social"
                        formControlName="razonSocial"
                        type="text"
                        class="form-control"
                        id="razonSocial"
                    />
                    <ng-container *ngFor="let error of formErrores('razonSocial', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tipoDocumento" class="form-label">Tipo de Documento</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Documento"
                        ngbPopover="Tipo de documento del cliente."
                    ></i>
                    <ng-select
                        [items]="relaciones.tiposDocumento"
                        labelForId="tipoDocumento"
                        formControlName="tipoDocumento"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoDocumento', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="numeroDocumento" class="form-label">Número de Documento</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Número de Documento"
                        ngbPopover="Número de documento del cliente."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text" *ngIf="nombreMascaraNumeroDocumento">{{
                            nombreMascaraNumeroDocumento
                        }}</span>
                        <input
                            placeholder="Número de Documento"
                            formControlName="numeroDocumento"
                            [mask]="mascaraNumeroDocumento"
                            [showMaskTyped]="true"
                            type="text"
                            class="form-control"
                            id="numeroDocumento"
                        />
                        <ng-container *ngFor="let error of formErrores('numeroDocumento', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>

                        <button
                            class="btn btn-outline-primary"
                            type="button"
                            (click)="autoNumeroDocumento()"
                            ngbTooltip="Obtener Número de Documento del País"
                        >
                            <i class="bi bi-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tipoPersona" class="form-label">Tipo de Persona</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Persona"
                        ngbPopover="Tipo de persona del cliente."
                    ></i>
                    <ng-select
                        [items]="tiposPersona"
                        bindValue="nombre"
                        labelForId="tipoPersona"
                        formControlName="tipoPersona"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoPersona', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="provincia" class="form-label">Provincia</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Provincia"
                        ngbPopover="Provincia o estado del cliente."
                    ></i>
                    <ng-select
                        [items]="relaciones.provincias"
                        labelForId="provincia"
                        formControlName="provincia"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('provincia', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="domicilio" class="form-label">Domicilio</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Domicilio"
                        ngbPopover="Domicilio del cliente."
                    ></i>
                    <input
                        placeholder="Domicilio"
                        formControlName="domicilio"
                        type="text"
                        class="form-control"
                        id="domicilio"
                    />
                    <ng-container *ngFor="let error of formErrores('domicilio', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tipoResponsableIVA" class="form-label">Tipo de Responsable de IVA</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Responsable de IVA"
                        ngbPopover="Tipo de responsable de IVA del cliente."
                    ></i>
                    <ng-select
                        [items]="relaciones.tiposResponsableIVA"
                        labelForId="tipoResponsableIVA"
                        formControlName="tipoResponsableIVA"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoResponsableIVA', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="moneda" class="form-label">Moneda</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Moneda de Facturación"
                        ngbPopover="Moneda de facturación del cliente. Se utilizará como moneda por defecto en la generación de comprobantes de venta."
                    ></i>
                    <ng-select
                        [items]="relaciones.monedas"
                        labelForId="moneda"
                        formControlName="moneda"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('moneda', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="empresas" class="form-label">Empresas</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Empresas"
                        ngbPopover="Empresas a las que pertenece el cliente."
                    ></i>
                    <ng-select
                        [items]="relaciones.empresas"
                        labelForId="empresas"
                        [multiple]="true"
                        formControlName="empresas"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('empresas', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-template>
