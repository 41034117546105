import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComprobanteCompra } from '@entities/comprobante-compra.entity';
import { ComprobanteCompraView } from '@entities/views/comprobante-compra.view';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class ComprobanteCompraService extends BaseService<ComprobanteCompra> {
    public constructor(http: HttpClient) {
        super('compras/comprobante-compra', http);
    }
}

@Injectable({ providedIn: 'root' })
export class ComprobanteCompraViewService extends BaseService<ComprobanteCompraView> {
    public constructor(http: HttpClient) {
        super('compras/comprobante-compra/view', http);
    }
}
