import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CentroCostoService } from '@compras/services/centro-costo.service';
import { CentroCosto } from '@entities/centro-costo.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-centro-costo',
    templateUrl: './centro-costo.component.html',
    styleUrls: ['./centro-costo.component.scss'],
})
export class CentroCostoComponent extends ABMCComponent<CentroCosto> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Centro de Costo';
    public titulo = 'Centros de Costo';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        empresa: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Empresa',
            data: 'empresa.nombre',
            searchable: true,
        },
    ];

    public constructor(
        protected centroCostoService: CentroCostoService,
        protected empresaService: EmpresaService,
        private formBuilder: FormBuilder,
    ) {
        super(centroCostoService, {
            inicializaciones: {
                nombre: 'Centro de Costo',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
        });
    }
}
