import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TipoAlicuotaIVA } from '@entities/tipo-alicuota-iva.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { TipoAlicuotaIVAService } from '@ventas/services/tipo-alicuota-iva.service';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'kratos-tipo-alicuota-iva',
    templateUrl: './tipo-alicuota-iva.component.html',
    styleUrls: ['./tipo-alicuota-iva.component.scss'],
})
export class TipoAlicuotaIVAComponent extends ABMCComponent<TipoAlicuotaIVA> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public mascaraPorcentaje = 'separator.2';
    public nombre = 'Tipo de Alícuota de IVA';
    public titulo = 'Tipos de Alícuotas de IVA';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        porcentaje: ['', Validators.required],
        codigoAFIP: [''],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Porcentaje',
            data: 'porcentaje',
            tipo: 'decimal',
            searchable: true,
        },
        {
            title: 'Código AFIP',
            data: 'codigoAFIP',
            searchable: true,
        },
    ];

    public constructor(
        protected tipoAlicuotaIVAService: TipoAlicuotaIVAService,
        private formBuilder: FormBuilder,
    ) {
        super(tipoAlicuotaIVAService, {
            inicializaciones: {
                nombre: 'Tipo de Alícuota de IVA',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    public toFixed = (value: string | number | undefined | null): number => {
        const formattedValue = String(value).split(' ').join('');
        if (String(value).includes('.') && String(value).split('.').length === 2) {
            const decimal = String(value).split('.')[1]?.length;
            if (decimal && decimal > 2) {
                return Number(parseFloat(formattedValue).toFixed(2));
            }
        }
        return Number(formattedValue);
    };

    protected obtenerRelaciones(): Observable<any> {
        return of({});
    }
}
