import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipoDatoOpcional } from '@entities/tipo-dato-opcional.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class TipoDatoOpcionalService extends BaseService<TipoDatoOpcional> {
    public constructor(http: HttpClient) {
        super('ventas/tipo-dato-opcional', http);
    }
}
