import { BaseEntity } from '@entities/base.entity';
import { MonedaCotizacion } from '@entities/moneda-cotizacion.entity';

export class Moneda extends BaseEntity {
    public nombre?: string;
    public cotizacion?: number;
    public abreviatura?: string;
    public simbolo?: string;
    public codigoAFIP?: string;
    public cotizaciones?: MonedaCotizacion[];
}
