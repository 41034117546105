import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ThemeService } from '@core/services/theme.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'kratos-modal-formulario',
    templateUrl: './modal-formulario.component.html',
    styleUrls: ['./modal-formulario.component.scss'],
})
export class ModalFormularioComponent implements OnInit {
    @HostBinding('class') public class = 'flex-fill';

    @Input() public titulo = 'Kratos Modal Formulario';
    @Input() public formTemplate!: TemplateRef<any>;
    @Input() public footerTemplate!: TemplateRef<any>;
    @Input() public botonAceptar = false;
    @Input() public form!: FormGroup;
    @Output() public aceptarHandler = new EventEmitter();

    public accion: ABMCAccion = 'consulta';
    public cargando = false;
    public pantallaCompleta = false;
    public pantallaCompletaTooltip = 'Maximizar';

    private _formularioInvalido = false;

    public constructor(
        private themeService: ThemeService,
        private elRef: ElementRef,
        public activeModal: NgbActiveModal,
    ) {}

    public ngOnInit() {
        // Identificar si el modal se abrió inicialmente en modo pantalla completa
        const container = this.elRef.nativeElement.closest('.modal-dialog');
        if (container && container.classList.contains('modal-fullscreen')) {
            this.cambiarPantallaCompleta();
        }
    }

    public aceptar(): void {
        this._formularioInvalido = this.form && this.form.invalid;
        this.aceptarHandler.emit();
    }

    public formularioInvalido(): boolean {
        return this._formularioInvalido && this.form && this.form.invalid;
    }

    public cambiarPantallaCompleta() {
        this.pantallaCompleta = !this.pantallaCompleta;
        this.pantallaCompletaTooltip = this.pantallaCompleta ? 'Restaurar' : 'Maximizar';
        this.activeModal.update({
            fullscreen: this.pantallaCompleta,
        });
    }

    public getThemeSize(): string {
        return this.themeService.currentSize;
    }
}
