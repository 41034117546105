import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ComprobanteTransaccion } from '@entities/comprobante-transaccion.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCVolatilComponent } from '@shared/directives/abmc/abmc-volatil.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { format } from 'date-fns';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'kratos-comprobante-transaccion',
    templateUrl: './comprobante-transaccion.component.html',
    styleUrls: ['./comprobante-transaccion.component.scss'],
})
export class ComprobanteTransaccionComponent extends ABMCVolatilComponent<ComprobanteTransaccion> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Transacción';
    public titulo = 'Transacciones';
    public tablaOpciones = {
        botones: {
            size: 'sm',
        },
    };

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        fecha: [''],
        fechaVencimiento: [''],
        resultado: [''],
        codigoAutorizacion: [''],
        observaciones: [''],
        codigoError: [''],
        mensajeError: [''],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'Alta',
            data: 'alta',
            searchable: true,
            tipo: 'datetime',
        },
        {
            title: 'Fecha',
            data: 'fecha',
            searchable: true,
        },
        {
            title: 'Resultado',
            data: 'resultado',
            searchable: true,
        },
        {
            title: 'Código Autorización',
            data: 'codigoAutorizacion',
            searchable: true,
        },
    ];

    public constructor(private formBuilder: FormBuilder) {
        super({
            inicializaciones: {
                nombre: 'Transacción',
                genero: 'a',
                anchoModal: 'lg',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected override inicializarElemento(): void {
        super.inicializarElemento();

        // Establece por defecto la fecha actual
        this.form.get('fecha')?.setValue(format(new Date(), 'yyyy-MM-dd HH:mm'));
    }

    protected override crearElemento(): ComprobanteTransaccion {
        const elemento = super.crearElemento();
        return {
            ...elemento,
            alta: format(new Date(), 'yyyy-MM-dd HH:mm') as any,
        };
    }

    protected obtenerRelaciones(): Observable<any> {
        return of({});
    }
}
