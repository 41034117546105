import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TipoComprobanteDenominacionEnum } from '@entities/enums/tipo-comprobante-denominacion.enum';
import { ReporteLibroIVAVentasView } from '@entities/views/reportes/reporte-libro-iva-ventas.view';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ReporteComponent } from '@shared/directives/abmc/reporte.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { ReporteLibroIVAVentasService } from '@ventas/reportes/services/reporte-libro-iva-ventas.service';
import { format, parseISO } from 'date-fns';
import { Observable, finalize, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-reporte-libro-iva-ventas',
    templateUrl: './reporte-libro-iva-ventas.component.html',
    styleUrls: ['./reporte-libro-iva-ventas.component.scss'],
})
export class ReporteLibroIVAVentasComponent extends ReporteComponent<ReporteLibroIVAVentasView> implements OnInit {
    @ViewChild('tabla') public tabla!: TablaComponent;

    public titulo = 'Reporte: Libro IVA Ventas';

    public columnas: ColumnaTabla[] = [
        {
            title: 'Empresa',
            data: 'empresaNombre',
            searchable: true,
        },
        {
            title: 'Cliente',
            data: 'clienteRazonSocial',
            searchable: true,
        },
        {
            title: 'Tipo',
            data: 'tipoComprobanteNombre',
            searchable: true,
        },
        {
            title: 'Fecha',
            data: 'fecha',
            tipo: 'date',
            searchable: true,
        },
        {
            title: 'Pto. Vta.',
            data: 'puntoVentaNumero',
            searchable: true,
        },
        {
            title: 'Número',
            data: 'numero',
            searchable: true,
        },
        {
            title: 'Importe Neto',
            data: 'importeNeto',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Importe IVA',
            data: 'importeIVA',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Importe Total',
            data: 'importeTotal',
            tipo: 'moneda',
            searchable: true,
        },
    ];

    public constructor(
        protected reporteLibroIVAVentasService: ReporteLibroIVAVentasService,
        protected empresaService: EmpresaService,
    ) {
        super(reporteLibroIVAVentasService, {
            inicializaciones: {
                botones: [
                    {
                        extend: 'collection',
                        text: 'Reportes Contables',
                        className: 'btn-primary',
                        fade: 0,
                        background: false,
                        buttons: [
                            {
                                name: 'imprimirReporteContableEstandar',
                                text: 'Imprimir Reporte Contable',
                            },
                            {
                                name: 'imprimirReporteContableNuevo',
                                text: 'Imprimir Reporte Contable (Formato Nuevo)',
                            },
                            {
                                name: 'exportarLibroIVADigitalVentas',
                                text: 'Exportar Libro IVA Digital Ventas',
                            },
                        ],
                    },
                ],
            },
        });
    }

    public override ajaxHandler(event: any) {
        // Agregar filtros de denominación de TipoComprobante: FACTURA, NOTA_CREDITO, NOTA_DEBITO
        if (event?.dataTablesParameters?.searchBuilder) {
            event.dataTablesParameters.searchBuilder.criteria = this.filtrarTipoComprobante(
                event.dataTablesParameters.searchBuilder.criteria,
            );
            if (!event.dataTablesParameters.searchBuilder.logic) {
                event.dataTablesParameters.searchBuilder.logic = 'AND';
            }
        }
        super.ajaxHandler(event, this.reporteLibroIVAVentasService);
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
        });
    }

    protected override cargarBusquedaAvanzada(): void {
        this.tablaOpciones.order = [[3, 'asc']];
        this.tablaOpciones.searchBuilder = {
            columns: ['Empresa', 'Fecha'],
            preDefined: {
                criteria: [
                    {
                        data: 'Empresa',
                        origData: 'empresaNombre',
                        condition: '=',
                        value: [] as any[],
                    },
                    {
                        data: 'Fecha',
                        origData: 'fecha',
                        condition: 'between',
                        value: [] as any[],
                    },
                ],
            },
        };
        this.tablaOpciones.searchBuilder.preDefined.criteria.forEach((criteria: any) => {
            if (criteria.data === 'Empresa') {
                // Si existe, tomar el valor de la empresa del perfil del usuario
                try {
                    criteria.value = [
                        JSON.parse(localStorage.getItem('empresa') ?? '{}')?.nombre ??
                            this.relaciones.empresas[0]?.nombre ??
                            '',
                    ];
                } catch (error) {
                    criteria.value = [this.relaciones.empresas[0]?.nombre ?? ''];
                }
            }
            if (criteria.data === 'Fecha') {
                // Establecer el rango de fechas entre el primer y último día del mes actual
                // Utilizar formato de fecha 'yyyy-mm-dd'
                const fecha = new Date();
                const primerDia = new Date(fecha.getFullYear(), fecha.getMonth() - 1, 1);
                const ultimoDia = new Date(fecha.getFullYear(), fecha.getMonth(), 0);
                criteria.value = [format(primerDia, 'yyyy-MM-dd'), format(ultimoDia, 'yyyy-MM-dd')];
            }
        });
    }

    private async exportarLibroIVADigitalVentas() {
        this.tabla.recargar();
        // Deshabilitar botones para evitar múltiples clicks
        await this.tabla.deshabilitarBotones();
        const busquedaAvanzada = await this.tabla.obtenerBusquedaAvanzada();
        // Agregar filtros de denominación de TipoComprobante: FACTURA, NOTA_CREDITO, NOTA_DEBITO
        if (busquedaAvanzada) {
            busquedaAvanzada.criteria = this.filtrarTipoComprobante(busquedaAvanzada.criteria);
            if (!busquedaAvanzada.logic) {
                busquedaAvanzada.logic = 'AND';
            }
        }
        const fecha =
            busquedaAvanzada?.criteria?.find((c: any) => c.data === 'Fecha')?.value[0] ||
            format(new Date(), 'yyyy-MM-dd');
        this.subscription.add(
            this.reporteLibroIVAVentasService
                .exportarLibroIVADigitalVentas({
                    searchBuilder: busquedaAvanzada,
                })
                .pipe(finalize(() => this.tabla.habilitarBotones()))
                .subscribe({
                    next: (archivo) => {
                        this.descargarArchivo(archivo, fecha);
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    private async imprimirReporteContableEstandar() {
        await this.imprimirReporteContable(false);
    }

    private async imprimirReporteContableNuevo() {
        await this.imprimirReporteContable(true);
    }

    private async imprimirReporteContable(formatoNuevo = false) {
        this.tabla.recargar();
        // Deshabilitar botones para evitar múltiples clicks
        await this.tabla.deshabilitarBotones();
        const busquedaAvanzada = await this.tabla.obtenerBusquedaAvanzada();
        // Agregar filtros de denominación de TipoComprobante: FACTURA, NOTA_CREDITO, NOTA_DEBITO
        if (busquedaAvanzada) {
            busquedaAvanzada.criteria = this.filtrarTipoComprobante(busquedaAvanzada.criteria);
            if (!busquedaAvanzada.logic) {
                busquedaAvanzada.logic = 'AND';
            }
        }
        this.subscription.add(
            this.reporteLibroIVAVentasService
                .imprimir(
                    {
                        searchBuilder: busquedaAvanzada,
                    },
                    formatoNuevo,
                )
                .pipe(finalize(() => this.tabla.habilitarBotones()))
                .subscribe({
                    next: (respuesta) => {
                        const contentDisposition = respuesta.headers.get('Content-Disposition');
                        const nombreArchivo =
                            contentDisposition?.split('filename=')[1].replace(/"/g, '') ?? 'reporte.pdf';
                        const blob = new File([respuesta.body ?? ''], nombreArchivo, { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const pdfWindow = window.open(url, '_blank');
                        if (pdfWindow) {
                            pdfWindow.addEventListener('load', () => {
                                setTimeout(() => {
                                    pdfWindow.document.title = nombreArchivo;
                                    pdfWindow.name = nombreArchivo;
                                }, 1);
                            });
                        }
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    private descargarArchivo(blob: Blob, fecha: DateString) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        // Agregar año y mes al nombre del archivo
        a.download = `Libro_IVA_Digital_Ventas_${format(parseISO(fecha), 'yyyy-MM')}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    private filtrarTipoComprobante(criteria: any[]): any[] {
        criteria = criteria || [];
        criteria.push({
            condition: 'in',
            data: 'Tipo',
            origData: 'tipoComprobanteDenominacion',
            type: 'string',
            value: [
                TipoComprobanteDenominacionEnum.FACTURA,
                TipoComprobanteDenominacionEnum.NOTA_CREDITO,
                TipoComprobanteDenominacionEnum.NOTA_DEBITO,
            ],
        });
        return criteria;
    }
}
