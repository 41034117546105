import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { obtenerTipoTributoDenominaciones } from '@entities/enums/tipo-tributo-denominacion.enum';
import { TipoTributo } from '@entities/tipo-tributo.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { TipoTributoService } from '@ventas/services/tipo-tributo.service';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'kratos-tipo-tributo',
    templateUrl: './tipo-tributo.component.html',
    styleUrls: ['./tipo-tributo.component.scss'],
})
export class TipoTributoComponent extends ABMCComponent<TipoTributo> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public tipoTributoDenominaciones = obtenerTipoTributoDenominaciones();
    public tipoTributoSignos = [-1, 0, 1];
    public nombre = 'Tipo de Tributo';
    public titulo = 'Tipos de Tributo';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        codigoAFIP: [''],
        denominacion: ['', Validators.required],
        signo: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Denominación',
            data: 'denominacion',
            searchable: true,
        },
        {
            title: 'Código AFIP',
            data: 'codigoAFIP',
            searchable: true,
        },
        {
            title: 'Signo',
            data: 'signo',
            searchable: true,
        },
    ];

    public constructor(
        protected tipoTributoService: TipoTributoService,
        private formBuilder: FormBuilder,
    ) {
        super(tipoTributoService, {
            inicializaciones: {
                nombre: 'Tipo de Tributo',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return of({});
    }
}
