import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PaisService } from '@configuracion/services/pais.service';
import { ProvinciaService } from '@configuracion/services/provincia.service';
import { Pais } from '@entities/pais.entity';
import { Provincia } from '@entities/provincia.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { Observable, forkJoin } from 'rxjs';
import { PaisNumeroDocumentoComponent } from './pais-numero-documento.component';

@Component({
    selector: 'kratos-pais',
    templateUrl: './pais.component.html',
    styleUrls: ['./pais.component.scss'],
})
export class PaisComponent extends ABMCComponent<Pais> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    @ViewChild('paisNumeroDocumento') private paisNumeroDocumento!: PaisNumeroDocumentoComponent;

    public nombre = 'País';
    public titulo = 'Países';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        provincias: [[]],
        codigoAFIP: [''],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Código AFIP',
            data: 'codigoAFIP',
            searchable: true,
        },
    ];

    public constructor(
        protected paisService: PaisService,
        protected provinciaService: ProvinciaService,
        private formBuilder: FormBuilder,
    ) {
        super(paisService, {
            inicializaciones: {
                nombre: 'País',
                anchoModal: 'lg',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    public buscarProvincia = (term: string, item: Provincia) => {
        term = term.toLocaleLowerCase();
        return (
            (item?.nombre?.toLocaleLowerCase().indexOf(term) ?? -1) > -1 ||
            (item?.pais?.nombre?.toLocaleLowerCase().indexOf(term) ?? -1) > -1
        );
    };

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            provincias: this.provinciaService.obtenerTodos(),
        });
    }

    protected override cargarElemento(elemento: Pais) {
        super.cargarElemento(elemento);
        this.paisNumeroDocumento.elementos = elemento?.numerosDocumento ?? [];
        this.paisNumeroDocumento.tabla.recargar();
    }

    protected override crearElemento(): Pais {
        this.elementoActual = { ...new Pais(), ...this.elementoActual };
        this.elementoActual.numerosDocumento = this.paisNumeroDocumento.elementos ?? [];
        // Nulificar los IDs de los elementos que tengan ID negativo, ya que son elementos volátiles
        this.elementoActual.numerosDocumento.forEach((numeroDocumento) => {
            if (numeroDocumento.id && numeroDocumento.id < 0) {
                numeroDocumento.id = undefined;
            }
        });
        return super.crearElemento();
    }
}
