<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="tipoTributo" class="form-label">Tipo</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Tributo"
                        ngbPopover="Tipo de tributo."
                    ></i>
                    <ng-select
                        [items]="relaciones.tiposTributo"
                        labelForId="tipoTributo"
                        formControlName="tipoTributo"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoTributo', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="importe" class="form-label">Importe</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Importe"
                        ngbPopover="Importe del medio de pago."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">{{ moneda.simbolo }}</span>
                        <input
                            placeholder="Importe"
                            formControlName="importe"
                            [mask]="mascaraImporte"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="1000000000000000"
                            [outputTransformFn]="toFixedImporte"
                            class="form-control"
                            id="importe"
                        />
                        <ng-container *ngFor="let error of formErrores('importe', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="descripcion" class="form-label">Descripción</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Descripción"
                        ngbPopover="Descripción del medio de pago."
                    ></i>
                    <textarea
                        maxlength="255"
                        placeholder="Descripción"
                        formControlName="descripcion"
                        rows="3"
                        type="text"
                        class="form-control"
                        id="descripcion"
                    ></textarea>
                </div>
            </div>
        </div>
    </form>
</ng-template>
