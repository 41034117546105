import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ThemeService } from '@core/services/theme.service';
import { BusquedaDTO } from '@dtos/busqueda.dto';
import { RespuestaDTO } from '@dtos/respuesta.dto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export abstract class BaseService<T> {
    private themeService = inject(ThemeService);

    protected API_URL = environment.API_URL;
    protected endpointUrl: string;

    public constructor(
        endpointUrl: string,
        protected http: HttpClient,
    ) {
        this.endpointUrl = endpointUrl;
    }

    public obtener(id: number): Observable<T> {
        return this.http.get<T>(`${this.API_URL}/${this.endpointUrl}/${id}`);
    }

    public obtenerTodos(): Observable<RespuestaDTO<T>>;
    public obtenerTodos(parametros: any): Observable<RespuestaDTO<T>>;
    public obtenerTodos(parametros?: any | undefined): Observable<RespuestaDTO<T>> {
        if (this.themeService.currentDebug === 'true') {
            parametros = { ...parametros, incluirEliminados: true };
        }
        if (parametros == null) {
            return this.http.get<RespuestaDTO<T>>(`${this.API_URL}/${this.endpointUrl}`);
        }
        const busqueda = new BusquedaDTO(parametros);
        return this.http.post<RespuestaDTO<T>>(`${this.API_URL}/${this.endpointUrl}/buscar`, busqueda);
    }

    public modificar(entidad: T): Observable<T> {
        return this.http.put<T>(`${this.API_URL}/${this.endpointUrl}`, entidad);
    }

    public crear(entidad: T): Observable<T> {
        return this.http.post<T>(`${this.API_URL}/${this.endpointUrl}`, entidad);
    }

    public eliminar(id: number): Observable<any> {
        return this.http.delete(`${this.API_URL}/${this.endpointUrl}/${id}`);
    }

    public destruir(id: number): Observable<any> {
        return this.http.delete(`${this.API_URL}/${this.endpointUrl}/destruir/${id}`);
    }

    public restaurar(id: number): Observable<any> {
        return this.http.patch(`${this.API_URL}/${this.endpointUrl}/restaurar/${id}`, null);
    }
}
