<h2 class="mb-3">{{ titulo }}</h2>

<kratos-tabla
    #tabla
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="nombre" class="form-label">Nombre</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Nombre"
                        ngbPopover="Nombre del punto de venta."
                    ></i>
                    <input placeholder="Nombre" formControlName="nombre" type="text" class="form-control" id="nombre" />
                    <ng-container *ngFor="let error of formErrores('nombre', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="numero" class="form-label">Número</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Número"
                        ngbPopover="Número del punto de venta. Se utiliza para la numeración de los comprobantes."
                    ></i>
                    <input
                        placeholder="Número"
                        formControlName="numero"
                        type="number"
                        class="form-control"
                        id="numero"
                    />
                    <ng-container *ngFor="let error of formErrores('numero', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <div class="form-check form-switch">
                        <label for="activo" class="form-check-label">Activo</label
                        ><i
                            *ngIf="getThemeHelpEnabled()"
                            class="instrucciones-pantalla bi bi-question-circle"
                            popoverTitle="Activo"
                            ngbPopover="Indica si el punto de venta está activo o no."
                        ></i>
                        <input
                            placeholder="Activo"
                            formControlName="activo"
                            type="checkbox"
                            class="form-check-input me-1"
                            id="activo"
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tipoPuntoVenta" class="form-label">Tipo</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de punto de venta"
                        ngbPopover="Tipo de punto de venta. Se utiliza para determinar el tipo de comprobante que se puede emitir y la lógica de generación del comprobante."
                    ></i>
                    <ng-select
                        [items]="relaciones.tiposPuntosVenta"
                        labelForId="tipoPuntoVenta"
                        formControlName="tipoPuntoVenta"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoPuntoVenta', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="empresa" class="form-label">Empresa</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Empresa"
                        ngbPopover="Empresa a la que pertenece el punto de venta."
                    ></i>
                    <ng-select
                        [items]="relaciones.empresas"
                        labelForId="empresa"
                        formControlName="empresa"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('empresa', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-template>
