<div class="login-bg-image" style="background-image: url('/assets/{{ loginBgImage }}');"></div>
<div class="position-absolute top-35 start-50 translate-middle d-flex">
    <div class="login-form p-3 p-lg-5 rounded-4 d-inline-flex flex-column">
        <div class="d-flex justify-content-center">
            <img src="assets/{{ loginImage }}" class="img-fluid mb-3" alt="Kratos Logo" />
        </div>
        <div class="d-flex justify-content-center form-login mt-4">
            <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
                <h4 class="text-center mb-lg-4">Ingreso al sistema</h4>
                <div class="mb-2 mb-lg-4">
                    <label class="col-form-label col-form-label-lg text-primary-emphasis p-0" for="email">Email</label>
                    <input
                        name="email"
                        type="email"
                        class="form-control form-control-lg"
                        formControlName="email"
                        placeholder="Email"
                        required
                    />
                </div>
                <div class="mb-2">
                    <label class="col-form-label col-form-label-lg text-primary-emphasis p-0" for="password"
                        >Contraseña</label
                    >
                    <input
                        name="password"
                        type="password"
                        class="form-control form-control-lg"
                        formControlName="contrasena"
                        placeholder="Contraseña"
                    />
                </div>
                <button type="submit" class="btn btn-primary btn-lg w-100 mt-3 mt-lg-4" [disabled]="cargando">
                    <span class="spinner-border spinner-border-sm mx-3" *ngIf="cargando"></span>
                    <span *ngIf="!cargando">Ingresar</span>
                </button>
            </form>
        </div>
    </div>
</div>
<kratos-toasts-container></kratos-toasts-container>
