import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { ThemeService } from '@core/services/theme.service';
import { RolUsuarioEnum } from '@entities/enums/usuario-rol.enum';
import { AutoUnsubscribe } from '@helpers/auto-unsubscribe.decorator';
import { ToastService } from '@shared/services/toast.service';
import jwt_decode from 'jwt-decode';
import { Subscription, finalize } from 'rxjs';

@Component({
    selector: 'kratos-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
@AutoUnsubscribe
export class LoginComponent {
    private subscription = new Subscription();

    public loginForm: FormGroup;
    public loginBgImage = `login-background-${Math.floor(Math.random() * (2 - 1 + 1)) + 1}.jpg`;
    public loginImage: string =
        Math.floor(Math.random() * (100 - 1 + 1)) + 1 === 100 ? 'login-logo.png' : 'kratos-logo.png';
    public userThemeMode: string;
    public cargando = false;

    private intentos = 0;

    public constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private themeService: ThemeService,
        private toastService: ToastService,
    ) {
        this.loginForm = this.fb.group({
            email: [''],
            contrasena: [''],
        });

        this.userThemeMode = this.themeService.currentMode;
        this.themeService.currentMode = 'light';
    }

    public loginUser() {
        this.cargando = true;
        this.subscription.add(
            this.authService
                .login(this.loginForm.value)
                .pipe(
                    finalize(() => {
                        this.cargando = false;
                    }),
                )
                .subscribe({
                    next: (resp) => {
                        localStorage.setItem('access_token', resp.access_token);
                        const decodedToken = this.getDecodedAccessToken(resp.access_token);
                        this.authService.getUserProfile(decodedToken.sub).subscribe({
                            next: (res) => {
                                this.intentos = 0;
                                localStorage.setItem('usuario', JSON.stringify(res));
                                // Si el usuario no tiene rol de administrador, seleccionar la primera empresa
                                if (res.rol !== RolUsuarioEnum.ADMINISTRADOR) {
                                    localStorage.setItem('empresa', JSON.stringify(res.empresas[0]));
                                }
                                this.router.navigate(['administracion/perfil']);
                                this.themeService.currentMode = this.userThemeMode;
                                this.toastService.successHandler(`¡Bienvenido, ${res.nombre}!`);
                            },
                            error: (error) => {
                                // Si se recibe un error 401, intentar limpiar la empresa y volver a intentar una vez
                                if (error.status === 401 && this.intentos === 0) {
                                    this.intentos++;
                                    localStorage.removeItem('empresa');
                                    this.loginUser();
                                    return;
                                }
                                this.toastService.errorHandler(error);
                            },
                        });
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    private getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
}
