<kratos-comprobante-venta
    (init)="comprobanteVentaInit($event)"
    (afterViewInit)="comprobanteVentaAfterViewInit($event)"
></kratos-comprobante-venta>

<ng-template #comprobanteVentaBusquedaTemplate>
    <kratos-comprobante-venta (init)="comprobanteVentaBusquedaInit($event)"></kratos-comprobante-venta>
</ng-template>

<ng-template #formCambiarSaldoTemplate>
    <form [formGroup]="formCambiarSaldo" #formCambiarSaldoElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="importe" class="form-label">Importe</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Importe"
                        ngbPopover="Ingrese el importe del saldo a compensar del comprobante. Este valor debe ser mayor o igual a cero."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">{{ moneda.simbolo }}</span>
                        <input
                            placeholder="Importe"
                            formControlName="importe"
                            [mask]="mascaraImporte"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="1000000000000000"
                            [outputTransformFn]="toFixedImporte"
                            class="form-control"
                            id="importe"
                        />
                        <ng-container
                            *ngFor="
                                let error of formErrores('importe', formCambiarSaldo, formCambiarSaldoErroresControles)
                            "
                        >
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
