import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PuntoVenta } from '@entities/punto-venta.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { PuntoVentaService } from '@ventas/services/punto-venta.service';
import { TipoPuntoVentaService } from '@ventas/services/tipo-punto-venta.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-punto-venta',
    templateUrl: './punto-venta.component.html',
    styleUrls: ['./punto-venta.component.scss'],
})
export class PuntoVentaComponent extends ABMCComponent<PuntoVenta> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Punto de Venta';
    public titulo = 'Puntos de Venta';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        numero: ['', Validators.required],
        activo: [true],
        tipoPuntoVenta: ['', Validators.required],
        empresa: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Número',
            data: 'numero',
            tipo: 'number',
            searchable: true,
        },
        {
            title: 'Activo',
            data: 'activo',
            tipo: 'boolean',
        },
        {
            title: 'Tipo',
            data: 'tipoPuntoVenta.nombre',
            searchable: true,
        },
        {
            title: 'Empresa',
            data: 'empresa.nombre',
            searchable: true,
        },
    ];

    public constructor(
        protected puntoVentaService: PuntoVentaService,
        protected tipoPuntoVentaService: TipoPuntoVentaService,
        protected empresaService: EmpresaService,
        private formBuilder: FormBuilder,
    ) {
        super(puntoVentaService, {
            inicializaciones: {
                nombre: 'Punto de Venta',
                genero: 'o',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            tiposPuntosVenta: this.tipoPuntoVentaService.obtenerTodos(),
            empresas: this.empresaService.obtenerTodos(),
        });
    }

    protected override inicializarElemento(): void {
        super.inicializarElemento();
        // Establecer valor iniciales por defecto
        this.form.get('activo')?.setValue(true);
    }
}
