import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { obtenerTiposPersona } from '@entities/enums/tipo-persona.enum';
import { PaisNumeroDocumento } from '@entities/pais-numero-documento.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCVolatilComponent } from '@shared/directives/abmc/abmc-volatil.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'kratos-pais-numero-documento',
    templateUrl: './pais-numero-documento.component.html',
    styleUrls: ['./pais-numero-documento.component.scss'],
})
export class PaisNumeroDocumentoComponent extends ABMCVolatilComponent<PaisNumeroDocumento> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public tiposPersona = obtenerTiposPersona();
    public nombre = 'Número de Documento';
    public titulo = 'Números de Documento';
    public tablaOpciones = {
        botones: {
            size: 'sm',
        },
    };

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        numeroDocumento: ['', Validators.required],
        tipoPersona: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'Número de Documento',
            data: 'numeroDocumento',
            searchable: true,
        },
        {
            title: 'Tipo de Persona',
            data: 'tipoPersona',
            searchable: true,
        },
    ];

    public constructor(private formBuilder: FormBuilder) {
        super({
            inicializaciones: {
                nombre: 'Número de Documento',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return of({});
    }
}
