import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ComprobanteCompraTributo } from '@entities/comprobante-compra-tributo.entity';
import { ComprobanteCompra } from '@entities/comprobante-compra.entity';
import { Moneda } from '@entities/moneda.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCVolatilComponent } from '@shared/directives/abmc/abmc-volatil.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { TipoTributoService } from '@ventas/services/tipo-tributo.service';
import Big from 'big.js';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-comprobante-compra-tributo',
    templateUrl: './comprobante-compra-tributo.component.html',
    styleUrls: ['./comprobante-compra-tributo.component.scss'],
})
export class ComprobanteCompraTributoComponent extends ABMCVolatilComponent<ComprobanteCompraTributo> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public mascaraImporte = 'separator.2';
    public nombre = 'Tributo';
    public titulo = 'Tributos';
    public tablaOpciones = {
        botones: {
            size: 'sm',
        },
    };
    public moneda: Moneda = { simbolo: '$', cotizacion: +1.0 };

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        descripcion: [''],
        importe: ['', Validators.required],
        tipoTributo: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'Tipo',
            data: 'tipoTributo.nombre',
            searchable: true,
        },
        {
            title: 'Descripción',
            data: 'descripcion',
            searchable: true,
        },
        {
            title: 'Importe',
            data: 'importe',
            tipo: 'moneda',
            searchable: true,
        },
    ];

    public constructor(
        protected tipoTributoService: TipoTributoService,
        private formBuilder: FormBuilder,
    ) {
        super({
            inicializaciones: {
                nombre: 'Tributo',
                anchoModal: 'lg',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    public toFixedImporte = (value: string | number | undefined | null): number => {
        return this.toFixed(value, 2);
    };

    public toFixed(value: string | number | undefined | null, decimales = 2): number {
        const formattedValue = String(value).split(' ').join('');
        if (String(value).includes('.') && String(value).split('.').length === 2) {
            const decimal = String(value).split('.')[1]?.length;
            if (decimal && decimal > decimales) {
                return Number(parseFloat(formattedValue).toFixed(decimales));
            }
        }
        return Number(formattedValue);
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            tiposTributo: this.tipoTributoService.obtenerTodos(),
        });
    }

    protected override crearElemento(): ComprobanteCompraTributo {
        const elemento = new ComprobanteCompraTributo(super.crearElemento());
        // Convertir importes del elemento a la moneda oficial, según la cotización del comprobante
        elemento.importe = this.convertirImporte(elemento.importe ?? 0, this.moneda.cotizacion);
        return elemento;
    }

    protected override cargarElemento(elemento: ComprobanteCompraTributo): void {
        // Convertir importes de moneda oficial a moneda seleccionada por el usuario, según la cotización del comprobante
        const item = new ComprobanteCompraTributo(elemento);
        const cotizacion = 1 / (this.moneda.cotizacion ?? 1);
        item.importe = this.convertirImporte(elemento.importe ?? 0, cotizacion);
        super.cargarElemento(item);
    }

    private convertirImporte(importe: number, cotizacion = +1.0): number {
        return Big(importe).times(Big(cotizacion)).round(2).toNumber();
    }

    public saldarComprobante(comprobanteCompra: ComprobanteCompra): void {
        this.subscription.add(
            forkJoin({
                relaciones: this.obtenerRelaciones(),
            }).subscribe({
                next: ({ relaciones }) => {
                    this.cargarRelaciones(relaciones);
                    for (const tributo of comprobanteCompra.tributos ?? []) {
                        const item = this.crearElementoSaldo(tributo);
                        this.subscription.add(
                            this.baseService.crear(item).subscribe({
                                next: (resp: any) => {
                                    this.postAlta(resp);
                                },
                                error: this.toastService.errorHandler.bind(this.toastService),
                            }),
                        );
                    }
                },
                error: this.toastService.errorHandler.bind(this.toastService),
            }),
        );
    }

    private crearElementoSaldo(comprobanteCompraTributo: ComprobanteCompraTributo): ComprobanteCompraTributo {
        return new ComprobanteCompraTributo({
            importe: comprobanteCompraTributo.importe,
            descripcion: comprobanteCompraTributo.descripcion,
            tipoTributo: comprobanteCompraTributo.tipoTributo,
        });
    }
}
