import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth/auth.guard';
import { ClienteComponent } from '@ventas/components/cliente.component';
import { ComprobanteVentaComponent } from '@ventas/components/comprobante-venta.component';
import { MonedaComponent } from '@ventas/components/moneda.component';
import { ProductoComponent } from '@ventas/components/producto.component';
import { PuntoVentaComponent } from '@ventas/components/punto-venta.component';
import { TipoActividadComponent } from '@ventas/components/tipo-actividad.component';
import { TipoAlicuotaIVAComponent } from '@ventas/components/tipo-alicuota-iva.component';
import { TipoConceptoComponent } from '@ventas/components/tipo-concepto.component';
import { TipoDatoOpcionalComponent } from '@ventas/components/tipo-dato-opcional.component';
import { TipoMedioPagoComponent } from '@ventas/components/tipo-medio-pago.component';
import { TipoPuntoVentaComponent } from '@ventas/components/tipo-punto-venta.component';
import { TipoTributoComponent } from '@ventas/components/tipo-tributo.component';
import { UnidadMedidaComponent } from '@ventas/components/unidad-medida.component';
import { ReporteCobrosPendientesComponent } from '@ventas/reportes/components/reporte-cobros-pendientes.component';
import { ReporteContableVentasJurisdiccionComponent } from '@ventas/reportes/components/reporte-contable-ventas-jurisdiccion.component';
import { ReporteCuentaCorrienteComponent } from '@ventas/reportes/components/reporte-cuenta-corriente.component';
import { ReporteLibroIVAVentasComponent } from '@ventas/reportes/components/reporte-libro-iva-ventas.component';
import { ReporteSaldosClientesComponent } from '@ventas/reportes/components/reporte-saldos-clientes.component';

const routes: Routes = [
    {
        path: '',
        title: 'Ventas',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'comprobante-venta',
                title: 'Comprobantes de Venta',
                component: ComprobanteVentaComponent,
            },
            {
                path: 'cliente',
                title: 'Clientes',
                component: ClienteComponent,
            },
            {
                path: 'producto',
                title: 'Productos',
                component: ProductoComponent,
            },
            {
                path: 'punto-venta',
                title: 'Puntos de Venta',
                component: PuntoVentaComponent,
            },
            {
                path: 'unidad-medida',
                title: 'Unidades de Medida',
                component: UnidadMedidaComponent,
            },
            {
                path: 'moneda',
                title: 'Monedas',
                component: MonedaComponent,
            },
            {
                path: 'tipo-actividad',
                title: 'Tipos de Actividad',
                component: TipoActividadComponent,
            },
            {
                path: 'tipo-alicuota-iva',
                title: 'Tipos de Alícuotas de IVA',
                component: TipoAlicuotaIVAComponent,
            },
            {
                path: 'tipo-concepto',
                title: 'Tipos de Conceptos',
                component: TipoConceptoComponent,
            },
            {
                path: 'tipo-dato-opcional',
                title: 'Tipos de Datos Opcionales',
                component: TipoDatoOpcionalComponent,
            },
            {
                path: 'tipo-medio-pago',
                title: 'Tipos de Medios de Pago',
                component: TipoMedioPagoComponent,
            },
            {
                path: 'tipo-punto-venta',
                title: 'Tipos de Punto de Venta',
                component: TipoPuntoVentaComponent,
            },
            {
                path: 'tipo-tributo',
                title: 'Tipos de Tributo',
                component: TipoTributoComponent,
            },
            {
                path: 'reporte/cuenta-corriente',
                title: 'Reporte: Cuenta Corriente',
                component: ReporteCuentaCorrienteComponent,
            },
            {
                path: 'reporte/saldos-clientes',
                title: 'Reporte: Saldos de Clientes',
                component: ReporteSaldosClientesComponent,
            },
            {
                path: 'reporte/cobros-pendientes',
                title: 'Reporte: Cobros Pendientes',
                component: ReporteCobrosPendientesComponent,
            },
            {
                path: 'reporte/libro-iva-ventas',
                title: 'Reporte: Libro IVA Ventas',
                component: ReporteLibroIVAVentasComponent,
            },
            {
                path: 'reporte/contable-ventas-jurisdiccion',
                title: 'Reporte Contable: Ventas por Jurisdicción',
                component: ReporteContableVentasJurisdiccionComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class VentasRoutingModule {}
