import { AdministracionModule } from '@administracion/administracion.module';
import { NgModule } from '@angular/core';
import { ComprasModule } from '@compras/compras.module';
import { ConfiguracionModule } from '@configuracion/configuracion.module';
import { CoreComponent } from '@core/components/core/core.component';
import { ThemeSwitcherComponent } from '@core/components/theme-switcher/theme-switcher.component';
import { CoreRoutingModule } from '@core/core.routing.module';
import { MigracionModule } from '@migracion/migracion.module';
import { SharedModule } from '@shared/shared.module';
import { VentasModule } from '@ventas/ventas.module';

@NgModule({
    declarations: [ThemeSwitcherComponent, CoreComponent],
    imports: [
        SharedModule,
        CoreRoutingModule,
        AdministracionModule,
        ConfiguracionModule,
        MigracionModule,
        VentasModule,
        ComprasModule,
    ],
})
export class CoreModule {}
