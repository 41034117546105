<h2 class="mb-3" *ngIf="titulo">{{ titulo }}</h2>

<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
    (botonHandler)="botonHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col" [ngClass]="{ 'd-none': facturacionSimple }">
                    <label for="empresa" class="form-label">Empresa</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Empresa"
                        ngbPopover="Empresa emisora del comprobante."
                    ></i>
                    <ng-select
                        [items]="relaciones.empresas"
                        labelForId="empresa"
                        formControlName="empresa"
                        class="form-control"
                        (change)="cambiarEmpresa($event, { inicializar: true })"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('empresa', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="proveedor" class="form-label">Proveedor</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Proveedor"
                        ngbPopover="Proveedor del comprobante."
                    ></i>
                    <div class="input-group">
                        <button
                            class="input-group-text btn btn-outline-primary"
                            popoverTitle="Agregar Proveedor"
                            ngbPopover="Agregar un nuevo proveedor al sistema. Se abrirá una ventana para completar los datos del proveedor. Una vez completados los datos, el proveedor se seleccionará automáticamente."
                            (click)="agregarProveedor()"
                        >
                            <i class="bi bi-person-plus-fill"></i>
                        </button>
                        <ng-select
                            [items]="relaciones.proveedores"
                            bindLabel="razonSocial"
                            labelForId="proveedor"
                            [searchFn]="buscarProveedor"
                            [virtualScroll]="true"
                            formControlName="proveedor"
                            class="form-control"
                            (change)="cambiarProveedor($event)"
                        >
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                <span [ngOptionHighlight]="search">{{ item.razonSocial }}</span
                                >&NonBreakingSpace;&dash;&NonBreakingSpace;<span [ngOptionHighlight]="search"
                                    >{{ item.tipoDocumento?.nombre }} {{ item.numeroDocumento }}</span
                                >
                            </ng-template>
                        </ng-select>
                    </div>
                    <ng-container *ngFor="let error of formErrores('proveedor', form, formErroresControles)">
                        <div class="invalid-feedback" [ngClass]="{ 'd-block': form.get('proveedor')?.touched }">
                            {{ error }}
                        </div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="fecha" class="form-label">Fecha</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Fecha"
                        ngbPopover="Fecha del comprobante."
                    ></i>
                    <div class="input-group">
                        <input
                            placeholder="dd/mm/aaaa"
                            formControlName="fecha"
                            class="form-control"
                            id="fecha"
                            ngbDatepicker
                            #fecha="ngbDatepicker"
                            (change)="cambiarFecha($event)"
                            (dateSelect)="cambiarFecha($event)"
                        />
                        <button
                            *ngIf="form.get('fecha')?.value && !form?.get('fecha')?.disabled"
                            class="btn btn-sm btn-outline-secondary bi bi-x-lg"
                            (click)="form.get('fecha')?.setValue(null)"
                            type="button"
                        ></button>
                        <button
                            *ngIf="!form.get('fecha')?.disabled"
                            class="btn btn-outline-secondary bi bi-calendar3"
                            (click)="fecha.toggle()"
                            type="button"
                        ></button>
                        <ng-container *ngFor="let error of formErrores('fecha', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tipoComprobante" class="form-label">Tipo de Comprobante</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Comprobante"
                        ngbPopover="Tipo de comprobante."
                    ></i>
                    <ng-select
                        [items]="relaciones.tiposComprobante"
                        labelForId="tipoComprobante"
                        formControlName="tipoComprobante"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoComprobante', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="centroCosto" class="form-label">Centro de Costo</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Centro de Costo"
                        ngbPopover="Centro de costo del comprobante."
                    ></i>
                    <span *ngIf="cargando.centroCosto" class="spinner-grow spinner-grow-sm"></span>
                    <ng-select
                        [items]="relaciones.centrosCosto"
                        bindLabel="nombre"
                        labelForId="centroCosto"
                        [searchFn]="buscarCentroCosto"
                        formControlName="centroCosto"
                        class="form-control"
                    >
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <span [ngOptionHighlight]="search">{{ item.nombre }}</span>
                        </ng-template>
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('centroCosto', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="puntoVenta" class="form-label">Punto de Venta</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Punto de Venta"
                        ngbPopover="Punto de venta del comprobante."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">#</span>
                        <input
                            type="number"
                            formControlName="puntoVenta"
                            [mask]="mascaraPuntoVenta"
                            class="form-control"
                            id="puntoVenta"
                            placeholder="Punto de Venta"
                        />
                        <ng-container *ngFor="let error of formErrores('puntoVenta', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="col">
                    <label for="numero" class="form-label">Número</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Número"
                        ngbPopover="Número del comprobante. Debe ser único junto con el proveedor, tipo de comprobante, y punto de venta."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">#</span>
                        <input
                            type="number"
                            formControlName="numero"
                            [mask]="mascaraNumero"
                            class="form-control"
                            id="numero"
                            placeholder="Número"
                        />
                        <ng-container *ngFor="let error of formErrores('numero', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tipoDocumento" class="form-label">Tipo Documento</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Documento"
                        ngbPopover="Tipo de documento del proveedor del comprobante."
                    ></i>
                    <ng-select
                        [items]="relaciones.tiposDocumento"
                        labelForId="tipoDocumento"
                        formControlName="tipoDocumento"
                        class="form-control"
                        (change)="cambiarTipoDocumento($event)"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoDocumento', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="numeroDocumento" class="form-label">Número de Documento</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Número de Documento"
                        ngbPopover="Número de documento del proveedor."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text" *ngIf="nombreMascaraNumeroDocumento">{{
                            nombreMascaraNumeroDocumento
                        }}</span>
                        <input
                            placeholder="Número de Documento"
                            formControlName="numeroDocumento"
                            [mask]="mascaraNumeroDocumento"
                            [showMaskTyped]="true"
                            type="text"
                            class="form-control"
                            id="numeroDocumento"
                        />
                        <ng-container *ngFor="let error of formErrores('numeroDocumento', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3" [ngClass]="{ 'd-none': facturacionSimple }">
                <div class="col">
                    <label for="tipoActividad" class="form-label">Tipo de Actividad</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Actividad"
                        ngbPopover="Tipo de actividad del comprobante."
                    ></i>
                    <ng-select
                        [items]="relaciones.tiposActividad"
                        labelForId="tipoActividad"
                        formControlName="tipoActividad"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoActividad', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="moneda" class="form-label">Moneda</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Moneda"
                        ngbPopover="Moneda del comprobante."
                    ></i>
                    <ng-select
                        [items]="relaciones.monedas"
                        labelForId="moneda"
                        formControlName="moneda"
                        class="form-control"
                        (change)="cambiarMoneda($event)"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('moneda', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="cotizacion" class="form-label">Cotización</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Cotización"
                        ngbPopover="Cotización del comprobante en relación a la moneda oficial."
                    ></i>
                    <span *ngIf="cargando.cotizacion" class="spinner-grow spinner-grow-sm"></span>
                    <div class="input-group">
                        <span class="input-group-text">{{ monedaOficial?.simbolo ?? '$' }}</span>
                        <input
                            placeholder="Cotización"
                            formControlName="cotizacion"
                            [mask]="mascaraCotizacion"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="100000000000"
                            [outputTransformFn]="toFixedCotizacion"
                            class="form-control"
                            id="cotizacion"
                            (change)="actualizarCotizacion()"
                            [readonly]="cotizacionOficial()"
                        />
                        <button
                            class="btn btn-outline-primary"
                            type="button"
                            (click)="obtenerCotizacionAFIP()"
                            ngbTooltip="Obtener cotización según AFIP"
                        >
                            AFIP <i class="bi bi-currency-exchange"></i>
                        </button>
                        <ng-container *ngFor="let error of formErrores('cotizacion', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div ngbNav #nav="ngbNav" class="nav-tabs mt-3 mx-n3 px-3" [destroyOnHide]="false">
        <ng-container ngbNavItem="items">
            <button ngbNavLink>Items</button>
            <ng-template ngbNavContent #itemsTemplate>
                <kratos-comprobante-compra-item #comprobanteCompraItem></kratos-comprobante-compra-item>
                <ng-container *ngFor="let error of formErrores('items', form, formErroresControles)">
                    <div class="invalid-feedback">{{ error }}</div>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem="mediosPago">
            <button ngbNavLink>Medios de Pago</button>
            <ng-template ngbNavContent #mediosPagoTemplate>
                <kratos-comprobante-compra-medio-pago
                    #comprobanteCompraMedioPago
                ></kratos-comprobante-compra-medio-pago>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem="tributos">
            <button ngbNavLink>Tributos</button>
            <ng-template ngbNavContent #tributosTemplate>
                <kratos-comprobante-compra-tributo #comprobanteCompraTributo></kratos-comprobante-compra-tributo>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem="comprobantesAsociados">
            <button ngbNavLink>Comprobantes Asociados</button>
            <ng-template ngbNavContent #comprobantesAsociadosTemplate>
                <kratos-comprobante-compra-comprobante-asociado
                    #comprobanteCompraComprobanteAsociado
                ></kratos-comprobante-compra-comprobante-asociado>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem="transacciones">
            <button ngbNavLink>Transacciones</button>
            <ng-template ngbNavContent #transaccionesTemplate>
                <kratos-comprobante-transaccion #comprobanteTransaccion></kratos-comprobante-transaccion>
            </ng-template>
        </ng-container>
    </div>
    <div [ngbNavOutlet]="nav" class="pt-3"></div>
</ng-template>

<ng-template #footerTemplate>
    <div class="form-check form-switch">
        <label for="facturacionSimple" class="form-check-label">Facturación Simple</label
        ><i
            class="instrucciones-pantalla bi bi-question-circle"
            popoverTitle="Facturación Simple"
            ngbPopover="Habilita la facturación simple."
        ></i>
        <input
            placeholder="Facturación Simple"
            [(ngModel)]="facturacionSimple"
            [ngModelOptions]="{ standalone: true }"
            (change)="cambiarFacturacionSimple()"
            type="checkbox"
            class="form-check-input me-1"
            id="facturacionSimple"
        />
    </div>
</ng-template>

<kratos-proveedor #proveedorComponent *ngIf="agregandoProveedor" [inicializarTabla]="false"></kratos-proveedor>
