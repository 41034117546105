import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipoResponsableIVA } from '@entities/tipo-responsable-iva.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class TipoResponsableIVAService extends BaseService<TipoResponsableIVA> {
    public constructor(http: HttpClient) {
        super('configuracion/tipo-responsable-iva', http);
    }
}
