export enum TipoPuntoVentaDenominacionEnum {
    FACTURA_ELECTRONICA = 'Factura Electrónica',
    FACTUWEB = 'FactuWeb',
    CONTROLADOR_FISCAL = 'Controlador Fiscal',
    COMPROBANTES_EXPORTACION = 'Comprobantes de Exportación',
    BONOS_FISCALES_ELECTRONICOS = 'Bonos Fiscales Electrónicos',
    CAEA = 'CAEA',
}

export const tipoPuntoVentaDenominacionIdPorKeyValue = (tipoPuntoVentaDenominacion: any) => {
    let id = null;
    switch (tipoPuntoVentaDenominacion) {
        case 0:
        case 'FACTURA_ELECTRONICA':
            id = 0;
            break;
        case 1:
        case 'FACTUWEB':
            id = 1;
            break;
        case 2:
        case 'CONTROLADOR_FISCAL':
            id = 2;
            break;
        case 3:
        case 'COMPROBANTES_EXPORTACION':
            id = 3;
            break;
        case 4:
        case 'BONOS_FISCALES_ELECTRONICOS':
            id = 4;
            break;
        case 5:
        case 'CAEA':
            id = 5;
            break;
    }
    return id;
};

export const obtenerTipoPuntoVentaDenominaciones = () => [
    { id: 0, clave: 'FACTURA_ELECTRONICA', nombre: 'Factura Electrónica' },
    { id: 1, clave: 'FACTUWEB', nombre: 'FactuWeb' },
    { id: 2, clave: 'CONTROLADOR_FISCAL', nombre: 'Controlador Fiscal' },
    { id: 3, clave: 'COMPROBANTES_EXPORTACION', nombre: 'Comprobantes de Exportación' },
    { id: 4, clave: 'BONOS_FISCALES_ELECTRONICOS', nombre: 'Bonos Fiscales Electrónicos' },
    { id: 5, clave: 'CAEA', nombre: 'CAEA' },
];
