import { ParametroService } from '@administracion/services/parametro.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MotivoCompraService } from '@compras/services/motivo-compra.service';
import { ComprobanteCompraItem } from '@entities/comprobante-compra-item.entity';
import { ComprobanteCompra } from '@entities/comprobante-compra.entity';
import { Moneda } from '@entities/moneda.entity';
import { UnidadMedida } from '@entities/unidad-medida.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCVolatilComponent } from '@shared/directives/abmc/abmc-volatil.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { TipoAlicuotaIVAService } from '@ventas/services/tipo-alicuota-iva.service';
import { UnidadMedidaService } from '@ventas/services/unidad-medida.service';
import Big from 'big.js';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-comprobante-compra-item',
    templateUrl: './comprobante-compra-item.component.html',
    styleUrls: ['./comprobante-compra-item.component.scss'],
})
export class ComprobanteCompraItemComponent extends ABMCVolatilComponent<ComprobanteCompraItem> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;
    @ViewChild('formCargaRapidaElement') public formCargaRapidaElement!: ElementRef<any>;

    public facturacionSimple = false;
    public mascaraImporte = 'separator.2';
    public unidadMedidaAbreviatura = '';
    public nombre = 'Item';
    public titulo = 'Items';
    public tablaOpciones = {
        botones: {
            size: 'sm',
        },
    };
    public moneda: Moneda = { simbolo: '$', cotizacion: +1.0 };

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        descripcion: ['', Validators.required],
        cantidad: ['', Validators.required],
        precioUnitario: ['', Validators.required],
        motivoCompra: [''],
        tipoAlicuotaIVA: ['', Validators.required],
        unidadMedida: [''],
    });

    public formCargaRapida = this.formBuilder.group({
        motivoCompra: [null],
        tipoAlicuotaIVA: [null, Validators.required],
        precioUnitario: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'Motivo',
            data: 'motivoCompra.nombre',
            searchable: true,
            defaultContent: '',
        },
        // {
        //     title: 'Descripción',
        //     data: 'descripcion',
        //     searchable: true,
        // },
        // {
        //     title: 'Cantidad',
        //     data: 'cantidad',
        //     tipo: 'number',
        //     searchable: true,
        //     render: (data: any, type: any, row: any, meta: any) => {
        //         return `${data.toLocaleString('es-ES', { minimumFractionDigits: 2 })}${
        //             row.unidadMedida?.abreviatura ? ` ${row.unidadMedida.abreviatura}` : ''
        //         }`;
        //     },
        //     width: '5rem',
        // },
        {
            title: 'Importe Neto',
            data: 'precioUnitario',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Alícuota IVA',
            data: 'tipoAlicuotaIVA.nombre',
            searchable: true,
            width: '7rem',
        },
        {
            title: 'Importe IVA',
            data: 'importeIVA',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Importe Total',
            data: 'importeTotal',
            tipo: 'moneda',
            searchable: true,
        },
    ];

    public constructor(
        protected motivoCompraService: MotivoCompraService,
        protected tipoAlicuotaIVAService: TipoAlicuotaIVAService,
        protected unidadMedidaService: UnidadMedidaService,
        protected parametroService: ParametroService,
        private formBuilder: FormBuilder,
    ) {
        super({
            inicializaciones: {
                nombre: 'Item',
                anchoModal: 'lg',
                acciones: [
                    {
                        nombre: 'modificacion',
                        icono: 'pencil-fill',
                        tooltip: `Modificar`,
                        deshabilitado: (elemento: ComprobanteCompraItem) =>
                            !elemento || (elemento && elemento.baja != null) || elemento.comprobanteCompraAsociadoId,
                    },
                    {
                        nombre: 'baja',
                        icono: 'x-lg',
                        color: 'warning',
                        tooltip: `Eliminar`,
                        deshabilitado: (elemento: ComprobanteCompraItem) =>
                            !elemento || (elemento && elemento.baja != null) || elemento.comprobanteCompraAsociadoId,
                    },
                ],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    consulta: true,
                },
            },
        });
        this.facturacionSimple = localStorage.getItem('facturacionSimple') === 'true';
        this.form.get('unidadMedida')?.valueChanges.subscribe(this.cambiarUnidadMedida.bind(this));

        // Establecer columnas para facturación simple
        if (this.facturacionSimple) {
            this.columnas = this.columnas.filter((columna) => {
                return !['motivoCompra.nombre', 'tipoAlicuotaIVA.nombre'].includes(columna.data);
            });
        }

        // Precargar relaciones
        this.subscription.add(
            forkJoin({
                relaciones: this.obtenerRelaciones(),
            }).subscribe({
                next: ({ relaciones }) => {
                    this.cargarRelaciones(relaciones);
                },
                error: this.toastService.errorHandler.bind(this.toastService),
            }),
        );
    }

    public cambiarMotivoCompra(motivoCompra: any): void {
        this.form.get('motivoCompra')?.setValue(motivoCompra ?? null);
    }

    public cambiarUnidadMedida(unidadMedida: any): void {
        this.unidadMedidaAbreviatura = unidadMedida?.abreviatura ?? '';
    }

    public buscarUnidadMedida = (term: string, item: UnidadMedida) => {
        term = term.toLocaleLowerCase();
        return (
            (item?.nombre?.toLocaleLowerCase().indexOf(term) ?? -1) > -1 ||
            (item?.abreviatura?.toLocaleLowerCase().indexOf(term) ?? -1) > -1
        );
    };

    public toFixedImporte = (value: string | number | undefined | null): number => {
        return this.toFixed(value, 2);
    };

    public toFixed(value: string | number | undefined | null, decimales = 2): number {
        const formattedValue = String(value).split(' ').join('');
        if (String(value).includes('.') && String(value).split('.').length === 2) {
            const decimal = String(value).split('.')[1]?.length;
            if (decimal && decimal > decimales) {
                return Number(parseFloat(formattedValue).toFixed(decimales));
            }
        }
        return Number(formattedValue);
    }

    public saldarComprobante(comprobanteCompra: ComprobanteCompra): void {
        if (this.relaciones && Object.keys(this.relaciones).length) {
            this.agregarItemSaldo(comprobanteCompra);
            return;
        }
        this.subscription.add(
            forkJoin({
                relaciones: this.obtenerRelaciones(),
            }).subscribe({
                next: ({ relaciones }) => {
                    this.cargarRelaciones(relaciones);
                    this.agregarItemSaldo(comprobanteCompra);
                },
                error: this.toastService.errorHandler.bind(this.toastService),
            }),
        );
    }

    public agregarItemCargaRapida(): void {
        this.formValidar(this.formCargaRapida, this.formCargaRapidaElement);
        if (!this.formCargaRapida.valid) {
            return;
        }
        const item = new ComprobanteCompraItem({ ...this.formCargaRapida.value } as ComprobanteCompraItem);
        item.motivoCompra = item.motivoCompra || { nombre: '' };
        item.descripcion = `Neto gravado`;
        item.cantidad = +1.0;
        item.precioUnitario = this.convertirImporte(item.precioUnitario ?? 0, this.moneda.cotizacion);
        item.recalcularImportes();
        this.subscription.add(
            this.baseService.crear(item).subscribe({
                next: (resp: any) => {
                    setTimeout(() => {
                        this.postAlta(resp);
                    });
                },
                error: this.toastService.errorHandler.bind(this.toastService),
            }),
        );
    }

    public obtenerImporteTotal(): number {
        return this.elementos.reduce((total: number, item: ComprobanteCompraItem) => total + item.importeTotal, 0) ?? 0;
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            motivosCompra: this.motivoCompraService.obtenerTodos(),
            tiposAlicuotaIVA: this.tipoAlicuotaIVAService.obtenerTodos(),
            unidadesMedida: this.unidadMedidaService.obtenerTodos(),
        });
    }

    protected override crearElemento(): ComprobanteCompraItem {
        const elemento = new ComprobanteCompraItem(super.crearElemento());
        // Convertir importes del elemento a la moneda oficial, según la cotización del comprobante
        elemento.precioUnitario = this.convertirImporte(elemento.precioUnitario ?? 0, this.moneda.cotizacion);
        elemento.recalcularImportes();
        return elemento;
    }

    protected override cargarElemento(elemento: ComprobanteCompraItem): void {
        // Convertir importes de moneda oficial a moneda seleccionada por el usuario, según la cotización del comprobante
        const item = new ComprobanteCompraItem(elemento);
        const cotizacion = 1 / (this.moneda.cotizacion ?? 1);
        item.precioUnitario = this.convertirImporte(elemento.precioUnitario ?? 0, cotizacion);
        super.cargarElemento(item);
    }

    protected override inicializarElemento(): void {
        super.inicializarElemento();
    }

    private convertirImporte(importe: number, cotizacion = +1.0): number {
        return Big(importe).times(Big(cotizacion)).round(2).toNumber();
    }

    private agregarItemSaldo(comprobanteCompra: ComprobanteCompra): void {
        // Agrupar items del comprobante por alícuota de IVA, sumar importes, y crear un item de saldo por cada grupo
        const items: ComprobanteCompraItem[] = [];
        (comprobanteCompra.items ?? []).forEach((comprobanteCompraItem) => {
            if (!comprobanteCompraItem.tipoAlicuotaIVA) {
                return;
            }
            const item = items.find((item) => item.tipoAlicuotaIVA?.id === comprobanteCompraItem.tipoAlicuotaIVA?.id);
            if (item) {
                item.precioUnitario = Big(item.precioUnitario ?? 0)
                    .plus(comprobanteCompraItem.importeNeto)
                    .toNumber();
                item.recalcularImportes();
            } else {
                items.push(this.crearElementoSaldo(comprobanteCompra, comprobanteCompraItem));
            }
        });
        // Agregar items de saldo al comprobante
        items.forEach((item) => {
            this.subscription.add(
                this.baseService.crear(item).subscribe({
                    next: (resp: any) => {
                        setTimeout(() => {
                            this.postAlta(resp);
                        });
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
            );
        });
    }

    private crearElementoSaldo(
        comprobanteCompra: ComprobanteCompra,
        comprobanteCompraItem: ComprobanteCompraItem,
    ): ComprobanteCompraItem {
        return new ComprobanteCompraItem({
            motivoCompra: { nombre: '' },
            cantidad: 1,
            precioUnitario: comprobanteCompraItem.importeNeto,
            descripcion: `Saldo del comprobante ${comprobanteCompra?.comprobante?.tipoComprobante?.nombre} ${
                String(comprobanteCompra?.puntoVenta).padStart(4, '0') ?? '0000'
            }-${
                String(comprobanteCompra?.comprobante?.numero).padStart(8, '0') ?? '00000000'
            } IVA ${comprobanteCompraItem.tipoAlicuotaIVA?.nombre}`,
            tipoAlicuotaIVA: comprobanteCompraItem.tipoAlicuotaIVA,
            unidadMedida: comprobanteCompraItem.unidadMedida,
            comprobanteCompraAsociadoId: comprobanteCompra?.id,
        });
    }
}
