export enum MigracionSITablaEnum {
    // General - Ventas
    si_customers = 'si_customers',
    si_products = 'si_products',

    // Ventas
    si_invoices = 'si_invoices',
    si_invoice_items = 'si_invoice_items',
    si_payment = 'si_payment',
}

export const obtenerMigracionSITablas = () => [
    { id: 0, clave: 'si_customers', nombre: '(General - Ventas) Customers' },
    { id: 1, clave: 'si_products', nombre: '(General - Ventas) Products' },
    { id: 2, clave: 'si_invoices', nombre: '(Ventas) Invoices' },
    { id: 3, clave: 'si_invoice_items', nombre: '(Ventas) Invoice Items' },
    { id: 4, clave: 'si_payment', nombre: '(Ventas) Payment' },
];
