import { EmpresaService } from '@administracion/services/empresa.service';
import { ParametroService } from '@administracion/services/parametro.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Parametro } from '@entities/parametro.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-parametro',
    templateUrl: './parametro.component.html',
    styleUrls: ['./parametro.component.scss'],
})
export class ParametroComponent extends ABMCComponent<Parametro> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Parámetro';
    public titulo = 'Parámetros';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        empresa: ['', Validators.required],
        descripcion: ['', Validators.required],
        valor: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Descripción',
            data: 'descripcion',
            searchable: true,
        },
        {
            title: 'Valor',
            data: 'valor',
            searchable: true,
        },
        {
            title: 'Empresa',
            data: 'empresa.nombre',
            searchable: true,
        },
    ];

    public constructor(
        protected parametroService: ParametroService,
        protected empresaService: EmpresaService,
        private formBuilder: FormBuilder,
    ) {
        super(parametroService, {
            inicializaciones: {
                nombre: 'Parámetro',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones() {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
        });
    }
}
