<h2 class="mb-3">{{ titulo }}</h2>

<kratos-tabla
    #tabla
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
    (botonHandler)="botonHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="empresa" class="form-label">Empresa</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Empresa"
                        ngbPopover="Empresa emisora del comprobante."
                    ></i>
                    <ng-select
                        [items]="relaciones.empresas"
                        labelForId="empresa"
                        formControlName="empresa"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('empresa', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tablaOrigen" class="form-label">Tabla Origen</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tabla Origen"
                        ngbPopover="Tabla de la base de datos origen."
                    ></i>
                    <ng-select
                        [items]="tablas"
                        bindValue="clave"
                        labelForId="tablaOrigen"
                        formControlName="tablaOrigen"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tablaOrigen', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tablaDestino" class="form-label">Tabla Destino</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tabla Destino"
                        ngbPopover="Tabla de la base de datos destino."
                    ></i>
                    <input
                        placeholder="Tabla Destino"
                        formControlName="tablaDestino"
                        type="text"
                        class="form-control"
                        id="tablaDestino"
                    />
                    <ng-container *ngFor="let error of formErrores('tablaDestino', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="idOrigen" class="form-label">ID Origen</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="ID Origen"
                        ngbPopover="ID de la tabla origen."
                    ></i>
                    <input
                        placeholder="ID Origen"
                        formControlName="idOrigen"
                        type="number"
                        class="form-control"
                        id="idOrigen"
                    />
                    <ng-container *ngFor="let error of formErrores('idOrigen', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="idDestino" class="form-label">ID Destino</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="ID Destino"
                        ngbPopover="ID de la tabla destino."
                    ></i>
                    <input
                        placeholder="ID Destino"
                        formControlName="idDestino"
                        type="number"
                        class="form-control"
                        id="idDestino"
                    />
                    <ng-container *ngFor="let error of formErrores('idDestino', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #formImportarTemplate>
    <form [formGroup]="formImportar" #formImportarElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="empresa" class="form-label">Empresa</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Empresa"
                        ngbPopover="Empresa a la que pertenece la base de datos."
                    ></i>
                    <ng-select
                        [items]="relacionesImportar.empresas"
                        labelForId="empresa"
                        formControlName="empresa"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container
                        *ngFor="let error of formErrores('empresa', formImportar, formImportarErroresControles)"
                    >
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tabla" class="form-label">Tabla</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tabla"
                        ngbPopover="Tabla de la base de datos a importar."
                    ></i>
                    <ng-select
                        [items]="relacionesImportar.tablas"
                        bindValue="clave"
                        labelForId="tabla"
                        formControlName="tabla"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container
                        *ngFor="let error of formErrores('tabla', formImportar, formImportarErroresControles)"
                    >
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="desde" class="form-label">Desde (N° Registro)</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Desde"
                        ngbPopover="Número de registro desde el cual se importarán los datos."
                    ></i>
                    <input placeholder="Desde" formControlName="desde" type="number" class="form-control" id="desde" />
                    <ng-container
                        *ngFor="let error of formErrores('desde', formImportar, formImportarErroresControles)"
                    >
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="cantidad" class="form-label">Cantidad (N° Registros)</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Cantidad"
                        ngbPopover="Cantidad de registros a importar."
                    ></i>
                    <input
                        placeholder="Cantidad"
                        formControlName="cantidad"
                        type="number"
                        class="form-control"
                        id="cantidad"
                    />
                    <ng-container
                        *ngFor="let error of formErrores('cantidad', formImportar, formImportarErroresControles)"
                    >
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <div class="form-check form-switch">
                        <label for="ordenInverso" class="form-check-label">Orden Inverso</label
                        ><i
                            *ngIf="getThemeHelpEnabled()"
                            class="instrucciones-pantalla bi bi-question-circle"
                            popoverTitle="Orden Inverso"
                            ngbPopover="Leer registros de la tabla origen en orden inverso."
                        ></i>
                        <input
                            placeholder="Orden Inverso"
                            formControlName="ordenInverso"
                            type="checkbox"
                            class="form-check-input me-1"
                            id="ordenInverso"
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <div class="form-check form-switch">
                        <label for="importarTodo" class="form-check-label">Importación Completa</label
                        ><i
                            *ngIf="getThemeHelpEnabled()"
                            class="instrucciones-pantalla bi bi-question-circle"
                            popoverTitle="Importación Completa"
                            ngbPopover="Solicitar una importación completa de todos las tablas disponibles y soportadas en la base de datos origen."
                        ></i>
                        <input
                            placeholder="Importar Todo"
                            formControlName="importarTodo"
                            type="checkbox"
                            class="form-check-input me-1"
                            id="importarTodo"
                        />
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="pt-3" *ngIf="importarResultado">
        <i
            class="bi"
            [ngClass]="{
                'bi-info-circle': !importarResultadoEstado,
                'text-info': !importarResultadoEstado,
                'bi-check-lg': importarResultadoEstado === 'OK',
                'text-success': importarResultadoEstado === 'OK',
                'bi-x-lg': importarResultadoEstado === 'ERROR',
                'text-danger': importarResultadoEstado === 'ERROR'
            }"
        ></i
        >&NonBreakingSpace;<strong>Resultado de la importación:</strong>
        <div [innerHTML]="importarResultado | safeHtml"></div>
    </div>
</ng-template>
