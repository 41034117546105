<h2 class="mb-3" *ngIf="titulo">{{ titulo }}</h2>

<div class="mb-3">
    <h6>Opciones de Impresión</h6>
    <div class="form-check form-switch">
        <label for="detallado">Detallado</label>
        <input class="form-check-input" type="checkbox" id="detallado" [(ngModel)]="detallado" />
    </div>
</div>

<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    [autoInit]="false"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
    (botonHandler)="botonHandler($event)"
></kratos-tabla>
