import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PaisService } from '@configuracion/services/pais.service';
import { ProvinciaService } from '@configuracion/services/provincia.service';
import { Provincia } from '@entities/provincia.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-provincia',
    templateUrl: './provincia.component.html',
    styleUrls: ['./provincia.component.scss'],
})
export class ProvinciaComponent extends ABMCComponent<Provincia> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Provincia';
    public titulo = 'Provincias';
    // public paises: Pais[] = [];

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        pais: ['', Validators.required],
        codigoAFIP: [''],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'País',
            data: 'pais.nombre',
            searchable: true,
        },
        {
            title: 'Código AFIP',
            data: 'codigoAFIP',
            searchable: true,
        },
    ];

    public constructor(
        protected provinciaService: ProvinciaService,
        protected paisService: PaisService,
        private formBuilder: FormBuilder,
    ) {
        super(provinciaService, {
            inicializaciones: {
                nombre: 'Provincia',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            paises: this.paisService.obtenerTodos(),
        });
    }
}
