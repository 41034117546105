import { EmpresaService } from '@administracion/services/empresa.service';
import { ParametroService } from '@administracion/services/parametro.service';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IdiomaService } from '@configuracion/services/idioma.service';
import { TipoComprobanteService } from '@configuracion/services/tipo-comprobante.service';
import { TipoDocumentoService } from '@configuracion/services/tipo-documento.service';
import { BaseEntity } from '@entities/base.entity';
import { Cliente } from '@entities/cliente';
import { ComprobanteVentaItem } from '@entities/comprobante-venta-item.entity';
import { ComprobanteVenta } from '@entities/comprobante-venta.entity';
import { Comprobante } from '@entities/comprobante.entity';
import { RespuestaDTO } from '@entities/dtos/respuesta.dto';
import { Empresa } from '@entities/empresa.entity';
import { AFIPServicioEnum } from '@entities/enums/afip-servicio.enum';
import { TipoComprobanteDenominacionEnum } from '@entities/enums/tipo-comprobante-denominacion.enum';
import { TipoComprobanteLetraEnum } from '@entities/enums/tipo-comprobante-letra.enum';
import { obtenerTipoDocumentoDenominaciones } from '@entities/enums/tipo-documento-denominacion.enum';
import { Idioma } from '@entities/idioma.entity';
import { MonedaCotizacion } from '@entities/moneda-cotizacion.entity';
import { Moneda } from '@entities/moneda.entity';
import { PuntoVenta } from '@entities/punto-venta.entity';
import { TipoActividad } from '@entities/tipo-actividad.entity';
import { TipoAlicuotaIVA } from '@entities/tipo-alicuota-iva.entity';
import { TipoComprobante } from '@entities/tipo-comprobante.entity';
import { TipoConcepto } from '@entities/tipo-concepto.entity';
import { TipoDocumento } from '@entities/tipo-documento.entity';
import { ComprobanteVentaView } from '@entities/views/comprobante-venta.view';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ModalFormularioComponent } from '@shared/components/modal-formulario/modal-formulario.component';
import { ModalInformacionComponent } from '@shared/components/modal-informacion/modal-informacion.component';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { ClienteComponent } from '@ventas/components/cliente.component';
import { ComprobanteTransaccionComponent } from '@ventas/components/comprobante-transaccion.component';
import { ComprobanteVentaComprobanteAsociadoComponent } from '@ventas/components/comprobante-venta-comprobante-asociado.component';
import { ComprobanteVentaDatoOpcionalComponent } from '@ventas/components/comprobante-venta-dato-opcional.component';
import { ComprobanteVentaItemComponent } from '@ventas/components/comprobante-venta-item.component';
import { ComprobanteVentaMedioPagoComponent } from '@ventas/components/comprobante-venta-medio-pago.component';
import { ComprobanteVentaTributoComponent } from '@ventas/components/comprobante-venta-tributo.component';
import { ClienteService } from '@ventas/services/cliente.service';
import { ComprobanteVentaService, ComprobanteVentaViewService } from '@ventas/services/comprobante-venta.service';
import { MonedaService } from '@ventas/services/moneda.service';
import { PuntoVentaService } from '@ventas/services/punto-venta.service';
import { TipoActividadService } from '@ventas/services/tipo-actividad.service';
import { TipoAlicuotaIVAService } from '@ventas/services/tipo-alicuota-iva.service';
import { TipoConceptoService } from '@ventas/services/tipo-concepto.service';
import { TipoMedioPagoService } from '@ventas/services/tipo-medio-pago.service';
import Big from 'big.js';
import { format, parse, parseISO } from 'date-fns';
import { Observable, finalize, forkJoin, lastValueFrom } from 'rxjs';

const MONEDA_OFICIAL_CODIGO_AFIP = 'PES';

@Component({
    selector: 'kratos-comprobante-venta',
    templateUrl: './comprobante-venta.component.html',
    styleUrls: ['./comprobante-venta.component.scss'],
})
export class ComprobanteVentaComponent extends ABMCComponent<ComprobanteVenta> implements OnInit {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;
    @ViewChild('footerTemplate') public footerModalTemplate!: TemplateRef<any>;

    @ViewChild('comprobanteVentaItem') private comprobanteVentaItem!: ComprobanteVentaItemComponent;
    @ViewChild('comprobanteVentaMedioPago') private comprobanteVentaMedioPago!: ComprobanteVentaMedioPagoComponent;
    @ViewChild('comprobanteVentaTributo') private comprobanteVentaTributo!: ComprobanteVentaTributoComponent;
    @ViewChild('comprobanteVentaDatoOpcional')
    private comprobanteVentaDatoOpcional!: ComprobanteVentaDatoOpcionalComponent;
    @ViewChild('comprobanteVentaComprobanteAsociado')
    private comprobanteVentaComprobanteAsociado!: ComprobanteVentaComprobanteAsociadoComponent;
    @ViewChild('comprobanteTransaccion') private comprobanteTransaccion!: ComprobanteTransaccionComponent;

    @ViewChild('nav') private nav!: NgbNav;

    @ViewChild('formConsultarUltimoComprobanteTemplate')
    public formConsultarUltimoComprobanteTemplate!: TemplateRef<any>;
    @ViewChild('formConsultarUltimoComprobanteElement') public formConsultarUltimoComprobanteElement!: ElementRef<any>;

    public monedaOficial: Moneda | null = null;
    public monedaOficialEmpresa: Moneda | null = null;
    private cotizacionAnterior: number | null = null;
    public agregandoCliente = false;

    @ViewChild('clienteComponent') private clienteComponent!: ClienteComponent;

    public facturacionSimple = false;
    public facturacionInvoice = false;
    public siguienteNumeroAFIP = false;
    public generarCAE = false;
    public tipoDocumentoDenominaciones = obtenerTipoDocumentoDenominaciones();
    public mascaraNumero = '99999990';
    public mascaraNumeroDocumento = '';
    public mascaraCotizacion = 'separator.6';
    public nombreMascaraNumeroDocumento = '';
    public nombre = 'Comprobante de Venta';
    public titulo = 'Comprobantes de Venta';
    public relacionesConsultarUltimoComprobante: any = {};
    public tablaOpciones = {
        order: [[3, 'desc']],
    };
    public cargando = {
        tipoConcepto: false,
        tipoActividad: false,
        idioma: false,
        moneda: false,
        cotizacion: false,
        puntoVenta: false,
        tipoComprobante: false,
        numero: false,
    };

    protected formErroresControles = {
        items: {
            required: 'Debe agregar al menos un item',
        },
    };
    protected formConsultarUltimoComprobanteErroresControles = {};

    public form = this.formBuilder.group({
        fecha: ['', Validators.required],
        numeroDocumento: ['', Validators.required],
        tipoDocumento: ['', Validators.required],
        tipoComprobante: ['', Validators.required],
        puntoVenta: ['', Validators.required],
        numero: ['', Validators.required],
        empresa: ['', Validators.required],
        cliente: ['', Validators.required],
        cotizacion: ['', Validators.required],
        moneda: ['', Validators.required],
        tipoConcepto: ['', Validators.required],
        tipoActividad: ['', Validators.required],
        idioma: ['', Validators.required],
        observaciones: [''],
        items: [[]],
    });

    public formConsultarUltimoComprobante = this.formBuilder.group({
        empresa: ['', Validators.required],
        tipoComprobante: ['', Validators.required],
        puntoVenta: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
            render: (data: any, type: any, row: any, meta: any) => {
                return data
                    ? `<div class="position-relative">${data}${
                          row.codigoAutorizacion
                              ? `<span class="position-absolute top-0 end-0 badge rounded-pill bg-success" title="Comprobante Autorizado"><i class="bi bi-check2"></i></span>`
                              : ''
                      }</div>`
                    : '';
            },
        },
        {
            title: 'Cliente',
            data: 'clienteRazonSocial',
            searchable: true,
        },
        {
            title: 'Tipo',
            data: 'tipoComprobanteNombre',
            searchable: true,
        },
        {
            title: 'Fecha',
            data: 'fecha',
            tipo: 'date',
            searchable: true,
        },
        {
            title: 'Pto. Vta.',
            data: 'puntoVentaNumero',
            searchable: true,
        },
        {
            title: 'Número',
            data: 'numero',
            searchable: true,
        },
        // {
        //     title: 'Importe Neto',
        //     data: 'importeNeto',
        //     tipo: 'moneda',
        //     searchable: true,
        // },
        // {
        //     title: 'Importe IVA',
        //     data: 'importeIVA',
        //     tipo: 'moneda',
        //     searchable: true,
        // },
        {
            title: 'Importe Total',
            data: 'importeTotal',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Saldo',
            data: 'saldo',
            tipo: 'moneda',
            searchable: true,
        },
    ];

    public constructor(
        protected comprobanteVentaService: ComprobanteVentaService,
        protected comprobanteVentaViewService: ComprobanteVentaViewService,
        protected empresaService: EmpresaService,
        protected clienteService: ClienteService,
        protected puntoVentaService: PuntoVentaService,
        protected tipoComprobanteService: TipoComprobanteService,
        protected tipoDocumentoService: TipoDocumentoService,
        protected monedaService: MonedaService,
        protected tipoConceptoService: TipoConceptoService,
        protected tipoActividadService: TipoActividadService,
        protected idiomaService: IdiomaService,
        protected tipoMedioPagoService: TipoMedioPagoService,
        protected tipoAlicuotaIVAService: TipoAlicuotaIVAService,
        protected parametroService: ParametroService,
        private formBuilder: FormBuilder,
    ) {
        super(comprobanteVentaService, {
            inicializaciones: {
                nombre: 'Comprobante de Venta',
                genero: 'o',
                anchoModal: 'xl',
                acciones: [
                    {
                        nombre: 'cobrarComprobante',
                        icono: 'credit-card',
                        tooltip: 'Cobrar Comprobante',
                        deshabilitado: (elemento: ComprobanteVentaView) => !elemento?.saldo,
                    },
                    {
                        nombre: 'imprimirComprobante',
                        icono: 'printer',
                        tooltip: 'Imprimir Comprobante',
                        cargando: (elemento: ComprobanteVentaView) => (elemento as any).__imprimiendo,
                    },
                    {
                        nombre: 'cancelarComprobante',
                        icono: 'x-lg',
                        texto: 'Cancelar con NC/ND',
                        grupo: 1,
                    },
                    {
                        nombre: 'baja',
                        icono: 'x-lg',
                        color: 'warning',
                        texto: `Eliminar`,
                        deshabilitado: (elemento: BaseEntity) => !elemento || (elemento && elemento.baja != null),
                        grupo: 1,
                    },
                    {
                        nombre: 'solicitarCAE',
                        icono: 'check2-circle',
                        texto: 'Solicitar CAE',
                        cargando: (elemento: ComprobanteVentaView) => (elemento as any).__solicitandoCAE,
                        deshabilitado: (elemento: ComprobanteVentaView) =>
                            !elemento || (elemento && elemento.codigoAutorizacion != null),
                        grupo: 1,
                    },
                    {
                        nombre: 'generarQR',
                        icono: 'qr-code',
                        texto: 'Generar QR',
                        cargando: (elemento: ComprobanteVentaView) => (elemento as any).__generandoQR,
                        grupo: 1,
                    },
                    {
                        nombre: 'descargarComprobante',
                        icono: 'download',
                        texto: 'Descargar',
                        cargando: (elemento: ComprobanteVentaView) => (elemento as any).__descargando,
                        grupo: 1,
                    },
                ],
                botones: [
                    {
                        name: 'consultarUltimoComprobante',
                        text: 'Consultar Último Comprobante',
                    },
                ],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: false,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
        this.subscription.add(
            this.afterViewInit.subscribe(() => {
                this.footerTemplate = this.footerModalTemplate;
            }),
        );
        this.facturacionSimple = localStorage.getItem('facturacionSimple') === 'true';
        this.facturacionInvoice = localStorage.getItem('facturacionInvoice') === 'true';
        this.siguienteNumeroAFIP = localStorage.getItem('siguienteNumeroAFIP') === 'true';
        this.generarCAE = localStorage.getItem('generarCAE') === 'true';
        this.subscription.add(this.elementoInicializado.subscribe(this.vincularItemsComprobantesAsociados.bind(this)));
        this.subscription.add(this.elementoCargado.subscribe(this.vincularItemsComprobantesAsociados.bind(this)));
    }

    public cambiarFecha(fecha?: any): void {
        this.establecerCotizacion();
    }

    public cambiarMoneda(moneda?: Moneda, opciones?: { sobreescribir?: boolean }): void {
        if (!this.form.get('moneda')?.value || opciones?.sobreescribir === true) {
            this.form.get('moneda')?.setValue((moneda ?? this.monedaOficialEmpresa) as any);
        }
        this.establecerCotizacion();
    }

    public cambiarEmpresa(empresa?: Empresa, opciones?: { saldar?: boolean; inicializar?: boolean }): void {
        if (!this.form.get('empresa')?.value) {
            this.form.get('empresa')?.setValue(empresa as any);
        }
        if (!empresa || opciones?.saldar === true) {
            return;
        }
        this.cargarParametrosPorDefecto(empresa, opciones);
        this.siguienteNumero();
    }

    public cambiarCliente(cliente?: Cliente): void {
        this.form.get('cliente')?.setValue((cliente as any) ?? null);
        this.cambiarTipoDocumento(cliente?.tipoDocumento, { sobreescribir: true });
        this.form.get('numeroDocumento')?.setValue(cliente?.numeroDocumento as any);
        const moneda = this.relaciones.monedas.find((m: Moneda) => m.id === cliente?.monedaId);
        this.cambiarMoneda(moneda, { sobreescribir: true });
        this.comprobanteVentaComprobanteAsociado.cliente = cliente;
    }

    public cambiarTipoDocumento(tipoDocumento?: TipoDocumento, opciones?: { sobreescribir?: boolean }): void {
        if (!this.form.get('tipoDocumento')?.value || opciones?.sobreescribir === true) {
            this.form.get('tipoDocumento')?.setValue((tipoDocumento as any) ?? null);
        }
        const { mascaraNumeroDocumento, nombreMascaraNumeroDocumento } =
            this.obtenerMascaraTipoDocumento(tipoDocumento);
        this.mascaraNumeroDocumento = mascaraNumeroDocumento;
        this.nombreMascaraNumeroDocumento = nombreMascaraNumeroDocumento;
    }

    public cambiarTipoComprobante(tipoComprobante?: TipoComprobante): void {
        this.form.get('tipoComprobante')?.setValue((tipoComprobante as any) ?? null);
        this.siguienteNumero();
    }

    public cambiarPuntoVenta(puntoVenta?: PuntoVenta): void {
        this.form.get('puntoVenta')?.setValue((puntoVenta as any) ?? null);
        this.siguienteNumero();
    }

    public actualizarCotizacion(): void {
        const cotizacionAnterior =
            this.cotizacionAnterior != null
                ? this.cotizacionAnterior
                : Big(this.elementoActual?.cotizacion ?? 1).toNumber();
        const cotizacion = Big(this.form.get('cotizacion')?.value ?? 1).toNumber();
        this.cotizacionAnterior = cotizacion;
        const moneda = {
            ...(this.form.get('moneda')?.value as Moneda),
            cotizacion,
        };
        // Actualizar los importes en base a la nueva cotización
        this.comprobanteVentaItem.elementos.forEach((item) => {
            item.precioUnitario = Big(item.precioUnitario ?? 0)
                .div(Big(cotizacionAnterior))
                .times(Big(cotizacion))
                .toNumber();
            item.importeBonificado = Big(item.importeBonificado ?? 0)
                .div(Big(cotizacionAnterior))
                .times(Big(cotizacion))
                .toNumber();
            item.recalcularImportes();
        });
        this.comprobanteVentaMedioPago.elementos.forEach((medioPago) => {
            medioPago.importe = Big(medioPago.importe ?? 0)
                .div(Big(cotizacionAnterior))
                .times(Big(cotizacion))
                .toNumber();
        });
        this.comprobanteVentaTributo.elementos.forEach((tributo) => {
            tributo.importe = Big(tributo.importe ?? 0)
                .div(Big(cotizacionAnterior))
                .times(Big(cotizacion))
                .toNumber();
        });
        this.comprobanteVentaComprobanteAsociado.elementos.forEach((comprobanteAsociado) => {
            comprobanteAsociado.importe = Big(comprobanteAsociado.importe ?? 0)
                .div(Big(cotizacionAnterior))
                .times(Big(cotizacion))
                .toNumber();
        });
        this.comprobanteVentaItem.moneda = moneda;
        this.comprobanteVentaItem.tabla.opciones.moneda = moneda;
        this.comprobanteVentaItem.tabla.recargar();
        this.comprobanteVentaMedioPago.moneda = moneda;
        this.comprobanteVentaMedioPago.tabla.opciones.moneda = moneda;
        this.comprobanteVentaMedioPago.tabla.recargar();
        this.comprobanteVentaTributo.moneda = moneda;
        this.comprobanteVentaTributo.tabla.opciones.moneda = moneda;
        this.comprobanteVentaTributo.tabla.recargar();
        this.comprobanteVentaComprobanteAsociado.moneda = moneda;
        this.comprobanteVentaComprobanteAsociado.recargar();
    }

    public buscarPuntoVenta = (term: string, item: PuntoVenta) => {
        term = term.toLocaleLowerCase();
        return (
            (item?.numero?.toString().toLocaleLowerCase().indexOf(term) ?? -1) > -1 ||
            (item?.nombre?.toLocaleLowerCase().indexOf(term) ?? -1) > -1
        );
    };

    public buscarCliente = (term: string, item: Cliente) => {
        term = term.toLocaleLowerCase();
        return (
            (item?.razonSocial?.toLocaleLowerCase().indexOf(term) ?? -1) > -1 ||
            (item?.tipoDocumento?.nombre?.toLocaleLowerCase().indexOf(term) ?? -1) > -1 ||
            (item?.numeroDocumento?.toLocaleLowerCase().indexOf(term) ?? -1) > -1
        );
    };

    public toFixedCotizacion = (value: string | number | undefined | null): number => {
        return this.toFixed(value, 6);
    };

    public toFixed(value: string | number | undefined | null, decimales = 2): number {
        const formattedValue = String(value).split(' ').join('');
        if (String(value).includes('.') && String(value).split('.').length === 2) {
            const decimal = String(value).split('.')[1]?.length;
            if (decimal && decimal > decimales) {
                return Number(parseFloat(formattedValue).toFixed(decimales));
            }
        }
        return Number(formattedValue);
    }

    public cotizacionOficial(): boolean {
        return this.monedaOficial?.id === (this.form.get('moneda')?.value as Moneda)?.id;
    }

    public override ajaxHandler(event: any) {
        super.ajaxHandler(event, this.comprobanteVentaViewService);
    }

    public agregarCliente(): void {
        this.agregandoCliente = true;
        setTimeout(() => {
            // Invocar ClienteComponent en un modal para agregar un nuevo cliente
            this.clienteComponent.alta();
            this.subscription.add(
                this.clienteComponent.modalRef.hidden.subscribe(() => {
                    this.agregandoCliente = false;
                }),
            );
            this.subscription.add(
                this.clienteComponent.postAltaEvent.subscribe((clienteNuevo: Cliente) => {
                    this.subscription.add(
                        this.clienteService.obtenerTodos().subscribe({
                            next: (respuesta: RespuestaDTO<Cliente>) => {
                                this.relaciones.clientes = respuesta?.elementos ?? [];
                                this.cambiarCliente(clienteNuevo);
                            },
                            error: this.toastService.errorHandler.bind(this.toastService),
                        }),
                    );
                }),
            );
        });
    }

    public cambiarFacturacionSimple(): void {
        localStorage.setItem('facturacionSimple', `${this.facturacionSimple}`);
        this.comprobanteVentaItem.facturacionSimple = this.facturacionSimple;
    }

    public cambiarFacturacionInvoice(): void {
        localStorage.setItem('facturacionInvoice', `${this.facturacionInvoice}`);
        this.comprobanteVentaItem.facturacionInvoice = this.facturacionInvoice;
    }

    public obtenerCotizacionAFIP(): void {
        const empresa = this.form.get('empresa')?.value as Empresa;
        if (!empresa || !empresa.id) {
            this.toastService.warningHandler('Debe seleccionar una empresa para obtener la cotización');
            return;
        }
        const moneda = this.form.get('moneda')?.value as Moneda;
        if (!moneda || !moneda.id) {
            this.toastService.warningHandler('Debe seleccionar una moneda para obtener la cotización');
            return;
        }
        if (moneda.id === this.monedaOficial?.id) {
            this.toastService.infoHandler('No es necesario obtener la cotización para la moneda oficial');
            return;
        }
        this.form.get('cotizacion')?.disable();
        this.cargando.cotizacion = true;
        this.modalRef.componentInstance.cargando = true;
        this.subscription.add(
            this.monedaService
                .obtenerCotizacionAFIP(empresa.id, moneda.id)
                .pipe(
                    finalize(() => {
                        this.form.get('cotizacion')?.enable();
                        this.cargando.cotizacion = false;
                        this.modalRef.componentInstance.cargando = false;
                    }),
                )
                .subscribe({
                    next: (monedaCotizacion: MonedaCotizacion) => {
                        if (!monedaCotizacion) {
                            this.toastService.errorHandler(
                                `No se pudo obtener la cotización de la moneda ${moneda.nombre}`,
                            );
                        }
                        const cotizacion = Big(monedaCotizacion.cotizacion ?? +1.0).toFixed(6);
                        this.form.get('cotizacion')?.setValue(cotizacion);
                        this.actualizarCotizacion();
                        this.toastService.successHandler(
                            `Cotización actualizada a ${this.monedaOficial?.simbolo ?? '$'} ${this.toFixedCotizacion(
                                cotizacion,
                            ).toLocaleString('es-ES', {
                                minimumFractionDigits: 2,
                            })} según AFIP para ${moneda.nombre} (${moneda.codigoAFIP}) en la fecha ${format(
                                monedaCotizacion.fecha
                                    ? parse(monedaCotizacion.fecha, 'yyyy-MM-dd', new Date())
                                    : new Date(),
                                'dd/MM/yyyy',
                            )}`,
                        );
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    public obtenerSiguienteNumeroAFIP(): void {
        const empresa = this.form.get('empresa')?.value as Empresa;
        if (!empresa || !empresa.id) {
            this.toastService.warningHandler(
                'Debe seleccionar una empresa para obtener el siguiente número de comprobante',
            );
            return;
        }
        const tipoComprobante = this.form.get('tipoComprobante')?.value as TipoComprobante;
        if (!tipoComprobante || !tipoComprobante.id) {
            this.toastService.warningHandler(
                'Debe seleccionar un tipo de comprobante para obtener el siguiente número',
            );
            return;
        }
        const puntoVenta = this.form.get('puntoVenta')?.value as PuntoVenta;
        if (!puntoVenta || !puntoVenta.id) {
            this.toastService.warningHandler('Debe seleccionar un punto de venta para obtener el siguiente número');
            return;
        }
        this.form.get('numero')?.disable();
        this.cargando.numero = true;
        this.modalRef.componentInstance.cargando = true;
        this.subscription.add(
            this.comprobanteVentaViewService
                .siguienteNumero(empresa.id, puntoVenta.id, tipoComprobante.id, true, AFIPServicioEnum.WSFE)
                .pipe(
                    finalize(() => {
                        this.form.get('numero')?.enable();
                        this.cargando.numero = false;
                        this.modalRef.componentInstance.cargando = false;
                    }),
                )
                .subscribe({
                    next: (siguienteNumero: number) => {
                        if (!siguienteNumero) {
                            this.toastService.errorHandler(
                                `No se pudo obtener el siguiente número de comprobante para ${tipoComprobante.nombre} en el punto de venta ${puntoVenta.numero}`,
                            );
                        }
                        this.form.get('numero')?.setValue(siguienteNumero as any);
                        this.toastService.successHandler(
                            `Siguiente número de comprobante según AFIP para ${tipoComprobante.nombre} en el punto de venta ${puntoVenta.numero}: ${siguienteNumero}`,
                        );
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    public monedaOficialAFIP(): boolean {
        return this.monedaOficialEmpresa?.codigoAFIP === MONEDA_OFICIAL_CODIGO_AFIP;
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
            clientes: this.clienteService.obtenerTodos(),
            puntosVenta: this.puntoVentaService.obtenerActivos(),
            tiposComprobante: this.tipoComprobanteService.obtenerTodos(),
            tiposDocumento: this.tipoDocumentoService.obtenerTodos(),
            monedas: this.monedaService.obtenerTodos(),
            tiposConcepto: this.tipoConceptoService.obtenerTodos(),
            tiposActividad: this.tipoActividadService.obtenerTodos(),
            idiomas: this.idiomaService.obtenerTodos(),
            tiposAlicuotaIVA: this.tipoAlicuotaIVAService.obtenerTodos(),
        });
    }

    protected override cargarRelaciones(relaciones: any): void {
        super.cargarRelaciones(relaciones);
        this.monedaOficial = this.relaciones.monedas.find((m: Moneda) => m.codigoAFIP === MONEDA_OFICIAL_CODIGO_AFIP);
    }

    protected override cargarElemento(elemento: ComprobanteVenta) {
        this.cotizacionAnterior = elemento?.cotizacion ?? null;
        elemento = { ...elemento, ...elemento?.comprobante, id: elemento?.id };
        super.cargarElemento(elemento);

        this.cargarParametrosPorDefecto(elemento?.empresa);
        this.cambiarTipoDocumento(elemento?.comprobante?.tipoDocumento);
        this.actualizarCotizacion();

        // Cargar e instanciar items
        const items = (elemento?.items ?? []).map((item) => new ComprobanteVentaItem(item));
        this.comprobanteVentaItem.elementos = items;
        this.comprobanteVentaItem.tabla.recargar();

        // Cargar medioPago
        const mediosPago = elemento?.mediosPago ?? [];
        this.comprobanteVentaMedioPago.elementos = mediosPago;
        this.comprobanteVentaMedioPago.tabla.recargar();

        // Cargar tributos
        const tributos = elemento?.tributos ?? [];
        this.comprobanteVentaTributo.elementos = tributos;
        this.comprobanteVentaTributo.tabla.recargar();

        // Cargar datosOpcionales
        const datosOpcionales = elemento?.datosOpcionales ?? [];
        this.comprobanteVentaDatoOpcional.elementos = datosOpcionales;
        this.comprobanteVentaDatoOpcional.tabla.recargar();

        // Cargar comprobantes asociados
        const comprobantesAsociados = elemento?.comprobantesAsociados ?? [];
        this.comprobanteVentaComprobanteAsociado.elementos = comprobantesAsociados;
        this.comprobanteVentaComprobanteAsociado.recargar();

        // Cargar transacciones
        const transacciones = elemento?.comprobante?.transacciones ?? [];
        this.comprobanteTransaccion.elementos = transacciones;
        this.comprobanteTransaccion.tabla.recargar();
    }

    protected override crearElemento(): ComprobanteVenta {
        this.elementoActual = { ...new ComprobanteVenta(), ...this.elementoActual };

        // Crear comprobante
        const comprobante = { ...new Comprobante(), ...this.elementoActual.comprobante };
        comprobante.numero = Number(this.form.get('numero')?.value);
        const fecha = parseISO(this.form.get('fecha')?.value ?? '');
        // Agregar hora actual a la fecha
        comprobante.fecha = format(fecha, 'yyyy-MM-dd HH:mm:ss') as DateTimeString;
        if (fecha.getHours() === 0 && fecha.getMinutes() === 0 && fecha.getSeconds() === 0) {
            comprobante.fecha = `${format(fecha, 'yyyy-MM-dd')} ${format(new Date(), 'HH:mm:ss')}` as DateTimeString;
        }
        comprobante.numeroDocumento = this.form.get('numeroDocumento')?.value ?? '';
        comprobante.tipoComprobante = this.form.get('tipoComprobante')?.value as TipoComprobante;
        comprobante.tipoDocumento = this.form.get('tipoDocumento')?.value as TipoDocumento;
        comprobante.transacciones = this.elementoActual?.comprobante?.transacciones ?? [];

        // Asociar items
        this.elementoActual.items = this.comprobanteVentaItem.elementos ?? [];
        this.elementoActual.items.forEach((item) => {
            if (item.id && item.id < 0) {
                item.id = undefined;
            }
        });

        // Asociar mediosPago
        this.elementoActual.mediosPago = this.comprobanteVentaMedioPago.elementos ?? [];
        this.elementoActual.mediosPago.forEach((medioPago) => {
            if (medioPago.id && medioPago.id < 0) {
                medioPago.id = undefined;
            }
        });

        // Asociar tributos
        this.elementoActual.tributos = this.comprobanteVentaTributo.elementos ?? [];
        this.elementoActual.tributos.forEach((tributo) => {
            if (tributo.id && tributo.id < 0) {
                tributo.id = undefined;
            }
        });

        // Asociar datosOpcionales
        this.elementoActual.datosOpcionales = this.comprobanteVentaDatoOpcional.elementos ?? [];
        this.elementoActual.datosOpcionales.forEach((datoOpcional) => {
            if (datoOpcional.id && datoOpcional.id < 0) {
                datoOpcional.id = undefined;
            }
        });

        // Asociar comprobantes asociados
        this.elementoActual.comprobantesAsociados = this.comprobanteVentaComprobanteAsociado.elementos ?? [];

        // Asociar transacciones
        comprobante.transacciones = this.comprobanteTransaccion.elementos ?? [];
        comprobante.transacciones.forEach((transaccion) => {
            if (transaccion.id && transaccion.id < 0) {
                transaccion.id = undefined;
            }
        });

        this.elementoActual.comprobante = comprobante;

        const comprobanteVenta = super.crearElemento() as ComprobanteVenta;
        comprobanteVenta.items = this.elementoActual.items;

        return comprobanteVenta;
    }

    protected override inicializarElemento(): void {
        this.cotizacionAnterior = null;
        super.inicializarElemento();
        setTimeout(() => {
            // Si existe, tomar el valor de la empresa del perfil del usuario
            try {
                let empresa = JSON.parse(localStorage.getItem('empresa') ?? '{}') as Empresa;
                if (this.relaciones?.empresas?.length > 0) {
                    const empresaPerfil = this.relaciones?.empresas?.find((e: Empresa) => e.id === empresa.id);
                    if (empresaPerfil) {
                        empresa = empresaPerfil;
                    }
                    this.cambiarEmpresa(empresa.id ? empresa : this.relaciones?.empresas?.[0], { inicializar: true });
                }
            } catch (error) {
                this.cambiarEmpresa(this.relaciones?.empresas?.[0], { inicializar: true });
            }
            // Establece por defecto la fecha actual
            this.form.get('fecha')?.setValue(format(new Date(), 'yyyy-MM-dd'));
            // Reiniciar tipo de documento
            this.cambiarTipoDocumento(this.form.get('tipoDocumento')?.value as TipoDocumento);
        });
    }

    protected override async postAlta(elemento: ComprobanteVenta): Promise<void> {
        try {
            if (this.generarCAE) {
                this.modalRef.componentInstance.cargando = true;
                await this.solicitarCAE(elemento, true);
                this.modalRef.componentInstance.cargando = false;
            }
            super.postAlta(elemento);
        } catch (error) {
            //
        }
    }

    private siguienteNumero() {
        // Buscar siguiente número de comprobante según tipo de comprobante y punto de venta
        const tipoComprobante = this.form.get('tipoComprobante')?.value as TipoComprobante;
        const puntoVenta = this.form.get('puntoVenta')?.value as PuntoVenta;
        const empresa = this.form.get('empresa')?.value as Empresa;
        if (!puntoVenta || !empresa || !tipoComprobante) {
            return;
        }
        this.form.get('numero')?.disable();
        this.cargando.numero = true;
        this.modalRef.componentInstance.cargando = true;
        this.subscription.add(
            this.comprobanteVentaViewService
                .siguienteNumero(
                    empresa.id as number,
                    puntoVenta.id as number,
                    tipoComprobante.id as number,
                    this.siguienteNumeroAFIP,
                )
                .pipe(
                    finalize(() => {
                        this.form.get('numero')?.enable();
                        this.cargando.numero = false;
                        this.modalRef.componentInstance.cargando = false;
                    }),
                )
                .subscribe({
                    next: (numero: number) => {
                        this.form.get('numero')?.setValue(numero as any);
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    protected override formValidar(form: FormGroup, formElement: ElementRef<any>) {
        super.formValidar(form, formElement);
        this.validarItems();
    }

    private obtenerMascaraTipoDocumento(tipoDocumento: any): {
        mascaraNumeroDocumento: string;
        nombreMascaraNumeroDocumento: string;
    } {
        const denominacion = tipoDocumento?.denominacion;
        if (denominacion) {
            const tipoDocumentoDenominacion = this.tipoDocumentoDenominaciones.find(
                (tipoDocumentoDenominacion) => tipoDocumentoDenominacion.nombre === denominacion,
            );
            if (tipoDocumentoDenominacion) {
                return {
                    mascaraNumeroDocumento: tipoDocumentoDenominacion.mascara,
                    nombreMascaraNumeroDocumento: tipoDocumentoDenominacion.nombre,
                };
            }
        }
        return { mascaraNumeroDocumento: '', nombreMascaraNumeroDocumento: '' };
    }

    private establecerCotizacion(): void {
        const moneda = this.form.get('moneda')?.value as Moneda;
        const fecha = this.form.get('fecha')?.value as DateString;
        if (!moneda || !moneda.id) {
            return;
        }
        if (moneda && !fecha) {
            this.form.get('cotizacion')?.setValue(Big(moneda.cotizacion ?? +1.0).toFixed(6));
            this.actualizarCotizacion();
            return;
        }
        // Si es la moneda oficial de la empresa, establecer cotización 1
        if (moneda.id === this.monedaOficialEmpresa?.id) {
            this.form.get('cotizacion')?.setValue(Big(+1.0).toFixed(6));
            this.actualizarCotizacion();
            return;
        }
        this.form.get('cotizacion')?.disable();
        this.cargando.cotizacion = true;
        this.modalRef.componentInstance.cargando = true;
        this.subscription.add(
            this.monedaService
                .obtenerCotizacionPorFecha(moneda.id, fecha)
                .pipe(
                    finalize(() => {
                        this.form.get('cotizacion')?.enable();
                        this.cargando.cotizacion = false;
                        this.modalRef.componentInstance.cargando = false;
                    }),
                )
                .subscribe({
                    next: (monedaCotizacion) => {
                        if (!monedaCotizacion) {
                            this.toastService.errorHandler(
                                `No se pudo obtener la cotización de la moneda ${moneda.nombre}`,
                            );
                        }
                        const cotizacion = Big(monedaCotizacion?.cotizacion ?? moneda.cotizacion ?? +1.0).toFixed(6);
                        this.form.get('cotizacion')?.setValue(cotizacion);
                        this.actualizarCotizacion();
                        this.toastService.successHandler(
                            `Cotización actualizada a ${this.monedaOficial?.simbolo ?? '$'} ${this.toFixedCotizacion(
                                cotizacion,
                            ).toLocaleString('es-ES', {
                                minimumFractionDigits: 2,
                            })} en la fecha ${format(
                                monedaCotizacion.fecha
                                    ? parse(monedaCotizacion.fecha, 'yyyy-MM-dd', new Date())
                                    : new Date(fecha),
                                'dd/MM/yyyy',
                            )}`,
                        );
                    },
                    error: (error) => {
                        error.class = 'warning';
                        this.form.get('cotizacion')?.setValue(Big(moneda.cotizacion ?? 1).toFixed(6));
                        this.actualizarCotizacion();
                        this.toastService.errorHandler(error);
                    },
                }),
        );
    }

    private saldarComprobante(elemento: ComprobanteVentaView, cancelar = false): void {
        if (!elemento || !elemento.id) {
            return;
        }
        // Crear nuevo comprobante de tipo Recibo, con la misma letra que el comporbante a saldar
        // Completar los datos del recibo en base a los datos del comprobante a saldar (cliente, empresa, punto de venta, etc.)
        // Agregar entre los comprobantes asociados el comprobante a saldar
        // Agregar un item por el saldo del comprobante a saldar
        // Agregar un medio de pago por el saldo del comprobante a saldar
        this.subscription.add(
            this.comprobanteVentaService.obtener(elemento.id).subscribe((comprobante) => {
                if (!comprobante || !comprobante.id) {
                    return;
                }
                const sub = this.elementoInicializado.subscribe(() => {
                    setTimeout(() => {
                        this.nav.select('comprobantesAsociados');
                        // Si se está cancelando el comprobante, y la denominación de su tipo de comprobante es "Factura",
                        // cambiar la denominación para que sea considerado "Nota de Débito"
                        if (
                            cancelar &&
                            comprobante.comprobante?.tipoComprobante?.denominacion ===
                                TipoComprobanteDenominacionEnum.FACTURA
                        ) {
                            comprobante.comprobante.tipoComprobante.denominacion =
                                TipoComprobanteDenominacionEnum.NOTA_DEBITO;
                        }
                        // Si se está cobrando el comprobante, y la denominación de su tipo de comprobante es "Nota de Débito",
                        // cambiar la denominación para que sea considerado "Factura"
                        if (
                            !cancelar &&
                            comprobante.comprobante?.tipoComprobante?.denominacion ===
                                TipoComprobanteDenominacionEnum.NOTA_DEBITO
                        ) {
                            comprobante.comprobante.tipoComprobante.denominacion =
                                TipoComprobanteDenominacionEnum.FACTURA;
                        }
                        const tipoComprobanteOpuesto = this.obtenerTipoComprobanteOpuesto(
                            comprobante.comprobante?.tipoComprobante,
                        );
                        // Si el tipo de comprobante es de denominación "Recibo",
                        // agrupar todos los items en uno solo con alícuota 0,
                        // y eliminar los tributos sumándolos al item agrupado
                        if (tipoComprobanteOpuesto?.denominacion === TipoComprobanteDenominacionEnum.RECIBO) {
                            const item = new ComprobanteVentaItem({
                                cantidad: +1.0,
                                precioUnitario: Math.abs(elemento.saldo ?? +0.0),
                                importeBonificado: +0.0,
                                tipoAlicuotaIVA: this.relaciones.tiposAlicuotaIVA.find(
                                    (tipoAlicuotaIVA: TipoAlicuotaIVA) => tipoAlicuotaIVA.porcentaje === 0,
                                ),
                            });
                            item.recalcularImportes();
                            comprobante.items = [item];
                            comprobante.tributos = [];
                        }
                        this.comprobanteVentaItem.saldarComprobante(comprobante);
                        // Si el tipo de comprobante es Recibo, agregar un medio de pago por el saldo del comprobante a saldar
                        if (tipoComprobanteOpuesto?.denominacion === TipoComprobanteDenominacionEnum.RECIBO) {
                            this.comprobanteVentaMedioPago.saldarComprobante(comprobante);
                        }
                        // Si el tipo de comprobante es Nota de crédito o Nota de débito, saldar los tributos del comprobante compensado
                        if (
                            tipoComprobanteOpuesto?.denominacion === TipoComprobanteDenominacionEnum.NOTA_CREDITO ||
                            tipoComprobanteOpuesto?.denominacion === TipoComprobanteDenominacionEnum.NOTA_DEBITO
                        ) {
                            this.comprobanteVentaTributo.saldarComprobante(comprobante);
                        }
                        this.comprobanteVentaComprobanteAsociado.elementos = [
                            {
                                comprobanteVentaAsociado: elemento,
                                importe: Math.abs(elemento.importeTotal ?? +0.0),
                            } as any,
                        ];
                        this.comprobanteVentaComprobanteAsociado.cliente = comprobante.cliente;
                        this.comprobanteVentaComprobanteAsociado.recargar();
                        this.form.get('tipoConcepto')?.setValue(comprobante.tipoConcepto as any);
                        this.form.get('tipoActividad')?.setValue(comprobante.tipoActividad as any);
                        this.form.get('idioma')?.setValue(comprobante.idioma as any);
                        this.form.get('moneda')?.setValue(comprobante.moneda as any);
                        this.form.get('cotizacion')?.setValue(comprobante.cotizacion as any);
                        this.form.get('cliente')?.setValue(comprobante.cliente as any);
                        this.form.get('numeroDocumento')?.setValue(comprobante.comprobante?.numeroDocumento as any);
                        this.cambiarTipoDocumento(comprobante.comprobante?.tipoDocumento);
                        this.cambiarTipoComprobante(tipoComprobanteOpuesto);
                        this.cambiarPuntoVenta(comprobante.puntoVenta);
                        this.cambiarEmpresa(comprobante.empresa, { saldar: true });
                        this.cotizacionAnterior = comprobante.cotizacion ?? +1.0;
                        this.actualizarCotizacion();
                        sub.unsubscribe();
                    });
                });
                this.subscription.add(sub);
            }),
        );
        this.alta();
    }

    private cobrarComprobante(elemento: ComprobanteVentaView): void {
        if (!elemento || !elemento.id) {
            return;
        }
        this.saldarComprobante(elemento);
    }

    private cancelarComprobante(elemento: ComprobanteVentaView): void {
        if (!elemento || !elemento.id) {
            return;
        }
        this.saldarComprobante(elemento, true);
    }

    private async solicitarCAE(elemento: ComprobanteVentaView, generarQR = true) {
        try {
            if (!elemento || !elemento.id) {
                return;
            }
            (elemento as any).__solicitandoCAE = true;
            const comprobanteTransaccion$ = this.comprobanteVentaService.solicitarCAE(elemento.id, generarQR);
            const comprobanteTransaccion = await lastValueFrom(comprobanteTransaccion$);
            (elemento as any).__solicitandoCAE = false;
            if (comprobanteTransaccion?.resultado === 'R' || comprobanteTransaccion?.codigoError) {
                this.toastService.errorHandler({
                    header: `Comprobante de Venta ID ${elemento.id} rechazado`,
                    status: comprobanteTransaccion.codigoError,
                    message:
                        comprobanteTransaccion.mensajeError ??
                        comprobanteTransaccion.observaciones ??
                        `Error al solicitar CAE`,
                });
                return;
            }
            let mensaje = 'CAE solicitado con éxito';
            // Adicionar observaciones al mensaje, si las hay
            if (comprobanteTransaccion?.observaciones) {
                mensaje += `: ${comprobanteTransaccion.observaciones}`;
            }
            this.toastService.successHandler(mensaje);
        } catch (error) {
            ((elemento as any) ?? {}).__solicitandoCAE = false;
            this.toastService.errorHandler(error);
        }
    }

    private async generarQR(elemento: ComprobanteVentaView) {
        try {
            if (!elemento || !elemento.id) {
                return;
            }
            (elemento as any).__generandoQR = true;
            const respuesta$ = this.comprobanteVentaService.generarQR(elemento.id);
            const respuesta = await lastValueFrom(respuesta$);
            (elemento as any).__generandoQR = false;
            if (respuesta != null) {
                // Convertir Buffer a imagen y mostrar en el toast
                const blob = new Blob([respuesta], { type: 'image/png' });
                const url = URL.createObjectURL(blob);
                const img = new Image();
                img.style.width = '10rem';
                img.src = url;
                img.onload = () => {
                    URL.revokeObjectURL(url);
                };
                this.toastService.successHandler('QR generado con éxito', img);
                return;
            }
            this.toastService.errorHandler('Error al generar QR');
        } catch (error) {
            ((elemento as any) ?? {}).__generandoQR = false;
            this.toastService.errorHandler(error);
        }
    }

    private cargarParametrosPorDefecto(empresa?: Empresa, opciones?: { inicializar?: boolean }) {
        if (!empresa) {
            return;
        }
        // Cargar datos por defecto
        this.form.get('tipoConcepto')?.disable();
        this.form.get('tipoActividad')?.disable();
        this.form.get('idioma')?.disable();
        this.form.get('moneda')?.disable();
        this.form.get('cotizacion')?.disable();
        this.form.get('puntoVenta')?.disable();
        this.form.get('tipoComprobante')?.disable();
        this.comprobanteVentaItem.formCargaRapida.get('tipoAlicuotaIVA')?.disable();
        this.cargando.tipoConcepto = true;
        this.cargando.tipoActividad = true;
        this.cargando.idioma = true;
        this.cargando.moneda = true;
        this.cargando.cotizacion = true;
        this.cargando.puntoVenta = true;
        this.cargando.tipoComprobante = true;
        this.comprobanteVentaItem.cargando.tipoAlicuotaIVA = true;
        this.modalRef.componentInstance.cargando = true;
        this.subscription.add(
            forkJoin({
                parametroTipoConceptoDefecto: this.parametroService.obtenerPorNombre(
                    empresa.id,
                    'TIPO_CONCEPTO_DEFECTO',
                ),
                parametroTipoActividadDefecto: this.parametroService.obtenerPorNombre(
                    empresa.id,
                    'TIPO_ACTIVIDAD_DEFECTO',
                ),
                parametroIdiomaOficial: this.parametroService.obtenerPorNombre(empresa.id, 'IDIOMA_OFICIAL'),
                parametroMonedaOficial: this.parametroService.obtenerPorNombre(empresa.id, 'MONEDA_OFICIAL'),
                parametroUnidadMedidaDefecto: this.parametroService.obtenerPorNombre(
                    empresa.id,
                    'UNIDAD_MEDIDA_DEFECTO',
                ),
                parametroPuntoVentaDefecto: this.parametroService.obtenerPorNombre(empresa.id, 'PUNTO_VENTA_DEFECTO'),
                parametroTipoComprobanteVentaDefecto: this.parametroService.obtenerPorNombre(
                    empresa.id,
                    'TIPO_COMPROBANTE_VENTA_DEFECTO',
                ),
                parametroTipoAlicuotaIVADefecto: this.parametroService.obtenerPorNombre(
                    empresa.id,
                    'TIPO_ALICUOTA_IVA_DEFECTO',
                ),
            })
                .pipe(
                    finalize(() => {
                        this.form.get('tipoConcepto')?.enable();
                        this.form.get('tipoActividad')?.enable();
                        this.form.get('idioma')?.enable();
                        this.form.get('moneda')?.enable();
                        this.form.get('cotizacion')?.enable();
                        this.form.get('puntoVenta')?.enable();
                        this.form.get('tipoComprobante')?.enable();
                        this.comprobanteVentaItem.formCargaRapida.get('tipoAlicuotaIVA')?.enable();
                        this.cargando.tipoConcepto = false;
                        this.cargando.tipoActividad = false;
                        this.cargando.idioma = false;
                        this.cargando.moneda = false;
                        this.cargando.cotizacion = false;
                        this.cargando.puntoVenta = false;
                        this.cargando.tipoComprobante = false;
                        this.comprobanteVentaItem.cargando.tipoAlicuotaIVA = false;
                        this.modalRef.componentInstance.cargando = false;
                    }),
                )
                .subscribe({
                    next: (parametrosDefecto) => {
                        const parametroTipoConceptoDefecto = parametrosDefecto?.parametroTipoConceptoDefecto;
                        if (parametroTipoConceptoDefecto?.valor && this.relaciones.tiposConcepto?.length) {
                            const tipoConcepto = this.relaciones.tiposConcepto.find(
                                (tipoConcepto: TipoConcepto) =>
                                    tipoConcepto.codigoAFIP === parametroTipoConceptoDefecto.valor,
                            );
                            if (tipoConcepto && !this.form.get('tipoConcepto')?.value) {
                                this.form.get('tipoConcepto')?.setValue(tipoConcepto);
                            }
                        }
                        const parametroTipoActividadDefecto = parametrosDefecto?.parametroTipoActividadDefecto;
                        if (parametroTipoActividadDefecto?.valor && this.relaciones.tiposActividad?.length) {
                            const tipoActividad = this.relaciones.tiposActividad.find(
                                (tipoActividad: TipoActividad) =>
                                    tipoActividad.codigoAFIP === parametroTipoActividadDefecto.valor,
                            );
                            if (tipoActividad && !this.form.get('tipoActividad')?.value) {
                                this.form.get('tipoActividad')?.setValue(tipoActividad);
                            }
                        }
                        const parametroIdiomaOficial = parametrosDefecto?.parametroIdiomaOficial;
                        if (parametroIdiomaOficial?.valor && this.relaciones.idiomas?.length) {
                            const idioma = this.relaciones.idiomas.find(
                                (idioma: Idioma) => idioma.codigoISO === parametroIdiomaOficial.valor,
                            );
                            if (idioma && !this.form.get('idioma')?.value) {
                                this.form.get('idioma')?.setValue(idioma);
                            }
                        }
                        const parametroMonedaOficial = parametrosDefecto?.parametroMonedaOficial;
                        if (parametroMonedaOficial?.valor && this.relaciones.monedas?.length) {
                            const moneda = this.relaciones.monedas.find(
                                (moneda: Moneda) => moneda.codigoAFIP === parametroMonedaOficial.valor,
                            );
                            if (moneda) {
                                this.monedaOficialEmpresa = moneda;
                                if (!this.form.get('cotizacion')?.value) {
                                    this.form.get('cotizacion')?.setValue(moneda?.cotizacion);
                                    this.actualizarCotizacion();
                                }
                                if (!this.form.get('moneda')?.value || opciones?.inicializar === true) {
                                    this.cambiarMoneda(moneda, { sobreescribir: opciones?.inicializar });
                                }
                            }
                        }
                        const parametroUnidadMedidaDefecto = parametrosDefecto?.parametroUnidadMedidaDefecto;
                        if (parametroUnidadMedidaDefecto?.valor) {
                            this.comprobanteVentaItem.parametroUnidadMedidaDefecto = parametroUnidadMedidaDefecto;
                        }
                        const parametroPuntoVentaDefecto = parametrosDefecto?.parametroPuntoVentaDefecto;
                        if (parametroPuntoVentaDefecto?.valor && this.relaciones.puntosVenta?.length) {
                            const puntoVenta = this.relaciones.puntosVenta.find(
                                (puntoVenta: PuntoVenta) => puntoVenta.nombre === parametroPuntoVentaDefecto.valor,
                            );
                            if (puntoVenta && !this.form.get('puntoVenta')?.value) {
                                this.cambiarPuntoVenta(puntoVenta);
                            }
                        }
                        const parametroTipoComprobanteVentaDefecto =
                            parametrosDefecto?.parametroTipoComprobanteVentaDefecto;
                        if (parametroTipoComprobanteVentaDefecto?.valor && this.relaciones.tiposComprobante?.length) {
                            let valor: number | undefined = Number(parametroTipoComprobanteVentaDefecto.valor);
                            if (isNaN(valor)) {
                                valor = undefined;
                            }
                            const tipoComprobante = this.relaciones.tiposComprobante.find(
                                (tipoComprobante: TipoComprobante) => tipoComprobante.id === valor,
                            );
                            if (tipoComprobante && !this.form.get('tipoComprobante')?.value) {
                                this.cambiarTipoComprobante(tipoComprobante);
                            }
                        }
                        const parametroTipoAlicuotaIVADefecto = parametrosDefecto?.parametroTipoAlicuotaIVADefecto;
                        if (parametroTipoAlicuotaIVADefecto?.valor && this.relaciones.tiposAlicuotaIVA?.length) {
                            const tipoAlicuotaIVA = this.relaciones.tiposAlicuotaIVA.find(
                                (tipoAlicuotaIVA: TipoAlicuotaIVA) =>
                                    tipoAlicuotaIVA.codigoAFIP === parametroTipoAlicuotaIVADefecto.valor,
                            );
                            if (tipoAlicuotaIVA) {
                                this.comprobanteVentaItem.formCargaRapida
                                    .get('tipoAlicuotaIVA')
                                    ?.setValue(tipoAlicuotaIVA);
                            }
                        }
                        // Si la moneda oficial de la empresa es diferente a Pesos Argentinos,
                        // configurar el componente para trabajar con invoices
                        this.facturacionInvoice = false;
                        if (!this.monedaOficialAFIP()) {
                            this.facturacionInvoice = true;
                        }
                        this.cambiarFacturacionInvoice();
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    private consultarUltimoComprobante(): void {
        const modalRef = this.modalService.open(ModalFormularioComponent, {
            size: 'lg',
            centered: true,
        });
        this.formConsultarUltimoComprobante.reset();
        this.formConsultarUltimoComprobante.disable();
        modalRef.componentInstance.formTemplate = this.formConsultarUltimoComprobanteTemplate;
        modalRef.componentInstance.form = this.formConsultarUltimoComprobante;
        modalRef.componentInstance.titulo = `Consultar Último Comprobante Autorizado`;
        modalRef.componentInstance.botonAceptar = true;
        modalRef.componentInstance.cargando = true;
        this.subscription.add(
            forkJoin({
                relaciones: forkJoin({
                    empresas: this.empresaService.obtenerTodos(),
                    puntosVenta: this.puntoVentaService.obtenerTodos(),
                    tiposComprobante: this.tipoComprobanteService.obtenerTodos(),
                }),
            })
                .pipe(
                    finalize(() => {
                        modalRef.componentInstance.cargando = false;
                        this.formConsultarUltimoComprobante.enable();
                    }),
                )
                .subscribe({
                    next: ({ relaciones }) => {
                        this.relacionesConsultarUltimoComprobante = {};
                        Object.keys(relaciones).forEach((key) => {
                            this.relacionesConsultarUltimoComprobante[key] =
                                (relaciones as any)[key]?.elementos ?? (relaciones as any)[key];
                        });
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
        this.subscription.add(
            modalRef.componentInstance.aceptarHandler.subscribe(() => {
                this.formValidar(this.formConsultarUltimoComprobante, this.formConsultarUltimoComprobanteElement);
                if (!this.formConsultarUltimoComprobante.valid) {
                    return;
                }
                const empresa = this.formConsultarUltimoComprobante.get('empresa')?.value as Empresa;
                const tipoComprobante = this.formConsultarUltimoComprobante.get('tipoComprobante')
                    ?.value as TipoComprobante;
                const puntoVenta = this.formConsultarUltimoComprobante.get('puntoVenta')?.value as PuntoVenta;
                if (!empresa?.id || !tipoComprobante?.id || !puntoVenta?.id) {
                    return;
                }
                modalRef.componentInstance.cargando = true;
                this.subscription.add(
                    this.comprobanteVentaService
                        .consultarUltimoComprobante(
                            (this.formConsultarUltimoComprobante.get('empresa')?.value as Empresa).id as number,
                            (this.formConsultarUltimoComprobante.get('tipoComprobante')?.value as TipoComprobante)
                                .id as number,
                            (this.formConsultarUltimoComprobante.get('puntoVenta')?.value as PuntoVenta).id as number,
                        )
                        .pipe(finalize(() => (modalRef.componentInstance.cargando = false)))
                        .subscribe({
                            next: (comprobanteVenta: ComprobanteVenta) => {
                                if (comprobanteVenta?.id) {
                                    this.consulta(comprobanteVenta);
                                    this.modalRef.componentInstance.titulo = `Último Comprobante Autorizado: ID ${comprobanteVenta.id}`;
                                    return;
                                }
                                const modalInfoRef = this.modalService.open(ModalInformacionComponent, {
                                    centered: true,
                                });
                                modalInfoRef.componentInstance.titulo = 'Último Comprobante Autorizado';
                                // Mostrar empresa, tipo de comprobante, punto de venta y número como innerHTML
                                const numero = comprobanteVenta?.comprobante?.numero ?? 0;
                                modalInfoRef.componentInstance.mensaje =
                                    comprobanteVenta && numero
                                        ? `
                                    <h5 class="mb-3">Comprobante no registrado en el sistema</h5>
                                    <form>
                                        <div class="row">
                                            <label class="col-sm-5 col-form-label" for="empresa">Empresa</label>
                                            <div class="col">
                                                <input type="text" class="form-control" id="empresa" value="${
                                                    empresa?.nombre ?? ''
                                                }" readonly>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <label class="col-sm-5 col-form-label" for="tipoComprobante">Tipo de Comprobante</label>
                                            <div class="col">
                                                <input type="text" class="form-control" id="tipoComprobante" value="${
                                                    tipoComprobante?.nombre ?? ''
                                                }" readonly>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <label class="col-sm-5 col-form-label" for="puntoVenta">Punto de Venta</label>
                                            <div class="col">
                                                <input type="text" class="form-control" id="puntoVenta" value="${
                                                    puntoVenta?.numero ?? ''
                                                }" readonly>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <label class="col-sm-5 col-form-label" for="numero">Número</label>
                                            <div class="col">
                                                <input type="text" class="form-control" id="numero" value="${numero}" readonly>
                                            </div>
                                        </div>
                                    </form>
                                    `
                                        : 'No se encontró ningún comprobante.';
                            },
                            error: this.toastService.errorHandler.bind(this.toastService),
                        }),
                );
            }),
        );
    }

    private obtenerTipoComprobanteOpuesto(tipoComprobante: TipoComprobante | undefined): TipoComprobante | undefined {
        if (!tipoComprobante) {
            return undefined;
        }
        // Obtener el tipo de comprobante con la misma letra pero distinto tipo, utilizando por defecto la letra X
        // Buscar tipo de comprobante:
        // Si se está saldando una Factura, buscar un Recibo X, o en su defecto un Recibo de la misma letra
        // Si se está saldando una Nota de Débito, buscar una Nota de Crédito
        // Si se está saldando un Recibo o Nota de Crédito, buscar una Nota de Débito
        const tiposComprobante = (this.relaciones.tiposComprobante as TipoComprobante[])
            .filter((tc) => tc.letra === tipoComprobante.letra || tc.letra === TipoComprobanteLetraEnum.X)
            .sort((a, b) => (a.letra ?? '').localeCompare(b.letra ?? ''));
        switch (tipoComprobante.denominacion) {
            case TipoComprobanteDenominacionEnum.FACTURA:
                return (
                    tiposComprobante.find(
                        (tc) =>
                            tc.denominacion === TipoComprobanteDenominacionEnum.RECIBO &&
                            tc.signo === -1 &&
                            tc.letra === 'X',
                    ) ??
                    tiposComprobante.find(
                        (tc) => tc.denominacion === TipoComprobanteDenominacionEnum.RECIBO && tc.signo === -1,
                    )
                );
            case TipoComprobanteDenominacionEnum.NOTA_DEBITO:
                return tiposComprobante.find(
                    (tc) => tc.denominacion === TipoComprobanteDenominacionEnum.NOTA_CREDITO && tc.signo === -1,
                );
            case TipoComprobanteDenominacionEnum.RECIBO:
            case TipoComprobanteDenominacionEnum.NOTA_CREDITO:
                return tiposComprobante.find(
                    (tc) => tc.denominacion === TipoComprobanteDenominacionEnum.NOTA_DEBITO && tc.signo === 1,
                );
            default:
                return undefined;
        }
    }

    private imprimirComprobante(elemento: ComprobanteVentaView): void {
        if (!elemento || !elemento.id) {
            return;
        }
        (elemento as any).__imprimiendo = true;
        this.subscription.add(
            this.comprobanteVentaService
                .imprimirComprobante(elemento.id, true)
                .pipe(finalize(() => ((elemento as any).__imprimiendo = false)))
                .subscribe({
                    next: (respuesta) => {
                        const contentDisposition = respuesta.headers.get('Content-Disposition');
                        const nombreArchivo =
                            contentDisposition?.split('filename=')[1].replace(/"/g, '') ?? 'comprobante.pdf';
                        const blob = new File([respuesta.body ?? ''], nombreArchivo, { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const pdfWindow = window.open(url, '_blank');
                        if (pdfWindow) {
                            pdfWindow.addEventListener('load', () => {
                                setTimeout(() => {
                                    pdfWindow.document.title = nombreArchivo;
                                    pdfWindow.name = nombreArchivo;
                                }, 1);
                            });
                        }
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    private descargarComprobante(elemento: ComprobanteVentaView): void {
        if (!elemento || !elemento.id) {
            return;
        }
        (elemento as any).__descargando = true;
        this.subscription.add(
            this.comprobanteVentaService
                .imprimirComprobante(elemento.id, false)
                .pipe(finalize(() => ((elemento as any).__descargando = false)))
                .subscribe({
                    next: (respuesta) => {
                        const contentDisposition = respuesta.headers.get('Content-Disposition');
                        const nombreArchivo =
                            contentDisposition?.split('filename=')[1].replace(/"/g, '') ?? 'comprobante.pdf';
                        const blob = new File([respuesta.body ?? ''], nombreArchivo, { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = nombreArchivo;
                        link.click();
                        URL.revokeObjectURL(url);
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    private validarItems() {
        if (!this.comprobanteVentaItem) {
            return;
        }
        // Validar que exista al menos un item
        if (!this.comprobanteVentaItem.elementos.length) {
            this.form.get('items')?.setErrors({ required: true });
            this.comprobanteVentaItem.hostClasses = 'is-invalid invalid-standalone';
        } else {
            this.comprobanteVentaItem.hostClasses = '';
        }
        this.comprobanteVentaItem.tabla.validar((): boolean => this.comprobanteVentaItem.elementos.length > 0);
    }

    private vincularItemsComprobantesAsociados() {
        // Vincular modificaciones entre los items y los comprobantes asociados
        this.subscription.add(
            this.comprobanteVentaComprobanteAsociado.postModificacionEvent.subscribe((c) => {
                // Buscar los items asociados al saldo del comprobante a saldar
                const items = this.comprobanteVentaItem.elementos.filter(
                    (i) => i.comprobanteVentaAsociadoId === c.comprobanteVentaAsociado.id,
                );
                // Capturar el precio unitario actual de cada item, para que en el caso de que sean varios, se mantenga la proporción
                const importeTotalActual = items.reduce((total, item) => total + (item.importeTotal ?? +0.0), +0.0);
                const proporciones = items.map((item) => {
                    return {
                        id: item.id,
                        proporcion: (item.importeTotal ?? +0.0) / (importeTotalActual > 0 ? importeTotalActual : 1),
                    };
                });
                // Calcular el precio unitario de cada item, según la proporción del importe total (importe)
                items.forEach((item) => {
                    if (item) {
                        const proporcion = proporciones.find((p) => item.id === p.id)?.proporcion ?? 0;
                        const importeTotal = Big(c.importe).times(Big(proporcion)).toNumber();
                        // Calcular el precio unitario del item, considerando que el importe total del item debe quedar como precio unitario + IVA
                        const importeNeto = Big(importeTotal)
                            .div(Big(1).plus(Big(item.tipoAlicuotaIVA?.porcentaje ?? 0).div(100)))
                            .toNumber();
                        item.precioUnitario = importeNeto;
                        item.recalcularImportes();
                    }
                });
                this.comprobanteVentaItem.tabla.recargar();
                this.comprobanteVentaMedioPago.recalcularImportes(this.comprobanteVentaItem.obtenerImporteTotal());
                this.validarItems();
            }),
        );
        this.subscription.add(
            this.comprobanteVentaItem.postModificacionEvent.subscribe((item) => {
                // Buscar el comprobante asociado al item y actualizar el importe
                const comprobanteAsociado = this.comprobanteVentaComprobanteAsociado.elementos.find(
                    (c) => c.comprobanteVentaAsociado?.id === item.comprobanteVentaAsociadoId,
                );
                if (comprobanteAsociado) {
                    comprobanteAsociado.importe = Math.abs(item.precioUnitario ?? +0.0);
                    this.comprobanteVentaComprobanteAsociado.recargar();
                    this.comprobanteVentaMedioPago.recalcularImportes(this.comprobanteVentaItem.obtenerImporteTotal());
                }
            }),
        );
        // Vincular eliminaciones entre los items y los comprobantes asociados
        this.subscription.add(
            this.comprobanteVentaComprobanteAsociado.postBajaEvent.subscribe((c) => {
                // Eliminar los items asociados al saldo del comprobante a saldar
                const items = this.comprobanteVentaItem.elementos.filter((i) => i.comprobanteVentaAsociadoId === c.id);
                items.forEach((item) => {
                    if (item && item.id) {
                        this.comprobanteVentaItem.baseService.eliminar(item.id);
                    }
                });
                this.comprobanteVentaItem.tabla.recargar();
                this.comprobanteVentaMedioPago.recalcularImportes(this.comprobanteVentaItem.obtenerImporteTotal());
                this.validarItems();
            }),
        );
        // Vincular alta de comprobantes asociados con los items
        this.subscription.add(
            this.comprobanteVentaComprobanteAsociado.postAltaEvent.subscribe((c) => {
                // Crear un item asociado al saldo del comprobante a saldar
                this.comprobanteVentaItem.saldarComprobante(c.comprobanteVentaAsociado);
                this.comprobanteVentaMedioPago.recalcularImportes(this.comprobanteVentaItem.obtenerImporteTotal());
            }),
        );
        this.subscription.add(
            this.comprobanteVentaItem.postAltaEvent.subscribe((item) => {
                this.validarItems();
            }),
        );
    }
}
