import { EmpresaService } from '@administracion/services/empresa.service';
import { UsuarioService } from '@administracion/services/usuario.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Empresa } from '@entities/empresa.entity';
import { Usuario } from '@entities/usuario.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-empresa',
    templateUrl: './empresa.component.html',
    styleUrls: ['./empresa.component.scss'],
})
export class EmpresaComponent extends ABMCComponent<Empresa> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Empresa';
    public titulo = 'Empresas';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        numeroDocumento: ['', Validators.required],
        iibb: [''],
        inicioActividades: [''],
        direccion: [''],
        telefono: [''],
        email: [''],
        web: [''],
        usuarios: [[]],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'CUIT',
            data: 'numeroDocumento',
            searchable: true,
        },
    ];

    public constructor(
        protected empresaService: EmpresaService,
        protected usuarioService: UsuarioService,
        private formBuilder: FormBuilder,
    ) {
        super(empresaService, {
            inicializaciones: {
                nombre: 'Empresa',
                acciones: [],
                botones: [],
                anchoModal: 'lg',
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    public buscarUsuario = (term: string, item: Usuario) => {
        term = term.toLocaleLowerCase();
        return (
            (item?.nombre?.toLocaleLowerCase().indexOf(term) ?? -1) > -1 ||
            (item?.email?.toLocaleLowerCase().indexOf(term) ?? -1) > -1
        );
    };

    protected obtenerRelaciones() {
        return forkJoin({
            usuarios: this.usuarioService.obtenerTodos(),
        });
    }
}
