import { MigracionHermesTablaEnum } from '@entities/enums/migracion/migracion-hermes-tabla.enum';

export class MigracionHermesImportarTablaDTO {
    public empresaId?: number;

    public tabla?: MigracionHermesTablaEnum;

    public desde: number;

    public cantidad: number;

    public orden: string;

    public constructor(partial: Partial<MigracionHermesImportarTablaDTO>) {
        Object.assign(this, partial);
        this.desde = partial.desde ?? 1;
        this.cantidad = partial.cantidad ?? 100;
        this.orden = partial.orden ?? 'ASC';
    }
}
