import { BaseEntity } from '@entities/base.entity';
import { ComprobanteVenta } from '@entities/comprobante-venta.entity';
import { TipoTributo } from '@entities/tipo-tributo.entity';

export class ComprobanteVentaTributo extends BaseEntity {
    public importe?: number;
    public descripcion?: string;
    public comprobanteVenta?: ComprobanteVenta;
    public tipoTributo?: TipoTributo;

    public constructor(partial: Partial<ComprobanteVentaTributo> = {}) {
        super();
        Object.assign(this, partial);
    }
}
