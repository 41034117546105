import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MotivoCompraService } from '@compras/services/motivo-compra.service';
import { MotivoCompra } from '@entities/motivo-compra.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-motivo-compra',
    templateUrl: './motivo-compra.component.html',
    styleUrls: ['./motivo-compra.component.scss'],
})
export class MotivoCompraComponent extends ABMCComponent<MotivoCompra> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Motivo de Compra';
    public titulo = 'Motivos de Compra';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        abreviatura: [''],
        puntero: [''],
        empresa: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Abreviatura',
            data: 'abreviatura',
            searchable: true,
        },
        {
            title: 'Puntero',
            data: 'puntero',
            tipo: 'number',
            searchable: true,
        },
        {
            title: 'Empresa',
            data: 'empresa.nombre',
            searchable: true,
        },
    ];

    public constructor(
        protected motivoCompraService: MotivoCompraService,
        protected empresaService: EmpresaService,
        private formBuilder: FormBuilder,
    ) {
        super(motivoCompraService, {
            inicializaciones: {
                nombre: 'Motivo de Compra',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
        });
    }
}
