export enum TipoMedioPagoDenominacionEnum {
    EFECTIVO = 'Efectivo',
    CHEQUE = 'Cheque',
    TRANSFERENCIA = 'Transferencia',
    DEPOSITO = 'Depósito',
    TARJETA = 'Tarjeta',
}

export const tipoMedioPagoDenominacionIdPorKeyValue = (tipoMedioPagoDenominacion: any) => {
    let id = null;
    switch (tipoMedioPagoDenominacion) {
        case 0:
        case 'EFECTIVO':
            id = 0;
            break;
        case 1:
        case 'CHEQUE':
            id = 1;
            break;
        case 2:
        case 'TRANSFERENCIA':
            id = 2;
            break;
        case 3:
        case 'DEPOSITO':
            id = 3;
            break;
        case 4:
        case 'TARJETA':
            id = 4;
            break;
    }
    return id;
};

export const obtenerTipoMedioPagoDenominaciones = () => [
    { id: 0, clave: 'EFECTIVO', nombre: 'Efectivo' },
    { id: 1, clave: 'CHEQUE', nombre: 'Cheque' },
    { id: 2, clave: 'TRANSFERENCIA', nombre: 'Transferencia' },
    { id: 3, clave: 'DEPOSITO', nombre: 'Depósito' },
    { id: 4, clave: 'TARJETA', nombre: 'Tarjeta' },
];
