import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { ThemeService } from '@core/services/theme.service';
import { NgbDropdown, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { GlobalConfigService } from '@shared/services/global-config.service';
import { ToastService } from '@shared/services/toast.service';

@Component({
    selector: 'kratos-core',
    templateUrl: './core.component.html',
    styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements AfterViewInit, OnDestroy {
    @ViewChild('sidebarHeader') private sidebarHeader!: ElementRef;
    @ViewChild('sidebar') private sidebar!: ElementRef;
    @ViewChild('sidebarAyuda') private sidebarAyuda!: ElementRef;
    @ViewChild('sidebarHidden') private sidebarHidden!: ElementRef;
    @ViewChildren('sidebarDropdown') private sidebarDropdown!: QueryList<NgbDropdown>;

    private sidebarCollapsed = false;

    public usuario = JSON.parse(localStorage.getItem('usuario') ?? '{}');
    public popoverDelay = GlobalConfigService.POPOVER_DELAY;

    public submenuUsuario: any[] = [];
    public menuNavbar: any[] = [];
    public menuSidebar: any[] = [];

    public constructor(
        private authService: AuthService,
        private offcanvasService: NgbOffcanvas,
        private themeService: ThemeService,
        private renderer: Renderer2,
        private toastService: ToastService,
    ) {
        this.submenuUsuario = [
            {
                nombre: 'Perfil',
                url: '/administracion/perfil',
            },
        ];
        this.menuNavbar = [
            {
                nombre: 'Administración',
                url: '/administracion',
            },
            {
                nombre: 'Configuración',
                url: '/configuracion',
            },
            {
                nombre: 'Ventas',
                url: '/ventas',
            },
            {
                nombre: 'Compras',
                url: '/compras',
            },
        ];
        this.menuSidebar = [
            {
                nombre: 'Administración',
                icon: 'building-fill-gear',
                url: '/administracion',
                submenu: [
                    {
                        nombre: 'Empresas',
                        url: '/empresa',
                        descripcion:
                            'Permite administrar las empresas del sistema. Se pueden crear, editar y eliminar empresas. Las empresas se utilizan para agrupar usuarios. Los usuarios pueden pertenecer a una o más empresas. Las empresas se utilizan para agrupar múltiples elementos del sistema, como por ejemplo, los comprobantes de venta y comprobantes de compra.',
                    },
                    {
                        nombre: 'Usuarios',
                        url: '/usuario',
                        descripcion:
                            'Permite administrar los usuarios del sistema. Se pueden crear, editar y eliminar usuarios. También se pueden asignar roles a los usuarios. Los roles permiten definir los permisos de los usuarios en el sistema. Los permisos son las acciones que puede realizar un usuario en el sistema. Por ejemplo, un usuario puede tener permiso para crear, editar y eliminar usuarios, pero no para crear, editar y eliminar empresas.',
                    },
                    {
                        nombre: 'Parámetros',
                        url: '/parametro',
                        descripcion:
                            'Permite administrar los parámetros del sistema. Se pueden crear, editar y eliminar parámetros. Los parámetros se utilizan para configurar el sistema. Por ejemplo, el parámetro "Moneda Oficial" se utiliza para configurar la moneda oficial del sistema con la que se calcularán las cotizaciones. Los parámetros se utilizan en diferentes escenarios del sistema.',
                    },
                ],
            },
            {
                nombre: 'Configuración',
                icon: 'sliders',
                url: '/configuracion',
                submenu: [
                    {
                        nombre: 'Tipos de Responsables de IVA',
                        url: '/tipo-responsable-iva',
                        descripcion:
                            'Permite administrar los tipos de responsables de IVA del sistema. Se pueden crear, editar y eliminar tipos de responsables de IVA. Los tipos de responsables de IVA se utilizan en la carga de Comprobantes de Venta y Comprobantes de Compra, para informar a AFIP el tipo de responsable de IVA del receptor del comprobante.',
                    },
                    {
                        nombre: 'Tipos de Documentos',
                        url: '/tipo-documento',
                        descripcion:
                            'Permite administrar los tipos de documentos del sistema. Se pueden crear, editar y eliminar tipos de documentos. Los tipos de documentos se utilizan en la carga de Clientes, para informar a AFIP el tipo de documento del receptor del comprobante.',
                    },
                    {
                        nombre: 'Tipos de Comprobante',
                        url: '/tipo-comprobante',
                        descripcion:
                            'Permite administrar los tipos de comprobantes del sistema. Se pueden crear, editar y eliminar tipos de comprobantes. Los tipos de comprobantes se utilizan en la carga de Comprobantes de Venta y Comprobantes de Compra, para informar a AFIP el tipo de comprobante. El tipo de comprobante determina la lógica de generación y validación de los comprobantes.',
                    },
                    {
                        nombre: 'Países',
                        url: '/pais',
                        descripcion:
                            'Permite administrar los países del sistema. Se pueden crear, editar y eliminar países. Los países se asocian a provincias.',
                    },
                    {
                        nombre: 'Provincias',
                        url: '/provincia',
                        descripcion:
                            'Permite administrar las provincias del sistema. Se pueden crear, editar y eliminar provincias. Las provincias se asocian a países.',
                    },
                    {
                        nombre: 'Idiomas',
                        url: '/idioma',
                        descripcion:
                            'Permite administrar los idiomas del sistema. Se pueden crear, editar y eliminar idiomas. Los idiomas se utilizan en la carga de Comprobantes de Venta y Comprobantes de Compra, para informar a AFIP el idioma en el que se emitió el comprobante.',
                    },
                ],
            },
            {
                nombre: 'Migraciones',
                icon: 'box-arrow-in-down',
                url: '/migracion',
                submenu: [
                    {
                        nombre: 'Tareas de Migración',
                        url: '/migracion-tarea',
                        descripcion:
                            'Permite administrar las tareas de migración del sistema y ver el estado actual y el historial de las tareas de migración. Las tareas de migración se utilizan para migrar datos de diferentes fuentes al sistema. Las tareas de migración se utilizan para migrar datos de diferentes sistemas al sistema.',
                    },
                    {
                        nombre: 'Hermes',
                        url: '/migracion-hermes',
                        descripcion:
                            'Permite migrar datos de Hermes al sistema. Se pueden migrar diferentes tablas de Hermes al sistema. Las tablas de Hermes se utilizan para migrar datos de la base de datos de Hermes al sistema. Las tablas de Hermes se utilizan en diferentes escenarios del sistema.',
                    },
                    {
                        nombre: 'Simple Invoices',
                        url: '/migracion-si',
                        descripcion:
                            'Permite migrar datos de Simple Invoices al sistema. Se pueden migrar diferentes tablas de Simple Invoices al sistema. Las tablas de Simple Invoices se utilizan para migrar datos de la base de datos de Simple Invoices al sistema. Las tablas de Simple Invoices se utilizan en diferentes escenarios del sistema.',
                    },
                ],
            },
            {
                nombre: 'Ventas',
                icon: 'receipt-cutoff',
                url: '/ventas',
                submenu: [
                    {
                        nombre: 'Comprobantes de Venta',
                        url: '/comprobante-venta',
                        descripcion:
                            'Permite administrar los comprobantes de venta del sistema. Se pueden crear, editar y eliminar comprobantes de venta. Los comprobantes de venta se utilizan para informar a AFIP las operaciones de venta de la empresa.',
                    },
                    {
                        nombre: 'Clientes',
                        url: '/cliente',
                        descripcion:
                            'Permite administrar los clientes del sistema. Se pueden crear, editar y eliminar clientes. Los clientes se utilizan en la carga de Comprobantes de Venta, para informar a AFIP el receptor del comprobante.',
                    },
                    {
                        nombre: 'Productos',
                        url: '/producto',
                        descripcion:
                            'Permite administrar los productos del sistema. Se pueden crear, editar y eliminar productos. Los productos se utilizan en la carga de Comprobantes de Venta, para informar a AFIP los productos o servicios que se facturan.',
                    },
                    {
                        nombre: 'Reportes',
                        submenu: [
                            {
                                nombre: 'Cuenta Corriente',
                                url: '/reporte/cuenta-corriente',
                                descripcion:
                                    'Permite visualizar la cuenta corriente de un cliente. Se pueden filtrar los comprobantes de venta por cliente, tipo de comprobante, punto de venta, número de comprobante, fecha de comprobante y fecha de vencimiento. También se pueden visualizar los saldos deudores y acreedores del cliente.',
                            },
                            {
                                nombre: 'Saldos de Clientes',
                                url: '/reporte/saldos-clientes',
                                descripcion:
                                    'Permite visualizar los saldos de los clientes de la empresa. Se puede establecer una fecha de corte para visualizar los saldos de los clientes a esa fecha.',
                            },
                            {
                                nombre: 'Cobros Pendientes',
                                url: '/reporte/cobros-pendientes',
                                descripcion:
                                    'Permite visualizar los cobros pendientes de la empresa. Se pueden filtrar los comprobantes de venta por cliente, tipo de comprobante, punto de venta, número de comprobante, fecha de comprobante y fecha de vencimiento.',
                            },
                            {
                                nombre: 'Libro IVA Ventas',
                                url: '/reporte/libro-iva-ventas',
                                descripcion:
                                    'Permite visualizar el libro IVA Ventas de la empresa. Se pueden filtrar los comprobantes de venta por cliente, tipo de comprobante, punto de venta, número de comprobante, fecha de comprobante y fecha de vencimiento.',
                            },
                            {
                                nombre: 'Contable: Ventas por Jurisdicción',
                                url: '/reporte/contable-ventas-jurisdiccion',
                                descripcion:
                                    'Permite visualizar el reporte contable de ventas por jurisdicción de la empresa. Se pueden filtrar los comprobantes de venta por empresa y fecha.',
                            },
                        ],
                    },
                    {
                        nombre: 'Configuración de Ventas',
                        submenu: [
                            {
                                nombre: 'Puntos de Venta',
                                url: '/punto-venta',
                                descripcion:
                                    'Permite administrar los puntos de venta del sistema. Se pueden crear, editar y eliminar puntos de venta. Los puntos de venta se utilizan en la carga de Comprobantes de Venta, para informar a AFIP el punto de venta del comprobante.',
                            },
                            {
                                nombre: 'Unidades de Medida',
                                url: '/unidad-medida',
                                descripcion:
                                    'Permite administrar las unidades de medida del sistema. Se pueden crear, editar y eliminar unidades de medida. Las unidades de medida se utilizan en la carga de Comprobantes de Venta, para informar a AFIP la unidad de medida de los productos o servicios que se facturan.',
                            },
                            {
                                nombre: 'Monedas',
                                url: '/moneda',
                                descripcion:
                                    'Permite administrar las monedas del sistema. Se pueden crear, editar y eliminar monedas. Las monedas se utilizan en la carga de Comprobantes de Venta, para informar a AFIP la moneda en la que se emitió el comprobante.',
                            },
                            {
                                nombre: 'Tipos de Actividad',
                                url: '/tipo-actividad',
                                descripcion:
                                    'Permite administrar los tipos de actividad del sistema. Se pueden crear, editar y eliminar tipos de actividad. Los tipos de actividad se utilizan en la carga de Comprobantes de Venta, para informar a AFIP el tipo de actividad del emisor del comprobante.',
                            },
                            {
                                nombre: 'Tipos de Alícuotas de IVA',
                                url: '/tipo-alicuota-iva',
                                descripcion:
                                    'Permite administrar los tipos de alícuotas de IVA del sistema. Se pueden crear, editar y eliminar tipos de alícuotas de IVA. Los tipos de alícuotas de IVA se utilizan en la carga de Comprobantes de Venta, para informar a AFIP la alícuota de IVA de los conceptos o detalle del comprobante.',
                            },
                            {
                                nombre: 'Tipos de Conceptos',
                                url: '/tipo-concepto',
                                descripcion:
                                    'Permite administrar los tipos de conceptos del sistema. Se pueden crear, editar y eliminar tipos de conceptos. Los tipos de conceptos se utilizan en la carga de Comprobantes de Venta, para informar a AFIP el tipo de concepto del comprobante.',
                            },
                            {
                                nombre: 'Tipos de Datos Opcionales',
                                url: '/tipo-dato-opcional',
                                descripcion:
                                    'Permite administrar los tipos de datos opcionales del sistema. Se pueden crear, editar y eliminar tipos de datos opcionales. Los tipos de datos opcionales se utilizan en la carga de Comprobantes de Venta, para informar a AFIP los datos opcionales del comprobante en diferentes escenarios.',
                            },
                            {
                                nombre: 'Tipos de Medios de Pago',
                                url: '/tipo-medio-pago',
                                descripcion:
                                    'Permite administrar los tipos de medios de pago del sistema. Se pueden crear, editar y eliminar tipos de medios de pago. Los tipos de medios de pago se utilizan en la carga de Comprobantes de Venta y Comprobantes de Compra, para determinar la forma de pago de los comprobantes.',
                            },
                            {
                                nombre: 'Tipos de Punto de Venta',
                                url: '/tipo-punto-venta',
                                descripcion:
                                    'Permite administrar los tipos de punto de venta del sistema. Se pueden crear, editar y eliminar tipos de punto de venta. Los tipos de punto de venta se utilizan para determinar la lógica de aprobación de los Comprobantes de Venta. Por ejemplo, si el tipo de punto de venta es Facturación Electrónica, la aprobación de los comprobantes estará vinculada a la aprobación de AFIP.',
                            },
                            {
                                nombre: 'Tipos de Tributo',
                                url: '/tipo-tributo',
                                descripcion:
                                    'Permite administrar los tipos de tributo del sistema. Se pueden crear, editar y eliminar tipos de tributo. Los tipos de tributo se utilizan en la carga de Comprobantes de Venta, para informar a AFIP los tributos e impuestos del comprobante.',
                            },
                        ],
                    },
                ],
            },
            {
                nombre: 'Compras',
                icon: 'bag-check-fill',
                url: '/compras',
                submenu: [
                    {
                        nombre: 'Comprobantes de Compra',
                        url: '/comprobante-compra',
                        descripcion:
                            'Permite administrar los comprobantes de compra del sistema. Se pueden crear, editar y eliminar comprobantes de compra.',
                    },
                    {
                        nombre: 'Proveedores',
                        url: '/proveedor',
                        descripcion:
                            'Permite administrar los proveedores del sistema. Se pueden crear, editar y eliminar proveedores. Los proveedores se utilizan en la carga de Comprobantes de Compra.',
                    },
                    {
                        nombre: 'Reportes',
                        submenu: [
                            {
                                nombre: 'Pagos Pendientes',
                                url: '/reporte/pagos-pendientes',
                                descripcion:
                                    'Permite visualizar los pagos pendientes de la empresa. Se pueden filtrar los comprobantes de compra por proveedor, tipo de comprobante, punto de venta, número de comprobante, fecha de comprobante y fecha de vencimiento.',
                            },
                            {
                                nombre: 'Libro IVA Compras',
                                url: '/reporte/libro-iva-compras',
                                descripcion:
                                    'Permite visualizar el libro IVA Compras de la empresa. Se pueden filtrar los comprobantes de compra por proveedor, tipo de comprobante, punto de venta, número de comprobante, fecha de comprobante y fecha de vencimiento.',
                            },
                            {
                                nombre: 'Contable: Compras',
                                url: '/reporte/contable-compras',
                                descripcion:
                                    'Permite visualizar el reporte contable de compras de la empresa. Se pueden filtrar los comprobantes de compra por empresa y fecha.',
                            },
                            {
                                nombre: 'Contable: Motivos de Compras',
                                url: '/reporte/contable-compras-motivos',
                                descripcion:
                                    'Permite visualizar el reporte contable de motivos de compras de la empresa. Se pueden filtrar los comprobantes de compra por empresa y fecha.',
                            },
                        ],
                    },
                    {
                        nombre: 'Configuración de Compras',
                        submenu: [
                            {
                                nombre: 'Centros de Costo',
                                url: '/centro-costo',
                                descripcion:
                                    'Permite administrar los centros de costo del sistema. Se pueden crear, editar y eliminar centros de costo. Los centros de costo se utilizan en la carga de Comprobantes de Compra.',
                            },
                            {
                                nombre: 'Motivos de Compra',
                                url: '/motivo-compra',
                                descripcion:
                                    'Permite administrar los motivos de compras del sistema. Se pueden crear, editar y eliminar motivos de comprobante de compras. Los motivos de compra se utilizan en la carga de Items de Comprobantes de Compra.',
                            },
                        ],
                    },
                ],
            },
        ];
    }

    public ngAfterViewInit(): void {
        this.sidebarCollapsed = this.themeService.currentSidebarCollapsed === 'true';
        if (this.sidebarCollapsed) {
            this.hideSidebar();
        }
        setTimeout(
            () =>
                this.sidebarDropdown.forEach((dropdown) => {
                    const ids = (dropdown as any)._elementRef.nativeElement.id.split('-');
                    let splitIndex = 1;
                    let sidebar = this.menuSidebar[ids[splitIndex]];
                    let parentUrl = '';
                    splitIndex++;
                    while (splitIndex < ids.length) {
                        if (sidebar.submenu) {
                            parentUrl += sidebar.url ?? '';
                            sidebar = sidebar.submenu[ids[splitIndex]];
                        }
                        splitIndex++;
                    }
                    if (this.urlActual(sidebar, parentUrl)) {
                        dropdown.open();
                    }
                }),
            0,
        );
    }

    public ngOnDestroy(): void {
        this.toastService.clear();
    }

    public logout() {
        this.authService.logout();
    }

    public openOffcanvas(content: TemplateRef<any>, position: string) {
        this.offcanvasService.open(content, {
            position: position as any,
        });
    }

    public switchThemeHelp(): void {
        if (this.themeService.currentHelp === 'false') {
            this.themeService.currentHelp = 'true';
        } else if (this.themeService.currentHelp === 'true') {
            this.themeService.currentHelp = 'false';
        }
    }

    public getThemeMode(): string {
        return this.themeService.currentMode;
    }

    public getThemeSize(): string {
        return this.themeService.currentSize;
    }

    public getThemeHelp(): string {
        return this.themeService.currentHelp;
    }

    public getThemeSidebarCollapsed(): boolean {
        return this.themeService.currentSidebarCollapsed === 'true';
    }

    public usuarioLogueado(): boolean {
        return this.authService.isLoggedIn;
    }

    public sidebarToggle(): void {
        this.sidebarCollapsed = !this.sidebarCollapsed;
        if (this.sidebarCollapsed) {
            this.themeService.currentSidebarCollapsed = 'true';
            this.hideSidebar();
        } else {
            this.themeService.currentSidebarCollapsed = 'false';
            this.renderer.addClass(this.sidebarHeader.nativeElement, 'd-lg-flex');
            this.renderer.addClass(this.sidebar.nativeElement, 'd-lg-flex');
            this.renderer.addClass(this.sidebarAyuda.nativeElement, 'd-lg-flex');
            this.renderer.removeClass(this.sidebarHidden.nativeElement, 'd-lg-inline-flex');
        }
    }

    public getThemeHelpEnabled(): boolean {
        return this.themeService.currentHelp === 'true';
    }

    public submenuUrlActual(menu: any, submenu: any): boolean {
        return submenu.submenu.some((item: any) => window.location.pathname === `${menu.url}${item.url}`);
    }

    public obtenerEmailMesaAyuda(): string {
        const mailto = GlobalConfigService.MESA_AYUDA_EMAIL;
        const subject = `${GlobalConfigService.MESA_AYUDA_ASUNTO} (ID: ${this.usuario.id})`;
        const body = `Usuario: ${this.usuario.nombre}%0D%0AEmail: ${this.usuario.email}%0D%0A%0D%0A`;
        return `mailto:${mailto}?subject=${subject}&body=${body}`;
    }

    public empresaActual() {
        try {
            return JSON.parse(localStorage.getItem('empresa') ?? '{}');
        } catch (e) {
            return {};
        }
    }

    private hideSidebar(): void {
        this.renderer.removeClass(this.sidebarHeader.nativeElement, 'd-lg-flex');
        this.renderer.removeClass(this.sidebar.nativeElement, 'd-lg-flex');
        this.renderer.removeClass(this.sidebarAyuda.nativeElement, 'd-lg-flex');
        this.renderer.addClass(this.sidebarHidden.nativeElement, 'd-lg-inline-flex');
    }

    private urlActual(sidebar: any, parentUrl = ''): boolean {
        if (sidebar.url && window.location.pathname === `${parentUrl}${sidebar.url}`) {
            return true;
        } else if (sidebar.submenu) {
            return sidebar.submenu.some((item: any) => this.urlActual(item, `${parentUrl}${sidebar.url ?? ''}`));
        }
        return false;
    }
}
