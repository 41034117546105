export enum MigracionHermesTablaEnum {
    // General
    TIPO_COMPROBANTE = 'TIPO_COMPROBANTE',
    TIPO_DOCUMENTO = 'TIPO_DOCUMENTO',
    PAISES = 'PAISES',
    PROVINCIAS = 'PROVINCIAS',
    TIPO_ALICUOTA_IVA = 'TIPO_ALICUOTA_IVA',
    TIPO_RESPONSABLE_IVA = 'TIPO_RESPONSABLE_IVA',

    // General - Ventas
    CLIENTES = 'CLIENTES',
    MEDIOS_PAGO = 'MEDIOS_PAGO',
    CONCEPTOS_COMPROBANTES = 'CONCEPTOS_COMPROBANTES',
    TIPO_MONEDA = 'TIPO_MONEDA',
    PUNTOS_VENTA = 'PUNTOS_VENTA',
    TIPO_UNIDAD_MEDIDA = 'TIPO_UNIDAD_MEDIDA',

    // General - Compras
    PROVEEDORES = 'PROVEEDORES',
    TIPO_COMPRO_COMPRAS = 'TIPO_COMPRO_COMPRAS',
    CENTRO_COSTO = 'CENTRO_COSTO',
    TIPO_PERCEPCION = 'TIPO_PERCEPCION',
    MOTIVOS_COMPRO_COMPRAS = 'MOTIVOS_COMPRO_COMPRAS',

    // Ventas
    COMPROBANTES = 'COMPROBANTES',
    COMPRO_ITEMS = 'COMPRO_ITEMS',
    COBROS = 'COBROS',
    COBRO_ITEMS = 'COBRO_ITEMS',
    COBRO_COMPOSICION = 'COBRO_COMPOSICION',
    COBRO_SALDOS = 'COBRO_SALDOS',

    // Compras
    COMPROBANTES_COMPRAS = 'COMPROBANTES_COMPRAS',
    COMPROBANTES_COMPRAS_IMPORTES = 'COMPROBANTES_COMPRAS_IMPORTES',
    COMPROBANTES_COMPRAS_MOTIVOS = 'COMPROBANTES_COMPRAS_MOTIVOS',
}

export const obtenerMigracionHermesTablas = () => [
    { id: 0, clave: 'TIPO_COMPROBANTE', nombre: '(General) Tipos de Comprobante' },
    { id: 1, clave: 'TIPO_DOCUMENTO', nombre: '(General) Tipos de Documento' },
    { id: 2, clave: 'PAISES', nombre: '(General) Países' },
    { id: 3, clave: 'PROVINCIAS', nombre: '(General) Provincias' },
    { id: 4, clave: 'TIPO_ALICUOTA_IVA', nombre: '(General) Tipos de Alicuota de IVA' },
    { id: 5, clave: 'TIPO_RESPONSABLE_IVA', nombre: '(General) Tipos de Responsable de IVA' },
    { id: 6, clave: 'CLIENTES', nombre: '(General - Ventas) Clientes' },
    { id: 7, clave: 'MEDIOS_PAGO', nombre: '(General - Ventas) Medios de Pago' },
    { id: 8, clave: 'CONCEPTOS_COMPROBANTES', nombre: '(General - Ventas) Conceptos de Comprobantes' },
    { id: 9, clave: 'TIPO_MONEDA', nombre: '(General - Ventas) Tipos de Moneda' },
    { id: 10, clave: 'PUNTOS_VENTA', nombre: '(General - Ventas) Puntos de Venta' },
    { id: 11, clave: 'TIPO_UNIDAD_MEDIDA', nombre: '(General - Ventas) Tipos de Unidad de Medida' },
    { id: 12, clave: 'PROVEEDORES', nombre: '(General - Compras) Proveedores' },
    { id: 13, clave: 'TIPO_COMPRO_COMPRAS', nombre: '(General - Compras) Tipos de Comprobante de Compras' },
    { id: 14, clave: 'CENTRO_COSTO', nombre: '(General - Compras) Centro de Costo' },
    { id: 15, clave: 'TIPO_PERCEPCION', nombre: '(General - Compras) Tipos de Percepción' },
    { id: 16, clave: 'MOTIVOS_COMPRO_COMPRAS', nombre: '(General - Compras) Motivos de Comprobante de Compras' },
    { id: 17, clave: 'COMPROBANTES', nombre: '(Ventas) Comprobantes' },
    { id: 18, clave: 'COMPRO_ITEMS', nombre: '(Ventas) Items de Comprobantes' },
    { id: 19, clave: 'COBROS', nombre: '(Ventas) Cobros' },
    { id: 20, clave: 'COBRO_ITEMS', nombre: '(Ventas) Items de Cobros' },
    { id: 21, clave: 'COBRO_COMPOSICION', nombre: '(Ventas) Composición de Cobros' },
    { id: 22, clave: 'COBRO_SALDOS', nombre: '(Ventas) Saldos de Cobros' },
    { id: 23, clave: 'COMPROBANTES_COMPRAS', nombre: '(Compras) Comprobantes de Compras' },
    { id: 24, clave: 'COMPROBANTES_COMPRAS_IMPORTES', nombre: '(Compras) Importes de Comprobantes de Compras' },
    { id: 25, clave: 'COMPROBANTES_COMPRAS_MOTIVOS', nombre: '(Compras) Motivos de Comprobantes de Compras' },
];
