<!-- HEADER -->
<header class="navbar navbar-expand-lg sticky-top bg-primary-subtle">
    <nav class="container-xxl flex-wrap flex-lg-nowrap">
        <!-- MENÚ IZQUIERDA -->
        <div class="d-flex">
            <button class="navbar-toggler d-lg-none p-2" (click)="openOffcanvas(bdSidebar, 'start')">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>

        <!-- BRAND -->
        <a class="navbar-brand d-lg-none" routerLink="/">
            <img class="object-fit-scale" src="/assets/kratos-logo-icon.png" width="48" height="48" />
        </a>
        <a class="navbar-brand d-none d-lg-block ps-2" routerLink="/">
            <img
                class="object-fit-scale"
                src="/assets/kratos-logo-text.png"
                width="120"
                height="40"
                [ngClass]="{ 'high-brightness': getThemeMode() === 'dark' }"
            />
        </a>

        <!-- MENÚ DERECHA -->
        <div class="d-flex">
            <button class="navbar-toggler d-lg-none p-2" (click)="openOffcanvas(bdNavbar, 'end')">
                <span class="bi bi-three-dots"></span>
            </button>
        </div>

        <div class="offcanvas-lg flex-grow-1 d-none d-lg-block" id="bdNavbar">
            <ng-container *ngTemplateOutlet="bdNavbarBody"></ng-container>
        </div>
    </nav>
</header>

<!-- BODY -->
<div
    class="d-flex px-0 shadow-lg w-100"
    [ngClass]="{ 'container-fluid': getThemeSize() === 'fluid', 'container-xxl': getThemeSize() === 'xxl' }"
>
    <kratos-toasts-container></kratos-toasts-container>
    <aside #sidebarHidden class="z-1 position-fixed sidebar-hidden bg-secondary-subtle d-none border-end">
        <div class="z-3">
            <button type="button" class="btn btn-lg px-1 py-0" (click)="sidebarToggle()" ngbTooltip="Expandir">
                <i class="bi bi-arrow-bar-right"></i>
            </button>
        </div>
    </aside>
    <aside
        #sidebarHeader
        class="aside-header z-1 p-0 position-fixed sidebar bg-secondary-subtle d-none d-lg-flex flex-wrap border-end border-bottom"
    >
        <div class="sidebar-content offcanvas-lg d-flex w-100" id="bdSidebarHeader">
            <div class="position-absolute top-0 end-0 z-3">
                <button type="button" class="btn btn-lg px-1 py-0" (click)="sidebarToggle()" ngbTooltip="Contraer">
                    <i class="bi bi-arrow-bar-left"></i>
                </button>
            </div>
            <ng-container *ngTemplateOutlet="bdSidebarHeaderBody"></ng-container>
        </div>
    </aside>
    <aside
        #sidebar
        class="aside-menu z-1 p-3 position-fixed sidebar bg-secondary-subtle d-none d-lg-flex flex-wrap border-end"
    >
        <div class="sidebar-content offcanvas-lg d-flex w-100" id="bdSidebar">
            <ng-container *ngTemplateOutlet="bdSidebarBody"></ng-container>
        </div>
    </aside>
    <aside
        #sidebarAyuda
        class="aside-ayuda z-1 p-0 position-fixed sidebar bg-secondary-subtle d-none d-lg-flex flex-wrap border-end border-top"
    >
        <div class="sidebar-content offcanvas-lg d-flex w-100" id="bdSidebarAyuda">
            <ng-container *ngTemplateOutlet="bdSidebarAyudaBody"></ng-container>
        </div>
    </aside>
    <div
        class="d-none d-lg-block"
        [ngClass]="{
            'main-margin-left': !getThemeSidebarCollapsed(),
            'main-margin-left-collapsed': getThemeSidebarCollapsed()
        }"
    ></div>
    <main class="bg-text d-flex flex-fill overflow-x-hidden">
        <div class="container-fluid p-4">
            <router-outlet></router-outlet>
        </div>
    </main>
</div>

<!-- FOOTER -->
<footer id="footer" class="px-1 pt-1 sticky-bottom bg-primary-subtle">
    <div class="row g-0">
        <div class="col-12 col-lg-6">
            <p class="mb-0">© Folder IT 2024</p>
        </div>
    </div>
</footer>

<!-- NAVIGATION BAR -->
<ng-template #bdNavbar let-offcanvasNavbar>
    <div class="navbar">
        <div class="offcanvas-header border-bottom w-100">
            <h5 class="offcanvas-title">Kratos</h5>
            <button class="btn-close" (click)="offcanvasNavbar.close('close')"></button>
        </div>
        <ng-container *ngTemplateOutlet="bdNavbarBody"></ng-container>
    </div>
</ng-template>
<ng-template #bdNavbarBody>
    <div class="offcanvas-body">
        <ul class="navbar-nav flex-row flex-wrap">
            <li class="nav-item col-6 col-lg-auto" *ngFor="let menu of menuNavbar">
                <button
                    class="nav-link"
                    routerLink="{{ menu.url }}"
                    routerLinkActive="active"
                    [disabled]="menu.disabled"
                >
                    {{ menu.nombre }}
                </button>
            </li>
        </ul>
        <hr class="d-lg-none" />
        <ul class="navbar-nav flex-row flex-wrap ms-md-auto">
            <li class="navbar-brand me-0 col-lg-auto">
                <span class="fw-normal">{{ empresaActual()?.nombre ?? '' }}</span>
            </li>
        </ul>
        <hr class="d-lg-none" />
        <ul class="navbar-nav flex-row flex-wrap ms-md-auto">
            <li class="nav-item col-6 col-lg-auto">
                <a class="nav-link" href="https://twitter.com/folderit" target="_blank" rel="noopener">
                    <i class="bi bi-twitter"></i><span class="d-lg-none">Twitter</span>
                </a>
            </li>
            <li class="nav-item col-12 col-lg-auto">
                <div class="vr d-none d-lg-flex h-100 mx-lg-2"></div>
                <hr class="d-lg-none" />
            </li>
            <li ngbDropdown class="col-6 col-lg-auto" *ngIf="usuarioLogueado()">
                <button ngbDropdownToggle class="btn btn-link nav-link">
                    <i class="bi bi-person-fill"></i>
                    <span class="d-lg-none">Usuario</span>
                </button>
                <div ngbDropdownMenu class="dropdown-menu-lg-end" *ngFor="let submenu of submenuUsuario">
                    <button ngbDropdownItem routerLink="{{ submenu.url }}" routerLinkActive="active">
                        <span>{{ submenu.nombre }}</span>
                    </button>
                </div>
            </li>
            <kratos-theme-switcher></kratos-theme-switcher>
            <li class="nav-item col-12 col-lg-auto">
                <div class="vr d-none d-lg-flex h-100 mx-lg-2"></div>
                <hr class="d-lg-none" />
            </li>
            <li class="nav-item col-6 col-lg-auto" *ngIf="!usuarioLogueado()">
                <button class="nav-link" routerLink="/login">Ingresar</button>
            </li>
            <li class="nav-item col-6 col-lg-auto" *ngIf="usuarioLogueado()">
                <button class="nav-link" (click)="logout()">Salir</button>
            </li>
        </ul>
    </div>
</ng-template>

<!-- SIDE BAR -->
<ng-template #bdSidebar let-offcanvasSidebar>
    <div class="offcanvas-header border-bottom">
        <h5 class="offcanvas-title">Menú</h5>
        <button class="btn-close" (click)="offcanvasSidebar.close('close')"></button>
    </div>
    <ng-container *ngTemplateOutlet="bdSidebarBody"></ng-container>
</ng-template>
<ng-template #bdSidebarHeaderBody>
    <div class="navbar offcanvas-body w-100 d-flex flex-column align-items-center">
        <div class="navbar-nav small p-1">
            <strong class="fs-6">
                <span>Menú</span>
            </strong>
        </div>
    </div>
</ng-template>
<ng-template #bdSidebarBody>
    <div class="navbar offcanvas-body w-100 d-flex flex-column row-gap-3 align-items-stretch">
        <ul
            #sidebarDropdown="ngbDropdown"
            ngbDropdown
            [autoClose]="false"
            class="navbar-nav small"
            [attr.id]="'sidebarDropdown-' + menuIndex"
            *ngFor="let menu of menuSidebar; index as menuIndex"
        >
            <strong ngbDropdownToggle role="button" class="fs-6">
                <i class="bi bi-{{ menu.icon }}"></i>
                <span>{{ menu.nombre }}</span>
            </strong>
            <div ngbDropdownMenu *ngIf="menu.submenu">
                <li class="nav-item" *ngFor="let item of menu.submenu; index as submenuIndex">
                    <ng-container *ngIf="item.submenu">
                        <ng-template
                            [ngTemplateOutlet]="sidebarMenuSubmenu"
                            [ngTemplateOutletContext]="{
                                menu: menu,
                                item: item,
                                parentId: 'sidebarDropdown-' + menuIndex,
                                index: submenuIndex
                            }"
                        ></ng-template>
                    </ng-container>
                    <ng-container *ngIf="!item.submenu">
                        <ng-template
                            [ngTemplateOutlet]="sidebarMenuButton"
                            [ngTemplateOutletContext]="{ menu: menu, item: item }"
                        ></ng-template>
                    </ng-container>
                </li>
            </div>
        </ul>
        <div class="mx-0 flex-grow-1"></div>
    </div>
</ng-template>
<ng-template #bdSidebarAyudaBody>
    <div class="navbar offcanvas-body w-100 d-flex flex-column row-gap-3 align-items-stretch">
        <div class="navbar-nav small p-3">
            <strong class="fs-6">
                <i class="bi bi-info-circle"></i>
                <span>Ayuda</span>
            </strong>
            <li class="nav-item">
                <div class="form-check form-switch nav-link px-1 py-1 my-0">
                    <input
                        class="form-check-input ms-3 me-1"
                        type="checkbox"
                        role="button"
                        id="instruccionesPantalla"
                        (change)="switchThemeHelp()"
                        [checked]="getThemeHelp() === 'true'"
                    />
                    <label class="form-check-label" role="button" for="instruccionesPantalla"
                        >Instrucciones en Pantalla
                        <i
                            class="instrucciones-pantalla bi bi-question-circle"
                            popoverTitle="Instrucciones en Pantalla"
                            ngbPopover="Permite mostrar instrucciones en pantalla. Las instrucciones en pantalla son textos que aparecen cerca de los elementos sobre los que ubique el cursor. Las instrucciones en pantalla son útiles para aprender a utilizar el sistema. Se recomienda desactivar las instrucciones en pantalla una vez que se aprendió a utilizar el sistema. Las instrucciones en pantalla se pueden activar y desactivar en cualquier momento."
                        ></i
                    ></label>
                </div>
            </li>
            <li class="nav-item">
                <button routerLink="/ayuda/manual-usuario" class="nav-link px-4 py-1" disabled>
                    Manual de Usuario
                </button>
            </li>
            <li class="nav-item">
                <button routerLink="/ayuda/manual-administrador" class="nav-link px-4 py-1" disabled>
                    Manual de Administrador
                </button>
            </li>
            <li class="nav-item">
                <a [href]="obtenerEmailMesaAyuda()" class="nav-link px-4 py-1"
                    >Mesa de Ayuda
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Mesa de Ayuda"
                        ngbPopover="Si tiene alguna duda o inconveniente, puede enviar un email a la mesa de ayuda. Se responderá a la brevedad. Se recomienda incluir el ID del usuario en el asunto del email. El ID del usuario es {{
                            usuario?.id
                        }}. También se recomienda incluir el nombre y email del usuario en el cuerpo del email. El nombre del usuario es {{
                            usuario?.nombre
                        }} y el email del usuario es {{ usuario?.email }}. Esto facilitará la comunicación."
                    ></i
                ></a>
            </li>
        </div>
    </div>
</ng-template>

<ng-template #sidebarMenuSubmenu let-menu="menu" let-item="item" let-parentId="parentId" let-index="index">
    <ul ngbDropdown #sidebarDropdown="ngbDropdown" class="ps-4" [autoClose]="false" [attr.id]="parentId + '-' + index">
        <strong
            ngbDropdownToggle
            role="button"
            class="nav-link px-0 py-1 text-start"
            [ngClass]="{ active: submenuUrlActual(menu, item) }"
        >
            <i *ngIf="item.icon" class="bi bi-{{ item.icon }}"></i>
            <span>{{ item.nombre }}</span>
        </strong>
        <div ngbDropdownMenu>
            <li class="nav-item" *ngFor="let subitem of item.submenu; index as submenuIndex" role="button">
                <ng-container *ngIf="subitem.submenu">
                    <ng-template
                        [ngTemplateOutlet]="sidebarMenuSubmenu"
                        [ngTemplateOutletContext]="{
                            menu: menu,
                            item: subitem,
                            parentId: parentId + '-' + index,
                            index: submenuIndex
                        }"
                    ></ng-template>
                </ng-container>
                <ng-container *ngIf="!subitem.submenu">
                    <ng-template
                        [ngTemplateOutlet]="sidebarMenuButton"
                        [ngTemplateOutletContext]="{ menu: menu, item: subitem }"
                    ></ng-template>
                </ng-container>
            </li>
        </div>
    </ul>
</ng-template>

<ng-template #sidebarMenuButton let-menu="menu" let-item="item">
    <button
        routerLink="{{ menu.url }}{{ item.url }}"
        routerLinkActive="active"
        class="nav-link px-4 py-1 text-start"
        [disabled]="item.disabled"
    >
        {{ item.nombre }}
        <i
            *ngIf="getThemeHelpEnabled() && item.descripcion"
            class="instrucciones-pantalla bi bi-question-circle"
            popoverTitle="{{ item.nombre }}"
            ngbPopover="{{ item.descripcion ?? undefined }}"
        ></i>
    </button>
</ng-template>
