<div class="modal-header bg-secondary-subtle">
    <h4 class="modal-title">{{ titulo }}</h4>
    <button type="button" class="btn-close" (click)="activeModal.dismiss('cross')"></button>
</div>
<div class="modal-body">
    <div [innerHTML]="mensaje | safeHtml"></div>
</div>
<div class="modal-footer bg-secondary-subtle">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('cerrar')">Cerrar</button>
</div>
