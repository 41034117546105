<div class="float-end icono-advertencia-container">
    <div class="icono-advertencia-container-inner">
        <i
            class="bi bi-exclamation-diamond-fill icono-advertencia"
            popoverTitle="Precaución"
            ngbPopover="Los cambios realizados en esta sección pueden afectar el funcionamiento de la aplicación. Opere con cautela. Ante cualquier duda, consulte con el administrador."
            popoverClass="popover-warning"
        ></i>
    </div>
</div>
<h2 class="mx-n4 mt-n4 mb-3 px-4 pt-4 seccion-advertencia">
    <span class="bg-body px-2">{{ titulo }}</span>
</h2>

<kratos-tabla
    #tabla
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="nombre" class="form-label">Nombre</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Nombre"
                        ngbPopover="Nombre del parámetro."
                    ></i>
                    <input placeholder="Nombre" formControlName="nombre" type="text" class="form-control" id="nombre" />
                    <ng-container *ngFor="let error of formErrores('nombre', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="empresa" class="form-label">Empresa</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Empresa"
                        ngbPopover="Empresa a la que pertenece el parámetro."
                    ></i>
                    <ng-select
                        [items]="relaciones.empresas"
                        labelForId="empresa"
                        formControlName="empresa"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('empresa', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="descripcion" class="form-label">Descripción</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Descripción"
                        ngbPopover="Descripción del parámetro."
                    ></i>
                    <textarea
                        maxlength="255"
                        placeholder="Descripción"
                        formControlName="descripcion"
                        rows="3"
                        type="text"
                        class="form-control"
                        id="descripcion"
                    >
                    </textarea>
                    <ng-container *ngFor="let error of formErrores('descripcion', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="valor" class="form-label">Valor</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Valor"
                        ngbPopover="Valor del parámetro."
                    ></i>
                    <input placeholder="Valor" formControlName="valor" type="text" class="form-control" id="valor" />
                    <ng-container *ngFor="let error of formErrores('valor', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-template>
