import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusquedaDTO } from '@entities/dtos/busqueda.dto';
import { ReporteLibroIVAVentasView } from '@entities/views/reportes/reporte-libro-iva-ventas.view';
import { BaseService } from '@shared/services/base.service';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReporteLibroIVAVentasService extends BaseService<ReporteLibroIVAVentasView> {
    public constructor(http: HttpClient) {
        super('ventas/reporte/libro-iva-ventas', http);
    }

    public exportarLibroIVADigitalVentas(parametros: any): Observable<Blob> {
        const busqueda = new BusquedaDTO(parametros);
        return this.http.post(`${this.API_URL}/${this.endpointUrl}/exportar-libro-iva-digital-ventas`, busqueda, {
            responseType: 'blob',
        });
    }

    public imprimir(parametros: any, formatoNuevo = false): Observable<HttpResponse<Blob>> {
        const busqueda = new BusquedaDTO(parametros);
        return this.http
            .post(`${this.API_URL}/${this.endpointUrl}/imprimir${formatoNuevo ? '?formatoNuevo=true' : ''}`, busqueda, {
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(map((response) => response));
    }
}
