import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Producto } from '@entities/producto.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { ProductoService } from '@ventas/services/producto.service';
import { TipoAlicuotaIVAService } from '@ventas/services/tipo-alicuota-iva.service';
import { UnidadMedidaService } from '@ventas/services/unidad-medida.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-producto',
    templateUrl: './producto.component.html',
    styleUrls: ['./producto.component.scss'],
})
export class ProductoComponent extends ABMCComponent<Producto> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public nombre = 'Producto';
    public titulo = 'Productos';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        descripcion: [''],
        unidadMedida: ['', Validators.required],
        tipoAlicuotaIVA: [''],
        empresa: ['', Validators.required],
        codigoNCM: [''],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Unidad de Medida',
            data: 'unidadMedida.nombre',
            searchable: true,
        },
        {
            title: 'Empresa',
            data: 'empresa.nombre',
            searchable: true,
        },
        {
            title: 'Código NCM',
            data: 'codigoNCM',
            searchable: true,
        },
    ];

    public constructor(
        protected productoService: ProductoService,
        protected unidadMedidaService: UnidadMedidaService,
        protected tipoAlicuotaIVAService: TipoAlicuotaIVAService,
        protected empresaService: EmpresaService,
        private formBuilder: FormBuilder,
    ) {
        super(productoService, {
            inicializaciones: {
                nombre: 'Producto',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            unidadesMedida: this.unidadMedidaService.obtenerTodos(),
            tiposAlicuotaIVA: this.tipoAlicuotaIVAService.obtenerTodos(),
            empresas: this.empresaService.obtenerTodos(),
        });
    }
}
