<h5 class="mb-3">{{ titulo }}</h5>

<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="numeroDocumento" class="form-label">Número de Documento</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Número de Documento"
                        ngbPopover="Número de documento del país."
                    ></i>
                    <input
                        placeholder="Número de Documento"
                        formControlName="numeroDocumento"
                        type="text"
                        class="form-control"
                        id="numeroDocumento"
                    />
                    <ng-container *ngFor="let error of formErrores('numeroDocumento', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tipoPersona" class="form-label">Tipo de Persona</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Persona"
                        ngbPopover="Tipo de persona del número de documento."
                    ></i>
                    <ng-select
                        [items]="tiposPersona"
                        bindValue="nombre"
                        labelForId="tipoPersona"
                        formControlName="tipoPersona"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoPersona', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-template>
