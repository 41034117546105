import { BaseEntity } from '@entities/base.entity';
import { ComprobanteCompra } from '@entities/comprobante-compra.entity';
import { TipoTributo } from '@entities/tipo-tributo.entity';

export class ComprobanteCompraTributo extends BaseEntity {
    public importe?: number;
    public descripcion?: string;
    public comprobanteCompra?: ComprobanteCompra;
    public tipoTributo?: TipoTributo;

    public constructor(partial: Partial<ComprobanteCompraTributo> = {}) {
        super();
        Object.assign(this, partial);
    }
}
