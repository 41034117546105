<div class="modal-header bg-secondary-subtle z-1">
    <h4 class="modal-title">{{ titulo }}</h4>
    <span class="spinner-border mx-3" *ngIf="cargando && !botonAceptar"></span>
    <div class="flex-fill"></div>
    <button
        type="button"
        class="btn-fs me-2"
        [ngClass]="{ expand: !pantallaCompleta, contract: pantallaCompleta }"
        (click)="cambiarPantallaCompleta()"
        [ngbTooltip]="pantallaCompletaTooltip"
    >
        &ZeroWidthSpace;
    </button>
    <button type="button" class="btn-close" (click)="activeModal.dismiss('cross')">&ZeroWidthSpace;</button>
</div>
<div class="modal-body shadow-lg" [ngClass]="{ 'container-xxl': getThemeSize() === 'xxl' }">
    <ng-template *ngTemplateOutlet="formTemplate"></ng-template>
</div>
<div class="modal-footer bg-secondary-subtle z-1">
    <div class="me-auto">
        <ng-template *ngTemplateOutlet="footerTemplate"></ng-template>
    </div>
    <div *ngIf="formularioInvalido()" class="formulario-invalido">El formulario contiene campos con errores</div>
    <button *ngIf="botonAceptar" type="button" class="btn btn-primary" (click)="aceptar()" [disabled]="cargando">
        <span class="spinner-border spinner-border-sm mx-3" *ngIf="cargando"></span>
        <span *ngIf="!cargando">Aceptar</span>
    </button>
    <button type="button" class="btn btn-secondary" ngbAutofocus (click)="activeModal.close('cerrar')">Cerrar</button>
</div>
