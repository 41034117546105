<h2 class="mb-3" *ngIf="titulo">{{ titulo }}</h2>

<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
    (botonHandler)="botonHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col" [ngClass]="{ 'd-none': facturacionSimple }">
                    <label for="empresa" class="form-label">Empresa</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Empresa"
                        ngbPopover="Empresa emisora del comprobante."
                    ></i>
                    <ng-select
                        [items]="relaciones.empresas"
                        labelForId="empresa"
                        formControlName="empresa"
                        class="form-control"
                        (change)="cambiarEmpresa($event, { inicializar: true })"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('empresa', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="cliente" class="form-label">Cliente</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Cliente"
                        ngbPopover="Cliente del comprobante."
                    ></i>
                    <div class="input-group">
                        <button
                            class="input-group-text btn btn-outline-primary"
                            popoverTitle="Agregar Cliente"
                            ngbPopover="Agregar un nuevo cliente al sistema. Se abrirá una ventana para completar los datos del cliente. Una vez completados los datos, el cliente se seleccionará automáticamente."
                            (click)="agregarCliente()"
                        >
                            <i class="bi bi-person-plus-fill"></i>
                        </button>
                        <ng-select
                            [items]="relaciones.clientes"
                            bindLabel="razonSocial"
                            labelForId="cliente"
                            [searchFn]="buscarCliente"
                            [virtualScroll]="true"
                            formControlName="cliente"
                            class="form-control"
                            (change)="cambiarCliente($event)"
                        >
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                <span [ngOptionHighlight]="search">{{ item.razonSocial }}</span
                                >&NonBreakingSpace;&dash;&NonBreakingSpace;<span [ngOptionHighlight]="search"
                                    >{{ item.tipoDocumento?.nombre }} {{ item.numeroDocumento }}</span
                                >
                            </ng-template>
                        </ng-select>
                    </div>
                    <ng-container *ngFor="let error of formErrores('cliente', form, formErroresControles)">
                        <div class="invalid-feedback" [ngClass]="{ 'd-block': form.get('cliente')?.touched }">
                            {{ error }}
                        </div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="fecha" class="form-label">Fecha</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Fecha"
                        ngbPopover="Fecha del comprobante."
                    ></i>
                    <div class="input-group">
                        <input
                            placeholder="dd/mm/aaaa"
                            formControlName="fecha"
                            class="form-control"
                            id="fecha"
                            ngbDatepicker
                            #fecha="ngbDatepicker"
                            (change)="cambiarFecha($event)"
                            (dateSelect)="cambiarFecha($event)"
                        />
                        <button
                            *ngIf="form.get('fecha')?.value && !form?.get('fecha')?.disabled"
                            class="btn btn-sm btn-outline-secondary bi bi-x-lg"
                            (click)="form.get('fecha')?.setValue(null)"
                            type="button"
                        ></button>
                        <button
                            *ngIf="!form.get('fecha')?.disabled"
                            class="btn btn-outline-secondary bi bi-calendar3"
                            (click)="fecha.toggle()"
                            type="button"
                        ></button>
                        <ng-container *ngFor="let error of formErrores('fecha', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tipoComprobante" class="form-label">Tipo de Comprobante</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Comprobante"
                        ngbPopover="Tipo de comprobante."
                    ></i>
                    <span *ngIf="cargando.tipoComprobante" class="spinner-grow spinner-grow-sm"></span>
                    <ng-select
                        [items]="relaciones.tiposComprobante"
                        labelForId="tipoComprobante"
                        formControlName="tipoComprobante"
                        class="form-control"
                        (change)="cambiarTipoComprobante($event)"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoComprobante', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="puntoVenta" class="form-label">Punto de Venta</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Punto de Venta"
                        ngbPopover="Punto de venta del comprobante."
                    ></i>
                    <span *ngIf="cargando.puntoVenta" class="spinner-grow spinner-grow-sm"></span>
                    <ng-select
                        [items]="relaciones.puntosVenta"
                        bindLabel="numero"
                        labelForId="puntoVenta"
                        [searchFn]="buscarPuntoVenta"
                        formControlName="puntoVenta"
                        class="form-control"
                        (change)="cambiarPuntoVenta($event)"
                    >
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <span [ngOptionHighlight]="search">{{ item.numero }}</span
                            >&colon;&NonBreakingSpace;<span [ngOptionHighlight]="search">{{ item.nombre }}</span>
                        </ng-template>
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('puntoVenta', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="numero" class="form-label">Número</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Número"
                        ngbPopover="Número del comprobante. Debe ser único junto con el cliente, tipo de comprobante, y punto de venta."
                    ></i>
                    <span *ngIf="cargando.numero" class="spinner-grow spinner-grow-sm"></span>
                    <div class="input-group">
                        <span class="input-group-text">#</span>
                        <input
                            type="number"
                            formControlName="numero"
                            [mask]="mascaraNumero"
                            class="form-control"
                            id="numero"
                            placeholder="Número"
                        />
                        <button
                            class="btn btn-outline-primary"
                            type="button"
                            (click)="obtenerSiguienteNumeroAFIP()"
                            ngbTooltip="Obtener siguiente número según AFIP"
                            [ngClass]="{ 'd-none': facturacionInvoice }"
                        >
                            AFIP <i class="bi bi-123"></i>
                        </button>
                        <ng-container *ngFor="let error of formErrores('numero', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3" [ngClass]="{ 'd-none': facturacionInvoice }">
                <div class="col">
                    <label for="tipoDocumento" class="form-label">Tipo Documento</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Documento"
                        ngbPopover="Tipo de documento del cliente del comprobante."
                    ></i>
                    <ng-select
                        [items]="relaciones.tiposDocumento"
                        labelForId="tipoDocumento"
                        formControlName="tipoDocumento"
                        class="form-control"
                        (change)="cambiarTipoDocumento($event)"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoDocumento', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="numeroDocumento" class="form-label">Número de Documento</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Número de Documento"
                        ngbPopover="Número de documento del cliente."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text" *ngIf="nombreMascaraNumeroDocumento">{{
                            nombreMascaraNumeroDocumento
                        }}</span>
                        <input
                            placeholder="Número de Documento"
                            formControlName="numeroDocumento"
                            [mask]="mascaraNumeroDocumento"
                            [showMaskTyped]="true"
                            type="text"
                            class="form-control"
                            id="numeroDocumento"
                        />
                        <ng-container *ngFor="let error of formErrores('numeroDocumento', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3" [ngClass]="{ 'd-none': facturacionSimple || facturacionInvoice }">
                <div class="col">
                    <label for="moneda" class="form-label">Moneda</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Moneda"
                        ngbPopover="Moneda del comprobante."
                    ></i>
                    <span *ngIf="cargando.moneda" class="spinner-grow spinner-grow-sm"></span>
                    <ng-select
                        [items]="relaciones.monedas"
                        labelForId="moneda"
                        formControlName="moneda"
                        class="form-control"
                        (change)="cambiarMoneda($event)"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('moneda', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="cotizacion" class="form-label">Cotización</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Cotización"
                        ngbPopover="Cotización del comprobante en relación a la moneda oficial."
                    ></i>
                    <span *ngIf="cargando.cotizacion" class="spinner-grow spinner-grow-sm"></span>
                    <div class="input-group">
                        <span class="input-group-text">{{ monedaOficial?.simbolo ?? '$' }}</span>
                        <input
                            placeholder="Cotización"
                            formControlName="cotizacion"
                            [mask]="mascaraCotizacion"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="100000000000"
                            [outputTransformFn]="toFixedCotizacion"
                            class="form-control"
                            id="cotizacion"
                            (change)="actualizarCotizacion()"
                            [readonly]="cotizacionOficial()"
                        />
                        <button
                            class="btn btn-outline-primary"
                            type="button"
                            (click)="obtenerCotizacionAFIP()"
                            ngbTooltip="Obtener cotización según AFIP"
                        >
                            AFIP <i class="bi bi-currency-exchange"></i>
                        </button>
                        <ng-container *ngFor="let error of formErrores('cotizacion', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-3" [ngClass]="{ 'd-none': facturacionSimple || facturacionInvoice }">
                <div class="col">
                    <label for="tipoConcepto" class="form-label">Tipo de Concepto</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Concepto"
                        ngbPopover="Tipo de concepto del comprobante."
                    ></i>
                    <span *ngIf="cargando.tipoConcepto" class="spinner-grow spinner-grow-sm"></span>
                    <ng-select
                        [items]="relaciones.tiposConcepto"
                        labelForId="tipoConcepto"
                        formControlName="tipoConcepto"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoConcepto', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="tipoActividad" class="form-label">Tipo de Actividad</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Actividad"
                        ngbPopover="Tipo de actividad del comprobante."
                    ></i>
                    <span *ngIf="cargando.tipoActividad" class="spinner-grow spinner-grow-sm"></span>
                    <ng-select
                        [items]="relaciones.tiposActividad"
                        labelForId="tipoActividad"
                        formControlName="tipoActividad"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoActividad', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="idioma" class="form-label">Idioma</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Idioma"
                        ngbPopover="Idioma del comprobante."
                    ></i>
                    <span *ngIf="cargando.idioma" class="spinner-grow spinner-grow-sm"></span>
                    <ng-select
                        [items]="relaciones.idiomas"
                        labelForId="idioma"
                        formControlName="idioma"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('idioma', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3" [ngClass]="{ 'd-none': facturacionSimple || facturacionInvoice }">
                <div class="col">
                    <label for="observaciones" class="form-label">Observaciones</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Observaciones"
                        ngbPopover="Observaciones del comprobante."
                    ></i>
                    <textarea
                        maxlength="255"
                        placeholder="Observaciones"
                        formControlName="observaciones"
                        rows="3"
                        type="text"
                        class="form-control"
                        id="observaciones"
                    ></textarea>
                </div>
            </div>
        </div>
    </form>
    <div ngbNav #nav="ngbNav" class="nav-tabs mt-3 mx-n3 px-3" [destroyOnHide]="false">
        <ng-container ngbNavItem="items">
            <button ngbNavLink>Items</button>
            <ng-template ngbNavContent #itemsTemplate>
                <kratos-comprobante-venta-item #comprobanteVentaItem></kratos-comprobante-venta-item>
                <ng-container *ngFor="let error of formErrores('items', form, formErroresControles)">
                    <div class="invalid-feedback">{{ error }}</div>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem="mediosPago">
            <button ngbNavLink [ngClass]="{ 'd-none': facturacionInvoice }">Medios de Pago</button>
            <ng-template ngbNavContent #mediosPagoTemplate>
                <kratos-comprobante-venta-medio-pago #comprobanteVentaMedioPago></kratos-comprobante-venta-medio-pago>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem="tributos">
            <button ngbNavLink [ngClass]="{ 'd-none': facturacionInvoice }">Tributos</button>
            <ng-template ngbNavContent #tributosTemplate>
                <kratos-comprobante-venta-tributo #comprobanteVentaTributo></kratos-comprobante-venta-tributo>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem="datosOpcionales">
            <button ngbNavLink [ngClass]="{ 'd-none': facturacionInvoice }">Datos Opcionales</button>
            <ng-template ngbNavContent #datosOpcionalesTemplate>
                <kratos-comprobante-venta-dato-opcional
                    #comprobanteVentaDatoOpcional
                ></kratos-comprobante-venta-dato-opcional>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem="comprobantesAsociados">
            <button ngbNavLink>Comprobantes Asociados</button>
            <ng-template ngbNavContent #comprobantesAsociadosTemplate>
                <kratos-comprobante-venta-comprobante-asociado
                    #comprobanteVentaComprobanteAsociado
                ></kratos-comprobante-venta-comprobante-asociado>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem="transacciones">
            <button ngbNavLink [ngClass]="{ 'd-none': facturacionInvoice }">Transacciones</button>
            <ng-template ngbNavContent #transaccionesTemplate>
                <kratos-comprobante-transaccion #comprobanteTransaccion></kratos-comprobante-transaccion>
            </ng-template>
        </ng-container>
    </div>
    <div [ngbNavOutlet]="nav" class="pt-3"></div>
</ng-template>

<ng-template #formConsultarUltimoComprobanteTemplate>
    <form [formGroup]="formConsultarUltimoComprobante" #formConsultarUltimoComprobanteElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="empresa" class="form-label">Empresa</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Empresa"
                        ngbPopover="Empresa emisora del comprobante."
                    ></i>
                    <ng-select
                        [items]="relacionesConsultarUltimoComprobante.empresas"
                        labelForId="empresa"
                        formControlName="empresa"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container
                        *ngFor="
                            let error of formErrores(
                                'empresa',
                                formConsultarUltimoComprobante,
                                formConsultarUltimoComprobanteErroresControles
                            )
                        "
                    >
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tipoComprobante" class="form-label">Tipo de Comprobante</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Tipo de Comprobante"
                        ngbPopover="Tipo de comprobante."
                    ></i>
                    <ng-select
                        [items]="relacionesConsultarUltimoComprobante.tiposComprobante"
                        labelForId="tipoComprobante"
                        formControlName="tipoComprobante"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container
                        *ngFor="
                            let error of formErrores(
                                'tipoComprobante',
                                formConsultarUltimoComprobante,
                                formConsultarUltimoComprobanteErroresControles
                            )
                        "
                    >
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="puntoVenta" class="form-label">Punto de Venta</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Punto de Venta"
                        ngbPopover="Punto de venta del comprobante."
                    ></i>
                    <ng-select
                        [items]="relacionesConsultarUltimoComprobante.puntosVenta"
                        bindLabel="numero"
                        labelForId="puntoVenta"
                        [searchFn]="buscarPuntoVenta"
                        formControlName="puntoVenta"
                        class="form-control"
                    >
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <span [ngOptionHighlight]="search">{{ item.numero }}</span
                            >&colon;&NonBreakingSpace;<span [ngOptionHighlight]="search">{{ item.nombre }}</span>
                        </ng-template>
                    </ng-select>
                    <ng-container
                        *ngFor="
                            let error of formErrores(
                                'puntoVenta',
                                formConsultarUltimoComprobante,
                                formConsultarUltimoComprobanteErroresControles
                            )
                        "
                    >
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #footerTemplate>
    <div class="d-flex gap-3">
        <div class="form-check form-switch">
            <label for="facturacionSimple" class="form-check-label">Facturación Simple</label
            ><i
                class="instrucciones-pantalla bi bi-question-circle"
                popoverTitle="Facturación Simple"
                ngbPopover="Habilita la facturación simple."
            ></i>
            <input
                placeholder="Facturación Simple"
                [(ngModel)]="facturacionSimple"
                [ngModelOptions]="{ standalone: true }"
                (change)="cambiarFacturacionSimple()"
                type="checkbox"
                class="form-check-input me-1"
                id="facturacionSimple"
            />
        </div>
        <div class="form-check form-switch" [ngClass]="{ 'd-none': monedaOficialAFIP() }">
            <label for="facturacionInvoice" class="form-check-label">Formato de Invoices</label
            ><i
                class="instrucciones-pantalla bi bi-question-circle"
                popoverTitle="Formato de Invoices"
                ngbPopover="Habilita el formato de invoices."
            ></i>
            <input
                placeholder="Formato de Invoices"
                [(ngModel)]="facturacionInvoice"
                [ngModelOptions]="{ standalone: true }"
                (change)="cambiarFacturacionInvoice()"
                type="checkbox"
                class="form-check-input me-1"
                id="facturacionInvoice"
            />
        </div>
    </div>
</ng-template>

<kratos-cliente #clienteComponent *ngIf="agregandoCliente" [inicializarTabla]="false"></kratos-cliente>
