export enum TipoDocumentoDenominacionEnum {
    DNI = 'DNI',
    CUIT_CUIL = 'CUIT CUIL',
}

export const tipoDocumentoDenominacionIdPorKeyValue = (tipoDocumentoDenominacion: any) => {
    let id = null;
    switch (tipoDocumentoDenominacion) {
        case 0:
        case 'DNI':
            id = 0;
            break;
        case 1:
        case 'CUIT_CUIL':
            id = 1;
            break;
    }
    return id;
};

export const obtenerTipoDocumentoDenominaciones = () => [
    { id: 0, clave: 'DNI', nombre: 'DNI', mascara: '00.000.000' },
    { id: 1, clave: 'CUIT_CUIL', nombre: 'CUIT CUIL', mascara: '00-00000000-0' },
];
