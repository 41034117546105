import { EmpresaService } from '@administracion/services/empresa.service';
import { Component } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { ThemeService } from '@core/services/theme.service';
import { Empresa } from '@entities/empresa.entity';
import { RolUsuarioEnum } from '@entities/enums/usuario-rol.enum';
import { Moneda } from '@entities/moneda.entity';
import { Usuario } from '@entities/usuario.entity';
import { AutoUnsubscribe } from '@helpers/auto-unsubscribe.decorator';
import { ToastService } from '@shared/services/toast.service';
import { MonedaService } from '@ventas/services/moneda.service';
import { Subscription, finalize, forkJoin } from 'rxjs';

@Component({
    selector: 'kratos-perfil',
    templateUrl: './perfil.component.html',
    styleUrls: ['./perfil.component.scss'],
})
@AutoUnsubscribe
export class PerfilComponent {
    private subscription = new Subscription();

    public usuarioActual: Usuario = {};
    public monedaActual!: Moneda;
    public monedas: Moneda[] = [];
    public empresaActual!: Empresa;
    public empresas: Empresa[] = [];
    public cargando = true;
    public administrador: boolean;
    public facturacionSimple: boolean;
    public siguienteNumeroAFIP: boolean;
    public generarCAE: boolean;

    public constructor(
        public authService: AuthService,
        private toastService: ToastService,
        private themeService: ThemeService,
        private monedaService: MonedaService,
        private empresaService: EmpresaService,
    ) {
        const id = JSON.parse(localStorage.getItem('usuario') ?? '{}').id;
        this.administrador = JSON.parse(localStorage.getItem('usuario') ?? '{}').rol === RolUsuarioEnum.ADMINISTRADOR;
        this.facturacionSimple = localStorage.getItem('facturacionSimple') === 'true';
        this.siguienteNumeroAFIP = localStorage.getItem('siguienteNumeroAFIP') === 'true';
        this.generarCAE = localStorage.getItem('generarCAE') === 'true';
        const empresaLocalStorage = localStorage.getItem('empresa');
        // Si es administrador, obtener todas las empresas
        if (this.administrador) {
            localStorage.removeItem('empresa');
        }
        this.subscription.add(
            forkJoin({
                usuario: this.authService.getUserProfile(id),
                monedas: this.monedaService.obtenerTodos(),
                empresas: this.empresaService.obtenerTodos(),
            })
                .pipe(finalize(() => (this.cargando = false)))
                .subscribe({
                    next: ({ usuario, monedas, empresas }) => {
                        this.usuarioActual = usuario;
                        this.monedas = monedas?.elementos || [];
                        if (this.administrador) {
                            this.empresas = empresas?.elementos || [];
                        } else {
                            this.empresas = usuario?.empresas || [];
                        }
                        // Seleccionar la moneda actual almacenada en el localStorage
                        const moneda = JSON.parse(localStorage.getItem('moneda') ?? '{}');
                        if (moneda?.id) {
                            this.monedaActual = moneda;
                        }
                        // Seleccionar la empresa actual almacenada en el localStorage
                        if (empresaLocalStorage) {
                            localStorage.setItem('empresa', empresaLocalStorage);
                        }
                        const empresa = JSON.parse(localStorage.getItem('empresa') ?? '{}');
                        if (empresa?.id && this.empresas.find((e) => e.id === empresa.id)) {
                            this.empresaActual = empresa;
                        }
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
        );
    }

    public getThemeHelpEnabled(): boolean {
        return this.themeService.currentHelp === 'true';
    }

    public buscarMoneda = (term: string, item: Moneda) => {
        term = term.toLocaleLowerCase();
        return (
            (item?.abreviatura?.toLocaleLowerCase().indexOf(term) ?? -1) > -1 ||
            (item?.nombre?.toLocaleLowerCase().indexOf(term) ?? -1) > -1
        );
    };

    public cambiarMoneda(moneda: Moneda): void {
        // Si la moneda es undefined, remover la moneda del localStorage
        if (!moneda) {
            localStorage.removeItem('moneda');
            return;
        }
        localStorage.setItem('moneda', JSON.stringify(moneda));
    }

    public cambiarEmpresa(empresa: Empresa): void {
        // Si la empresa es undefined, remover la empresa del localStorage
        if (!empresa) {
            localStorage.removeItem('empresa');
            return;
        }
        localStorage.setItem('empresa', JSON.stringify(empresa));
    }

    public cambiarFacturacionSimple(): void {
        localStorage.setItem('facturacionSimple', `${this.facturacionSimple}`);
    }

    public cambiarSiguienteNumeroAFIP(): void {
        localStorage.setItem('siguienteNumeroAFIP', `${this.siguienteNumeroAFIP}`);
    }

    public cambiarGenerarCAE(): void {
        localStorage.setItem('generarCAE', `${this.generarCAE}`);
    }
}
