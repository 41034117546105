import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Usuario } from 'src/domain/entities/usuario.entity';
import { BaseService } from '../../shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class UsuarioService extends BaseService<Usuario> {
    public constructor(http: HttpClient) {
        super('administracion/usuario', http);
    }
}
