export enum TipoPersonaEnum {
    PERSONA_JURIDICA = 'Persona Jurídica',
    PERSONA_FISICA = 'Persona Física',
    PERSONA_OTROS = 'Otros',
}

export const tipoPersonaIdPorKeyValue = (tipoPersona: any) => {
    let id = null;
    switch (tipoPersona) {
        case 0:
        case 'PERSONA_JURIDICA':
            id = 0;
            break;
        case 1:
        case 'PERSONA_FISICA':
            id = 1;
            break;
        case 2:
        case 'PERSONA_OTROS':
            id = 2;
            break;
    }
    return id;
};

export const obtenerTiposPersona = () => [
    { id: 0, clave: 'PERSONA_JURIDICA', nombre: 'Persona Jurídica' },
    { id: 1, clave: 'PERSONA_FISICA', nombre: 'Persona Física' },
    { id: 2, clave: 'PERSONA_OTROS', nombre: 'Otros' },
];
