<!-- Agregar formulario con los datos básicos para carga rápida de items, sin necesidad de utilizar un modal -->
<form [formGroup]="formCargaRapida" #formCargaRapidaElement class="mb-3">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col">
                <div class="input-group">
                    <span class="input-group-text">Motivo</span>
                    <ng-select
                        [items]="relaciones.motivosCompra"
                        labelForId="motivoCompra"
                        formControlName="motivoCompra"
                        class="form-control"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col">
                <div class="input-group">
                    <span class="input-group-text">IVA</span>
                    <ng-select
                        [items]="relaciones.tiposAlicuotaIVA"
                        labelForId="tipoAlicuotaIVA"
                        formControlName="tipoAlicuotaIVA"
                        class="form-control"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col">
                <div class="input-group">
                    <span class="input-group-text">{{ moneda.simbolo }}</span>
                    <div class="form-floating">
                        <input
                            placeholder="Importe Neto"
                            formControlName="precioUnitario"
                            [mask]="mascaraImporte"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="1000000000000000"
                            [outputTransformFn]="toFixedImporte"
                            class="form-control"
                            id="precioUnitario"
                        />
                        <label for="precioUnitario" class="form-label">Importe Neto</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <button
                    class="btn btn-primary"
                    popoverTitle="Agregar Item"
                    ngbPopover="Agregar item a la tabla de items del comprobante de venta. Se utilizarán los datos ingresados en los campos de carga rápida."
                    (click)="agregarItemCargaRapida()"
                    type="button"
                >
                    Agregar Item
                </button>
            </div>
        </div>
    </div>
</form>

<div class="mx-n3 border-bottom mb-3"></div>

<kratos-tabla
    #tabla
    [opciones]="tablaOpciones"
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="motivoCompra" class="form-label">Motivo</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Motivo"
                        ngbPopover="Motivo de la compra."
                    ></i>
                    <ng-select
                        [items]="relaciones.motivosCompra"
                        labelForId="motivoCompra"
                        formControlName="motivoCompra"
                        class="form-control"
                        (change)="cambiarMotivoCompra($event)"
                    >
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <span [ngOptionHighlight]="search">{{ item.nombre }}</span>
                        </ng-template>
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('motivoCompra', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="descripcion" class="form-label">Descripción</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Descripción"
                        ngbPopover="Descripción del item."
                    ></i>
                    <textarea
                        maxlength="255"
                        placeholder="Descripción"
                        formControlName="descripcion"
                        rows="3"
                        type="text"
                        class="form-control"
                        id="descripcion"
                    ></textarea>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="tipoAlicuotaIVA" class="form-label">Alícuota IVA</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Alícuota de IVA"
                        ngbPopover="Alícuota de IVA del item."
                    ></i>
                    <ng-select
                        [items]="relaciones.tiposAlicuotaIVA"
                        labelForId="tipoAlicuotaIVA"
                        formControlName="tipoAlicuotaIVA"
                        class="form-control"
                    >
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('tipoAlicuotaIVA', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="unidadMedida" class="form-label">Unidad de Medida</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Unidad de Medida"
                        ngbPopover="Unidad de medida del item."
                    ></i>
                    <ng-select
                        [items]="relaciones.unidadesMedida"
                        labelForId="unidadMedida"
                        [searchFn]="buscarUnidadMedida"
                        formControlName="unidadMedida"
                        class="form-control"
                    >
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <span [ngOptionHighlight]="search">{{ item.nombre }}</span
                            >&NonBreakingSpace;<small [ngOptionHighlight]="search"
                                >&#40;{{ item.abreviatura }}&#41;</small
                            >
                        </ng-template>
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('unidadMedida', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="cantidad" class="form-label">Cantidad</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Cantidad"
                        ngbPopover="Cantidad del item."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">#</span>
                        <input
                            placeholder="Cantidad"
                            formControlName="cantidad"
                            [mask]="mascaraImporte"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="1000000000000000"
                            [outputTransformFn]="toFixedImporte"
                            class="form-control"
                            id="cantidad"
                        />
                        <span class="input-group-text" *ngIf="unidadMedidaAbreviatura">{{
                            unidadMedidaAbreviatura
                        }}</span>
                        <ng-container *ngFor="let error of formErrores('cantidad', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="col">
                    <label for="precioUnitario" class="form-label">Precio Unitario</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Precio Unitario"
                        ngbPopover="Precio unitario del item."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">{{ moneda.simbolo }}</span>
                        <input
                            placeholder="Precio Unitario"
                            formControlName="precioUnitario"
                            [mask]="mascaraImporte"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="1000000000000000"
                            [outputTransformFn]="toFixedImporte"
                            class="form-control"
                            id="precioUnitario"
                        />
                        <ng-container *ngFor="let error of formErrores('precioUnitario', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
