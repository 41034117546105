import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth/auth.guard';
import { MigracionHermesComponent } from '@migracion/components/migracion-hermes.component';
import { MigracionSIComponent } from '@migracion/components/migracion-si.component';
import { MigracionTareaComponent } from '@migracion/components/migracion-tarea.component';

const routes: Routes = [
    {
        path: '',
        title: 'Migración',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'migracion-tarea',
                title: 'Tarea de Migración',
                component: MigracionTareaComponent,
            },
            {
                path: 'migracion-hermes',
                title: 'Migración Hermes',
                component: MigracionHermesComponent,
            },
            {
                path: 'migracion-si',
                title: 'Migración Simple Invoices',
                component: MigracionSIComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MigracionRoutingModule {}
