import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'kratos-modal-confirmacion',
    templateUrl: './modal-confirmacion.component.html',
    styleUrls: ['./modal-confirmacion.component.scss'],
})
export class ModalConfirmacionComponent {
    @Input() public titulo = 'Kratos Modal Confirmación';
    public mensaje = '¿Está seguro que desea realizar esta acción?';
    public advertencia = false;
    public cargando = false;

    public constructor(public activeModal: NgbActiveModal) {}

    @Output() public aceptarHandler = new EventEmitter();

    public aceptar(): void {
        this.aceptarHandler.emit();
    }
}
