import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CentroCosto } from '@entities/centro-costo.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class CentroCostoService extends BaseService<CentroCosto> {
    public constructor(http: HttpClient) {
        super('compras/centro-costo', http);
    }
}
