import { AdministracionRoutingModule } from '@administracion/administracion.routing.module';
import { EmpresaComponent } from '@administracion/components/empresa.component';
import { ParametroComponent } from '@administracion/components/parametro.component';
import { PerfilComponent } from '@administracion/components/perfil.component';
import { UsuarioComponent } from '@administracion/components/usuario.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [EmpresaComponent, UsuarioComponent, ParametroComponent, PerfilComponent],
    imports: [SharedModule, AdministracionRoutingModule],
})
export class AdministracionModule {}
