import { Component, ElementRef, EventEmitter, Output, Renderer2, TemplateRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ThemeService } from '@core/services/theme.service';
import { Cliente } from '@entities/cliente';
import { ComprobanteVentaItem } from '@entities/comprobante-venta-item.entity';
import { ComprobanteVentaSaldo } from '@entities/comprobante-venta-saldo.entity';
import { RespuestaDTO } from '@entities/dtos/respuesta.dto';
import { Moneda } from '@entities/moneda.entity';
import { ComprobanteVentaView } from '@entities/views/comprobante-venta.view';
import { AutoUnsubscribe } from '@helpers/auto-unsubscribe.decorator';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmacionComponent } from '@shared/components/modal-confirmacion/modal-confirmacion.component';
import { ModalFormularioComponent } from '@shared/components/modal-formulario/modal-formulario.component';
import { BaseService } from '@shared/services/base.service';
import { ToastService } from '@shared/services/toast.service';
import { ComprobanteVentaComponent } from '@ventas/components/comprobante-venta.component';
import { ComprobanteVentaService, ComprobanteVentaViewService } from '@ventas/services/comprobante-venta.service';
import Big from 'big.js';
import { Subscription, finalize, of } from 'rxjs';

@Component({
    selector: 'kratos-comprobante-venta-comprobante-asociado',
    templateUrl: './comprobante-venta-comprobante-asociado.component.html',
    styleUrls: ['./comprobante-venta-comprobante-asociado.component.scss'],
})
@AutoUnsubscribe
export class ComprobanteVentaComprobanteAsociadoComponent {
    protected subscription = new Subscription();
    protected toastService = inject(ToastService);
    protected modalService = inject(NgbModal);
    protected themeService = inject(ThemeService);
    protected renderer = inject(Renderer2);

    @ViewChild('comprobanteVentaBusquedaTemplate') public comprobanteVentaBusquedaTemplate!: TemplateRef<any>;
    @ViewChild('formCambiarSaldoTemplate') public formCambiarSaldoTemplate!: TemplateRef<any>;
    @ViewChild('formCambiarSaldoElement') public formCambiarSaldoElement!: ElementRef<any>;

    @Output() public postAltaEvent: EventEmitter<any> = new EventEmitter();
    @Output() public postBajaEvent: EventEmitter<any> = new EventEmitter();
    @Output() public postModificacionEvent: EventEmitter<any> = new EventEmitter();

    public elementos: ComprobanteVentaSaldo[] = [];
    public elementosView: ComprobanteVentaView[] = [];
    public mascaraImporte = 'separator.2';
    public moneda: Moneda = { simbolo: '$', cotizacion: +1.0 };

    public get cliente(): Cliente | undefined {
        return this._cliente;
    }

    public set cliente(value: Cliente | undefined) {
        this._cliente = value;
        this.comprobanteVenta.tabla.habilitarBoton('buscarComprobante', !!value);
    }

    // Servicio volátil para utilizar elementos en memoria
    public baseService = {
        eliminar: (id: number) => {
            this.elementos = this.elementos.filter((e) => e.comprobanteVentaAsociado?.id !== id);
            this.elementosView = this.elementosView.filter((e) => e.id !== id);
            return of(null);
        },
    } as BaseService<ComprobanteVentaView>;

    private comprobanteVenta!: ComprobanteVentaComponent;
    private nombre = 'Comprobante de Venta';
    private genero = 'o';
    private modalRefBuscar!: NgbModalRef;
    private _cliente: Cliente | undefined;

    protected formCambiarSaldoErroresControles = {};

    public formCambiarSaldo = this.formBuilder.group({
        importe: [''],
    });

    public constructor(
        private comprobanteVentaViewService: ComprobanteVentaViewService,
        private comprobanteVentaService: ComprobanteVentaService,
        private formBuilder: FormBuilder,
    ) {}

    public recargar(): void {
        this.comprobanteVenta?.tabla?.recargar();
    }

    public comprobanteVentaInit(componente: ComprobanteVentaComponent | any): void {
        this.comprobanteVenta = componente;
        // Desactivar el filtrado de la columna Id
        const columnaIdIndex = componente.columnas.findIndex((c: any) => c.data === 'id');
        if (columnaIdIndex >= 0) {
            componente.columnas[columnaIdIndex].searchable = false;
        }
        // Configurar tabla (acciones)
        componente.acciones = [
            {
                nombre: 'consulta',
                icono: 'search',
                tooltip: `Consultar`,
            },
            {
                nombre: 'modificacion',
                icono: 'pencil-fill',
                tooltip: `Cambiar Importe Saldado`,
            },
            {
                nombre: 'baja',
                icono: 'unlink',
                color: 'warning',
                tooltip: `Remover Comprobante`,
            },
        ];
        // Configurar tabla (botones)
        componente.botones = [
            {
                name: 'alta',
                text: `Buscar Comprobante`,
            },
        ];
        // Configurar componente de comprobante de venta
        componente.nombre = this.nombre;
        componente.titulo = '';
        if (componente.tablaOpciones.botones) {
            componente.tablaOpciones.botones.size = 'sm';
        } else {
            componente.tablaOpciones = {
                ...componente.tablaOpciones,
                botones: {
                    ...componente.tablaOpciones?.botones,
                    size: 'sm',
                },
            };
        }
        // Agregar columna 'Importe Saldado' a la tabla
        componente.columnas.push({
            title: 'Importe Saldado',
            data: 'importeSaldado',
            searchable: false,
            orderable: false,
            render: (data: any, type: any, row: any) => {
                return `${this.moneda.simbolo} ${Big(data)
                    .toNumber()
                    .toLocaleString('es-ES', { minimumFractionDigits: 2 })}`;
            },
        });
        // Sobreescribir método de inicialización de datos (cargar comprobantes de venta asociados)
        componente.alta = this.buscarComprobante.bind(this);
        // Sobreescribir método de eliminación de datos (remover comprobantes de venta asociados)
        componente.baja = this.baja.bind(this);
        // Sobreescribir método de modificación de datos (cambiar importe saldado)
        componente.modificacion = this.cambiarSaldo.bind(this);
        // Sobreescribir método de consulta de datos (para tabla)
        componente.ajaxHandler = (event: any, service = this.comprobanteVentaViewService) => {
            const callback = event.callback;
            const parametros = {
                ...event.dataTablesParameters,
            };
            // Agregar a la columna 'Id' el arreglo de todos los ids de this.elementos como filtro
            const columnaIdIndex = parametros.columns.findIndex((c: any) => c.data === 'id');
            const columnaIdSearch = {
                regex: false,
                value: this.elementos.map((e) => e.comprobanteVentaAsociado?.id),
            };
            // Si no hay elementos asociados, saltear búsqueda
            if (!columnaIdSearch.value.length) {
                callback({
                    recordsTotal: 0,
                    recordsFiltered: 0,
                    data: [],
                });
                return;
            }
            if (columnaIdIndex >= 0) {
                parametros.columns[columnaIdIndex].searchable = true;
                parametros.columns[columnaIdIndex].search = columnaIdSearch;
            } else {
                parametros.columns.push({
                    name: 'Id',
                    data: 'id',
                    searchable: true,
                    search: columnaIdSearch,
                });
            }
            this.subscription.add(
                service.obtenerTodos(parametros).subscribe({
                    next: (resp: RespuestaDTO<ComprobanteVentaView>) => {
                        // Establecer el saldo de los comprobantes de venta asociados: buscar el importe en this.elementos
                        if (resp.elementos) {
                            const cotizacion = 1 / (this.moneda.cotizacion ?? +1.0);
                            resp.elementos.forEach((elemento) => {
                                const comprobanteVentaAsociado = this.elementos.find(
                                    (e) => e.comprobanteVentaAsociado?.id === elemento.id,
                                );
                                (elemento as any).importeSaldado = this.convertirImporte(
                                    comprobanteVentaAsociado?.importe ?? +0.0,
                                    cotizacion,
                                );
                            });
                        }
                        callback({
                            recordsTotal: resp.cantidadTotal,
                            recordsFiltered: resp.cantidadFiltrada,
                            data: resp.elementos,
                        });
                    },
                    error: (error: any) => {
                        callback({
                            recordsTotal: 0,
                            recordsFiltered: 0,
                            data: [],
                        });
                        this.toastService.errorHandler(error);
                    },
                }),
            );
        };
    }

    public comprobanteVentaAfterViewInit(componente: ComprobanteVentaComponent | any): void {
        if (!componente) {
            return;
        }
        // Configurar componente de comprobante de venta
        this.subscription.add(
            this.comprobanteVenta.tabla.init.subscribe(() => {
                this.comprobanteVenta.tabla.habilitarBoton('buscarComprobante', false);
            }),
        );
    }

    public comprobanteVentaBusquedaInit(componente: ComprobanteVentaComponent | any): void {
        if (!componente) {
            return;
        }
        // Configurar componente de búsqueda de comprobantes de venta
        componente.titulo = '';
        componente.acciones = [
            {
                nombre: 'seleccionarComprobante',
                icono: 'check2',
                tooltip: `Seleccionar`,
                color: 'success',
            },
            {
                nombre: 'consulta',
                icono: 'search',
                tooltip: `Consultar`,
            },
        ];
        componente.botones = [];
        // Agregar método de selección de comprobantes de venta
        (componente as any).seleccionarComprobante = this.seleccionarComprobante.bind(this);
        componente.ajaxHandler = (event: any, service = this.comprobanteVentaViewService) => {
            const callback = event.callback;
            const parametros = {
                ...event.dataTablesParameters,
            };
            // Agregar a la columna 'Id' el arreglo de todos los ids de this.elementos como filtro
            const columnaIdIndex = parametros.columns.findIndex((c: any) => c.data === 'id');
            const columnaIdSearch = {
                not: true,
                regex: false,
                value: this.elementos.map((e) => e.comprobanteVentaAsociado?.id),
            };
            if (columnaIdIndex >= 0) {
                parametros.columns[columnaIdIndex].searchable = true;
                parametros.columns[columnaIdIndex].search = columnaIdSearch;
            } else {
                parametros.columns.push({
                    name: 'Id',
                    data: 'id',
                    searchable: true,
                    search: columnaIdSearch,
                });
            }
            const columnaClienteIndex = parametros.columns.findIndex((c: any) => c.data === 'clienteRazonSocial');
            const columnaClienteSearch = {
                regex: false,
                value: this.cliente?.razonSocial ?? '',
            };
            if (columnaClienteIndex >= 0) {
                parametros.columns[columnaClienteIndex].searchable = true;
                parametros.columns[columnaClienteIndex].search = columnaClienteSearch;
            } else {
                parametros.columns.push({
                    name: 'Cliente',
                    data: 'clienteRazonSocial',
                    searchable: true,
                    search: columnaClienteSearch,
                });
            }
            const columnaSaldoIndex = parametros.columns.findIndex((c: any) => c.data === 'saldo');
            const columnaSaldoSearch = {
                not: true,
                regex: false,
                value: 0,
                exact: true,
            };
            if (columnaSaldoIndex >= 0) {
                parametros.columns[columnaSaldoIndex].searchable = true;
                parametros.columns[columnaSaldoIndex].search = columnaSaldoSearch;
            } else {
                parametros.columns.push({
                    name: 'Saldo',
                    data: 'saldo',
                    searchable: true,
                    search: columnaSaldoSearch,
                });
            }
            this.subscription.add(
                service.obtenerTodos(parametros).subscribe({
                    next: (resp: RespuestaDTO<ComprobanteVentaView>) => {
                        callback({
                            recordsTotal: resp.cantidadTotal,
                            recordsFiltered: resp.cantidadFiltrada,
                            data: resp.elementos,
                        });
                    },
                    error: (error: any) => {
                        callback({
                            recordsTotal: 0,
                            recordsFiltered: 0,
                            data: [],
                        });
                        this.toastService.errorHandler(error);
                    },
                }),
            );
        };
    }

    public getThemeHelpEnabled(): boolean {
        return this.themeService.currentHelp === 'true';
    }

    public toFixed(value: string | number | undefined | null, decimales = 2): number {
        const formattedValue = String(value).split(' ').join('');
        if (String(value).includes('.') && String(value).split('.').length === 2) {
            const decimal = String(value).split('.')[1]?.length;
            if (decimal && decimal > decimales) {
                return Number(parseFloat(formattedValue).toFixed(decimales));
            }
        }
        return Number(formattedValue);
    }

    public toFixedImporte = (value: string | number | undefined | null): number => {
        return this.toFixed(value, 2);
    };

    public formErrores(control: string, form: FormGroup, formErroresControles: any): string[] {
        if (!control || !form || !form.get(control)) {
            return [];
        }
        const erroresControl = form.get(control)?.errors;
        if (!erroresControl) {
            return [];
        }
        const mensajes: { [key: string]: string } = {
            ngbDate: {
                invalid: 'El formato de la fecha es inválido',
            },
            required: 'Este campo es requerido',
            email: 'El formato del email es inválido',
            mask: 'El formato del número es inválido',
            ...formErroresControles[control],
        };
        return this.obtenerErrores(mensajes, erroresControl);
    }

    protected obtenerErrores(mensajes: { [x: string]: any }, erroresControl: { [x: string]: any }) {
        let errores: string[] = [];
        Object.keys(erroresControl).forEach((key) => {
            if (mensajes[key] && typeof mensajes[key] === 'string') {
                errores.push(mensajes[key]);
            }
            if (mensajes[key] && typeof mensajes[key] === 'object') {
                errores = errores.concat(this.obtenerErrores(mensajes[key], erroresControl[key]));
            }
        });
        return errores;
    }

    protected formValidar(form: FormGroup, formElement: ElementRef<any>) {
        this.renderer.removeClass(formElement.nativeElement, 'was-validated');
        Object.keys(form.controls).forEach((key) => {
            form.get(key)?.markAsTouched();
            form.get(key)?.updateValueAndValidity();
        });
        this.renderer.addClass(formElement.nativeElement, 'was-validated');
    }

    protected generoElemento(texto?: string): string {
        if (this.genero) {
            return this.genero;
        }

        const nombre = texto ?? this.nombre;
        return nombre?.charAt(nombre?.length - 1) === 'a' ? 'a' : 'o';
    }

    private convertirImporte(importe: number, cotizacion = +1.0): number {
        return Big(importe).times(Big(cotizacion)).round(2).toNumber();
    }

    private buscarComprobante(): void {
        this.modalRefBuscar = this.modalService.open(ModalFormularioComponent, {
            size: 'xl',
            centered: true,
        });
        this.modalRefBuscar.componentInstance.formTemplate = this.comprobanteVentaBusquedaTemplate;
        this.modalRefBuscar.componentInstance.titulo = `Buscar Comprobante de Venta`;
    }

    private seleccionarComprobante(comprobanteVentaSeleccionado: ComprobanteVentaView): void {
        const modalRef = this.modalService.open(ModalConfirmacionComponent, {
            centered: true,
        });
        modalRef.componentInstance.titulo = `Asociar ${this.nombre}: ID ${comprobanteVentaSeleccionado.id}`;
        modalRef.componentInstance.mensaje = '¿Está seguro que desea asociar este comprobante?';
        this.subscription.add(
            modalRef.componentInstance.aceptarHandler.subscribe(() => {
                if (!comprobanteVentaSeleccionado || !comprobanteVentaSeleccionado.id) {
                    return;
                }
                this.subscription.add(
                    this.comprobanteVentaService
                        .obtener(comprobanteVentaSeleccionado.id)
                        .subscribe((comprobanteVenta) => {
                            const cotizacion =
                                1 / ((comprobanteVenta.cotizacion ?? +1.0) / (this.moneda.cotizacion ?? +1.0));
                            const importe = Math.abs(
                                this.convertirImporte(comprobanteVentaSeleccionado.saldo ?? +0.0, cotizacion),
                            );
                            // Convertir precios unitarios de los items del comprobante de venta
                            for (let i = 0; i < (comprobanteVenta.items || []).length; i++) {
                                if (!comprobanteVenta.items || !comprobanteVenta.items[i]) {
                                    continue;
                                }
                                const item = new ComprobanteVentaItem(comprobanteVenta.items[i]);
                                if (item) {
                                    item.precioUnitario = this.convertirImporte(
                                        item.precioUnitario ?? +0.0,
                                        cotizacion,
                                    );
                                    item.recalcularImportes();
                                    comprobanteVenta.items[i] = item;
                                }
                            }
                            const comprobanteVentaAsociado = new ComprobanteVentaSaldo();
                            comprobanteVentaAsociado.comprobanteVentaAsociado = comprobanteVenta;
                            comprobanteVentaAsociado.importe = importe;
                            this.elementos.push(comprobanteVentaAsociado);
                            this.toastService.successHandler(
                                `${this.nombre}, ID ${comprobanteVenta.id}, asociad${this.generoElemento()} con éxito`,
                            );
                            modalRef.close('aceptar');
                            this.modalRefBuscar?.close('aceptar');
                            this.recargar();
                            this.postAltaEvent.emit(comprobanteVentaAsociado);
                        }),
                );
            }),
        );
    }

    private baja(elemento: ComprobanteVentaView) {
        if (!elemento || !elemento.id) {
            return;
        }
        const modalRef = this.modalService.open(ModalConfirmacionComponent, {
            centered: true,
        });
        modalRef.componentInstance.titulo = `Remover ${this.nombre}: ID ${elemento.id}`;
        modalRef.componentInstance.mensaje = '¿Está seguro que desea remover este registro?';
        this.subscription.add(
            modalRef.componentInstance.aceptarHandler.subscribe(() => {
                if (!elemento || !elemento.id) {
                    return;
                }
                modalRef.componentInstance.cargando = true;
                this.subscription.add(
                    this.baseService
                        .eliminar(elemento.id)
                        .pipe(finalize(() => (modalRef.componentInstance.cargando = false)))
                        .subscribe({
                            next: () => {
                                this.toastService.successHandler(
                                    `${this.nombre}, ID ${elemento.id}, removid${this.generoElemento()} con éxito`,
                                );
                                modalRef.close('aceptar');
                                this.recargar();
                                this.postBajaEvent.emit(elemento);
                            },
                            error: this.toastService.errorHandler.bind(this.toastService),
                        }),
                );
            }),
        );
    }

    private cambiarSaldo(elemento: ComprobanteVentaView) {
        const comprobanteVentaAsociadoIndex = this.elementos.findIndex(
            (e) => e.comprobanteVentaAsociado?.id === elemento?.id,
        );
        if (comprobanteVentaAsociadoIndex < 0) {
            return;
        }
        const modalRef = this.modalService.open(ModalFormularioComponent, {
            centered: true,
        });
        modalRef.componentInstance.formTemplate = this.formCambiarSaldoTemplate;
        modalRef.componentInstance.form = this.formCambiarSaldo;
        modalRef.componentInstance.titulo = `Cambiar Importe Saldado de ${this.nombre}: ID ${elemento?.id}`;
        modalRef.componentInstance.botonAceptar = true;
        const cotizacion = 1 / (this.moneda.cotizacion ?? +1.0);
        const importeAsociado = this.convertirImporte(
            this.elementos[comprobanteVentaAsociadoIndex].importe ?? Math.abs(elemento.saldo ?? +0.0),
            cotizacion,
        );
        this.formCambiarSaldo.reset();
        this.formCambiarSaldo.patchValue({
            importe: importeAsociado as any,
        });
        this.subscription.add(
            modalRef.componentInstance.aceptarHandler.subscribe(() => {
                this.formValidar(this.formCambiarSaldo, this.formCambiarSaldoElement);
                if (!this.formCambiarSaldo.valid) {
                    return;
                }
                const importe = this.convertirImporte(
                    Big(this.formCambiarSaldo.get('importe')?.value ?? +0.0).toNumber(),
                    1 / (cotizacion ?? +1.0),
                );
                this.elementos[comprobanteVentaAsociadoIndex].importe = importe;
                this.toastService.successHandler(`Saldo de ${this.nombre}, ID ${elemento?.id}, cambiado con éxito`);
                modalRef.close('aceptar');
                this.recargar();
                this.postModificacionEvent.emit(this.elementos[comprobanteVentaAsociadoIndex]);
            }),
        );
    }
}
