<h2>Perfil</h2>

<div class="col-xl-6">
    <form>
        <div class="row">
            <label for="nombre" class="col-sm-4 col-form-label">Nombre</label>
            <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Nombre"
                    [value]="this.usuarioActual.nombre"
                    disabled
                />
            </div>
        </div>
        <div class="row mt-3">
            <label for="email" class="col-sm-4 col-form-label">Email</label>
            <div class="col-sm-8">
                <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                    [value]="this.usuarioActual.email"
                    disabled
                />
            </div>
        </div>
        <div class="row mt-3">
            <label for="moneda" class="col-sm-4 col-form-label"
                >Moneda
                <i
                    *ngIf="getThemeHelpEnabled()"
                    class="instrucciones-pantalla bi bi-question-circle"
                    popoverTitle="Moneda"
                    ngbPopover="Moneda utilizada para expresar los importes en los elementos del sistema en relación a la moneda oficial. Si no selecciona ninguna, se utilizará la moneda por defecto del elemento. Por ejemplo, en los comprobantes de venta, se mostrará la moneda e importe con el que se registró el comprobante."
                ></i
            ></label>
            <div class="col-sm-8">
                <ng-select
                    [items]="monedas"
                    labelForId="moneda"
                    [searchFn]="buscarMoneda"
                    [(ngModel)]="this.monedaActual"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    (change)="cambiarMoneda($event)"
                >
                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        <small [ngOptionHighlight]="search">&#40;{{ item.abreviatura }}&#41;</small
                        >&NonBreakingSpace;<span [ngOptionHighlight]="search">{{ item.nombre }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="row mt-3">
            <label for="empresa" class="col-sm-4 col-form-label"
                >Empresa
                <i
                    *ngIf="getThemeHelpEnabled()"
                    class="instrucciones-pantalla bi bi-question-circle"
                    popoverTitle="Empresa"
                    ngbPopover="Empresa de la cual se visualizarán los datos en el sistema. Si no selecciona ninguna, se visualizarán los datos de todas las empresas a las que tiene acceso."
                ></i
            ></label>
            <div class="col-sm-8">
                <ng-select
                    [clearable]="administrador"
                    [items]="empresas"
                    labelForId="empresa"
                    [(ngModel)]="this.empresaActual"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    (change)="cambiarEmpresa($event)"
                >
                </ng-select>
            </div>
        </div>
        <fieldset class="row mt-3">
            <legend for="facturacionSimple" class="col-sm-4 col-form-label">
                Facturación Simple
                <i
                    *ngIf="getThemeHelpEnabled()"
                    class="instrucciones-pantalla bi bi-question-circle"
                    popoverTitle="Facturación Simple"
                    ngbPopover="Si está activado, se utilizará la facturación simple en los comprobantes de venta y compra, reduciendo la cantidad de campos a completar y completando de manera automática los campos que se puedan deducir de la información del cliente o proveedor. Si está desactivado, se utilizará la facturación completa."
                ></i>
            </legend>
            <div class="col-sm-8 col-form-label">
                <div class="form-check form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="button"
                        id="facturacionSimple"
                        [(ngModel)]="this.facturacionSimple"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="cambiarFacturacionSimple()"
                    />
                </div>
            </div>
        </fieldset>
        <fieldset class="row mt-3">
            <legend for="siguienteNumeroAFIP" class="col-sm-4 col-form-label">
                Número de Comprobante según AFIP
                <i
                    *ngIf="getThemeHelpEnabled()"
                    class="instrucciones-pantalla bi bi-question-circle"
                    popoverTitle="Número de Comprobante según AFIP"
                    ngbPopover="Si está activado, se utilizará el número de comprobante según AFIP en los comprobantes de venta. Si está desactivado, se utilizará el número de comprobante interno."
                ></i>
            </legend>
            <div class="col-sm-8 col-form-label">
                <div class="form-check form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="button"
                        id="siguienteNumeroAFIP"
                        [(ngModel)]="this.siguienteNumeroAFIP"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="cambiarSiguienteNumeroAFIP()"
                    />
                </div>
            </div>
        </fieldset>
        <fieldset class="row mt-3">
            <legend for="generarCAE" class="col-sm-4 col-form-label">
                Generar CAE y QR Automáticamente
                <i
                    *ngIf="getThemeHelpEnabled()"
                    class="instrucciones-pantalla bi bi-question-circle"
                    popoverTitle="Generar CAE y QR Automáticamente"
                    ngbPopover="Si está activado, se generará el CAE y QR automáticamente en los comprobantes de venta. Si está desactivado, se deberá generar el CAE y QR manualmente."
                ></i>
            </legend>
            <div class="col-sm-8 col-form-label">
                <div class="form-check form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="button"
                        id="generarCAE"
                        [(ngModel)]="this.generarCAE"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="cambiarGenerarCAE()"
                    />
                </div>
            </div>
        </fieldset>
    </form>
</div>
