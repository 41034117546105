import { Directive, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { RespuestaDTO } from '@dtos/respuesta.dto';
import { BaseEntity } from '@entities/base.entity';
import { BaseDTOEntity } from '@entities/dtos/base.dto';
import { AutoUnsubscribe } from '@helpers/auto-unsubscribe.decorator';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { AccionTabla } from '@shared/interfaces/accion-tabla.interface';
import { BotonTabla } from '@shared/interfaces/boton-tabla.interface';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { BaseService } from '@shared/services/base.service';
import { ToastService } from '@shared/services/toast.service';
import { BaseViewEntity } from '@views/base.view';
import { Observable, Subscription, forkJoin } from 'rxjs';

@Directive()
@AutoUnsubscribe
export abstract class ReporteComponent<T extends BaseEntity | BaseViewEntity | BaseDTOEntity> implements OnInit {
    protected subscription = new Subscription();
    protected toastService = inject(ToastService);

    @Output() public init: EventEmitter<ReporteComponent<T>> = new EventEmitter();

    public acciones: AccionTabla[] = [];
    public botones: BotonTabla[] = [];
    public relaciones: any = {};
    // TODO: remover lógica de primera carga (tablas sin SearchBuilder) y segunda carga (tablas con SearchBuilder)
    public primeraCarga = false;
    public segundaCarga = true;
    public paginacion = true;

    public tablaOpciones: any = {
        searchBuilder: {},
    };

    public abstract columnas: ColumnaTabla[];
    public abstract tabla: TablaComponent;

    public constructor(
        public baseService: BaseService<T>,
        configuracion?: {
            inicializaciones?: {
                acciones?: AccionTabla[];
                botones?: BotonTabla[];
            };
        },
    ) {
        const inicializaciones = configuracion?.inicializaciones;
        // Inicialización de Acciones de tabla y Botones
        if (inicializaciones?.acciones) {
            this.acciones = inicializaciones.acciones;
        }
        if (inicializaciones?.botones) {
            this.botones = inicializaciones.botones;
        }
        setTimeout(() => {
            this.subscription.add(
                forkJoin({
                    relaciones: this.obtenerRelaciones(),
                }).subscribe({
                    next: ({ relaciones }) => {
                        this.cargarRelaciones(relaciones);
                        this.cargarBusquedaAvanzada();
                        this.inicializarCondicionIgualA();
                        if ((this.tablaOpciones?.searchBuilder as any)?.preDefined != null) {
                            this.segundaCarga = false;
                        }
                        this.tabla.dtOptions.searchBuilder = this.tablaOpciones.searchBuilder;
                        this.tabla.construirTabla();
                        this.tabla.dtOptions.language = {
                            ...this.tabla.dtOptions.language,
                            emptyTable: `Seleccione condiciones de búsqueda y presione 'Recargar' para obtener resultados`,
                        };
                        this.tabla.inicializar();
                    },
                    error: this.toastService.errorHandler.bind(this.toastService),
                }),
            );
        });
    }

    public ngOnInit(): void {
        this.init.emit(this);
    }

    public ajaxHandler(event: any, service = this.baseService) {
        const callback = event.callback;
        const parametros = {
            ...event.dataTablesParameters,
        };
        if (!this.primeraCarga || !this.segundaCarga) {
            if (!this.primeraCarga) {
                this.primeraCarga = true;
            } else {
                this.segundaCarga = true;
            }
            callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
            });
            return;
        }
        if (!this.paginacion) {
            parametros.start = null;
            parametros.length = null;
        }
        this.subscription.add(
            service.obtenerTodos(parametros).subscribe({
                next: (resp: RespuestaDTO<T>) => {
                    callback({
                        recordsTotal: resp.cantidadTotal,
                        recordsFiltered: resp.cantidadFiltrada,
                        data: resp.elementos,
                    });
                },
                error: (error: any) => {
                    callback({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: [],
                    });
                    this.toastService.errorHandler(error);
                },
            }),
        );
    }

    public accionHandler(accion: AccionTabla) {
        if (!accion.nombre) {
            return;
        }
        const callback =
            typeof (this as any)[accion.nombre] === 'function'
                ? (this as any)[accion.nombre].bind(this)
                : () => {
                      this.toastService.errorHandler({
                          message: `No se encontró el método '${accion.nombre}' en el componente '${this.constructor.name}'`,
                          class: 'warning',
                      });
                  };
        callback(accion.data);
    }

    public botonHandler(boton: BotonTabla) {
        if (!boton.name) {
            return;
        }
        const callback =
            typeof (this as any)[boton.name] === 'function'
                ? (this as any)[boton.name].bind(this)
                : () => {
                      this.toastService.errorHandler({
                          message: `No se encontró el método '${boton.name}' en el componente '${this.constructor.name}'`,
                          class: 'warning',
                      });
                  };
        callback();
    }

    protected abstract obtenerRelaciones(): Observable<any>;

    protected cargarRelaciones(relaciones: any) {
        this.relaciones = {};
        Object.keys(relaciones).forEach((key) => {
            this.relaciones[key] = relaciones[key]?.elementos ?? relaciones[key];
        });
    }

    protected abstract cargarBusquedaAvanzada(): void;

    private inicializarCondicionIgualA() {
        if (!this.tablaOpciones?.searchBuilder) {
            return;
        }
        this.tablaOpciones.searchBuilder = {
            ...this.tablaOpciones.searchBuilder,
            conditions: {
                string: {
                    '=': {
                        init: this.condicionIgualA.bind(this),
                    },
                },
            },
        };
    }

    private condicionIgualA(that: any, fn: any, preDefined = null, array = false) {
        {
            const column = that.dom.data.children('option:selected').val();
            const indexArray = that.s.dt.rows().indexes().toArray();
            const settings = that.s.dt.settings()[0];
            const colName =
                settings.aoColumns[column].name || settings.aoColumns[column].title || settings.aoColumns[column].data;
            const colLabel = settings.aoColumns[column].label || 'nombre';
            that.dom.valueTitle.prop('selected', true);

            // Declare select element to be used with all of the default classes and listeners.
            const el = $('<select/>')
                .addClass(that.classes.value)
                .addClass(that.classes.dropDown)
                .addClass(that.classes.italic)
                .addClass(that.classes.select)
                .append(that.dom.valueTitle)
                .on('change.dtsb', function () {
                    $(this).removeClass(that.classes.italic);
                    fn(that, this);
                });

            if (that.c.greyscale) {
                el.addClass(that.classes.greyscale);
            }

            const added: any = [];
            const options: any = [];

            // Function to add an option to the select element
            const addOption = (filt: any, text?: any) => {
                if (text == null) {
                    text = filt;
                }
                if (that.s.type.includes('html') && filt !== null && typeof filt === 'string') {
                    filt.replace(/(<([^>]+)>)/gi, '');
                }

                // Add text and value, stripping out any html if that is the column type
                const opt = $('<option>', {
                    type: Array.isArray(filt) ? 'Array' : 'String',
                    value: filt,
                })
                    .data('sbv', filt)
                    .addClass(that.classes.option)
                    .addClass(that.classes.notItalic)
                    // Have to add the text this way so that special html characters are not escaped - &amp; etc.
                    .html(typeof text === 'string' ? text.replace(/(<([^>]+)>)/gi, '') : text);

                const val = opt.val();

                // Check that this value has not already been added
                if (added.indexOf(val) === -1) {
                    added.push(val);
                    options.push(opt);

                    if (preDefined !== null && Array.isArray(preDefined[0])) {
                        (preDefined as any)[0] = (preDefined as any)[0].sort().join(',');
                    }

                    // If this value was previously selected as indicated by preDefined, then select it again
                    if (preDefined !== null && opt.val() === preDefined[0]) {
                        opt.prop('selected', true);
                        el.removeClass(that.classes.notitalic);
                        that.dom.valueTitle.removeProp('selected');
                    }
                }
            };

            // Add all of the options from the table to the select element.
            // Only add one option for each possible value
            for (const index of indexArray) {
                const filter = settings.oApi._fnGetCellData(
                    settings,
                    index,
                    column,
                    typeof that.c.orthogonal === 'string' ? that.c.orthogonal : that.c.orthogonal.search,
                );
                const value = {
                    filter:
                        typeof filter === 'string'
                            ? filter.replace(/[\r\n\u2028]/g, ' ') // Need to replace certain characters to match search values
                            : filter,
                    index,
                    text: settings.oApi._fnGetCellData(
                        settings,
                        index,
                        column,
                        typeof that.c.orthogonal === 'string' ? that.c.orthogonal : that.c.orthogonal.display,
                    ),
                };

                // If we are dealing with an array type, either make sure we are working with arrays, or sort them
                if (that.s.type === 'array') {
                    value.filter = !Array.isArray(value.filter) ? [value.filter] : value.filter;
                    value.text = !Array.isArray(value.text) ? [value.text] : value.text;
                }

                // If this is to add the individual values within the array we need to loop over the array
                if (array) {
                    for (let i = 0; i < value.filter.length; i++) {
                        addOption(value.filter[i], value.text[i]);
                    }
                }
                // Otherwise the value that is in the cell is to be added
                else {
                    addOption(value.filter, Array.isArray(value.text) ? value.text.join(', ') : value.text);
                }
            }

            // Si la columna (propiedad 'name', 'title', 'data') se encuentra en la lista de this.relaciones, agregar las opciones
            // La columna debe tener la propiedad 'label' como la propiedad de la entidad para mostrar en la lista de opciones, o la entidad debe tener la propiedad 'nombre'
            let colNameRelacion = colName;
            if (
                !Object.getOwnPropertyNames(this.relaciones).includes(colNameRelacion) &&
                Object.getOwnPropertyNames(this.relaciones).includes(`${colName.toLowerCase()}s`)
            ) {
                colNameRelacion = `${colName.toLowerCase()}s`;
            }
            if (Object.getOwnPropertyNames(this.relaciones).includes(colNameRelacion)) {
                for (const relacion of this.relaciones[colNameRelacion]) {
                    addOption(relacion[colLabel] ?? relacion[colNameRelacion]);
                }
            }
            addOption('');

            // Ordenar las opciones
            options.sort((a: any, b: any) => {
                if (that.s.type === 'array' || that.s.type === 'string' || that.s.type === 'html') {
                    if (a.val() < b.val()) {
                        return -1;
                    } else if (a.val() > b.val()) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else if (that.s.type === 'num' || that.s.type === 'html-num') {
                    if (+a.val().replace(/(<([^>]+)>)/gi, '') < +b.val().replace(/(<([^>]+)>)/gi, '')) {
                        return -1;
                    } else if (+a.val().replace(/(<([^>]+)>)/gi, '') > +b.val().replace(/(<([^>]+)>)/gi, '')) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else if (that.s.type === 'num-fmt' || that.s.type === 'html-num-fmt') {
                    if (+a.val().replace(/[^0-9.]/g, '') < +b.val().replace(/[^0-9.]/g, '')) {
                        return -1;
                    } else if (+a.val().replace(/[^0-9.]/g, '') > +b.val().replace(/[^0-9.]/g, '')) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
                return 0;
            });

            for (const opt of options) {
                el.append(opt);
            }

            return el;
        }
    }
}
