import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TipoComprobanteService } from '@configuracion/services/tipo-comprobante.service';
import { obtenerTipoComprobanteDenominaciones } from '@entities/enums/tipo-comprobante-denominacion.enum';
import { obtenerTipoComprobanteLetras } from '@entities/enums/tipo-comprobante-letra.enum';
import { TipoComprobante } from '@entities/tipo-comprobante.entity';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ABMCComponent } from '@shared/directives/abmc/abmc.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { Observable, forkJoin, of } from 'rxjs';

@Component({
    selector: 'kratos-tipo-comprobante',
    templateUrl: './tipo-comprobante.component.html',
    styleUrls: ['./tipo-comprobante.component.scss'],
})
export class TipoComprobanteComponent extends ABMCComponent<TipoComprobante> {
    @ViewChild('tabla') public tabla!: TablaComponent;
    @ViewChild('formTemplate') public formTemplate!: TemplateRef<any>;
    @ViewChild('formElement') public formElement!: ElementRef<any>;

    public tipoComprobanteDenominaciones = obtenerTipoComprobanteDenominaciones();
    public tipoComprobanteLetras = obtenerTipoComprobanteLetras();
    public tipoComprobanteSignos = [-1, 0, 1];
    public nombre = 'Tipo de Comprobante';
    public titulo = 'Tipos de Comprobante';

    protected formErroresControles = {};

    public form = this.formBuilder.group({
        nombre: ['', Validators.required],
        empresa: ['', Validators.required],
        codigoAFIP: [''],
        denominacion: ['', Validators.required],
        letra: ['', Validators.required],
        signo: ['', Validators.required],
    });

    public columnas: ColumnaTabla[] = [
        {
            title: 'ID',
            data: 'id',
            tipo: 'number',
            searchable: true,
            width: '4rem',
        },
        {
            title: 'Nombre',
            data: 'nombre',
            searchable: true,
        },
        {
            title: 'Código AFIP',
            data: 'codigoAFIP',
            searchable: true,
        },
        {
            title: 'Denominación',
            data: 'denominacion',
            searchable: true,
        },
        {
            title: 'Letra',
            data: 'letra',
            searchable: true,
        },
        {
            title: 'Signo',
            data: 'signo',
            searchable: true,
        },
        {
            title: 'Empresa',
            data: 'empresa.nombre',
            searchable: true,
        },
    ];

    public constructor(
        protected tipoComprobanteService: TipoComprobanteService,
        protected empresaService: EmpresaService,
        private formBuilder: FormBuilder,
    ) {
        super(tipoComprobanteService, {
            inicializaciones: {
                nombre: 'Tipo de Comprobante',
                acciones: [],
                botones: [],
            },
            opciones: {
                botones: { alta: true },
                acciones: {
                    baja: true,
                    modificacion: true,
                    consulta: true,
                },
            },
        });
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
        });
    }
}
