import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pais } from '@entities/pais.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class PaisService extends BaseService<Pais> {
    public constructor(http: HttpClient) {
        super('configuracion/pais', http);
    }
}
