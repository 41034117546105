import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const EMPRESA_HEADER = 'x-empresa-id';

@Injectable()
export class EmpresaHeaderInterceptor implements HttpInterceptor {
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let clonedRequest = req.clone();
        // Si hay una empresa en el localStorage, se agrega en el header de la petición
        if (localStorage.getItem('empresa')) {
            try {
                const empresa = JSON.parse(localStorage.getItem('empresa') as string);
                clonedRequest = req.clone({ setHeaders: { [EMPRESA_HEADER]: empresa.id.toString() } });
            } catch (error) {
                console.error('Error al parsear la empresa', error);
            }
        }
        return next.handle(clonedRequest);
    }
}
