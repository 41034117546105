import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'form [formGroup]',
})
export class RequiredInputDirective implements OnInit {
    @Input() public formGroup!: FormGroup;
    private formId: string | undefined;

    public constructor(private elementRef: ElementRef) {}

    public ngOnInit(): void {
        // Obtener el ID autogenerado de formGroup en base al atributo ngcontent
        this.formId = this.elementRef.nativeElement
            .getAttributeNames()
            .find((name: string) => name.startsWith('_ngcontent'));
        // Ejecutar la primera vez inmediatamente
        this.refreshRequiredStatus();
        // Adjuntar un listener al observable de formGroup: statusChanges
        this.formGroup.statusChanges.subscribe(this.refreshRequiredStatus.bind(this));
    }

    private refreshRequiredStatus(): void {
        Object.keys(this.formGroup.controls)
            // Filtrar controles deshabilitados
            .filter((key) => this.formGroup.controls[key].status !== 'DISABLED')
            // Filter controles sin validadores
            .filter((key) => this.formGroup.controls[key].validator)
            .forEach((key) => {
                // Get this.elementRef's ngcontent unique identifier
                const control = this.formGroup.controls[key];
                const validator = control?.validator?.({} as AbstractControl);
                // Si el control es required, agregar la clase required-input al label del input
                if (validator && validator['required']) {
                    const label = this.elementRef.nativeElement.querySelector(
                        `[${this.formId}] label[for=${key}].form-label`,
                    );
                    if (label) {
                        label.classList.add('required-input');
                    }
                }
            });
    }
}
