import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReporteCuentaCorrienteView } from '@entities/views/reportes/reporte-cuenta-corriente.view';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class ReporteCuentaCorrienteService extends BaseService<ReporteCuentaCorrienteView> {
    public constructor(http: HttpClient) {
        super('ventas/reporte/cuenta-corriente', http);
    }
}
