import { NgModule } from '@angular/core';
import { MigracionHermesComponent } from '@migracion/components/migracion-hermes.component';
import { MigracionSIComponent } from '@migracion/components/migracion-si.component';
import { MigracionTareaComponent } from '@migracion/components/migracion-tarea.component';
import { MigracionRoutingModule } from '@migracion/migracion.routing.module';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [MigracionTareaComponent, MigracionHermesComponent, MigracionSIComponent],
    imports: [SharedModule, MigracionRoutingModule],
})
export class MigracionModule {}
