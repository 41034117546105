import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ClienteComponent } from '@ventas/components/cliente.component';
import { ComprobanteTransaccionComponent } from '@ventas/components/comprobante-transaccion.component';
import { ComprobanteVentaComprobanteAsociadoComponent } from '@ventas/components/comprobante-venta-comprobante-asociado.component';
import { ComprobanteVentaDatoOpcionalComponent } from '@ventas/components/comprobante-venta-dato-opcional.component';
import { ComprobanteVentaItemComponent } from '@ventas/components/comprobante-venta-item.component';
import { ComprobanteVentaMedioPagoComponent } from '@ventas/components/comprobante-venta-medio-pago.component';
import { ComprobanteVentaTributoComponent } from '@ventas/components/comprobante-venta-tributo.component';
import { ComprobanteVentaComponent } from '@ventas/components/comprobante-venta.component';
import { MonedaCotizacionComponent } from '@ventas/components/moneda-cotizacion.component';
import { MonedaComponent } from '@ventas/components/moneda.component';
import { ProductoComponent } from '@ventas/components/producto.component';
import { PuntoVentaComponent } from '@ventas/components/punto-venta.component';
import { TipoActividadComponent } from '@ventas/components/tipo-actividad.component';
import { TipoAlicuotaIVAComponent } from '@ventas/components/tipo-alicuota-iva.component';
import { TipoConceptoComponent } from '@ventas/components/tipo-concepto.component';
import { TipoDatoOpcionalComponent } from '@ventas/components/tipo-dato-opcional.component';
import { TipoMedioPagoComponent } from '@ventas/components/tipo-medio-pago.component';
import { TipoPuntoVentaComponent } from '@ventas/components/tipo-punto-venta.component';
import { TipoTributoComponent } from '@ventas/components/tipo-tributo.component';
import { UnidadMedidaComponent } from '@ventas/components/unidad-medida.component';
import { ReporteCobrosPendientesComponent } from '@ventas/reportes/components/reporte-cobros-pendientes.component';
import { ReporteContableVentasJurisdiccionComponent } from '@ventas/reportes/components/reporte-contable-ventas-jurisdiccion.component';
import { ReporteCuentaCorrienteComponent } from '@ventas/reportes/components/reporte-cuenta-corriente.component';
import { ReporteLibroIVAVentasComponent } from '@ventas/reportes/components/reporte-libro-iva-ventas.component';
import { ReporteSaldosClientesComponent } from '@ventas/reportes/components/reporte-saldos-clientes.component';
import { VentasRoutingModule } from '@ventas/ventas.routing.module';

@NgModule({
    declarations: [
        ComprobanteVentaComponent,
        ComprobanteVentaItemComponent,
        ComprobanteVentaMedioPagoComponent,
        ComprobanteVentaTributoComponent,
        ComprobanteVentaDatoOpcionalComponent,
        ComprobanteVentaComprobanteAsociadoComponent,
        ComprobanteTransaccionComponent,
        ClienteComponent,
        ProductoComponent,
        PuntoVentaComponent,
        UnidadMedidaComponent,
        MonedaComponent,
        MonedaCotizacionComponent,
        TipoActividadComponent,
        TipoAlicuotaIVAComponent,
        TipoConceptoComponent,
        TipoDatoOpcionalComponent,
        TipoMedioPagoComponent,
        TipoPuntoVentaComponent,
        TipoTributoComponent,
        ReporteCuentaCorrienteComponent,
        ReporteSaldosClientesComponent,
        ReporteCobrosPendientesComponent,
        ReporteLibroIVAVentasComponent,
        ReporteContableVentasJurisdiccionComponent,
    ],
    imports: [SharedModule, VentasRoutingModule],
    exports: [ComprobanteTransaccionComponent],
})
export class VentasModule {}
