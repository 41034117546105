import { NgModule } from '@angular/core';
import { IdiomaComponent } from '@configuracion/components/idioma.component';
import { PaisNumeroDocumentoComponent } from '@configuracion/components/pais-numero-documento.component';
import { PaisComponent } from '@configuracion/components/pais.component';
import { ProvinciaComponent } from '@configuracion/components/provincia.component';
import { TipoComprobanteComponent } from '@configuracion/components/tipo-comprobante.component';
import { TipoDocumentoComponent } from '@configuracion/components/tipo-documento.component';
import { TipoResponsableIVAComponent } from '@configuracion/components/tipo-responsable-iva.component';
import { ConfiguracionRoutingModule } from '@configuracion/configuracion.routing.module';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [
        IdiomaComponent,
        TipoResponsableIVAComponent,
        TipoDocumentoComponent,
        ProvinciaComponent,
        PaisComponent,
        PaisNumeroDocumentoComponent,
        TipoComprobanteComponent,
    ],
    imports: [SharedModule, ConfiguracionRoutingModule],
})
export class ConfiguracionModule {}
