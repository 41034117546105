import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ThemeService } from '@core/services/theme.service';
import { BaseEntity } from '@entities/base.entity';
import { AccionComponent } from '@shared/components/accion/accion.component';
import { AccionTabla } from '@shared/interfaces/accion-tabla.interface';
import { BotonTabla } from '@shared/interfaces/boton-tabla.interface';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { DataTableDirective } from 'angular-datatables';
import Big from 'big.js';
import { parseISO } from 'date-fns';
import { format, formatInTimeZone } from 'date-fns-tz';
import { Subject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const language_ES_ES = require('datatables.net-plugins/i18n/es-ES.js');

@Component({
    selector: 'kratos-tabla',
    templateUrl: './tabla.component.html',
    styleUrls: ['./tabla.component.scss'],
})
export class TablaComponent implements OnInit, AfterViewInit, OnDestroy {
    private COLUMNA_ACCIONES = 'Acciones';

    @ViewChild(DataTableDirective, { static: false }) private dtElement!: DataTableDirective;
    public dtTrigger: Subject<any> = new Subject();
    public dtOptions: any = {};
    public tablaId!: string | undefined;

    @Output() private ajaxHandler: EventEmitter<any> = new EventEmitter();
    @Output() private accionHandler: EventEmitter<AccionTabla> = new EventEmitter();
    @Output() private botonHandler: EventEmitter<BotonTabla> = new EventEmitter();
    @Output() public init: EventEmitter<void> = new EventEmitter();

    @ViewChild('accionNg') private accionNg!: TemplateRef<AccionComponent>;
    @Input() public opciones: any = {};
    @Input() public columnas: ColumnaTabla[] = [];
    @Input() public acciones: AccionTabla[] = [];
    @Input() public botones: BotonTabla[] = [];
    @Input() public autoInit = true;
    @Input() public paginacion = true;

    private _botones: BotonTabla[] = [];
    private _acciones: AccionTabla[] = [];
    private _columnas: ColumnaTabla[] = [];

    public constructor(private themeService: ThemeService) {}

    public ngOnInit(): void {
        setTimeout(() => {
            this.construirTabla();
        });
    }

    public ngAfterViewInit(): void {
        if (this.autoInit) {
            setTimeout(() => {
                this.inicializar();
            });
        }
    }

    public ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    public construirTabla() {
        if (!this._botones.length) {
            this._botones = this.botones.slice();
        }
        if (!this._acciones.length) {
            this._acciones = this.acciones.slice();
        }
        if (!this._columnas.length) {
            this._columnas = this.columnas.slice();
        }
        this.botones = this._botones.slice();
        this.acciones = this._acciones.slice();
        this.columnas = this._columnas.slice();
        // Agregar espacio entre botones por defecto y botones adicionales
        if (this.botones && this.botones.length) {
            this.botones.push({
                extend: 'spacer',
                style: '',
                className: 'botones-spacer',
            });
        }
        // Bindear botones adicionales a botonHandler
        this.botones.forEach((boton) => {
            // Si el botón extiende 'collection', bindear los botones hijos a botonHandler
            if (boton.extend === 'collection') {
                if (boton.buttons && boton.buttons.length) {
                    boton.buttons.forEach((botonHijo) => {
                        if (botonHijo.action) {
                            return;
                        }
                        botonHijo.action = () => {
                            this.botonHandler.emit(botonHijo);
                        };
                    });
                }
                return;
            }
            if (boton.action) {
                return;
            }
            boton.action = () => {
                this.botonHandler.emit(boton);
            };
        });
        // Agregar columnas Alta, Modificado, Baja, Version si está activado el modo dev/debug y las columnas no existen
        if (this.themeService.currentDebug === 'true') {
            if (!this.columnas.find((columna) => columna.data === 'alta')) {
                this.columnas.push({
                    title: 'Alta',
                    data: 'alta',
                    tipo: 'datetime',
                    searchable: true,
                });
            }
            if (!this.columnas.find((columna) => columna.data === 'modificado')) {
                this.columnas.push({
                    title: 'Modificado',
                    data: 'modificado',
                    tipo: 'datetime',
                    searchable: true,
                });
            }
            if (!this.columnas.find((columna) => columna.data === 'baja')) {
                this.columnas.push({
                    title: 'Baja',
                    data: 'baja',
                    tipo: 'datetime',
                    searchable: true,
                });
            }
            if (!this.columnas.find((columna) => columna.data === 'version')) {
                this.columnas.push({
                    title: 'Versión',
                    data: 'version',
                    tipo: 'number',
                    searchable: true,
                });
            }
        }
        // Agregar acción de Restaurar si está activado el modo dev/debug y la acción no existe
        if (this.themeService.currentDebug === 'true') {
            if (!this.acciones.find((accion) => accion.nombre === 'restaurar')) {
                this.acciones.push({
                    nombre: 'restauracion',
                    color: 'success',
                    texto: `Restaurar`,
                    grupo: 1,
                    deshabilitado: (elemento: BaseEntity) => !elemento || (elemento && elemento.baja == null),
                });
            }
        }
        if (this.columnas && this.columnas.length && this.acciones && this.acciones.length) {
            this.columnas.push({
                title: this.COLUMNA_ACCIONES,
                width: this.calcularAnchoAcciones(),
                className: 'actions-nowrap',
                orderable: false,
                searchable: false,
                data: null,
                defaultContent: '',
                ngTemplateRef: {
                    ref: this.accionNg,
                    context: {
                        acciones: this.acciones,
                        clickAccion: this.clickAccion.bind(this),
                    },
                },
            });
        }
        // Establecer renders de columnas en base al tipo de datos
        this.columnas.forEach((columna) => {
            // Si ya tiene un render establecido, no sobreescribir
            if (columna.render) {
                return;
            }
            switch (columna.tipo) {
                case 'date':
                    columna.render = (data: any, type: string) => {
                        if (type === 'display' || type === 'filter' || type === 'export') {
                            return data ? formatInTimeZone(parseISO(data), 'UTC', 'dd/MM/yyyy') : '';
                        }
                        return data;
                    };
                    columna.type = 'date';
                    break;
                case 'datetime':
                    columna.render = (data: any, type: string) => {
                        if (type === 'display' || type === 'filter' || type === 'export') {
                            return data ? format(parseISO(data), 'dd/MM/yyyy HH:mm:ss') : '';
                        }
                        return data;
                    };
                    columna.type = 'date';
                    break;
                case 'email':
                    columna.render = (data: any, type: string) => {
                        if (type === 'display') {
                            return data
                                ? `<a class="text-decoration-none" href="mailto:${data}"><span>${data}</span></a>`
                                : '';
                        }
                        return data;
                    };
                    break;
                case 'number':
                    columna.render = (data: any, type: string) => {
                        if (type === 'display') {
                            return data ? data.toLocaleString('es-ES') : '';
                        }
                        return data;
                    };
                    columna.type = 'num';
                    break;
                case 'decimal':
                    columna.render = (data: any, type: string) => {
                        if (type === 'display') {
                            return data ? data.toLocaleString('es-ES', { minimumFractionDigits: 2 }) : '';
                        }
                        return data;
                    };
                    columna.type = 'num';
                    break;
                case 'cotizacion':
                    columna.render = (data: any, type: string) => {
                        if (type === 'display') {
                            return data ? data.toLocaleString('es-ES', { minimumFractionDigits: 6 }) : '';
                        }
                        return data;
                    };
                    columna.type = 'num';
                    break;
                case 'moneda':
                    columna.render = (data: any, type: string, row: any) => {
                        if (type === 'display' || type === 'export') {
                            data = data || '0';
                            const moneda = row.moneda || {
                                simbolo: '$',
                                cotizacion: 1.0,
                            };
                            let cotizacion = Big(row.cotizacion ?? 1.0);
                            let simbolo = moneda.simbolo ?? '$';
                            let valor = Big(data);
                            // Si se establece una moneda para la tabla (por ejemplo, al crear comprobantes de venta o compra) utilizar su cotización
                            if (this.opciones.moneda) {
                                cotizacion = Big(this.opciones.moneda.cotizacion ?? 1.0);
                                simbolo = this.opciones.moneda.simbolo ?? '$';
                                valor = valor.div(cotizacion);
                                return `${simbolo} ${valor
                                    .round(2)
                                    .toNumber()
                                    .toLocaleString('es-ES', { minimumFractionDigits: 2 })}`;
                            }
                            // Obtener la moneda seleccionada por el usuario del localStorage y convertir el valor a la misma
                            const monedaUsuarioActual = JSON.parse(localStorage.getItem('moneda') || '{}');
                            if (monedaUsuarioActual?.id) {
                                // Si la moneda del usuario es la misma del elemento, tomar la cotización del elemento
                                // La moneda oficial siempre debe tener cotización 1.0 por lo que se mostrará directamente el valor del elemento
                                if (monedaUsuarioActual.id === moneda.id) {
                                    cotizacion = Big(row.cotizacion ?? 1.0);
                                } else {
                                    // TODO: tomar la cotización que corresponda a la fecha del elemento en vez de la de la moneda (última cotización)
                                    cotizacion = Big(monedaUsuarioActual.cotizacion ?? 1.0);
                                }
                                simbolo = monedaUsuarioActual.simbolo;
                            }
                            valor = valor.div(cotizacion);
                            return `${simbolo} ${valor
                                .round(2)
                                .toNumber()
                                .toLocaleString('es-ES', { minimumFractionDigits: 2 })}`;
                        }
                        return data;
                    };
                    columna.type = 'num';
                    break;
                case 'boolean':
                    columna.render = (data: any, type: string) => {
                        if (type === 'display' || type === 'export') {
                            return data ? 'Sí' : 'No';
                        }
                        return data;
                    };
                    break;
            }
        });
        // Desactivar búsqueda de columnas si se está utilizando el SearchBuilder
        let searchBuilder = null;
        const searchBuilderColumns = [];
        if (this.opciones.searchBuilder != null) {
            searchBuilder = {
                ...this.opciones.searchBuilder,
                enterSearch: true,
                depthLimit: 6,
                greyscale: true,
            };
            this.columnas.forEach((columna) => {
                columna.searchable = false;
            });
            // Convertir todas las columnas de tipo 'string' de searchBuilder a selectores de jQuery
            if (searchBuilder.columns && searchBuilder.columns.length) {
                for (let i = 0; i < searchBuilder.columns.length; i++) {
                    const columna = searchBuilder.columns[i];
                    if (typeof columna === 'string') {
                        searchBuilderColumns[i] = `th[title="${columna}"]`;
                    }
                }
            }
        }
        // Construir dom
        const elementoPaginacionMostrar = this.paginacion
            ? `<'col-sm-12 col-lg-${searchBuilder != null ? '12' : '6'}'l>`
            : '';
        const elementoPaginacionPagina = this.paginacion ? `<'col-sm-12 col-lg-7'p>` : '';
        let dom = '';
        if (searchBuilder != null) {
            dom += "<'row'<'col-sm-12 col-lg-12 tabla-botones mb-2'Q>>";
        }
        dom += "<'row'<'col-sm-12 col-lg-12 tabla-botones mb-2 d-flex justify-content-end'B>>";
        if (searchBuilder != null) {
            dom += `<'row'${elementoPaginacionMostrar}>`;
        } else {
            dom += `<'row'${elementoPaginacionMostrar}<'col-sm-12 col-lg-6'f>>`;
        }
        dom += "<'row table-row-container my-2'<'col-sm-12 table-container px-0'tr>>";
        dom += `<'row'<'col-sm-12 col-lg-5'i>${elementoPaginacionPagina}>`;
        // Construir tabla
        this.dtOptions = {
            dom: dom,
            processing: true,
            serverSide: true,
            search: {
                caseInsensitive: true,
                return: true,
            },
            ajax: this.ajaxAccion.bind(this),
            footerCallback: () => {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    this.tablaId = dtInstance.tables().nodes().to$().attr('id');
                });
            },
            initComplete: () => {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    const tablaId = dtInstance.tables().nodes().to$().attr('id');
                    // Si el botón existe dentro del arreglo this.botones, y tiene la clase 'btn-secondary' pero el elemento dentro del arreglo no, remover la clase
                    const botonesAdicionales = document.querySelectorAll(`#${tablaId}_wrapper .dt-buttons button`);
                    botonesAdicionales.forEach((botonAdicional, index) => {
                        // Obtener el texto del botón
                        const botonTexto = botonAdicional.textContent?.trim();
                        // Buscar el botón dentro de this.botones por el texto
                        const boton = this.botones.find((b) => b.text === botonTexto);
                        if (boton) {
                            botonAdicional.classList.remove('btn-secondary');
                            if (boton.className) {
                                botonAdicional.classList.add(boton.className);
                            } else {
                                botonAdicional.classList.add('btn-primary');
                            }
                        }
                    });
                    // Si this.opciones.botones.size es 'sm' o 'lg', agregar la clase 'btn-{size}' a todos los botones de la tabla
                    const botones = document.querySelectorAll(`#${tablaId}_wrapper .tabla-botones button`);
                    botones.forEach((boton) => {
                        if (this.opciones?.botones?.size === 'sm') {
                            boton.classList.add('btn-sm');
                        }
                        if (this.opciones?.botones?.size === 'lg') {
                            boton.classList.add('btn-lg');
                        }
                    });

                    // Establecer la cantidad de registros a buscar y almacenar la configuración en localStorage
                    if (this.paginacion) {
                        const pageLength = document.getElementsByName(`${tablaId}_length`)[0] as HTMLSelectElement;
                        pageLength.addEventListener('change', (event: any) => {
                            if (Number(event.target.value) === -1) {
                                return;
                            }
                            this.themeService.currentPageLength = event.target.value;
                        });
                    }

                    // Agregar el campo de búsqueda al pie de cada columna "buscable"
                    dtInstance.columns().every((index) => {
                        const column = dtInstance.column(index);
                        const columna = this.columnas[index];
                        if (!column.footer() || !columna.searchable || columna['title'] === this.COLUMNA_ACCIONES) {
                            return;
                        }
                        const input = document.getElementById(column.footer().children[0].id) as HTMLInputElement;
                        switch (columna.type) {
                            case 'date':
                                input.type = 'date';
                                break;
                            case 'email':
                                input.type = 'email';
                                break;
                            case 'number':
                                input.type = 'number';
                                break;
                            default:
                                input.type = 'text';
                                break;
                        }
                        this.addListenerMulti(input, 'keyup blur', (event: any) => {
                            const value = input.value.trim();
                            if (event.type === 'blur') {
                                if (value !== column.search()) {
                                    column.search(value).draw();
                                }
                                input.value = value;
                            }
                            if (
                                event.type === 'keyup' &&
                                event.key &&
                                event.key === 'Enter' &&
                                column.search() !== value
                            ) {
                                column.search(value).draw();
                                input.value = value;
                            }
                        });
                    });

                    // Agregar evento blur en el campo de búsqueda para que se ejecute la búsqueda
                    const search = document.getElementById(`${dtInstance.tables().nodes().to$().attr('id')}_filter`);
                    const input = search?.getElementsByTagName('input')[0] as HTMLInputElement;
                    this.addListenerMulti(input, 'keyup blur', (event: any) => {
                        const value = input.value.trim();
                        if (event.type === 'blur') {
                            if (value !== dtInstance.search()) {
                                dtInstance.search(value).draw();
                            }
                            input.value = value;
                        }
                        if (
                            event.type === 'keyup' &&
                            event.key &&
                            event.key === 'Enter' &&
                            dtInstance.search() !== value
                        ) {
                            dtInstance.search(value).draw();
                            input.value = value;
                        }
                    });
                });
            },
            createdRow: (row: Node, data: any, index: number) => {
                if (data.baja || data.eliminado || data.habilitado === false) {
                    const cells = row.childNodes;
                    cells.forEach((cell) => {
                        (cell as any).classList.add('text-warning');
                    });
                }
            },
            autoWidth: false,
            fixedColumns: {
                left: 0,
                right: 1,
            },
            pageLength: this.themeService.currentPageLength,
            columns: [...this.columnas],
            buttons: [
                ...this.botones,
                {
                    extend: 'collection',
                    text: 'Exportar',
                    className: 'btn-exportar',
                    fade: 0,
                    background: false,
                    buttons: [
                        {
                            extend: 'copyHtml5',
                            exportOptions: {
                                columns: ':visible:not(.actions-nowrap)',
                                orthogonal: 'export',
                            },
                        },
                        {
                            extend: 'csvHtml5',
                            exportOptions: {
                                columns: ':visible:not(.actions-nowrap)',
                                orthogonal: 'export',
                            },
                        },
                        {
                            extend: 'excelHtml5',
                            exportOptions: {
                                columns: ':visible:not(.actions-nowrap)',
                                orthogonal: 'export',
                            },
                        },
                        {
                            extend: 'pdfHtml5',
                            orientation: 'landscape',
                            pageSize: 'A4',
                            download: 'open',
                            exportOptions: {
                                columns: ':visible:not(.actions-nowrap)',
                                orthogonal: 'export',
                            },
                        },
                        {
                            extend: 'print',
                            orientation: 'landscape',
                            exportOptions: {
                                columns: ':visible:not(.actions-nowrap)',
                                orthogonal: 'export',
                            },
                        },
                    ],
                },
                // Condicionalmente agregar el botón Limpiar Filtro si no se está utilizando el SearchBuilder
                ...(searchBuilder != null
                    ? []
                    : [
                          {
                              text: 'Limpiar Filtros',
                              className: 'btn-limpiar-filtros',
                              action: (e: any, dt: any, node: any, config: any) => {
                                  dt.columns().every((index: any) => {
                                      const column = dt.column(index);
                                      const columna = this.columnas[index];
                                      if (!columna['title'] || columna['title'] === this.COLUMNA_ACCIONES) {
                                          return;
                                      }
                                      const input = document.getElementById(
                                          column.footer().children[0].id,
                                      ) as HTMLInputElement;
                                      if (!input) {
                                          return;
                                      }
                                      input.value = '';
                                      if (column.search() !== input.value) {
                                          column.search('');
                                      }
                                  });
                                  const search = document.getElementById(
                                      `${dt.tables().nodes().to$().attr('id')}_filter`,
                                  );
                                  const input = search?.getElementsByTagName('input')[0] as HTMLInputElement;
                                  input.value = '';
                                  dt.search('');
                                  dt.ajax.reload();
                              },
                          },
                      ]),
                {
                    text: 'Recargar',
                    className: 'btn-recargar',
                    action: (e: any, dt: any, node: any, config: any) => {
                        dt.ajax.reload();
                    },
                },
            ],
            searchBuilder: {
                ...searchBuilder,
                columns: searchBuilderColumns,
            },
            // TODO: implementar ordenamiento basado opcionalmente en el nombre de la columna
            order: [...(this.opciones.order || [0, 'asc'])],
            lengthMenu: [
                [10, 25, 50, 100, -1],
                [10, 25, 50, 100, 'Todos'],
            ],
            language: {
                ...language_ES_ES,
                decimal: ',',
                thousands: '.',
                searchBuilder: {
                    ...language_ES_ES.searchBuilder,
                    title: { 0: 'Búsqueda Avanzada', _: 'Búsqueda Avanzada (%d)' },
                    valueJoiner: 'y',
                },
            },
        };
    }

    public inicializar() {
        this.dtTrigger.next(this.dtOptions);
        this.init.emit();
    }

    public clickAccion(event: AccionTabla) {
        this.accionHandler.emit(event);
    }

    public listaColumnas() {
        return this.columnas.filter((columna) => columna.visible !== false);
    }

    public recargar() {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.ajax.reload();
        });
    }

    public habilitarBoton(nombre: string, habilitar = true) {
        const botonIndex = this.botones.findIndex((boton) => boton.name === nombre);
        if (botonIndex < 0) {
            return;
        }
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            const botonDataTable = (dtInstance as any).buttons(botonIndex);
            if (botonDataTable) {
                botonDataTable.enable(habilitar);
            }
        });
    }

    public validar(validar: (tabla?: DataTableDirective) => boolean) {
        // Agregar la clase 'is-invalid' al elemento con la clase 'table-container' hijo de la tabla si la validación falla
        const tablaRowContainer = document.querySelector(`#${this.tablaId}_wrapper .table-row-container`);
        const tablaContainer = document.querySelector(`#${this.tablaId}_wrapper .table-container`);
        if (!tablaRowContainer || !tablaContainer) {
            return;
        }
        if (validar(this.dtElement) === true) {
            tablaRowContainer.classList.remove('is-invalid');
            tablaContainer.classList.remove('is-invalid');
        } else {
            tablaRowContainer.classList.add('is-invalid');
            tablaContainer.classList.add('is-invalid');
        }
    }

    public async obtenerBusquedaAvanzada() {
        const dtInstance = await this.dtElement.dtInstance;
        const searchBuilder = (dtInstance as any).searchBuilder.getDetails();
        return searchBuilder;
    }

    public async deshabilitarBotones() {
        const dtInstance = await this.dtElement.dtInstance;
        const botones = (dtInstance as any).buttons();
        botones?.disable();
    }

    public async habilitarBotones() {
        const dtInstance = await this.dtElement.dtInstance;
        const botones = (dtInstance as any).buttons();
        botones?.enable();
    }

    private addListenerMulti(element: any, eventNames: string, listener: any) {
        if (!element || !eventNames || !listener) {
            return;
        }
        const events = eventNames.split(' ');
        for (let i = 0, iLen = events.length; i < iLen; i++) {
            element.addEventListener(events[i], listener, false);
        }
    }

    private ajaxAccion(dataTablesParameters: any, callback: any) {
        const event = {
            dataTablesParameters,
            callback,
        };
        this.ajaxHandler.emit(event);
    }

    private calcularAnchoAcciones(): string {
        const anchoMinimo = 0.5;
        const anchoAccion = 2.75;
        const anchoSeparador = 0.75;
        let anchoAccionesPrioritarias = 0;
        let anchoAcciones = 0;
        let anchoAccionesAdicionales = 0;
        if (this.acciones && this.acciones.length) {
            anchoAccionesPrioritarias = this.listaAcciones(-1).length ? anchoAccion + anchoSeparador : 0;
            anchoAcciones += this.listaAcciones(0).length * anchoAccion;
            anchoAccionesAdicionales = this.listaAcciones(1).length ? anchoAccion + anchoSeparador : 0;
        }
        return `${anchoMinimo + anchoAccionesPrioritarias + anchoAcciones + anchoAccionesAdicionales}rem`;
    }

    private listaAcciones(grupo?: number) {
        switch (grupo) {
            case -1:
                return this.acciones.filter((accion) => accion.grupo != null && accion.grupo < 0);
            case 0:
                return this.acciones.filter((accion) => accion.grupo == null || accion.grupo === 0);
            default:
                return this.acciones.filter((accion) => accion.grupo != null && accion.grupo > 0);
        }
    }
}
