<table
    datatable
    [dtOptions]="dtOptions"
    [dtTrigger]="dtTrigger"
    class="table table-sm table-bordered table-striped table-hover align-middle"
>
    <thead *ngIf="listaColumnas()">
        <tr>
            <ng-container *ngFor="let columna of columnas">
                <th [title]="columna.title">
                    <span>{{ columna.title }}</span>
                </th>
            </ng-container>
        </tr>
    </thead>
    <tbody></tbody>
    <tfoot *ngIf="listaColumnas()">
        <tr>
            <ng-container *ngFor="let columna of columnas">
                <th>
                    <span *ngIf="!columna.searchable">{{ columna.title }}</span>
                    <input
                        class="form-control form-control-sm"
                        *ngIf="columna.searchable"
                        id="tabla-{{ tablaId }}-buscar-{{ columna.data }}"
                        type="text"
                        placeholder="{{ columna.title }}"
                    />
                </th>
            </ng-container>
        </tr>
    </tfoot>
</table>

<ng-template #accionNg let-acciones="acciones" let-data="adtData" let-emitter="clickAccion">
    <kratos-accion [acciones]="acciones" [data]="data" (emitter)="emitter($event)"></kratos-accion>
</ng-template>
