<h2 class="mb-3">{{ titulo }}</h2>

<kratos-tabla
    #tabla
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement class="mb-3">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="nombre" class="form-label">Nombre</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Nombre"
                        ngbPopover="Nombre de la moneda."
                    ></i>
                    <input placeholder="Nombre" formControlName="nombre" type="text" class="form-control" id="nombre" />
                    <ng-container *ngFor="let error of formErrores('nombre', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="abreviatura" class="form-label">Abreviatura</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Abreviatura"
                        ngbPopover="Abreviatura de la moneda. Por ejemplo, para el dólar estadounidense, la abreviatura es USD."
                    ></i>
                    <input
                        placeholder="Abreviatura"
                        formControlName="abreviatura"
                        type="text"
                        class="form-control"
                        id="abreviatura"
                    />
                    <ng-container *ngFor="let error of formErrores('abreviatura', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="simbolo" class="form-label">Símbolo</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Símbolo"
                        ngbPopover="Símbolo de la moneda. Por ejemplo, para el dólar estadounidense, el símbolo es $. Se utiliza para visualizar la moneda en los comprobantes."
                    ></i>
                    <input
                        placeholder="Símbolo"
                        formControlName="simbolo"
                        type="text"
                        class="form-control"
                        id="simbolo"
                    />
                    <ng-container *ngFor="let error of formErrores('simbolo', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="cotizacion" class="form-label">Cotización</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Cotización"
                        ngbPopover="Última cotización registrada de la moneda. La cotización es la relación entre la moneda y la moneda de circulación oficial. Por ejemplo, si la moneda es el dólar estadounidense y la moneda de circulación oficial es el peso argentino, la cotización es la cantidad de pesos argentinos que se necesitan para comprar un dólar estadounidense."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">$</span>
                        <input
                            placeholder="Cotización"
                            formControlName="cotizacion"
                            [mask]="mascaraCotizacion"
                            [leadZero]="true"
                            thousandSeparator="."
                            decimalMarker=","
                            separatorLimit="100000000000"
                            [outputTransformFn]="toFixed"
                            class="form-control"
                            id="cotizacion"
                        />
                        <ng-container *ngFor="let error of formErrores('cotizacion', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="col">
                    <label for="codigoAFIP" class="form-label">Código AFIP</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Código AFIP"
                        ngbPopover="Código AFIP de la moneda. Este código es utilizado para la autorización de comprobantes. Se puede obtener en los listados de la AFIP."
                    ></i>
                    <input
                        placeholder="Código AFIP"
                        formControlName="codigoAFIP"
                        type="text"
                        class="form-control"
                        id="codigoAFIP"
                    />
                    <ng-container *ngFor="let error of formErrores('codigoAFIP', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
    <kratos-moneda-cotizacion #monedaCotizacion></kratos-moneda-cotizacion>
</ng-template>
