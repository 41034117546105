import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MigracionTarea } from '@entities/migracion-tarea.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class MigracionTareaService extends BaseService<MigracionTarea> {
    public constructor(http: HttpClient) {
        super('migracion/migracion-tarea', http);
    }
}
