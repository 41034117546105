<h2 class="mb-3">{{ titulo }}</h2>

<kratos-tabla
    #tabla
    [columnas]="columnas"
    [acciones]="acciones"
    [botones]="botones"
    (ajaxHandler)="ajaxHandler($event)"
    (accionHandler)="accionHandler($event)"
></kratos-tabla>

<ng-template #formTemplate>
    <form [formGroup]="form" #formElement>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col">
                    <label for="nombre" class="form-label">Nombre</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Nombre de la empresa"
                        ngbPopover="Nombre de la empresa. No puede estar vacío."
                    ></i>
                    <input
                        placeholder="Nombre de la empresa"
                        formControlName="nombre"
                        type="text"
                        class="form-control"
                        id="nombre"
                    />
                    <ng-container *ngFor="let error of formErrores('nombre', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="numeroDocumento" class="form-label">CUIT</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="CUIT de la empresa"
                        ngbPopover="CUIT de la empresa. No puede estar vacío."
                    ></i>
                    <input
                        placeholder="CUIT"
                        formControlName="numeroDocumento"
                        type="text"
                        class="form-control"
                        id="numeroDocumento"
                    />
                    <ng-container *ngFor="let error of formErrores('numeroDocumento', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="iibb" class="form-label">II.BB.</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="II.BB. de la empresa"
                        ngbPopover="Número de II.BB. Convenio Multilateral de la empresa. Utilizado en la generación de reportes."
                    ></i>
                    <input placeholder="II.BB." formControlName="iibb" type="text" class="form-control" id="iibb" />
                    <ng-container *ngFor="let error of formErrores('iibb', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="inicioActividades" class="form-label">Inicio de Actividades</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Inicio de actividades de la empresa"
                        ngbPopover="Fecha de inicio de actividades de la empresa. Se recomienda ingresar en formato mm/aaaa. Utilizado en la generación de reportes."
                    ></i>
                    <input
                        placeholder="Inicio de Actividades"
                        formControlName="inicioActividades"
                        type="text"
                        class="form-control"
                        id="inicioActividades"
                    />
                    <ng-container *ngFor="let error of formErrores('inicioActividades', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="direccion" class="form-label">Dirección</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Dirección de la empresa"
                        ngbPopover="Dirección de la empresa."
                    ></i>
                    <input
                        placeholder="Dirección"
                        formControlName="direccion"
                        type="text"
                        class="form-control"
                        id="direccion"
                    />
                    <ng-container *ngFor="let error of formErrores('direccion', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
                <div class="col">
                    <label for="telefono" class="form-label">Teléfono</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Teléfono de la empresa"
                        ngbPopover="Teléfono de la empresa. Utilizado en la impresión de documentos."
                    ></i>
                    <input
                        placeholder="Teléfono"
                        formControlName="telefono"
                        type="text"
                        class="form-control"
                        id="telefono"
                    />
                    <ng-container *ngFor="let error of formErrores('telefono', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="email" class="form-label">Email</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Email de la empresa"
                        ngbPopover="Email de contacto de la empresa. Utilizado en la impresión de documentos."
                    ></i>
                    <div class="input-group">
                        <span class="input-group-text">&#64;</span>
                        <input
                            placeholder="Email"
                            formControlName="email"
                            type="email"
                            class="form-control"
                            id="email"
                        />
                        <ng-container *ngFor="let error of formErrores('email', form, formErroresControles)">
                            <div class="invalid-feedback">{{ error }}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="col">
                    <label for="web" class="form-label">Web</label
                    ><i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Web de la empresa"
                        ngbPopover="Web de la empresa. Utilizado en la impresión de documentos."
                    ></i>
                    <input placeholder="Web" formControlName="web" type="text" class="form-control" id="web" />
                    <ng-container *ngFor="let error of formErrores('web', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="usuarios" class="form-label">Usuarios</label>
                    <i
                        *ngIf="getThemeHelpEnabled()"
                        class="instrucciones-pantalla bi bi-question-circle"
                        popoverTitle="Usuarios de la empresa"
                        ngbPopover="Deben ser usuarios válidos, entre las opciones disponibles."
                    ></i>
                    <ng-select
                        [items]="relaciones.usuarios"
                        labelForId="usuarios"
                        [multiple]="true"
                        [searchFn]="buscarUsuario"
                        formControlName="usuarios"
                        class="form-control"
                    >
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <span [ngOptionHighlight]="search">{{ item.nombre }}</span
                            >&NonBreakingSpace; &lt;<small [ngOptionHighlight]="search">{{ item.email }}</small
                            >&gt;
                        </ng-template>
                    </ng-select>
                    <ng-container *ngFor="let error of formErrores('usuarios', form, formErroresControles)">
                        <div class="invalid-feedback">{{ error }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-template>
