import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Idioma } from '@entities/idioma.entity';
import { BaseService } from '@shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class IdiomaService extends BaseService<Idioma> {
    public constructor(http: HttpClient) {
        super('configuracion/idioma', http);
    }
}
