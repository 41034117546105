import { BaseEntity } from '@entities/base.entity';
import { ComprobanteVenta } from '@entities/comprobante-venta.entity';
import { TipoMedioPago } from '@entities/tipo-medio-pago.entity';

export class ComprobanteVentaMedioPago extends BaseEntity {
    public descripcion?: string;
    public importe?: number;
    public comprobanteVenta?: ComprobanteVenta;
    public tipoMedioPago?: TipoMedioPago;

    public constructor(partial: Partial<ComprobanteVentaMedioPago> = {}) {
        super();
        Object.assign(this, partial);
    }
}
